define('impact-admin/controllers/frame/match-report', ['exports', 'ember', 'numeral', 'impact-admin/utils/api', 'impact-admin/models/organization-match'], function (exports, _ember, _numeral, _impactAdminUtilsApi, _impactAdminModelsOrganizationMatch) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports['default'] = _ember['default'].Controller.extend({
        newPledgeName: '',
        newAmount: null,

        ghdYear: '2025',

        manageGhd: _ember['default'].inject.service('manage-ghd'),
        application: _ember['default'].inject.controller(),
        isMatchAvailable: (function () {
            return this.get('manageGhd.isMatchAvailable');
        }).property('application'),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        selectedPledgeID: null,
        formattedMatch: _ember['default'].computed('match', function () {
            var matches = [];
            this.get('match').forEach(function (event) {
                event.set('formattedAmount', event.amount / 100);
                matches.push(event);
            });

            return matches;
        }),

        disableCharityUser: _ember['default'].computed('isImpact', 'isMatchAvailable', function () {
            return !this.get('isImpact') && !this.get('isMatchAvailable');
        }),

        actions: {

            downloadFile: _impactAdminUtilsApi['default'].downloadFile,

            saveProfile: function saveProfile(value) {
                if (value === 'dmfMatch') {
                    console.log(_impactAdminUtilsApi['default']);
                    this.saveDMFMatch().then(function (value) {
                        console.log(value);
                        // expected output: "Success!"
                    });
                }
                if (value === 'scheduledGoal') {
                    this.saveScheduledGoal().then(function (value) {
                        console.log(value);
                        // expected output: "Success!"
                    });
                }
                if (value === 'ghdGoal') {
                    this.saveGHDGoal().then(function (value) {
                        console.log(value);
                        // expected output: "Success!"
                    });
                }
            },

            scrollToRaisedMatch: function scrollToRaisedMatch() {
                //let elmnt = document.getElementById("raisedMatch");
                //elmnt.scrollIntoView();
                var id = 'raisedMatch';
                var yOffset = -85;
                var element = document.getElementById(id);
                var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                window.scrollTo({ top: y, behavior: 'smooth' });
            },

            cancel: function cancel() {
                var model = this.get('model');
                model.revertRecord();

                this.set('editable', false);
            },

            exportMatch: function exportMatch(matchList) {

                var dataToExport = [['Name', 'Amount']];
                matchList.forEach(function (pledge) {

                    var pledgeRow = [pledge.pledge_name, pledge.formattedAmount];
                    dataToExport.push(pledgeRow);
                });

                this.exportArrayToFile(dataToExport);
            },

            addPledge: function addPledge() {
                var _this = this;

                var self = this;
                var newPledgeName = this.get('newPledgeName');
                var newAmount = Math.round((0, _numeral['default'])().unformat(this.get('newAmount')) * 100);
                var org = this.get('model');

                if (!newAmount || !newPledgeName) {
                    this.growl.error('Please enter an amount and a description');
                } else {
                    (function () {
                        var newRecord = _impactAdminModelsOrganizationMatch['default'].createRecord({
                            pledge_name: newPledgeName,
                            amount: newAmount,
                            organization_id: org.id
                        });

                        //this.get('suggestions').pushObject(newRecord);
                        newRecord.save().then(function (json) {
                            if (json.response.error) {
                                _this.growl.error('Could not add suggestion. Please try again.');
                            } else {
                                var oldMatch = [].concat(_toConsumableArray(_this.get('match')));
                                console.log(newRecord);
                                oldMatch.push(newRecord.content);
                                self.set('match', oldMatch);
                                var oldTotal = _this.get('model.raised_match_dollars');
                                _this.set('model.raised_match_dollars', oldTotal + (0, _numeral['default'])().unformat(newRecord.content.amount) / 100);

                                org.set('match_is_complete', parseFloat((org.ghd_goal / 100).toFixed(2)) > 0 && (parseFloat((org.raised_match / 100).toFixed(2)) >= 3000 || org.dmf_match));

                                _this.growl.success('Match Pledge Added');
                                _this.setProperties({
                                    newAmount: 0,
                                    newPledgeName: ''
                                });
                            }
                        });
                    })();
                }
            },

            deletePledge: function deletePledge(pledge) {
                var _this2 = this;

                var self = this;
                pledge.deleteRecord().then(function () {
                    _this2.growl.success('Match Pledge Deleted');
                    var oldMatch = [].concat(_toConsumableArray(self.get('match')));
                    console.log(oldMatch);
                    self.set('match', oldMatch);

                    var oldTotal = self.get('model.raised_match_dollars');
                    self.set('model.raised_match_dollars', oldTotal - (0, _numeral['default'])().unformat(pledge.amount) / 100);

                    _this2.set('selectedPledgeID', null);
                })['catch'](function (reason) {
                    console.log(reason);
                    _this2.growl.error('An error occurred deleting this match entry.');
                });
            },
            updatePledge: function updatePledge(pledge) {
                var _this3 = this;

                var self = this;
                var oldPledgeAmount = pledge.get('amount');
                pledge.set('amount', Math.round((0, _numeral['default'])().unformat(pledge.get('formattedAmount')) * 100));
                pledge.save().then(function () {
                    _this3.growl.success('Match Pledge Updated');
                    var oldMatch = [].concat(_toConsumableArray(self.get('match')));
                    self.set('match', oldMatch);

                    var oldTotal = self.get('model.raised_match_dollars');
                    self.set('model.raised_match_dollars', oldTotal - (0, _numeral['default'])().unformat(oldPledgeAmount) / 100 + (0, _numeral['default'])().unformat(pledge.amount) / 100);

                    var org = self.get('model');
                    self.set('model.match_is_complete', parseFloat((org.ghd_goal / 100).toFixed(2)) > 0 && (parseFloat((org.raised_match / 100).toFixed(2)) >= 3000 || org.dmf_match));

                    _this3.set('selectedPledgeID', null);
                })['catch'](function (reason) {
                    console.log(reason);
                    _this3.growl.error('An error occurred deleting this match entry.');
                });
            },

            editPledge: function editPledge(pledge) {
                console.log(pledge);
                if (pledge) {
                    this.set('updatedAmount', Math.round(pledge.amount / 100));
                    this.set('selectedPledgeID', pledge.id);
                } else {
                    this.set('selectedPledgeID', null);
                }
            }
        },
        exportArrayToFile: function exportArrayToFile(data) {

            /*
                    console.log(array);
                    let csvContent = "data:text/csv;charset=utf-8,"  + array.map(e => e.join(",")).join("\n");
                    console.log(csvContent);
                    let encodedUri = encodeURI(csvContent);
                    window.open(encodedUri);
                }
            */
            var csvContent = '';
            data.forEach(function (infoArray, index) {
                console.log(infoArray);
                var dataString = '"' + infoArray.join('","') + '"';
                csvContent += index < data.length ? dataString + '\n' : dataString;
            });

            // The download function takes a CSV string, the filename and mimeType as parameters
            // Scroll/look down at the bottom of this snippet to see how download is called
            var download = function download(content, fileName, mimeType) {
                var a = document.createElement('a');
                mimeType = mimeType || 'application/octet-stream';

                if (navigator.msSaveBlob) {
                    // IE10
                    navigator.msSaveBlob(new Blob([content], {
                        type: mimeType
                    }), fileName);
                } else if (URL && 'download' in a) {
                    //html5 A[download]
                    a.href = URL.createObjectURL(new Blob([content], {
                        type: mimeType
                    }));
                    a.setAttribute('download', fileName);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
                }
            };
            var currentTime = new Date();

            var timestamp = currentTime.getMonth() + 1 + '-' + currentTime.getDate() + '-' + currentTime.getFullYear();
            download(csvContent, 'Match Pledges ' + timestamp + '.csv', 'text/csv;encoding:utf-8');
        },

        saveGHDGoal: function saveGHDGoal() {
            var _this4 = this;

            if (this.validate()) {
                (function () {
                    var self = _this4;
                    var org = _this4.get('model');

                    org.set('ghd_goal', Math.round((0, _numeral['default'])().unformat(org.get('ghd_goal_dollars')) * 100));

                    self.set('model', org);

                    if (org.isReallyDirty()) {
                        var newRecord = org.save(); //this._super(org);
                        newRecord.then(function (json) {
                            if (json.response.error) {
                                _this4.growl.error('Your GHD goal was not saved. Please try again.');
                                console.log(json.response.error);
                            } else {
                                org.set('match_is_complete', parseFloat((org.ghd_goal / 100).toFixed(2)) > 0 && (parseFloat((org.raised_match / 100).toFixed(2)) >= 3000 || org.dmf_match));
                                _this4.growl.success('GHD goal updated.');
                            }
                        });
                    }
                })();
            }
            return new Promise(function (resolve) {
                console.log("first function executed");
                resolve();
            });
        },
        saveDMFMatch: function saveDMFMatch() {
            var _this5 = this;

            if (this.validate()) {
                (function () {
                    var self = _this5;
                    var org = _this5.get('model');
                    console.log(Math.round((0, _numeral['default'])().unformat(org.get('dmf_match_dollars')) * 100));
                    if (self.get('isImpact')) {
                        console.log(org.get('dmf_match_dollars'));
                        org.set('dmf_match', Math.round((0, _numeral['default'])().unformat(org.get('dmf_match_dollars')) * 100));
                    }

                    self.set('model', org);

                    if (org.isReallyDirty()) {
                        var newRecord = org.save(); //this._super(org);

                        newRecord.then(function (json) {
                            if (json.response.error) {
                                _this5.growl.error('DMF Match did not save. Please try again.');
                                console.log(json.response.error);
                            } else {
                                org.set('match_is_complete', parseFloat((org.ghd_goal / 100).toFixed(2)) > 0 && (parseFloat((org.raised_match / 100).toFixed(2)) >= 3000 || org.dmf_match));
                                _this5.growl.success('DMF Match updated.');
                            }
                        });
                    }
                })();
            }
            return new Promise(function (resolve) {
                console.log("first function executed");
                resolve();
            });
        },

        validate: function validate() {
            return true;
        }

    });
});