define('impact-admin/components/volunteer-opportunity', ['exports', 'ember', 'moment', 'impact-admin/utils/api'], function (exports, _ember, _moment, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({
        editing: false,
        addedSkills: [],
        oldSkills: [],
        selectedSkills: [],
        select_single: false,
        select_group: false,
        select_indiv: false,
        select_family: false,
        select_youth: false,

        setupSkills: (function () {
            if (!this.get('isNew')) {
                // Get the existing skills and push them to the array
                // this.get('opportunity.skills') yeilds a promise, so, since this is a changeset object
                // we have to add '.content' to get the result
                var skills = this.get('opportunity.skills');
                var goodFor = this.get('opportunity.for_group_bit');

                if (goodFor & 1) {
                    this.set('select_single', true);
                }
                if (goodFor & 2) {
                    this.set('select_group', true);
                }
                if (goodFor & 4) {
                    this.set('select_indiv', true);
                }
                if (goodFor & 8) {
                    this.set('select_family', true);
                }
                if (goodFor & 16) {
                    this.set('select_youth', true);
                }

                if (skills) {
                    this.set('selectedSkills', skills);
                    this.set('oldSkills', skills);
                }
            }
        }).on('init'),

        skillsList: _ember['default'].computed('addedSkills.[]', 'skills', function () {
            var skillsList = [];
            var addedSkillsIds = this.get('addedSkills').mapBy('id');
            this.get('skills').forEach(function (skill) {
                var newSkill = {};
                if (_.contains(addedSkillsIds, skill.get('id'))) {
                    newSkill.disabled = true;
                }
                newSkill.skill = skill.get('skill');
                newSkill.id = skill.get('id');
                skillsList.push(newSkill);
            }, this);

            return skillsList;
        }),

        setupDateTime: (function () {
            var opportunity = this.get('opportunity');
            if (opportunity.get('start_date')) {
                this.set('start_date', (0, _moment['default'])(opportunity.get('start_date'), 'YYYY-MM-DD'));
            } else {
                this.set('start_date', null);
            }
            if (opportunity.get('start_time')) {
                this.set('start_time', (0, _moment['default'])(opportunity.get('start_time'), 'HH:mm:ss'));
            } else {
                this.set('start_time', null);
            }
            if (opportunity.get('end_date')) {
                this.set('end_date', (0, _moment['default'])(opportunity.get('end_date'), 'YYYY-MM-DD'));
            } else {
                this.set('end_date', null);
            }
            if (opportunity.get('end_time')) {
                this.set('end_time', (0, _moment['default'])(opportunity.get('end_time'), 'HH:mm:ss'));
            } else {
                this.set('end_time', null);
            }
        }).on('init'),

        setupOngoing: (function () {
            if (this.get('opportunity.ongoing')) {
                this.set('ongoingSelected', 'ongoing');
                this.set('isOngoing', true);
            } else {
                this.set('ongoingSelected', 'event');
                this.set('isOngoing', false);
            }
        }).on('init'),
        setupGroup: (function () {
            if (this.get('opportunity.is_group')) {
                this.set('isGroupSelected', 'group');
            } else {
                this.set('isGroupSelected', 'single');
            }
            this.set('is_group', this.get('opportunity.is_group'));
        }).on('init'),
        actions: {

            consoleLog: function consoleLog() {
                console.log(this);
            },

            updateSelectedSkills: function updateSelectedSkills(newSkills) {
                if (newSkills.length <= 5) {
                    this.set('selectedSkills', newSkills);
                } else {
                    alert('Only 3 types allowed. Please remove one before adding another');
                }
            },
            closeIfFull: function closeIfFull(select) {
                if (this.get('selectedTypes.length') >= 3) {
                    select.actions.close();
                }
            },

            addSkill: function addSkill(newSkill) {
                // let skill = this.get('skillsList').findBy('id', newSkill);
                this.get('addedSkills').pushObject(newSkill);
                //skill.set('disabled', true);
                this.set('newSkill', null);
            },
            removeSkill: function removeSkill(skillId) {
                var skill = this.get('skills').findBy('id', skillId);
                skill.set('disabled', false);
                this.get('addedSkills').removeObject(skill);
            },

            validateProperty: function validateProperty(changeset, property) {
                return changeset.validate(property);
            },
            edit: function edit() {
                this.reset();
                this.setupSkills();
                this.setupOngoing();
                this.setupDateTime();
                this.set('editing', true);
            },
            cancelEdit: function cancelEdit() {
                if (this.get('isNew')) {
                    this.sendAction('cancel');
                } else {
                    this.set('selectedSkills', this.get('oldSkills'));
                    this.set('editing', false);
                }
            },

            ongoingChanged: function ongoingChanged(selection) {
                if (selection === 'ongoing') {
                    this.set('isOngoing', true);
                } else {
                    this.set('isOngoing', false);
                }
            },
            groupChanged: function groupChanged(selection) {
                this.set('is_group', selection === 'group');
            },
            save: function save(opportunity) {
                var _this = this;

                var bit = 0;
                if (this.get('select_single')) {
                    bit = bit + 1;
                }

                if (this.get('select_group')) {
                    bit = bit + 2;
                }
                if (this.get('select_indiv')) {
                    bit = bit + 4;
                }
                if (this.get('select_family')) {
                    bit = bit + 8;
                }
                if (this.get('select_youth')) {
                    bit = bit + 16;
                }

                opportunity.validate().then(function () {
                    if (opportunity.get('isValid')) {
                        opportunity.set('is_group', _this.get('is_group'));

                        opportunity.set('for_group_bit', bit);

                        //Set ongoing
                        if (_this.get('isOngoing')) {
                            opportunity.set('ongoing', true);
                        } else {
                            opportunity.set('ongoing', false);

                            if (['start_date', 'end_date', 'start_time', 'end_time'].some(function (it) {
                                return _ember['default'].isEmpty(_this.get(it));
                            })) {
                                return alert('Scheduled events must have start & end, dates and times');
                            }

                            //Set date and time
                            opportunity.set('start_date', _this.get('start_date').format('MM/DD/YYYY'));
                            opportunity.set('end_date', _this.get('end_date').format('MM/DD/YYYY'));
                            opportunity.set('start_time', _this.get('start_time').format('hh:mm a'));
                            opportunity.set('end_time', _this.get('end_time').format('hh:mm a'));
                        }
                        var addedSkillsIds = _this.get('selectedSkills').mapBy('id');
                        var oldSkillsIds = _this.get('oldSkills').mapBy('id');
                        _this.sendAction('save', opportunity, addedSkillsIds, oldSkillsIds, _this.get('isNew'));
                        _this.set('oldSkills', _this.get('selectedSkills'));
                        _this.set('editing', false);
                        //this.reset();
                    } else {
                            alert('Please fix errors before saving');
                        }
                });
            },
            duplicate: function duplicate(opportunity) {
                this.sendAction('duplicate', opportunity);
            },
            remove: function remove(opportunity) {
                this.get('remove')(opportunity);
            },
            makePublic: function makePublic(opportunity) {
                this.get('makePublic')(opportunity);
            },
            makePrivate: function makePrivate(opportunity) {
                this.get('makePrivate')(opportunity);
            }
        },
        reset: function reset() {
            this.set('addedSkills', []);
            this.set('oldSkills', []);
        },

        changeCategories: function changeCategories(opportunity) {
            var _this2 = this;

            console.log(opportunity);
            console.log(this.get('newSkill'));

            var volunteer_id = this.get('opportunity.id');

            _impactAdminUtilsApi['default'].post('/volunteer/category', { volunteer_id: volunteer_id }, function (json) {
                _this2.set('errors', []);

                if (json.error || json.errors) {
                    if (json.error) {
                        _this2.get('errors').pushObject(json.error);
                    }

                    return;
                }
            }).then(function () {});
        }
    });
});
/* global _ */