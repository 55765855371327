define('impact-admin/controllers/frame/reports/campaigns/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        isProfile: false,
        isCampaigns: true,
        campaign_id: null,

        actions: {
            camp_change: function camp_change(val) {
                if (val.value > 0) {
                    this.set('org_id', val.value);
                    this.controllerFor('admin.profile.campaigns.campaign').set('campaign_id', null);
                    this.transitionToRoute('admin.profile.campaigns.campaign', this.get('org_id'));
                } else {
                    this.transitionToRoute('admin.profile.campaigns');
                }
            },

            transitionToNew: function transitionToNew() {
                this.set("campaign_id", null);
                this.transitionToRoute("admin.profile.campaigns.new");
            }
        }
    });
});