define('impact-admin/components/saved-credit-card', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        isVisa: (function () {
            return "Visa" === this.get('cardBrand');
        }).property('cardBrand'),

        isDiscover: (function () {
            return "Discover" === this.get('cardBrand');
        }).property('cardBrand'),

        isMasterCard: (function () {
            return "MasterCard" === this.get('cardBrand');
        }).property('cardBrand'),

        hasIcon: (function () {
            return this.get('isMasterCard') || this.get('isDiscover') || this.get('isVisa');
        }).property('isMasterCard', 'isDiscover', 'isVisa'),

        actions: {
            remove: function remove() {
                this.get('remove')();
            },

            update: function update() {
                this.get('update')();
            }

        }

    });
});