define('impact-admin/models/volunteer', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/volunteer_time_commitment', 'impact-admin/models/volunteer-opportunity-skills', 'moment'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsVolunteer_time_commitment, _impactAdminModelsVolunteerOpportunitySkills, _moment) {

    /*
     *Users Model
     */
    var Volunteer = _ember['default'].Model.extend({
        organization: _ember['default'].computed('organization_id', function () {
            return _impactAdminModelsOrganizations['default'].find(this.get('organization_id'));
        }),

        time_commitments: _ember['default'].computed('volunteer_time_commitment_id', function () {
            return _impactAdminModelsVolunteer_time_commitment['default'].find(this.get('volunteer_time_commitment_id'));
        }),

        skills: _ember['default'].computed('id', function () {
            if (this.get('id')) {
                return _impactAdminModelsVolunteerOpportunitySkills['default'].find({
                    volunteer_id: this.get('id')
                });
            } else {
                return [];
            }
        }),

        aaaunique: 'VAlue me already',

        event_date: _ember['default'].computed('ongoing', 'start_date', 'end_date', 'start_time', 'end_time', function () {
            if (!this.get('ongoing')) {
                var start = (0, _moment['default'])(this.get('start_date'), 'YYYY-MM-DD');
                var end = (0, _moment['default'])(this.get('end_date'), 'YYYY-MM-DD');

                if (this.get('start_date') === this.get('end_date')) {
                    var date = (0, _moment['default'])(this.get('start_date'), 'YYYY-MM-DD');
                    return date.format('dddd, MMMM, DD, YYYY');
                } else if (start.year() === end.year()) {
                    return start.format('dddd, MMMM, DD') + ' to ' + end.format('dddd, MMMM, DD, YYYY');
                } else {
                    return start.format('dddd, MMMM, DD, YYYY') + ' to ' + end.format('dddd, MMMM, DD, YYYY');
                }
            }
            return null;
        }),

        ongoing_valid: _ember['default'].computed('ongoing', 'time_commitment', function () {
            if (this.get('ongoing')) {
                return !!this.get('time_commitment');
            } else {
                return true;
            }
        }),

        event_valid: _ember['default'].computed('ongoing', 'start_date', 'end_date', 'start_time', 'end_time', function () {
            if (this.get('ongoing')) {
                return true;
            } else {
                return !_ember['default'].isEmpty(this.get('start_date')) && !_ember['default'].isEmpty(this.get('end_date')) && !_ember['default'].isEmpty(this.get('start_time')) && !_ember['default'].isEmpty(this.get('end_time'));
            }
        })
    });

    Volunteer.table = 'volunteer';

    exports['default'] = Volunteer;
});