define('impact-admin/controllers/frame/profile/index', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/utils/multi-select-updater', 'impact-admin/models/organizations-to-types', 'impact-admin/models/organizations-to-locations', 'impact-admin/models/organizations', 'numeral'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminUtilsMultiSelectUpdater, _impactAdminModelsOrganizationsToTypes, _impactAdminModelsOrganizationsToLocations, _impactAdminModelsOrganizations, _numeral) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        has_giving_hearts_day_access: _ember['default'].computed.alias('model.permissions.ghd_access'),
        pdfDocuments: _ember['default'].computed.alias('model.pdfDocuments'),

        operatingBudgets: [{
            label: "Under five-hundred thousand",
            value: "under_five_hundred_thousand"
        }, {
            label: "Over five-hundred thousand",
            value: "over_five_hundred_thousand"
        }],

        checkedLocations: _ember['default'].computed.filterBy('locations', 'isSelected'),

        videoUrl: (function () {
            var video = this.get('model.video');
            var prefix = "//www.youtube.com/embed/";
            if (video) {
                var youtubeid = undefined;
                if (video.match(/youtube/) && !video.match(/^embed/)) {
                    if (video.indexOf("?v=") > 0) {
                        if (video.indexOf("&", video.indexOf("?v=") + 3) > 0) {
                            youtubeid = video.substring(video.indexOf("?v=") + 3, video.indexOf("&", video.indexOf("?v=") + 3));
                        } else {
                            youtubeid = video.substring(video.indexOf("?v=") + 3);
                        }
                        return prefix + youtubeid;
                    }
                } else if (video.match(/youtu.be/)) {
                    youtubeid = video.substring(video.indexOf(".be") + 4);
                    return prefix + youtubeid;
                } else if (video.match(/vimeo/)) {
                    prefix = "//player.vimeo.com/video/";
                    var vimeoid = video.substring(video.indexOf(".com/") + 5);
                    return prefix + vimeoid;
                }
                return video;
            }
            return "";
        }).property('model.video'),

        selectedSection: 'general',

        editable: false,

        isGeneral: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'general';
        }),

        isMedia: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'media';
        }),

        isMission: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'mission';
        }),

        isEditable: _ember['default'].computed('editable', function () {
            return this.get('editable');
        }),

        isComplete: _ember['default'].computed('model.summary', 'model.story', 'locations.length', 'selectedTypes.length', 'model.address_valid', 'model.logo', 'model.name', 'model.operating_budget', 'model.board_members', function () {
            var model = this.get('model');
            var locations = this.get('locations');
            var selectedTypes = this.get('selectedTypes');
            return model.summary && model.story && locations.length && selectedTypes.length && model.address_valid && model.logo && model.name && model.operating_budget && model.board_members;
        }),

        modelWatch: (function () {
            var org = this.get('model');
            this.get('model.summary');
            if (org.street_street && org.street_city && org.street_postal_code && org.street_state) {
                this.set('model.address_valid', true);
            } else {
                this.set('model.address_valid', false);
            }
        }).observes('model', 'model.summary', 'model.story', 'locations.length', 'selectedTypes.length', 'model.address_valid', 'model.street_street', 'model.street_city', 'model.street_postal_code', 'model.street_state', 'model.logo', 'model.name', 'charity'),

        actions: {

            editProfile: function editProfile(editable) {
                this.set('editable', editable);
            },

            selectSection: function selectSection(section) {
                this.set('selectedSection', section);
            },

            setFact: function setFact(fact) {
                var _this2 = this;

                // toggle the fact
                var val = !this.get('model.' + fact);
                this.get('model').set(fact, val);
                this.get('model').save().then(function () {
                    _this2.growl.success('This charity has been updated.');
                })['catch'](function (reason) {
                    console.log(reason);
                    _this2.growl.error('There was an error updating this charity. Please try again.');
                });
            },
            sendActiveRequest: function sendActiveRequest() {
                var _this = this;
                _impactAdminUtilsApi['default'].get('/account/request-active-status', {
                    id: this.get('model.id')
                }, function (json) {
                    console.log(json);
                    if (json.result === 'Success') {
                        _this.growl.success('Request Sent');
                    } else {
                        console.log(json);
                        _this.growl.error('An error occurred while sending request. Please try again.');
                    }
                }, true);
            },
            saveProfile: function saveProfile() {
                this.saveInfo().then(function (value) {
                    console.log(value);
                    // expected output: "Success!"
                });
            },
            cancel: function cancel() {
                var model = this.get('model');
                model.revertRecord();

                var donation_suggestions = this.get('model.donation_suggestions');
                donation_suggestions.forEach(function (suggestion) {
                    suggestion.revertRecord();
                });

                this.set('editable', false);
            }
        },
        normalizeUrl: function normalizeUrl(url, platform) {
            // If the input URL doesn't start with 'https://' or 'http://', add 'https://'
            if (!/^https?:\/\//i.test(url)) {
                url = 'https://' + url;
            }
            // If the input URL doesn't have 'www.', add it after 'https://'
            if (!/^https:\/\/www\./i.test(url)) {
                url = url.replace(/^https:\/\//i, 'https://www.');
            }
            // If the input URL doesn't match '{platform}.com/*', return null (invalid)
            if (!new RegExp('^https?://www\\.' + platform + '\\.com/.*', 'i').test(url)) {
                return null;
            }

            return url;
        },
        saveInfo: function saveInfo() {
            var _this3 = this;

            if (this.validate()) {
                (function () {
                    var self = _this3;
                    var org = _this3.get('model');
                    var allTypes = _this3.get('types');

                    var new_types = _this3.get('selectedTypes').mapBy('id');
                    var old_types = _.pluck(_this3.get('model._clean.types'), 'id'); //_.clone(this.get('orgTypes.content'));
                    // []; //

                    var new_locations = _.pluck(_this3.get('checkedLocations'), 'id');
                    var old_locations = _.pluck(_this3.get('model._clean.locations'), 'id'); //_.clone(this.get('orgTypes.content'));

                    org.set('goal_amount', Math.round((0, _numeral['default'])().unformat(org.get('goal_amount_dollars')) * 100));
                    org.set('ghd_goal', Math.round((0, _numeral['default'])().unformat(org.get('ghd_goal_dollars')) * 100));
                    org.set('raised_match', Math.round((0, _numeral['default'])().unformat(org.get('raised_match_dollars')) * 100));
                    org.set('dmf_match', Math.round((0, _numeral['default'])().unformat(org.get('dmf_match_dollars')) * 100));
                    org.set('board_members', org.get('board_members') === null ? JSON.stringify([]) : Array.isArray(org.get('board_members')) ? JSON.stringify(org.get('board_members')) : org.get('board_members'));

                    if (org.get('website')) {
                        var website_url = org.get('website');
                        var urls = website_url.trim().split(/\s+/);

                        // Check if there are multiple URLs provided
                        if (urls.length > 1) {
                            website_url = null;
                        }
                        var regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/i;
                        // Check if the URL matches the pattern
                        if (regex.test(website_url)) {
                            org.set('website', website_url);
                        } else {
                            _this3.growl.error("Website is not saved, please make sure it's formatted correctly.");
                            org.set('website', self.get('website'));
                        }
                    }
                    //test if facebookURL and twitterURL matches pattern if facebook URL exists
                    if (org.get('facebook_url')) {
                        var facebook_url = _this3.normalizeUrl(org.get('facebook_url'), 'facebook');
                        if (facebook_url) {
                            org.set('facebook_url', facebook_url);
                        } else {
                            _this3.growl.error("Facebook URL is not saved. Please make sure it's correct");
                            //roll back facebook URL
                            org.set('facebook_url', self.get('facebook_url'));
                        }
                    }

                    if (org.get('twitter_url')) {
                        var twitter_url = _this3.normalizeUrl(org.get('twitter_url'), 'twitter');
                        if (twitter_url) {
                            org.set('twitter_url', twitter_url);
                        } else {
                            _this3.growl.error("Twitter URL is not saved. Please make sure it's correct");
                            //roll back twitter URL
                            org.set('twitter_url', self.get('twitter_url'));
                        }
                    }

                    if (org.get('instagram_url')) {
                        var instagram_url = _this3.normalizeUrl(org.get('instagram_url'), 'instagram');
                        if (instagram_url) {
                            org.set('instagram_url', instagram_url);
                        } else {
                            _this3.growl.error("Instagram URL is not saved. Please make sure it's correct");
                            //roll back twitter URL
                            org.set('instagram_url', self.get('instagram_url'));
                        }
                    }

                    if (org.get('linkedin_url')) {
                        var linkedin_url = _this3.normalizeUrl(org.get('linkedin_url'), 'linkedin');
                        if (linkedin_url) {
                            org.set('linkedin_url', linkedin_url);
                        } else {
                            _this3.growl.error("LinkedIn URL is not saved. Please make sure it's correct");
                            //roll back twitter URL
                            org.set('linkedin_url', self.get('linkedin_url'));
                        }
                    }
                    self.set('model', org);

                    if (org.isReallyDirty()) {
                        var newRecord = org.save(); //this._super(org);
                        newRecord.then(function (json) {
                            if (json.response.error) {
                                _this3.growl.error('Your profile changes were not saved. Please try again.');
                                console.log(json.response.error);
                            } else {
                                _this3.growl.success('Changes saved.');

                                (0, _impactAdminUtilsMultiSelectUpdater['default'])(json.record, new_types, old_types, _impactAdminModelsOrganizationsToTypes['default'], 'type_id', 'organization_id', function () {
                                    org._clean.types = self.get('model.typeObjects.content');
                                });

                                (0, _impactAdminUtilsMultiSelectUpdater['default'])(json.record, new_locations, old_locations, _impactAdminModelsOrganizationsToLocations['default'], 'location_id', 'organization_id');

                                //Retrieving the org again from the db
                                _impactAdminModelsOrganizations['default'].find(org.get('id')).then(function (json) {
                                    console.log(json);
                                    if (json && !json.error) {
                                        self.set('model', json);
                                    }
                                });
                            }
                        });
                    } else {
                        if (org.id) {

                            (0, _impactAdminUtilsMultiSelectUpdater['default'])(org, new_types, old_types, _impactAdminModelsOrganizationsToTypes['default'], 'type_id', 'organization_id', function () {
                                org._clean.types = self.get('model.typeObjects.content');
                            });

                            (0, _impactAdminUtilsMultiSelectUpdater['default'])(org, new_locations, old_locations, _impactAdminModelsOrganizationsToLocations['default'], 'location_id', 'organization_id', function () {
                                org._clean.locations = self.get('model.locationObjects.content');
                            });
                        }
                    }
                    var clentModelTypes = [];
                    for (var i = 0; i < allTypes.length; i++) {
                        for (var j = 0; j < new_types.length; j++) {
                            if (allTypes[i].id === new_types[j]) {
                                clentModelTypes.push(allTypes[i]);
                            }
                        }
                    }

                    _this3.model.set('types', clentModelTypes);
                })();
            }
            this.set('editable', false);
            return new Promise(function (resolve) {
                console.log("first function executed");
                resolve();
            });
        },

        validate: function validate() {
            return true;
        }

    });
});
/* global _ */