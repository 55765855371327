define('impact-admin/components/checkbox-limit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Checkbox.extend({
        //make sure that only 'checkLimit' number of checkboxes are checked.
        click: function click(evt) {
            var checkLimit = this.get('checkLimit');
            var checkedThings = this.get('checkedThings');

            if (!this.get('checked') && checkedThings.length >= checkLimit) {
                evt.stopPropagation();
                evt.preventDefault();
                this.set('checked', false);
                return false;
            }
            return true;
        }
    });
});