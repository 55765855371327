define('impact-admin/controllers/frame/prepaid-codes/prepaid-code-group', ['exports', 'ember', 'impact-admin/utils/api', 'numeral', 'moment', 'impact-admin/models/prepaid-code-groups'], function (exports, _ember, _impactAdminUtilsApi, _numeral, _moment, _impactAdminModelsPrepaidCodeGroups) {
    exports['default'] = _ember['default'].Controller.extend({
        averageValuePerCard: _ember['default'].computed('model', function () {
            var prepaidGroup = this.get('model').prepaidCodeGroup;
            return prepaidGroup.original_balance / prepaidGroup.count_of_prepaid_codes;
        }),
        loading: false,
        selectedOrg: _ember['default'].computed('model', function () {
            var prepaidGroup = this.get('model').prepaidCodeGroup;
            if (prepaidGroup.overage_org_id) {
                var organizations = this.get('model').organizations;
                var org = organizations.find(function (org) {
                    return org.id === prepaidGroup.overage_org_id;
                });
                return org || {};
            } else {
                return {};
            }
        }),
        issuedDate: _ember['default'].computed('model', function () {
            var prepaidGroup = this.get('model').prepaidCodeGroup;
            if (prepaidGroup.date_issued) {
                return (0, _moment['default'])(prepaidGroup.date_issued).format('LLL');
            } else {
                return '';
            }
        }),
        charities: _ember['default'].computed('model', function () {
            var charities = [];
            var organizations = this.get('model').organizations;
            organizations.forEach(function (charity) {
                charities.push(charity);
            });
            charities.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            charities.unshift({ id: 0, name: 'None' });
            return charities;
        }),
        hasRemainingBalance: _ember['default'].computed('model', function () {
            return this.get('model').prepaidCodeGroup.remaining_balance > 0;
        }),

        fulfilled_by: _ember['default'].computed('model.prepaidCodeGroup', function () {
            return this.get('model.prepaidCodeGroup.fulfilled_by');
        }),

        verified_by: _ember['default'].computed('model.prepaidCodeGroup', function () {
            return this.get('model.prepaidCodeGroup.verified_by');
        }),

        date_fulfilled: _ember['default'].computed('model.prepaidCodeGroup', function () {
            var date = this.get('model.prepaidCodeGroup.date_fulfilled');
            if (date) {
                return (0, _moment['default'])(date);
            }
            return;
        }),

        payment_received: _ember['default'].computed('model.prepaidCodeGroup', function () {
            return this.get('model.prepaidCodeGroup.payment_received');
        }),

        actions: {
            updateFulfillment: function updateFulfillment() {
                var _this = this;

                var selectedOrg = this.get('selectedOrg');

                var _getProperties = this.getProperties('fulfilled_by', 'verified_by', 'date_fulfilled', 'payment_received');

                var fulfilled_by = _getProperties.fulfilled_by;
                var verified_by = _getProperties.verified_by;
                var date_fulfilled = _getProperties.date_fulfilled;
                var payment_received = _getProperties.payment_received;

                var prepaidGroup = this.get('model').prepaidCodeGroup;
                _impactAdminModelsPrepaidCodeGroups['default'].find(prepaidGroup.id).then(function (group) {
                    group.set('overage_org_id', selectedOrg.id);
                    group.set('fulfilled_by', fulfilled_by);
                    group.set('verified_by', verified_by);
                    group.set('date_fulfilled', date_fulfilled ? date_fulfilled.toJSON() : null);
                    group.set('payment_received', payment_received);
                    group.save().then(function () {
                        _this.growl.success('Fulfillment info saved');
                    })['catch'](function (reason) {
                        console.log(reason);
                    });
                });
            },
            payout: function payout() {
                var _this2 = this;

                if (this.loading) {
                    return;
                }
                this.set('loading', true);

                var selectedOrg = this.get('selectedOrg');
                var overageOrgId = selectedOrg && selectedOrg.id === 0 ? null : selectedOrg.id;
                var prepaidGroupId = this.get('model').prepaidCodeGroup.id;
                var remainingBalance = (0, _numeral['default'])(this.get('model').prepaidCodeGroup.remaining_balance / 100).format('0,0.00');
                var originalBalance = (0, _numeral['default'])(this.get('model').prepaidCodeGroup.original_balance / 100).format('0,0.00');
                var message = 'This will payout the prepaid code group to donated organizations and close the group. ';
                if (remainingBalance <= 0) {
                    message += '$' + originalBalance + ' will be charged to DMF\'s bank account.';
                } else if (overageOrgId) {
                    message += 'The remaining balance of $' + remainingBalance + ' will be donated to ' + this.get('selectedOrg').name + '. A total of $' + originalBalance + ' will be charged to DMF\'s bank account.';
                } else {
                    var donatedAmount = (0, _numeral['default'])((this.get('model').prepaidCodeGroup.original_balance - this.get('model').prepaidCodeGroup.remaining_balance) / 100).format('0,0.00');
                    message += 'The remaining balance of $' + remainingBalance + ' will not be donated to any organization. A total of $' + donatedAmount + ' will be charged to DMF\'s bank account.';
                }

                if (!window.confirm(message)) {
                    this.set('loading', false);
                    return false;
                }

                _impactAdminUtilsApi['default'].post('/prepaid_code_groups/' + prepaidGroupId + '/payout', { overageOrgId: overageOrgId }, function (res) {
                    _this2.set('loading', false);

                    if (res.error) {
                        _this2.growl.error(res.error);
                        return;
                    }

                    _this2.growl.success('Prepaid group successfully paid out!');
                }, true);
            },

            orderReady: function orderReady() {
                var _this3 = this;

                var self = this;
                if (this.loading) {
                    return;
                }
                this.set('loading', true);
                var prepaidGroupId = this.get('model').prepaidCodeGroup.id;
                var customerEmail = undefined;
                if (this.get('model').prepaidCodeGroup.transaction && this.get('model').prepaidCodeGroup.transaction.email) {
                    customerEmail = this.get('model').prepaidCodeGroup.transaction.email;
                } else if (this.get('model').prepaidCodeGroup.purchaser_email) {
                    customerEmail = this.get('model').prepaidCodeGroup.purchaser_email;
                } else {
                    this.growl.warning('No valid email');
                    return;
                }

                _impactAdminUtilsApi['default'].post('/prepaid_code_groups/' + prepaidGroupId + '/order_ready', { customerEmail: customerEmail }, function (res) {
                    _this3.set('loading', false);

                    if (res.error) {
                        _this3.growl.error(res.error);
                        return;
                    }

                    self.set('model.prepaidCodeGroup.last_reminder_sent', res.result.last_reminder_sent);
                    _this3.growl.success('Email Successfully Sent');
                }, true);
            }
        }
    });
});