define('impact-admin/routes/application', ['exports', 'ember', 'impact-admin/utils/token', 'impact-admin/utils/api', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminUtilsToken, _impactAdminUtilsApi, _impactAdminConfigEnvironment) {
    var Route = _ember['default'].Route;
    var service = _ember['default'].inject.service;
    var get = _ember['default'].get;

    var ApplicationRoute = Route.extend({
        auth: service(),
        router: service(),
        title: _impactAdminConfigEnvironment['default'].appName,

        constructor: function constructor() {
            this._super.apply(this, arguments);
        },

        scrollTo: function scrollTo(anchorId) {
            _ember['default'].$('html,body').animate({
                scrollTop: $('#' + anchorId).offset().top
            }, 'slow');
            //}
        },

        model: function model() {
            if (_impactAdminUtilsToken['default'].get() || get(this, 'auth.isAuthenticated')) {
                return _impactAdminUtilsApi['default'].get('/admin_users/me', {}, function () {}, true);
            } else {
                return null;
            }
        },

        beforeModel: function beforeModel() {
            var _this = this;

            if (get(this, 'auth.isAuthenticated')) {
                return;
            }

            return get(this, 'auth').handleAuthentication().then(function () {
                if (get(_this, 'auth.isAuthenticated')) {
                    _this.transitionTo('frame.dashboard');
                }
            });
        },

        afterModel: function afterModel(user) {
            if (!user) {
                //No token
                return null;
            } else {
                if (user.error) {
                    this.controllerFor('application').set('user', null);
                    _impactAdminUtilsToken['default'].set(false);
                    _impactAdminUtilsToken['default'].setAuth0Tokens({});
                    simpleStorage.set('user', null);
                    simpleStorage.set('token', null);
                    this.transitionTo('logout');
                    this.growl.info('Session expired. Please log in again.');
                    window.alert('Your session has expired. Please login again.');
                } else if (user.result) {
                    //Logout if they are not impact or an org
                    if (user.result[0].type !== 'impact' && user.result[0].type !== 'organization' && user.result[0].type !== 'organization-donation' && user.result[0].type !== 'organization-volunteer') {
                        this.controllerFor('application').set('user', null);
                        _impactAdminUtilsToken['default'].set(false);
                        _impactAdminUtilsToken['default'].setAuth0Tokens({});
                        simpleStorage.set('user', null);
                        simpleStorage.set('token', null);
                        this.transitionTo('logout');
                        this.growl.info('Session expired. Please log in again.');
                    } else {
                        //This seems so wrong -- The model is the userObj, but also the controller.user...
                        var userObj = _ember['default'].Object.create(user.result[0]);
                        this.controllerFor('application').set('user', userObj);
                        return userObj;
                    }
                }
            }
        },

        actions: {
            willTransition: function willTransition() {
                var auth = this.get('auth');
                if (!auth.isExpired()) {
                    return;
                } else {
                    this.controllerFor('application').set('user', null);
                    _impactAdminUtilsToken['default'].set(false);
                    _impactAdminUtilsToken['default'].setAuth0Tokens({});
                    simpleStorage.set('user', null);
                    simpleStorage.set('token', null);
                    this.transitionTo('logout');
                    this.growl.info('Session expired. Please log in again.');
                    window.alert('Your session has expired. Please login again.');
                }
            }
        }
    });

    exports['default'] = ApplicationRoute;
});
/* globals $, simpleStorage */