define('impact-admin/routes/frame/reports/scheduled-donations', ['exports', 'ember', 'impact-admin/models/scheduled-donations', 'impact-admin/models/scheduled-transactions', 'impact-admin/mixins/table', 'moment'], function (exports, _ember, _impactAdminModelsScheduledDonations, _impactAdminModelsScheduledTransactions, _impactAdminMixinsTable, _moment) {

  var AdminReportsScheduledDonationsRoute = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      //Only impact is allowed in here
      if (!this.controllerFor('application').get('isImpact')) {
        this.transitionTo('frame.dashboard');
      }
    },

    model: function model() {
      return _ember['default'].RSVP.hash({
        donations: _impactAdminModelsScheduledDonations['default'].find(),
        transactions: _impactAdminModelsScheduledTransactions['default'].find({
          status: 'scheduled'
        })
      });
    },

    setupController: function setupController(controller, model) {
      _impactAdminMixinsTable['default'].apply(controller);

      model.donations.forEach(function (trans) {
        return trans.set('date_created', (0, _moment['default'])(trans.get('date_created'), 'YYYY-MM-DD').add(1, 'months').format('YYYY-MM-DD'));
      });

      controller.set('donations', model.donations);
      controller.set('transactions', model.transactions);
    }
  });

  exports['default'] = AdminReportsScheduledDonationsRoute;
});