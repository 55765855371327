define('impact-admin/components/credit-card-form', ['exports', 'ember'], function (exports, _ember) {
    /* global Stripe */
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            submit: function submit() {
                //Need to do this if the form is auto filled
                this.set('expirationDate', this.get('expirationDate').replace(/\D/g, ''));

                if (Stripe.card.cardType(this.get('cardNumber')) === 'American Express') {
                    this.growl.error('Because of the higher transaction rates, we do not take American Express cards. Please use a different card.');
                    return;
                }

                var card = {
                    name: this.get('cardName'),
                    number: this.get('cardNumber'),
                    exp_month: this.get('expirationDate').substring(0, 2),
                    exp_year: this.get('expirationDate').slice(2),
                    cvc: this.get('cvc')
                };
                this.get('onSubmit')(card);
            },
            cancel: function cancel() {
                this.get('cancel')();
            }
        }
    });
});