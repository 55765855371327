define('impact-admin/controllers/frame/tools/index', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({

        organzation_full_report_url: (function () {
            return '/account/payall';
        }).property('model'),

        actions: {

            downloadFile: function downloadFile(url, filename, fileType) {
                var _this = this;

                var confirmCharge = prompt("You are about to charge all Charities for 2024, please type \"confirm\" to proceed.");

                if (confirmCharge === "confirm") {
                    (function () {
                        var self = _this;
                        _this.set('chargingOrgs', true);
                        var cb = function cb(data) {
                            console.log(data);
                            var blob = new Blob([data]);
                            if (window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveBlob(blob, filename + '_' + new Date().toDateString() + '.' + fileType);
                            } else {
                                var a = window.document.createElement('a');
                                a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
                                a.download = filename + '_' + new Date().toDateString() + '.' + fileType;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                            }

                            self.set('chargingOrgs', false);
                        };
                        _impactAdminUtilsApi['default'].post(url, {}, cb, true, false, 'text');
                    })();
                } else {
                    alert("Error: Incorrect input");
                }
            },

            resendReceipts: function resendReceipts(email, adminEmail, specificTransaction) {
                console.log(email);
                if (email || specificTransaction) {
                    _impactAdminUtilsApi['default'].post('/giving/adminresendreceipts', {
                        email: email,
                        admin_email: adminEmail ? adminEmail : '',
                        specific_transaction_id: specificTransaction ? specificTransaction : ''
                    }, function (json) {
                        if (json.error) {
                            alert(json.error);
                            console.log(json.error);
                        } else {
                            alert("All of your donations receipts have been re-emailed to you!");
                        }
                    });
                }
                this.set('email', null);
            },
            clearAllGHDGoals: function clearAllGHDGoals() {
                var _this2 = this;

                var resp = confirm("Clear All GHD Goals?");
                if (!resp) {
                    return;
                }
                var savedOrgs = [];
                this.get('model.organizations').forEach(function (org) {
                    if (org.ghd_goal) {
                        org.set('ghd_goal', 0);
                        savedOrgs.push(org.save());
                    }
                });
                _ember['default'].RSVP.Promise.all(savedOrgs).then(function () {
                    _this2.growl.success(savedOrgs.length + ' DMF Goals Cleared');
                });
            },
            clearAllGHDMatch: function clearAllGHDMatch() {
                var _this3 = this;

                var resp = confirm("Clear All GHD Match?");
                if (!resp) {
                    return;
                }
                var savedOrgs = [];
                this.get('model.organizations').forEach(function (org) {
                    if (org.dmf_match) {
                        org.set('dmf_match', 0);
                        savedOrgs.push(org.save());
                    }
                });
                _ember['default'].RSVP.Promise.all(savedOrgs).then(function () {
                    _this3.growl.success(savedOrgs.length + ' DMF Matches Cleared');
                });
            },
            clearAllRaisedMatch: function clearAllRaisedMatch() {
                var _this4 = this;

                var resp = confirm("Clear All Raised Match?");
                if (!resp) {
                    return;
                }
                var savedOrgs = [];
                this.get('model.organizations').forEach(function (org) {
                    if (org.raised_match) {
                        org.set('raised_match', 0);
                        savedOrgs.push(org.save());
                    }
                });
                _ember['default'].RSVP.Promise.all(savedOrgs).then(function () {
                    _this4.growl.success(savedOrgs.length + ' Raised Matches Cleared');
                });
            },

            cancelAllMemberships: function cancelAllMemberships() {
                var _this5 = this;

                var confirmCharge = prompt("You are about to reset all organizations with a year long membership, please type \"confirm\" to proceed.");

                if (confirmCharge === "confirm") {
                    (function () {
                        //this.set('loading', true);
                        var self = _this5;
                        _this5.get('model.organizations').forEach(function (charity) {
                            console.log(charity.membership_length);
                            console.log(charity);
                            if (charity.membership_length === 'forever') {
                                //console.log('forever');
                                //console.log(charity);
                            } else if (!charity.membership_id) {
                                    //console.log('Membership Not Provided');
                                    //console.log(charity);
                                } else {
                                        var data = {
                                            charity_id: charity.id,
                                            membership_id: charity.membership_id
                                        };
                                        console.log('Cancelled');
                                        self.cancelMembership(data);
                                    }
                        });
                        //location.reload();
                    })();
                } else {
                        alert("Error: Incorrect input");
                    }
            }

        },

        cancelMembership: function cancelMembership(data) {

            var self = this;

            console.log(data);
            _impactAdminUtilsApi['default'].post('/membership/cancel', data, function (json) {
                if (json.error) {
                    console.log(json);
                    self.growl.error('There was an error while processing your request. Please try again.');
                } else {
                    self.growl.success('Your paid subscription has been successfully canceled.');
                    var emberMembership = _ember['default'].Object.create(json);
                    console.log(emberMembership);
                }
                //self.set('loading', false);
            }, true);
        }
    });
});