define('impact-admin/routes/frame/donations-by-donor', ['exports', 'ember', 'impact-admin/mixins/table', 'impact-admin/models/donations'], function (exports, _ember, _impactAdminMixinsTable, _impactAdminModelsDonations) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            var start_date = (Date.now() - 604800000) / 1000;
            var filter = { start_date: { '>=': start_date } };
            var donations = _impactAdminModelsDonations['default'].find(filter, {
                sort: { date: 'desc' },
                limit: 50
            });
            console.log(donations);

            return _ember['default'].RSVP.hash({
                donations: donations
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model.donations);

            if (model.donations.content && model.donations.content.length > 0) {
                var num_donations = model.donations.content[0].full_count;
                controller.set('donations_count', num_donations);
            }

            controller.set('lowerLimit', 0);
            if (model.donations.content && model.donations.content.length < 50) {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', model.donations.content.length);
            } else {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', 50);
            }

            _impactAdminMixinsTable['default'].apply(controller);
        }
    });
});