define('impact-admin/routes/frame/forms/form-builder', ['exports', 'ember', 'impact-admin/models/forms', 'impact-admin/models/form-elements'], function (exports, _ember, _impactAdminModelsForms, _impactAdminModelsFormElements) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(params) {

            var form_id = params.form_id;

            var forms = _impactAdminModelsForms['default'].find({
                id: form_id
            });

            var form_elements = _impactAdminModelsFormElements['default'].find({
                form_id: form_id
            }, {
                sort: {
                    'form_elements.sort_order': 'desc'
                }
            });

            return _ember['default'].RSVP.hash({
                forms: forms,
                form_elements: form_elements
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('form', model.forms.content[0]);
            controller.set('form_elements', model.form_elements.content.sortBy('sort_order'));
        }

    });
});