define('impact-admin/components/goods-tracker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Component.extend({

        dateRange: 8,

        currentTime: _ember['default'].computed('dateRange', function () {
            var dateRange = this.get('dateRange');
            if (!dateRange) {
                return 0;
            }
            return (0, _moment['default'])().subtract(dateRange, 'd').unix();
        }),

        goodsCount: _ember['default'].computed('goods', function () {
            var count = 0;
            this.get('goods').forEach(function (volunteerOpp) {
                if (volunteerOpp) {
                    count += 1;
                }
            });
            return count;
        }),
        goodsPledgesCount: _ember['default'].computed('goods_pledges', 'currentTime', function () {
            var count = 0;
            var dateRange = this.get('currentTime');
            this.get('goods_pledges').forEach(function (submission) {
                if (submission.submitted > dateRange) {
                    count += 1;
                }
            });
            return count;
        })
    });
});