define('impact-admin/models/organization-permissions', ['exports', 'ember', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminConfigEnvironment) {

    var OrganizationPermissions = _ember['default'].Model.extend({
        permissions_list: _ember['default'].computed('ghd_access', 'volunteer_access', 'donate_access', function () {
            var isImpact = _impactAdminConfigEnvironment['default'].isImpactApp;
            var list = [];

            if (isImpact) {
                list.push({
                    label: 'Giving Hearts Day',
                    value: this.get('ghd_access') || false,
                    type: 'ghd_access'
                }, {
                    label: 'Volunteer',
                    value: this.get('volunteer_access') || false,
                    type: 'volunteer_access'
                });
            }
            list.push({
                label: 'Donate Access',
                value: this.get('donate_access') || false,
                type: 'donate_access'
            });

            return list;
        })
    });

    OrganizationPermissions.table = 'organization_permissions';

    exports['default'] = OrganizationPermissions;
});