define('impact-admin/routes/frame/donations', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/donations', 'impact-admin/models/organization-campaigns', 'impact-admin/models/recurring-donations'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsDonations, _impactAdminModelsOrganizationCampaigns, _impactAdminModelsRecurringDonations) {

    // import ScheduledDonations from 'impact-admin/models/scheduled-donations';
    //import ScheduledTransactions from 'impact-admin/models/scheduled-transactions';

    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.donations'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var charity = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var donations = _impactAdminModelsDonations['default'].find({
                organization_id: params.charity_id
            }, //ghd_year: "2022"
            {
                sort: {
                    date: 'desc'
                },
                limit: 50,
                offset: 0
            });

            var campaigns = _impactAdminModelsOrganizationCampaigns['default'].find({
                organization_id: params.charity_id,
                archived: false
            });

            var recurring_donations = _impactAdminModelsRecurringDonations['default'].find({
                organization_id: params.charity_id
            }, {
                sort: {
                    'recurring_donations.id': 'DESC'
                }
            });

            // var scheduled_donations = ScheduledDonations.find({
            //     organization_id: params.charity_id,
            //     ghd_year: '2022',
            //     status: ['scheduled', 'canceled'],
            // }, {
            //     sort: {
            //         date: 'nextGiftDate'
            //     }
            // });

            //var scheduled_transactions = ScheduledTransactions.find();

            return _ember['default'].RSVP.hash({
                charity: charity,
                donations: donations,
                campaigns: campaigns,
                recurring_donations: recurring_donations
            });
        }

    });
});
// scheduled_donations: scheduled_donations,
//scheduled_transactions: scheduled_transactions