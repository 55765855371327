define('impact-admin/models/scheduled-transactions', ['exports', 'ember', 'impact-admin/models/users'], function (exports, _ember, _impactAdminModelsUsers) {

  /*
   * Scheduled Transactions Model
   */
  var ScheduledTransactions = _ember['default'].Model.extend({
    donor: _ember['default'].computed('user_id', function () {
      return _impactAdminModelsUsers['default'].find(this.get('user_id'));
    })
  });

  ScheduledTransactions.table = 'scheduled_transactions';

  exports['default'] = ScheduledTransactions;
});