define('impact-admin/controllers/application', ['exports', 'ember', 'impact-admin/utils/token', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminUtilsToken, _impactAdminConfigEnvironment) {
    /*
     * Main Application Frame
     */
    exports['default'] = _ember['default'].Controller.extend({
        isImpactApp: _impactAdminConfigEnvironment['default'].isImpactApp,
        auth: _ember['default'].inject.service('auth'),
        token: null,
        user: null,
        // As far as I can tell Ember is dumb and allows no inheritance of controllers,
        //models, or components, so We have this list in two places, oh well.
        tiers: [{
            label: 'Master',
            value: 'organization'
        }, {
            label: 'Donation Admin',
            value: 'organization-donation'
        }, {
            label: 'Volunteer Admin',
            value: 'organization-volunteer'
        }],

        reset: function reset() {
            this.set('user', null);
            _impactAdminUtilsToken['default'].set(false);
            _impactAdminUtilsToken['default'].setAuth0Tokens({});
            simpleStorage.set('user', null);
            simpleStorage.set('token', null);
        },

        isImpact: (function () {
            if (this.get('user')) {
                if (this.get('user.type').toUpperCase() === "IMPACT") {
                    return true;
                }
            }
            return false;
        }).property('user.type'),

        userTiers: (function () {
            return this.tiers;
        }).property('user.type'),

        canSeeVolunteerInfo: (function () {
            var type = this.get('user.type');
            return this.mode.is_impact_app && (type === 'organization' || type === 'organization-volunteer' || type === 'impact');
        }).property('user.type'),

        canSeeDonationInfo: (function () {
            var type = this.get('user.type');
            return type === 'organization' || type === 'organization-donation' || type === 'impact';
        }).property('user.type'),

        canSeeAllInfo: (function () {
            var type = this.get('user.type');
            console.log(type);
            return type === 'organization' || type === 'impact';
        }).property('user.type'),

        actions: {
            logout: function logout() {
                this.get('auth').logout();
            }
        }
    });
});
/* globals simpleStorage */