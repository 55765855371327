define('impact-admin/controllers/frame/match-history', ['exports', 'ember', 'impact-admin/models/organization-match', 'impact-admin/helpers/cents-to-dollars', 'impact-admin/helpers/timestamp-to-date'], function (exports, _ember, _impactAdminModelsOrganizationMatch, _impactAdminHelpersCentsToDollars, _impactAdminHelpersTimestampToDate) {
    exports['default'] = _ember['default'].Controller.extend({

        readOnly: true,

        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        //for the history section
        ghd_year: _ember['default'].computed('yearsParticipated', function () {
            return this.get('yearsParticipated')[0];
        }),

        matches: _ember['default'].computed('model', function () {
            return _impactAdminModelsOrganizationMatch['default'].find({
                organization_id: this.get('model').id
            }).content;
        }),

        actions: {

            yearChange: function yearChange(year) {
                this.set('ghd_year', year.target.value);
            },

            downloadCSV: function downloadCSV() {
                var year = this.get('ghd_year');
                var matchesForYear = this.get('matches').filter(function (match) {
                    return match.ghd_year === year;
                });

                var rows = [['Date', 'Pledge Name', 'Amount']];

                matchesForYear.map(function (match, index) {
                    rows[index + 1] = [_impactAdminHelpersTimestampToDate['default'].compute([match.date]).string, match.pledge_name, '$' + _impactAdminHelpersCentsToDollars['default'].compute([match.amount]).string];
                });

                var content = '',
                    newLine = '\r\n';
                for (var i = 0; i < rows.length; i++) {
                    var line = rows[i];
                    var j = 0;
                    for (var x = 0; x < line.length; x++) {
                        var item = line[x];
                        var it = item.replace(/"/g, '""');
                        if (it.search(/("|,|\n)/g) >= 0) {
                            it = '"' + it + '"';
                        }
                        content += (j > 0 ? ',' : '') + it;
                        ++j;
                    }
                    content += newLine;
                }
                var BOM = '﻿';
                var blob = new Blob([BOM + content], { type: 'text/csv;charset=utf-8' });

                var url = window.URL.createObjectURL(blob);
                var linkElem = document.createElement('a');
                linkElem.href = url;
                //set the file name
                linkElem.setAttribute('download', 'ghd_match_' + this.get('ghd_year') + '_' + this.get('model').name);
                linkElem.click();
            }
        }
    });
});