define('impact-admin/controllers/frame/ghdresources', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        selectedSection: 'logos',

        isLogos: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'logos';
        }),

        isMarketingTools: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'marketing';
        }),

        isTrainingMaterial: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'training';
        }),

        isAwards: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'awards';
        }),

        actions: {
            selectSection: function selectSection(section) {
                this.set('selectedSection', section);
            }
        }
    });
});