define('impact-admin/routes/frame/profile/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        setupController: function setupController(controller, model) {
            controller.set('model', model.organization);
            controller.set('locations', model.locations);
            controller.set('pdfDocuments', model.pdfDocuments);
            // there's something goofy with how tm-data saves the organization if we let power-select directly
            // access the organization.types list. not sure if it's buried in tm-data or in the
            // multi-select-updater updater code.
            // in any case, making native java objects of the respective lists works around this issue

            var types = model.types.map(function (t) {
                return { id: t.id, name: t.name };
            });
            controller.set('types', types);
            controller.set('selectedTypes', model.organization.types.map(function (st) {
                return types.find(function (t) {
                    return t.id === st.id;
                });
            }));

            var goodTypes = model.goodTypes.map(function (t) {
                return { id: t.id, category: t.category };
            });

            controller.set('goodTypes', goodTypes);
            controller.set('selectedGoods', model.organization.good_charity_categories.map(function (st) {
                return goodTypes.find(function (t) {
                    return t.id === st.id;
                });
            }));
        }
    });
});