define('impact-admin/components/charity-calendar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        initialAmount: 5,
        visibleAmount: 5,
        limitEventAmountList: _ember['default'].computed('events', 'visibleAmount', function () {
            var matches = [];
            var limit = this.get('visibleAmount');
            var count = 0;
            this.get('events').forEach(function (event) {
                if (count < limit) {
                    matches.push(event);
                }
                count++;
            }, this);

            return matches;
        }),

        actions: {

            seeMoreEvents: function seeMoreEvents(showMore) {
                this.set('visibleAmount', this.get('visibleAmount') + showMore);
            }

        }

    });
});