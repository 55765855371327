define('impact-admin/components/ghd-donation-tracker', ['exports', 'ember', 'numeral'], function (exports, _ember, _numeral) {
    exports['default'] = _ember['default'].Component.extend({

        isEditingGoal: false,

        ghd_goal_dollars: _ember['default'].computed('organization.ghd_goal', function () {
            if (this.get('organization.ghd_goal') && this.get('organization.ghd_goal') > 0) {
                return this.get('organization.ghd_goal') / 100;
            } else {
                return 0;
            }
        }),

        actions: {

            updateGoal: function updateGoal() {
                var _this = this;

                var self = this;
                var org = this.get('organization');

                org.set('ghd_goal', Math.round((0, _numeral['default'])().unformat(this.get('ghd_goal_dollars')) * 100));

                console.log(org);

                if (org.isReallyDirty()) {
                    var newRecord = org.save(); //this._super(org);
                    newRecord.then(function (json) {
                        console.log(json);
                        if (json.response.error) {
                            _this.growl.error('Your GHD goal was not saved. Please try again.');
                            console.log(json.response.error);
                        } else {
                            org.set('match_is_complete', parseFloat((org.ghd_goal / 100).toFixed(2)) > 0 && (parseFloat((org.raised_match / 100).toFixed(2)) >= 3000 || org.dmf_match));
                            _this.growl.success('GHD goal updated.');
                        }
                    });
                }
                return new Promise(function (resolve) {
                    console.log("first function executed");
                    self.set('isEditingGoal', false);
                    resolve();
                });
            },
            editGoal: function editGoal() {
                this.set('isEditingGoal', true); // Enable edit mode
            },

            cancelEdit: function cancelEdit() {
                this.set('isEditingGoal', false); // Cancel edit mode
            }
        }

    });
});