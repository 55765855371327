define('impact-admin/components/charity-list-filters', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    exports['default'] = Component.extend({

        /**
         * Tracking array for dirty filtering object properties
         */
        dirty: _ember['default'].A(),

        /**
         * Default filtering object
         */
        defaults: {
            membership: 'all',
            status: 'all',
            ghdAccess: 'all',
            type: 'all',
            budget: 'all',
            zipcode: "",
            stateName: 'all',
            query: "",
            generalProfileApproved: 'all',
            ghdProfileApproved: 'all',
            charityStatus: 'all'
        },

        stateList: [{ value: 'AL', label: 'AL' }, { value: 'AK', label: 'AK' }, { value: 'AZ', label: 'AZ' }, { value: 'AR', label: 'AR' }, { value: 'CA', label: 'CA' }, { value: 'CO', label: 'CO' }, { value: 'CT', label: 'CT' }, { value: 'DE', label: 'DE' }, { value: 'DC', label: 'DC' }, { value: 'FL', label: 'FL' }, { value: 'GA', label: 'GA' }, { value: 'HI', label: 'HI' }, { value: 'ID', label: 'ID' }, { value: 'IL', label: 'IL' }, { value: 'IN', label: 'IN' }, { value: 'IA', label: 'IA' }, { value: 'KS', label: 'KS' }, { value: 'KY', label: 'KY' }, { value: 'LA', label: 'LA' }, { value: 'ME', label: 'ME' }, { value: 'MD', label: 'MD' }, { value: 'MA', label: 'MA' }, { value: 'MI', label: 'MI' }, { value: 'MN', label: 'MN' }, { value: 'MS', label: 'MS' }, { value: 'MO', label: 'MO' }, { value: 'MT', label: 'MT' }, { value: 'NE', label: 'NE' }, { value: 'NV', label: 'NV' }, { value: 'NH', label: 'NH' }, { value: 'NJ', label: 'NJ' }, { value: 'NM', label: 'NM' }, { value: 'NY', label: 'NY' }, { value: 'NC', label: 'NC' }, { value: 'ND', label: 'ND' }, { value: 'OH', label: 'OH' }, { value: 'OK', label: 'OK' }, { value: 'OR', label: 'OR' }, { value: 'PA', label: 'PA' }, { value: 'RI', label: 'RI' }, { value: 'SC', label: 'SC' }, { value: 'SD', label: 'SD' }, { value: 'TN', label: 'TN' }, { value: 'TX', label: 'TX' }, { value: 'UT', label: 'UT' }, { value: 'VT', label: 'VT' }, { value: 'VA', label: 'VA' }, { value: 'WA', label: 'WA' }, { value: 'WV', label: 'WV' }, { value: 'WI', label: 'WI' }, { value: 'WY', label: 'WY' }],

        /**
         * Sets up instance of component
         */
        didInsertElement: function didInsertElement() {
            this.reset();
        },

        /**
         * Overridden set, passes parameters of set() to this.updateDirty()
         * to make sure the array of dirty properties is consistently up to date.
         *
         * @param keyName
         * @param value
         *
         * @return void
         */
        set: function set(keyName, value) {
            this.updateDirty(keyName, value);
            this._super.apply(this, arguments);
        },

        /**
         * Updates the dirty array if keyName matches a property of this.default. This
         * will only happen if the value is different than the properties current value
         * and different than the default value provided in this.default
         *
         * @param keyName
         * @param value
         *
         * @return void
         */
        updateDirty: function updateDirty(keyName, value) {
            var index = this.get('dirty').indexOf(value);

            if (_.has(this.get('defaults'), keyName)) {
                if (value !== this.get(keyName)) {
                    if (index === -1) {
                        this.get('dirty').pushObject(keyName);
                    }
                } else {
                    this.get('dirty').splice(index, 1);
                }
            }
        },

        /**
         * Resets the current status of the component to its post-init state
         */
        reset: function reset() {
            this.set('dirty', _ember['default'].A());
            this.setProperties(this.defaults);
            this.sendAction('updateFilters', {});
        },

        /**
         * Sets the parameter using this.set() and passes the compiled
         * filter object upward.
         *
         * @param key
         * @param value
         *
         * @return void
         */
        filter: function filter(key, value) {
            this.set(key, value);
            this.sendFilter();
        },

        sendFilter: _.debounce(function () {
            this.sendAction('updateFilters', this.compile());
        }, 200),

        /**
         * Creates a new object based on the keys available in defaults and whether
         * the value of those properties are different than those set in default.
         */
        compile: function compile() {
            var _this = this;

            return _.pick(this.getProperties(_.allKeys(this.defaults)), function (value, key) {
                return value !== _this.defaults[key];
            }) || {};
        },

        /**
         * Computed property testing if the filter object is dirty
         *
         * @return boolean
         */
        isDirty: computed('dirty.[]', function () {
            return this.get('dirty').length > 0;
        }),

        actions: {
            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterQuery: function filterQuery(value) {
                this.filter('query', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterZipcode: function filterZipcode(value) {
                this.filter('zipcode', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterMembership: function filterMembership(value) {
                this.filter('membership', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterGeneralProfileStatus: function filterGeneralProfileStatus(value) {
                this.filter('generalProfileApproved', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterGHDProfileStatus: function filterGHDProfileStatus(value) {
                this.filter('ghdProfileApproved', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterGhdAccess: function filterGhdAccess(value) {
                this.filter('ghdAccess', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterType: function filterType(value) {
                this.filter('type', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterBudget: function filterBudget(value) {
                this.filter('budget', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterState: function filterState(value) {
                this.filter('stateName', value);
            },

            /**
             * Proxy action passes to this.filter()
             *
             * @param value
             *
             * @return void
             */
            filterCharityStatus: function filterCharityStatus(value) {
                this.filter('charityStatus', value);
            },

            /**
             * Proxy action passes to this.reset()
             *
             * @return void
             */
            clearFilters: function clearFilters() {
                this.reset();
            }
        }

    });
});
/* global _ */