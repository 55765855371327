define('impact-admin/routes/frame/donation-receipts', ['exports', 'ember', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsOrganizations) {

    var DonationReceiptsRoute = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.donation-receipts'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var organization = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var hash = {
                organization: organization
            };

            return _ember['default'].RSVP.hash(hash);
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('organization', model.organization);
        }
    });

    exports['default'] = DonationReceiptsRoute;
});