define('impact-admin/models/products', ['exports', 'ember'], function (exports, _ember) {

    var Products = _ember['default'].Model.extend({

        formattedPrice: _ember['default'].computed('price', function () {
            var price = this.get('price');

            if (price && price > 0) {
                return price / 100;
            } else {
                return null;
            }
        })

    });

    Products.table = 'products';

    exports['default'] = Products;
});