define('impact-admin/router', ['exports', 'ember', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminConfigEnvironment) {

    var Router = _ember['default'].Router.extend({
        location: _impactAdminConfigEnvironment['default'].locationType,
        rootURL: _impactAdminConfigEnvironment['default'].rootURL
    });

    Router.map(function () {
        this.route('home', {
            path: '/login'
        });
        this.route('logout', {
            path: '/logout'
        });
        this.route('apply', {
            path: '/apply'
        });
        this.route('contact', {
            path: '/contact'
        });

        this.route('frame', {
            path: '/'
        }, function () {
            this.route('dashboard');
            this.route('donations-by-charity');
            this.route('donations', {
                path: '/donations/:charity_id'
            }, function () {
                this.route('scheduled');
                this.route('recurring');
            });
            this.route('account', {
                path: '/account/:charity_id'
            }, function () {
                this.route('edit');
                this.route('payment-history');
            });
            this.route('service-map');
            this.route('service-map-list');
            this.route('reports');

            this.route('campaigns', {
                path: '/campaigns/:charity_id'
            }, function () {
                this.route('campaign', {
                    path: 'campaign/:campaign_id'
                });
                this.route('new', {
                    path: '/campaign/new'
                });
            });
            this.route('matching-codes', function () {
                this.route('new');
            });
            this.route('change-password');
            this.route('donations-by-donor');
            this.route('add-donation', {
                path: '/donation/add'
            });
            this.route('manage');
            this.route('reports', {
                path: '/reports'
            }, function () {
                this.route('donations-by-type');
                this.route('stripe');
                this.route('recurring-donations');
                this.route('online-prepaid-codes');
                this.route('admin-users');
                this.route('emails');
                this.route('orgInfo');
                this.route('volunteer-opps-report');
            });

            this.route('profile', {
                path: '/profile/:charity_id'
            }, function () {
                this.route('edit');
            });

            this.route('match-report', {
                path: '/match-report/:charity_id'
            });
            this.route('match-history', {
                path: '/match-history/:charity_id'
            });
            this.route('volunteer', {
                path: '/volunteer/:charity_id'
            }, function () {
                this.route('applicants');
            });
            this.route('ghdresources');
            this.route('resources', { path: '/resources/:charity_id' });
            this.route('donors', function () {
                this.route('donor', {
                    path: '/:donor_id'
                });
            });
            this.route('settings');
            this.route('prepaid-codes', function () {
                this.route('new');
                this.route('prepaid-code-group', {
                    path: '/:prepaid_code_group_id'
                });
                this.route('prepaid-code', {
                    path: '/code/:prepaid_code_id'
                });
            });
            this.route('matching-codes', function () {
                this.route('new');
                this.route('matching-code-group', {
                    path: '/:matching_code_group_id'
                });
                this.route('edit', {
                    path: '/edit/:matching_code_group_id'
                });
            });

            this.route('goods', {
                path: '/goods/:charity_id'
            }, function () {
                this.route('applicants');
            });
            this.route('payments', {
                path: '/payments/:charity_id'
            });
            this.route('donation-receipts', {
                path: '/donation-receipts/:charity_id'
            });
            this.route('tools', function () {
                this.route('admin-users');
                this.route('contest-winners');
            });
            this.route('permissions', {
                path: '/permissions/:charity_id'
            });
            this.route('volunteer-applicants', {
                path: '/volunteer-applicants/:charity_id'
            }, function () {});
            this.route('goods-pledges', {
                path: '/good-pledges/:charity_id'
            }, function () {});
            this.route('calendar-events');
            this.route('links');
            this.route('processed-donations', {
                path: '/processed-donations/:charity_id'
            });
            this.route('card-reader');
            this.route('forms', function () {
                this.route('form-builder', {
                    path: '/form-builder/:form_id'
                });
                this.route('submissions', {
                    path: '/submissions/:form_id'
                });
            });
            this.route('form', {
                path: '/form/:form_id'
            });
            this.route('add-charity');
        });
        this.route('export', { path: '/export/:export_id' });
        this.route('scheduled');
    });

    ////You can trigger stuff to happen on every transistion here.
    _ember['default'].Route.reopen({
        activate: function activate() {
            this._super();
            window.scrollTo(0, 0);
        },
        deactivate: function deactivate() {}
    });
    exports['default'] = Router;
});