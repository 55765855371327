define('impact-admin/components/org-donation-row', ['exports', 'ember', 'impact-admin/utils/api', 'moment', 'numeral'], function (exports, _ember, _impactAdminUtilsApi, _moment, _numeral) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',

        toggleInfoPanel: false,
        checkEdit: false,
        showInfoArrows: (function () {
            if (this.get('toggleInfoPanel')) {
                return 'icon-arrow-down';
            } else {
                return 'icon-arrow-right';
            }
        }).property('toggleInfoPanel'),

        editAmount: (function () {
            return this.donation.amount_received / 100;
        }).property('donation.amount_received'),
        editedAmount: 0,

        name: (function () {
            var first = '';
            var last = '';

            if (this.get('donation.transaction_first_name') && this.get('donation.transaction_last_name')) {
                first = this.get('donation.transaction_first_name') ? this.get('donation.transaction_first_name').trim() : '';
                last = this.get('donation.transaction_last_name') ? this.get('donation.transaction_last_name').trim() : '';
                this.set('isTransactionName', true);
            } else {
                first = this.get('donation.first_name') ? this.get('donation.first_name').trim() : '';
                last = this.get('donation.last_name') ? this.get('donation.last_name').trim() : '';
                this.set('isTransactionName', false);
            }

            var codeGroupName = this.get('donation.donor_code_group_name') ? this.get('donation.donor_code_group_name') : '';

            if (first && last) {
                return last + ', ' + first;
            } else if (first) {
                return first;
            } else if (last) {
                return last;
            } else if (codeGroupName) {
                return codeGroupName;
            } else {
                return 'GUEST';
            }
        }).property('donation.last_name', 'donation.first_name', 'donation.transaction_first_name', 'donation.transaction_last_name', 'donation.donor_code_group_name'),

        // Refunds
        confirm_refund: false,
        refund_notes: '',
        sending_refund: false,
        refund_failed: false,

        refund_date: (function () {
            return _moment['default'].unix(this.get('donation.refund_created')).format("MM/DD/YYYY hh:mm:ss A");
        }).property('donation.refund_created'),

        toggleCheckEditForm: function toggleCheckEditForm(id) {
            this.toggleProperty('checkEdit');
            _ember['default'].$('#check-edit-' + id).slideToggle();
        },
        actions: {
            resendReceipts: function resendReceipts(specificTransaction) {
                if (specificTransaction) {
                    _impactAdminUtilsApi['default'].post('/giving/adminresendreceipts', {
                        specific_transaction_id: specificTransaction
                    }, function (json) {
                        if (json.error) {
                            alert(json.error);
                            console.log(json.error);
                        } else {
                            alert("A receipt of transaction #" + specificTransaction + ' has been resent!');
                        }
                    });
                }
                this.set('email', null);
            },

            //Toggles the hidden info associated with this row.
            toggleInfo: function toggleInfo(id) {
                this.toggleProperty('toggleInfoPanel');
                _ember['default'].$('#hidden-info-' + id).slideToggle();
                if (this.get('checkEdit')) {
                    var orig = this.get('originalValues');
                    this.set('donation.first_name', orig.first_name);
                    this.set('donation.last_name', orig.last_name);
                    this.toggleCheckEditForm(id);
                }
            },

            showCheckEdit: function showCheckEdit(id) {
                if (!this.get('checkEdit')) {
                    this.set('originalValues', {
                        first_name: this.donation.first_name,
                        last_name: this.donation.last_name
                    });
                }
                this.toggleCheckEditForm(id);
            },

            cancelEdit: function cancelEdit(id) {
                var orig = this.get('originalValues');
                this.set('donation.first_name', orig.first_name);
                this.set('donation.last_name', orig.last_name);
                this.toggleCheckEditForm(id);
            },

            saveCheck: function saveCheck(id) {
                var self = this;

                var checkUpdate = {
                    donation_id: id,
                    organization_id: this.donation.organization_id,
                    user_id: this.donation.user_id,
                    first_name: this.donation.first_name,
                    last_name: this.donation.last_name,
                    transaction_id: this.donation.transaction_id,
                    amount_received: Math.round((0, _numeral['default'])().unformat(this.editedAmount) * 100),
                    amount: Math.round((0, _numeral['default'])().unformat(this.editedAmount) * 100)
                };

                _impactAdminUtilsApi['default'].post('/giving/donate/edit', checkUpdate, function (json) {
                    if (json.error === undefined) {
                        self.growl.success('Check updated!');
                        self.set('donation.amount_received', checkUpdate.amount_received);
                        self.set('donation.amount', checkUpdate.amount);
                        self.toggleCheckEditForm(id);
                    } else {
                        console.log(json.error);
                        self.get('errors').pushObject(json.error);
                        self.growl.error('Failed to update check.');
                    }
                }, true);
            },

            start_refund: function start_refund() {
                this.set('refund_notes', '');
                this.set('confirm_refund', true);
            },
            do_refund: function do_refund() {
                var self = this;
                this.set('sending_refund', true);

                var donation = this.get('donation');

                _impactAdminUtilsApi['default'].get('/donations/' + donation.get('id') + '/refund/', {
                    note: donation.get('refund_notes')
                }, function (json) {
                    if (json.error) {
                        var message = typeof json.error === 'string' ? json.error : 'Refund failed';
                        self.growl.error(message);
                        self.set('refund_failed', true);
                    } else {
                        self.growl.success(json.result);
                        self.set('sending_refund', false);
                        self.set('donation.refunded', true);
                    }
                }, true);
            },
            cancel_refund: function cancel_refund() {
                this.set('confirm_refund', false);
                this.set('refund_notes', '');
            },
            donor_was_thanked: function donor_was_thanked(id) {

                var self = this;

                var checkUpdate = {
                    donation_id: id,
                    donor_thanked: !self.get('donation.donor_thanked')
                };

                _impactAdminUtilsApi['default'].post('/giving/donate/donor_thanked', checkUpdate, function (json) {
                    if (json.error === undefined) {
                        self.growl.success('Donation record has been updated');
                        self.set('donation.donor_thanked', true);
                    } else {
                        console.log(json.error);
                        self.get('errors').pushObject(json.error);
                        self.growl.error('Failed to update donation record.');
                    }
                }, true);
            }
        }
    });
});