define('impact-admin/routes/frame/campaigns/campaign', ['exports', 'ember', 'impact-admin/models/organization-campaigns'], function (exports, _ember, _impactAdminModelsOrganizationCampaigns) {
    exports['default'] = _ember['default'].Route.extend({
        campaign_id: null,
        model: function model(params) {
            return _impactAdminModelsOrganizationCampaigns['default'].find(params.campaign_id);
        },
        setupController: function setupController(controller, model, transition) {
            controller.set('model', model);

            _ember['default'].run.later(controller, function () {
                this.get('frame.campaigns').set('campaign_id', parseInt(transition.params['frame.campaigns.campaign'].campaign_id, 10));
            }, 500);
        }
    });
});