define('impact-admin/components/form-submission-table', ['exports', 'ember', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Component.extend({

        sortMethod: 'A-Z',

        sortedSubmissions: _ember['default'].computed('submissions', 'sortMethod', function () {
            var unsortedSubmissions = this.get('submissions');
            var sortMethod = this.get('sortMethod');

            if (sortMethod === 'A-Z') {
                return unsortedSubmissions.sortBy('org_name');
            }

            if (sortMethod === 'Z-A') {
                return unsortedSubmissions.sortBy('org_name').reverse();
            }

            if (sortMethod === 'assigned' || sortMethod === 'incomplete' || sortMethod === 'complete') {
                return 'submissions', _.sortBy(unsortedSubmissions.sortBy('org_name').reverse(), function (submission) {
                    return submission.status === sortMethod;
                }).reverse();
            }

            return unsortedSubmissions;
        }),

        actions: {
            grantAdminAccess: function grantAdminAccess(submission) {
                var _this = this;

                submission.set('full_admin_access', true);

                var orgToUpdate = _impactAdminModelsOrganizations['default'].find(submission.organization_id);

                orgToUpdate.set('full_admin_access', true);
                orgToUpdate.save().then(function () {
                    _this.growl.success(submission.org_name + ' has been granted full admin access.');
                })['catch'](function (reason) {
                    console.log(reason);
                });
            },

            toggleCharityReminderEmail: function toggleCharityReminderEmail(submission) {
                var _this2 = this;

                submission.set('reminder_message_enabled', !submission.get('reminder_message_enabled'));
                submission.save().then(function () {
                    _this2.growl.success(submission.org_name + ' has been granted full admin access.');
                })['catch'](function (reason) {
                    console.log(reason);
                });
            },

            deleteAssignment: function deleteAssignment(submission) {
                var _this3 = this;

                if (confirm('Are you sure you want to unassign ' + submission.org_name + ' from the form?')) {
                    submission.deleteRecord().then(function (json) {
                        if (json.response.error) {
                            _this3.growl.error('Could not delete the form assignment. Please try again.');
                        } else {
                            _this3.growl.success('Submission deleted');
                        }
                    });
                }
            },

            deleteOrganization: function deleteOrganization(submission) {
                var _this4 = this;

                var orgToDelete = _impactAdminModelsOrganizations['default'].find(submission.organization_id);
                if (confirm('Are you sure you want to unassign the form from ' + submission.org_name + ' and PERMENANTLY DELETE the organization?')) {
                    submission.deleteRecord().then(function (json) {
                        if (json.response.error) {
                            _this4.growl.error('Could not delete the form assignment. Please try again.');
                        } else {
                            _this4.growl.success('Submission deleted');
                            orgToDelete.deleteRecord().then(function (json) {
                                if (json.response.error) {
                                    _this4.growl.error('Could not delete the orginzation. Please try again.');
                                } else {
                                    _this4.growl.success('Organization deleted');
                                }
                            });
                        }
                    });
                }
            },

            sortBy: function sortBy(order) {
                this.set('sortMethod', order);
            }

        }
    });
});
/* global _ */