define('impact-admin/components/organization-scheduled-donation-table', ['exports', 'ember', 'impact-admin/models/scheduled-donations', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsScheduledDonations, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({
        sortDir: 'desc',
        sortCol: 'date_created',
        start: 1,
        stop: 0,
        //count: 0,
        orgCount: 0,
        isLoading: false,
        ascending: false,
        sortingBy: [],
        sortedScheduledDonations: _ember['default'].computed.sort('scheduled_donations', 'sort'),

        totalPages: _ember['default'].computed('count', 'step', function () {
            return Math.ceil(this.get('count') / this.get('step')) || 1;
        }),

        currentPage: _ember['default'].computed('start', 'totalPages', function () {
            return Math.ceil(this.get('start') / this.get('step'));
        }),

        sort: (function () {
            if (this.get('sortingBy').length > 0) {
                if (!this.get('ascending')) {
                    var sortProperty = this.get('sortingBy')[0];
                    sortProperty = sortProperty + ':desc';
                    return [sortProperty];
                } else {
                    return this.get('sortingBy');
                }
            } else {
                return [];
            }
        }).property('sortingBy', 'ascending'),

        setup: (function () {
            this.set('ascending', false);
            this.set('sortingBy', ['date_created']);
            if (!this.get('count')) {
                this.set('count', this.get('orgCount'));
            }
            if (!this.get('stop')) {
                this.set('stop', this.get('step'));
            }
        }).on('init'),

        actions: {
            showCancelScheduledDonationConfirmation: function showCancelScheduledDonationConfirmation(scheduled_donation) {
                var _this = this;

                if (confirm('Are you sure you would like to cancel this scheduled donation and all other scheduled donations with the transaction id ' + scheduled_donation.scheduled_transactions_id + '?')) {
                    this.set('loading', true);

                    _impactAdminUtilsApi['default'].post('/scheduled_transactions/cancel', {
                        scheduled_transaction_id: scheduled_donation.scheduled_transactions_id,
                        subscription_id: scheduled_donation.subscription_id,
                        customer_id: scheduled_donation.stripe_customer
                    }, function (json) {
                        if (json.error) {
                            _this.growl.error(json.error);
                        } else {
                            _this.growl.success('Your scheduled donation has been canceled.');
                            scheduled_donation.scheduled_transaction.status = 'canceled';
                        }
                        _this.set('loading', false);
                    }, true);
                }
            },

            getNext: function getNext() {
                if (this.get('newerDisabled') || this.get('isLoading')) {
                    return;
                } else {
                    var start = this.get('start') + this.get('step');
                    var _stop = this.get('stop') + this.get('step');

                    this.set('start', start);
                    this.set('stop', _stop);

                    var sort = {};
                    sort[this.get('sortCol')] = this.get('sortDir');

                    this.update_table(sort);
                }
            },
            getLast: function getLast() {
                if (this.get('newerDisabled') || this.get('isLoading')) {
                    return;
                } else {
                    var start = Math.floor(this.get('count') / this.get('step')) * this.get('step') - this.get('step');
                    if (this.get('count') % this.get('step') <= this.get('step')) {
                        start = this.get('count') % this.get('step');
                    }

                    start = this.get('count') - start + 1;
                    var _stop2 = this.get('count');

                    this.set('start', start);
                    this.set('stop', _stop2);

                    var sort = {};
                    sort[this.get('sortCol')] = this.get('sortDir');

                    this.update_table(sort);
                }
            },
            getFirst: function getFirst() {
                if (this.get('olderDisabled') || this.get('isLoading')) {
                    return;
                } else {
                    var start = 1;
                    var _stop3 = this.get('step');

                    this.set('start', start);
                    this.set('stop', _stop3);

                    var sort = {};
                    sort[this.get('sortCol')] = this.get('sortDir');

                    this.update_table(sort);
                }
            },
            getPrev: function getPrev() {
                if (this.get('olderDisabled') || this.get('isLoading')) {
                    return;
                } else {
                    var start = this.get('start') - this.get('step');
                    var _stop4 = this.get('start');

                    this.set('start', start);
                    this.set('stop', _stop4);

                    var sort = {};
                    sort[this.get('sortCol')] = this.get('sortDir');

                    this.update_table(sort);
                }
            },

            sortBy: function sortBy(column) {
                if (this.get('isLoading')) {
                    return;
                }
                var sort = {};
                var sortCol = this.get('sortCol');
                var sortDir = this.get('sortDir');

                this.set('start', 0);
                this.set('stop', this.get('step'));

                if (sortCol !== column) {
                    this.set('sortCol', column);
                    sortCol = column;
                }
                if (sortDir === null || sortDir === 'asc') {
                    this.set('sortDir', 'desc');
                    sortDir = 'desc';
                } else if (sortDir === 'desc') {
                    this.set('sortDir', 'asc');
                    sortDir = 'asc';
                }

                sort[column] = sortDir;
                this.update_table(sort, true);
            }
        },

        update_table: function update_table(sort) {
            var self = this;

            $('html, body').animate({
                scrollTop: this.$('#donations-table').offset().top - 125
            }, 500);

            this.set('isLoading', true);
            this.set('lowerLimit', this.get('start'));

            if (this.get('start') + this.get('step') > this.get('count')) {
                this.set('upperLimit', this.get('count'));
            } else {
                this.set('upperLimit', this.get('start') + this.get('step') - 1);
            }

            // something in tm-data is mucking with the filter and changing the organization_id value, so
            // we'll clone it so that it doesn't change the value in our controller
            var f = $.extend({}, this.get('filter'));

            _impactAdminModelsScheduledDonations['default'].find(f || {}, {
                sort: sort,
                limit: this.get('step'),
                offset: this.get('start')
            }).then(function (result) {
                var scheduled_donations = result;

                scheduled_donations.set('sortProperties', [self.get('sortCol')]);
                scheduled_donations.set('sortAscending', self.get('sortDir') === 'asc');

                self.set('sortingBy', [self.get('sortCol')]);
                self.set('ascending', self.get('sortDir') === 'asc');

                self.set('scheduled_donations', scheduled_donations);
                self.set('isLoading', false);
            });
        },

        olderDisabled: (function () {
            if (this.get('start') <= 1) {
                return true;
            }
            return false;
        }).property('start'),

        newerDisabled: (function () {
            if (this.get('stop') >= this.get('count')) {
                return true;
            }
            return false;
        }).property('stop', 'count'),

        lowerLimit: (function () {
            return this.get('start') + 1;
        }).property('start'),

        upperLimit: (function () {
            if (this.get('stop') >= this.get('count')) {
                return this.get('count');
            }
            return this.get('stop');
        }).property('stop', 'count'),

        reset: function reset() {
            this.set('start', 0);
            var scheduled_donations = this.get('scheduled_donations');
            scheduled_donations.set('sortProperties', ['date_created']);
            scheduled_donations.set('sortAscending', false);
            this.set('text_filter', '');
        },

        observesIsLoading: (function () {
            if (this.$()) {
                if (this.get('controller.isLoading')) {
                    this.$().find('.aj-overlay').fadeIn('slow');
                } else {
                    this.$().find('.aj-overlay').fadeOut(800);
                }
            }
        }).observes('isLoading'),

        observesSortDir: (function () {
            var span = $('<span>');

            span.css('padding', '3px');

            _ember['default'].$('th').find('span').detach();

            if (this.get('ascending')) {
                span.addClass('glyphicon glyphicon-chevron-up');
            } else {
                span.addClass('glyphicon glyphicon-chevron-down');
            }
            switch (this.get('sortingBy')[0]) {
                case 'amount_received':
                    _ember['default'].$("th:contains('Amount')").append(span);
                    break;
                case 'id':
                    _ember['default'].$("th:contains('Donation ID')").append(span);
                    break;
                case 'transaction_id':
                    _ember['default'].$("th:contains('Batch ID')").append(span);
                    break;
                case 'date':
                    _ember['default'].$("th:contains('Date')").append(span);
                    break;
                case 'last_name':
                    _ember['default'].$("th:contains('Name')").append(span);
                    break;
            }
        }).observes('ascending', 'sortingBy.[]')
    });
});
/* global $ */