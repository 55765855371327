define('impact-admin/controllers/frame', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        manageGhd: _ember['default'].inject.service('manage-ghd'),
        isGHD: (function () {
            return this.get('manageGhd.isGHD');
        }).property('application'),
        isMatchAvailable: (function () {
            return this.get('manageGhd.isMatchAvailable');
        }).property('manageGhd.isMatchAvailable'),
        isGHDCheckEntryAvailable: (function () {
            return this.get('manageGhd.isGHDCheckEntryAvailable');
        }).property('application'),
        showGHDDonations: (function () {
            return this.get('manageGhd.showGHDDonations');
        }).property('application'),
        currentGHDYear: (function () {
            return this.get('manageGhd.getCurrentGHDYear');
        }).property('application'),

        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        progressPercentage: _ember['default'].computed('model.have_registered_for_stripe', 'model.membership_active', 'model.profile_complete', 'model.match_is_complete', function () {
            var percentGoal = 0;
            if (this.get('model.have_registered_for_stripe')) {
                percentGoal += 25;
            }
            if (this.get('model.membership_active')) {
                percentGoal += 25;
            }
            if (this.get('model.profile_complete')) {
                percentGoal += 25;
            }
            if (this.get('model.match_is_complete')) {
                percentGoal += 25;
            }

            return percentGoal;
        }),

        isBarrelMap: (function () {
            return this.get('target.currentPath') === "frame.service-map";
        }).property('target.currentPath')
    });
});