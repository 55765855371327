define('impact-admin/controllers/frame/prepaid-codes/prepaid-code', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
  exports['default'] = _ember['default'].Controller.extend({
    recipient_name: _ember['default'].computed('model', function () {
      return this.get('model.recipient_name');
    }),

    recipient_email: _ember['default'].computed('model', function () {
      return this.get('model.recipient_email');
    }),

    recipient_address: _ember['default'].computed('model', function () {
      return this.get('model.recipient_address');
    }),

    recipient_city: _ember['default'].computed('model', function () {
      return this.get('model.recipient_city');
    }),

    recipient_state: _ember['default'].computed('model', function () {
      return this.get('model.recipient_state');
    }),

    recipient_zip: _ember['default'].computed('model', function () {
      return this.get('model.recipient_zip');
    }),

    emailDelivery: (function () {
      return this.get('model.delivery_method') === 'email';
    }).property('model'),

    mailDelivery: (function () {
      return this.get('model.delivery_method') === 'mail';
    }).property('model'),

    pickupDelivery: (function () {
      return this.get('model.delivery_method') === 'pickup';
    }).property('model'),

    actions: {
      savePrepaidCode: function savePrepaidCode() {
        var _this = this;

        var _getProperties = this.getProperties('recipient_name', 'recipient_email', 'recipient_address', 'recipient_city', 'recipient_state', 'recipient_zip');

        var recipient_name = _getProperties.recipient_name;
        var recipient_email = _getProperties.recipient_email;
        var recipient_address = _getProperties.recipient_address;
        var recipient_city = _getProperties.recipient_city;
        var recipient_state = _getProperties.recipient_state;
        var recipient_zip = _getProperties.recipient_zip;

        var code = Object.assign({}, this.get('model'), {
          recipient_name: recipient_name,
          recipient_email: recipient_email,
          recipient_address: recipient_address,
          recipient_city: recipient_city,
          recipient_state: recipient_state,
          recipient_zip: recipient_zip
        });

        var prepaidCode = new Promise(function (resolve) {
          _impactAdminUtilsApi['default'].post('/prepaid_codes/' + code.id, code, function (json) {
            if (json.error) {
              console.error('Failed to update prepaid code. (' + json.error + ')');
              return resolve({});
            }

            resolve(json.data);
          }, true);
        });

        Promise.resolve(prepaidCode).then(function () {
          _this.transitionToRoute('frame.prepaid-codes.prepaid-code-group', _this.get('model.prepaid_code_group_id'));
        })['catch'](function (error) {
          _this.growl.error(error);
          console.log(error);
        });
      }
    }

  });
});