define('impact-admin/routes/frame/forms/submissions', ['exports', 'ember', 'impact-admin/models/forms', 'impact-admin/models/form-submissions', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsForms, _impactAdminModelsFormSubmissions, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(params) {

            var form_id = params.form_id;

            var current_ghd_organizations = _impactAdminModelsOrganizations['default'].find({
                ghd_access: true
            }, {
                sort: {
                    id: 'DESC'
                }
            });

            var forms = _impactAdminModelsForms['default'].find({
                id: form_id
            });

            var form_submissions = _impactAdminModelsFormSubmissions['default'].find({
                form_id: form_id
            });

            return _ember['default'].RSVP.hash({
                forms: forms,
                form_submissions: form_submissions,
                current_ghd_organizations: current_ghd_organizations
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('form', model.forms.content[0]);
            controller.set('form_submissions', model.form_submissions.content.sortBy('org_name').reverse());
            controller.set('current_ghd_organizations', model.current_ghd_organizations.content);
        }

    });
});