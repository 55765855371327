define('impact-admin/models/organization-donation-suggestions', ['exports', 'ember', 'numeral'], function (exports, _ember, _numeral) {

    var OrganizationDonationSuggestions = _ember['default'].Model.extend({
        //The amount is originally in cents
        dollarAmount: _ember['default'].computed('amount', {
            get: function get() {
                return this.get('amount') / 100;
            },
            // setter
            set: function set(key, value) {
                var amount = (0, _numeral['default'])().unformat(value);
                if (amount < 0) {
                    return Math.abs(amount);
                }
                return value;
            }
        })
    });

    OrganizationDonationSuggestions.table = 'organization_donation_suggestions';

    exports['default'] = OrganizationDonationSuggestions;
});