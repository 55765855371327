define('impact-admin/controllers/frame/volunteer/applicants', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        queryParams: ['volunteer_id'],
        volunteer_id: 0,
        sortProperties: ['submitted:desc'],

        sortedModel: _ember['default'].computed.sort('filteredModel', 'sortProperties'),

        filteredModel: _ember['default'].computed('model.submissions.[]', 'volunteer_id', 'searchTerm', function () {
            var _this = this;

            var _getProperties = this.getProperties('searchTerm', 'volunteer_id');

            var searchTerm = _getProperties.searchTerm;
            var volunteer_id = _getProperties.volunteer_id;

            var submissions = this.get('model.submissions');

            submissions = submissions.filterBy('organization_id', parseInt(this.get('model.charity_id'), 10));

            if (volunteer_id) {
                submissions = submissions.filterBy('volunteer_id', volunteer_id);
            }

            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    submissions.forEach(function (submission) {
                        if ((submission.get('first_name') + submission.get('last_name')).toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(submission);
                        }
                    }, _this);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            }

            return submissions;
        }),

        hasFilters: _ember['default'].computed.or('volunteer_id', 'searchTerm'),

        actions: {
            clearFilters: function clearFilters() {
                this.setProperties({
                    searchTerm: null,
                    volunteer_id: 0
                });
            },
            deleteSubmission: function deleteSubmission(submission) {
                var _this2 = this;

                if (confirm('Are you sure you want to delete this submission? \nThis cannot be undone.')) {
                    submission.deleteRecord().then(function () {
                        _this2.growl.success('Submission deleted');
                    })['catch'](function (json) {
                        console.log(json);
                        _this2.growl.error('Could not delete this submissions at this time. Please try again.');
                    });
                }
            }
        }
    });
});