define('impact-admin/routes/export', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/mixins/table'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminMixinsTable) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            var export_id = params.export_id;

            return _ember['default'].RSVP.hash({
                donations: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/donations/guid/' + export_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve donations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                organizations: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/organizations/guid/' + export_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve organizations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                business_name: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/organizations/guid/' + export_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve organizations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.name);
                        }
                    }, true);
                })
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            _impactAdminMixinsTable['default'].apply(controller);
        }
    });
});