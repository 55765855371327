define('impact-admin/routes/frame/reports/admin-users', ['exports', 'ember', 'impact-admin/models/admin-users', 'impact-admin/models/organizations', 'impact-admin/mixins/table'], function (exports, _ember, _impactAdminModelsAdminUsers, _impactAdminModelsOrganizations, _impactAdminMixinsTable) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                users: _impactAdminModelsAdminUsers['default'].find(),
                organizations: _impactAdminModelsOrganizations['default'].find(),
                types: _ember['default'].A([{ id: 'impact', name: 'Impact' }, { id: 'organization', name: 'Charity Master' }, { id: 'organization-donation', name: 'Charity Donation Admin' }, { id: 'organization-volunteer', name: 'Charity Volunteer Admin' }])
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('filteredUsers', model.users);
            controller.set('sortedOrgs', model.organizations.sortBy('name'));
            _impactAdminMixinsTable['default'].apply(controller);
        }
    });
});