define('impact-admin/routes/frame/tools/contest-winners', ['exports', 'ember', 'impact-admin/models/winners', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsWinners, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel() {
            this._super();
        },

        model: function model() {
            return _impactAdminModelsOrganizations['default'].find({
                status: 'active'
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('organizations', model);
            controller.set('model', model);
            _impactAdminModelsWinners['default'].find().then(function (winners) {
                controller.set('winners', winners);
            });
        }
    });
});