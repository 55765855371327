define('impact-admin/initializers/ember-stripe-service', ['exports', 'ember', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.register('config:ember-stripe-service', _impactAdminConfigEnvironment['default'], { instantiate: false });
    application.inject('service:stripe', 'config', 'config:ember-stripe-service');

    if (_impactAdminConfigEnvironment['default'].LOG_STRIPE_SERVICE) {
      _ember['default'].Logger.info('StripeService: initialize');
    }

    if (!_impactAdminConfigEnvironment['default'].stripe.publishableKey) {
      throw new _ember['default'].Error('StripeService: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js');
    }

    Stripe.setPublishableKey(_impactAdminConfigEnvironment['default'].stripe.publishableKey);
  }

  exports['default'] = {
    name: 'ember-stripe-stripe',
    initialize: initialize
  };
});
/* global Stripe */