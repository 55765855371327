define('impact-admin/helpers/match-eq', ['exports', 'ember'], function (exports, _ember) {
    exports.matchEq = matchEq;

    function matchEq(params /*, hash*/) {

        var match = parseInt(params[0]); // type id
        var prev_match = parseInt(params[1] * 100);

        return match === prev_match;
    }

    exports['default'] = _ember['default'].Helper.helper(matchEq);
});