define('impact-admin/controllers/frame/change-password', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        errors: _ember['default'].A(),

        actions: {
            changePassword: function changePassword() {
                var self = this;
                self.set('errors', _ember['default'].A());

                _impactAdminUtilsApi['default'].post("/account/resetPassword", {}, function (res) {
                    if (res.error === undefined) {
                        self.growl.success("Reset Password email sent!");
                    } else {
                        self.get('errors').pushObject(res.error);
                    }
                }, true);
            }
        }
    });
});