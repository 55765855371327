define('impact-admin/controllers/frame/donations/recurring', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        manageGhd: _ember['default'].inject.service(),
        loading: false,
        actions: {
            showCancelRecurringDonationConfirmation: function showCancelRecurringDonationConfirmation(recurring_donation) {
                if (confirm('Are you sure you would like to cancel this recurring donation?')) {
                    var _this = this;
                    this.set('loading', true);

                    _impactAdminUtilsApi['default'].post('/recurring_donations/cancel', {
                        recurring_id: recurring_donation.get('id'),
                        subscription_id: recurring_donation.get('subscription_id'),
                        customer_id: recurring_donation.get('customer_id')
                    }, function (json) {
                        if (json.error) {
                            _this.growl.error(json.error);
                        } else {
                            _this.growl.success('Your recurring donation has been canceled.');
                            recurring_donation.set('status', 'canceled');
                        }
                        _this.set('loading', false);
                    }, true);
                }
            }
        }
    });
});