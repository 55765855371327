define('impact-admin/controllers/frame/reports/campaigns/campaign', ['exports', 'ember', 'impact-admin/models/campaigns', 'moment'], function (exports, _ember, _impactAdminModelsCampaigns, _moment) {
    exports['default'] = _ember['default'].Controller.extend({
        isEditing: false,
        campaign_id: null,

        fancy_start_date: (function () {
            return (0, _moment['default'])(this.get('start_date')).format('MM/DD/YYYY');
        }).property('start_date'),

        fancy_end_date: (function () {
            return (0, _moment['default'])(this.get('end_date')).format('MM/DD/YYYY');
        }).property('end_date'),

        actions: {
            toggleEditing: function toggleEditing() {
                this.set('isEditing', true);
            },
            cancel: function cancel() {
                this.set('isEditing', false);
            },
            save: function save() {
                var self = this;
                var model = _impactAdminModelsCampaigns['default'].find(this.get('id'));
                var update = model.save();
                update.then(function (res) {
                    if (res.response.error === null) {
                        self.set("isEditing", false);
                    } else {
                        self.growl.error("Error Updating Record");
                        console.log("error", res.response.error);
                    }
                });
            }
        }
    });
});