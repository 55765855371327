define('impact-admin/controllers/frame/add-donation', ['exports', 'ember', 'impact-admin/utils/api', 'numeral'], function (exports, _ember, _impactAdminUtilsApi, _numeral) {

    var AdminAddDonationController = _ember['default'].Controller.extend({
        errors: _ember['default'].A(),

        isImpact: _ember['default'].computed.alias('application.isImpact'),
        manageGhd: _ember['default'].inject.service('manage-ghd'),
        application: _ember['default'].inject.controller(),
        isGHDCheckEntryAvailable: (function () {
            return this.get('manageGhd.isGHDCheckEntryAvailable');
        }).property('application'),
        currentGHDYear: (function () {
            return this.get('manageGhd.getCurrentGHDYear');
        }).property('application'),

        transaction_type: 'Check',
        saveDonor: false,
        isGift: false,
        pickUserModalVisible: false,
        matchedUsers: [],
        matchedUserSelected: -1,
        submission_date: null,
        placeAutocompleteRestrictions: {
            country: 'us'
        },
        isGHD: (function () {
            return this.get('manageGhd.isGHD');
        }).property('application'),
        isCheck: function isCheck() {
            return this.transaction_type === 'Check';
        },
        shouldShowNextGHDCheckbox: (function () {
            //return this.get('isCheck') && !this.get('isGHD');
            return !this.get('isGHD') && this.get('isImpact');
        }).property('isCheck', 'isGHD'),
        donateToPlaceholder: (function () {
            return this.get('mode.is_impact_app') ? 'Select Charity' : 'Select Beneficiary';
        }).property('donateToPlaceHolder'),
        types: (function () {
            return this.get('isImpact') ? ['Check', 'Cash', 'Incentives/Doorprizes'] : ['Check'];
        }).property('isImpact'),
        selectedOrg: null,
        sortProps: ['name'],
        sortedCharities: (function () {
            var charities = _ember['default'].A([]);
            var isImpact = this.get('mode.is_impact_app');
            this.get('model').forEach(function (charity) {
                if (charity.ghd_access || !isImpact) {
                    charities.pushObject(charity);
                }
            });
            return charities.sortBy('name');
        }).property('model'), // Ember.computed.sort('model', 'sortProps'),
        matchedUser: (function () {
            var matchedUsers = this.get('matchedUsers');
            var matchedUsersIndex = this.get('matchedUserSelected');
            var useMatchedUser = matchedUsers.length && matchedUsersIndex !== -1;
            return useMatchedUser ? matchedUsers[matchedUsersIndex] : null;
        }).property('matchedUsers', 'matchedUserSelected'),

        setupDateTime: (function () {
            this.set('start_date', null);
            this.set('end_date', null);
        }).on('init'),

        actions: {
            chooseUser: function chooseUser() {
                var matchedUser = this.get('matchedUser');
                if (matchedUser) {
                    this.set('first_name', matchedUser.first_name);
                    this.set('last_name', matchedUser.last_name);
                    this.set('billing_city', matchedUser.billing_city);
                    this.set('billing_state', matchedUser.billing_state);
                    this.set('billing_street', matchedUser.billing_address);
                    this.set('phone', matchedUser.phone);
                    this.set('billing_postal_code', matchedUser.billing_postal_code);
                    this.set('email', matchedUser.email);
                    this.set('business', matchedUser.business);
                }
                this.send('closePickUserModal');
            },
            setSelectedUserIndex: function setSelectedUserIndex(index) {
                this.set('matchedUserSelected', index);
            },
            openPickUserModal: function openPickUserModal() {
                this.set('pickUserModalVisible', true);
            },
            closePickUserModal: function closePickUserModal() {
                this.set('pickUserModalVisible', false);
            },
            placeAutocompleteUpdate: function placeAutocompleteUpdate(result) {
                if (!result || !result.address_components) {
                    return;
                }
                console.log(result);
                var getAddressComponents = function getAddressComponents(address_components, type, shortname) {
                    var permutation = shortname ? 'short_name' : 'long_name';
                    return (address_components.find(function (ac) {
                        return ac.types.includes(type);
                    }) || {})[permutation];
                };
                var street_number = getAddressComponents(result.address_components, 'street_number');
                var route = getAddressComponents(result.address_components, 'route');
                var city = getAddressComponents(result.address_components, 'locality');
                var state = getAddressComponents(result.address_components, 'administrative_area_level_1', true);
                var zip = getAddressComponents(result.address_components, 'postal_code');
                this.set('billing_street', (street_number ? street_number + ' ' : '') + ('' + route));
                this.set('billing_city', city);
                this.set('billing_state', state);
                this.set('billing_postal_code', zip);
                this.send('lookForMatchedUser');
            },
            lookForMatchedUser: function lookForMatchedUser() {
                var self = this;

                var params = {
                    billing_street: this.get('billing_street'),
                    billing_city: this.get('billing_city'),
                    billing_state: this.get('billing_state'),
                    billing_postal_code: this.get('billing_postal_code'),
                    first_name: this.get('first_name'),
                    last_name: this.get('last_name')
                };

                _impactAdminUtilsApi['default'].get('/users/find-by-address', params, function (json) {
                    if (json.error === undefined) {
                        if (json.result.length) {
                            self.set('matchedUsers', json.result);
                            self.set('matchedUserSelected', -1);
                            self.send('openPickUserModal');
                        }
                    } else {
                        console.log(json.error);
                        self.get('errors').pushObject(json.error);
                        self.growl.error('Failed to find user.');
                    }
                }, true);
            },
            donate: function donate() {
                var _this = this;

                if (this.validate()) {
                    (function () {
                        var self = _this;
                        var matchedUser = _this.get('matchedUser');
                        var user = {
                            id: matchedUser ? matchedUser.id : undefined,
                            first_name: _this.get('first_name'),
                            last_name: _this.get('last_name'),
                            email: _this.get('email'),
                            phone: _this.get('phone'),
                            business: _this.get('business')
                        };

                        var transaction = {
                            transaction_type: _this.get('transaction_type'),
                            city: _this.get('billing_city'),
                            state: _this.get('billing_state'),
                            street: _this.get('billing_street'),
                            phone: _this.get('phone'),
                            zip: _this.get('billing_postal_code'),
                            email: _this.get('email')
                        };

                        var donation = {
                            organization_id: _this.get('selectedOrg.id'),
                            amount: Math.round((0, _numeral['default'])().unformat(_this.get('amount')) * 100),
                            amount_received: Math.round((0, _numeral['default'])().unformat(_this.get('amount')) * 100),
                            note: _this.get('note'),
                            is_a_gift: _this.get('isGift'),
                            is_for_ghd: _this.get('isForGhd'),
                            is_for_previous_ghd: _this.get('isForPreviousGhd'),
                            check_number: _this.get('check_number'),
                            soft_credit: _this.get('soft_credit')
                        };

                        //date: this.get('submission_date'),
                        var data = {
                            user: user,
                            transaction: transaction,
                            donation: donation
                        };

                        _impactAdminUtilsApi['default'].post('/giving/donate/manual', data, function (json) {
                            console.log(json);
                            if (json.error === undefined) {
                                self.reset();
                                self.growl.success('Donation added!');
                            } else {
                                console.log(json.error);
                                self.get('errors').pushObject(json.error);
                                self.growl.error('Failed to add donation.');
                            }
                        }, true);
                    })();
                }
            },
            transactionTypeSelected: function transactionTypeSelected(transactionType) {
                this.set('transaction_type', transactionType);
                this.set('isCheck', transactionType === 'Check');
            },
            ghdSelected: function ghdSelected(value) {
                if (value) {
                    this.set('isForGhd', true);
                    this.set('isForPreviousGhd', false);
                } else {
                    this.set('isForGhd', false);
                }
            },
            previousGhdSelected: function previousGhdSelected(value) {
                if (value) {
                    this.set('isForGhd', false);
                    this.set('isForPreviousGhd', true);
                } else {
                    this.set('isForPreviousGhd', false);
                }
            }
        },
        reset: function reset() {
            console.log('Is Impact: ' + this.get('isImpact'));

            //Org is not going to change if they are only a charity admin
            if (this.get('isImpact')) {
                this.set('selectedOrg', null);
            }
            this.set('amount', null);
            this.set('note', '');
            this.set('matchedUsers', []);
            this.set('matchedUserSelected', -1);
            this.set('transaction_type', 'Check');
            if (!this.get('saveDonor')) {
                this.set('business', '');
                this.set('first_name', '');
                this.set('last_name', '');
                this.set('phone', '');
                this.set('billing_city', '');
                this.set('billing_state', '');
                this.set('billing_street', '');
                this.set('billing_postal_code', '');
                this.set('email', '');
                this.set('isGift', false);
            }
        },

        validate: function validate() {
            var org_id = this.get('selectedOrg.id');
            var amount = this.get('amount');
            this.set('errors', _ember['default'].A());
            var errors = this.get('errors');

            if (org_id === undefined || org_id === null || org_id < 0) {
                errors.pushObject('Please select an organization.');
            }

            if (amount === undefined || amount === null || amount < 0) {
                errors.pushObject('Please enter a donation amount.');
            }

            if (this.get('isGift') && !this.get('business')) {
                errors.pushObject('Please enter a business name for the business gift.');
            }

            return errors.length === 0;
        }
    });

    exports['default'] = AdminAddDonationController;
});