define('impact-admin/routes/frame/resources', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminConfigEnvironment) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.resources'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            return _impactAdminModelsOrganizations['default'].find(params.charity_id);
        },

        setupController: function setupController(controller, model) {
            var origin = this.resolveAbsoluteOrigin(),
                charity_id = model.get('id');

            controller.setProperties({
                model: model,
                volunteer_blue: '<a href="%@1/#/charity/%@2"><img src="%@1/images/ghd-volunteer-blue.png" style="display:inline-block;height:50px;margin:15px" /></a>'.fmt(origin, charity_id),
                volunteer: '<a href="%@1/#/charity/%@2"><img src="%@1/images/ghd-volunteer2.png" style="display:inline-block;height:50px;margin:15px" /></a>'.fmt(origin, charity_id),
                donate: '<a href="%@1/#/charity/%@2"><img src="%@1/images/ghd-donate2.png" style="display:inline-block;height:50px;margin:15px" /></a>'.fmt(origin, charity_id)
            });
        },

        resolveAbsoluteOrigin: function resolveAbsoluteOrigin() {
            switch (_impactAdminConfigEnvironment['default'].environment) {
                case "development":
                    return "http://localhost:8000";
                default:
                    return "https://app.givingheartsday.org";
            }
        },

        actions: {
            copied: function copied() {
                this.growl.success('Copied to clipboard');
            }

        }

    });
});