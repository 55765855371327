define('impact-admin/routes/frame/donations-by-charity', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/types', 'impact-admin/mixins/table'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsTypes, _impactAdminMixinsTable) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            var charities = _impactAdminModelsOrganizations['default'].find();
            var types = _impactAdminModelsTypes['default'].find();

            return _ember['default'].RSVP.hash({
                charities: charities,
                types: types
            });
        },

        setupController: function setupController(controller, model) {
            _impactAdminMixinsTable['default'].apply(controller);
            controller.set('model', model.charities);
            controller.set('types', model.types);
        }
    });
});