define('impact-admin/components/good-opportunity', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Component.extend({
        editing: false,
        addedSkills: [],
        oldSkills: [],
        oldName: "",

        //skillsList: Ember.computed('addedSkills.[]', 'skills', function () {
        // let skillsList = [];
        //let addedSkillsIds = this.get('addedSkills').mapBy('id');
        // this.get('skills').forEach(function (skill) {
        //   let newSkill = {};
        //  if (_.contains(addedSkillsIds, skill.get('id'))) {
        //   newSkill.disabled = true;
        // }
        //newSkill.skill = skill.get('skill');
        //newSkill.id = skill.get('id');
        // skillsList.push(newSkill);
        // }, this);

        //  return skillsList;
        // }),

        setupDateTime: (function () {
            var opportunity = this.get('opportunity');
            if (opportunity.get('start_date')) {
                this.set('start_date', (0, _moment['default'])(opportunity.get('start_date'), 'YYYY-MM-DD'));
            } else {
                this.set('start_date', null);
            }
            if (opportunity.get('start_time')) {
                this.set('start_time', (0, _moment['default'])(opportunity.get('start_time'), 'HH:mm:ss'));
            } else {
                this.set('start_time', null);
            }
            if (opportunity.get('end_date')) {
                this.set('end_date', (0, _moment['default'])(opportunity.get('end_date'), 'YYYY-MM-DD'));
            } else {
                this.set('end_date', null);
            }
            if (opportunity.get('end_time')) {
                this.set('end_time', (0, _moment['default'])(opportunity.get('end_time'), 'HH:mm:ss'));
            } else {
                this.set('end_time', null);
            }
        }).on('init'),

        setupOngoing: (function () {
            if (this.get('opportunity.ongoing')) {
                this.set('ongoingSelected', 'ongoing');
                this.set('isOngoing', true);
            } else {
                this.set('ongoingSelected', 'event');
                this.set('isOngoing', false);
            }
        }).on('init'),
        setupGroup: (function () {
            if (this.get('opportunity.is_group')) {
                this.set('isGroupSelected', 'group');
            } else {
                this.set('isGroupSelected', 'single');
            }
            this.set('is_group', this.get('opportunity.is_group'));
        }).on('init'),
        actions: {
            validateProperty: function validateProperty(changeset, property) {
                return changeset.validate(property);
            },
            edit: function edit() {
                this.reset();
                this.setupOngoing();
                this.setupDateTime();
                this.set('oldName', this.get('opportunity.title'));
                this.set('editing', true);
            },
            cancelEdit: function cancelEdit() {
                if (this.get('isNew')) {
                    this.sendAction('cancel');
                } else {
                    this.set('opportunity.title', this.get('oldName'));
                    this.set('editing', false);
                }
            },
            addSkill: function addSkill(newSkill) {
                // let skill = this.get('skillsList').findBy('id', newSkill);
                this.get('addedSkills').pushObject(newSkill);
                //skill.set('disabled', true);
                this.set('newSkill', null);
            },
            removeSkill: function removeSkill(skillId) {
                var skill = this.get('skills').findBy('id', skillId);
                skill.set('disabled', false);
                this.get('addedSkills').removeObject(skill);
            },
            ongoingChanged: function ongoingChanged(selection) {
                if (selection === 'ongoing') {
                    this.set('isOngoing', true);
                } else {
                    this.set('isOngoing', false);
                }
            },
            groupChanged: function groupChanged(selection) {
                this.set('is_group', selection === 'group');
            },
            save: function save(opportunity) {

                this.sendAction('save', opportunity, this.get('isNew'));
                this.set('editing', false);
                this.reset();
                this.sendAction('cancel');
            },
            duplicate: function duplicate(opportunity) {
                this.sendAction('duplicate', opportunity);
            },
            remove: function remove(opportunity) {
                this.get('remove')(opportunity);
            }
        },
        reset: function reset() {
            this.set('addedSkills', []);
            this.set('oldSkills', []);
        }
    });
});