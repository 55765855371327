define('impact-admin/controllers/frame/form', ['exports', 'ember', 'impact-admin/models/form-submission-data'], function (exports, _ember, _impactAdminModelsFormSubmissionData) {
    exports['default'] = _ember['default'].Controller.extend({

        formSubmissionThankYou: false,

        isFormComplete: _ember['default'].computed('form_submission_data.@each.data', function () {

            var numberOfQuestions = 0;
            var answeredQuestions = 0;
            var formIndex = 0;
            var nextRequiredElement = undefined;

            this.get('form_submission_data').forEach(function (submission) {
                if (submission.type !== "header" && submission.type !== "divider" && submission.required) {
                    numberOfQuestions += 1;
                    answeredQuestions += 1;

                    if (submission.type === 'checkbox') {
                        if (submission.data === 'false' || !submission.data) {
                            answeredQuestions -= 1;
                            if (!nextRequiredElement) {
                                nextRequiredElement = 'submissionElement' + formIndex;
                            }
                        }
                    } else if (!submission.data) {
                        answeredQuestions -= 1;
                        if (!nextRequiredElement) {
                            nextRequiredElement = 'submissionElement' + formIndex;
                        }
                    }
                }

                formIndex += 1;
            });

            var formComplete = {
                isComplete: answeredQuestions === numberOfQuestions,
                numberOfQuestions: numberOfQuestions,
                answeredQuestions: answeredQuestions,
                nextRequiredElement: nextRequiredElement
            };

            return formComplete;
        }),

        formIsReadOnly: _ember['default'].computed('form_submission.status', function () {
            return this.get('form_submission.status') === 'complete';
        }),

        actions: {

            printForm: function printForm() {
                window.print();
            },

            scrollToElement: function scrollToElement(anchorId) {
                _ember['default'].$('html,body').animate({
                    scrollTop: $('#' + anchorId).offset().top - 125
                }, 500);
            },

            submitForm: function submitForm() {

                var self = this;
                var org_to_update = this.get('organization');

                self.form_submission_data.forEach(function (element) {

                    if (element.org_field) {
                        org_to_update.set(element.org_field, element.data);
                    }
                });

                self.form_submission.set('status', 'complete');
                if (self.form_submission.isReallyDirty()) {

                    self.form_submission.save().then(function () {
                        self.set('formSubmissionThankYou', true);
                    });
                }
                if (org_to_update.isReallyDirty()) {
                    org_to_update.save();
                }
                var elmnt = document.getElementById("form-title");
                elmnt.scrollIntoView();
            },

            createForm: function createForm() {

                var self = this;

                var user_answers = [];

                self.form_elements.forEach(function (element) {

                    var formSubmissionEntry = {
                        form_id: element.form_id,
                        organization_id: self.form_submission.organization_id,
                        form_element_id: element.id
                    };

                    if (element.org_field) {
                        formSubmissionEntry.data = self.organization[element.org_field];
                    }

                    console.log(formSubmissionEntry);

                    user_answers.push(_impactAdminModelsFormSubmissionData['default'].createRecord(formSubmissionEntry));
                });

                console.log(user_answers);

                var formSubmissionElementPromise = user_answers.map(function (formSubmissionEntry) {
                    if (formSubmissionEntry.isReallyDirty()) {
                        return formSubmissionEntry.save();
                    }
                });

                _ember['default'].RSVP.Promise.all(formSubmissionElementPromise).then(function (result) {
                    var success = result;
                    console.log(success);

                    self.form_submission.set('status', 'incomplete');
                    if (self.form_submission.isReallyDirty()) {
                        self.form_submission.save();
                    }

                    _impactAdminModelsFormSubmissionData['default'].find({
                        form_id: self.form.id,
                        organization_id: self.form_submission.organization_id
                    }).then(function (json) {
                        console.log(json);
                        self.set('form_submission_data', json.content.sortBy('sort_order'));
                    });
                });
            }
        }
    });
});