define('impact-admin/controllers/frame/add-charity', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/models/types'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminModelsTypes) {
    exports['default'] = _ember['default'].Controller.extend({
        alerts: _ember['default'].A(),
        alert_type: '',
        application: _ember['default'].inject.controller(),
        charitySaving: false,
        typeOptions: _impactAdminModelsTypes['default'].find().content,
        DMFLogo: "https://s3.us-west-2.amazonaws.com/uploads.impactgiveback.org/default/53/1f8a60d8f04fa395bf83bac8ff8c67/DMF-logo-for-statements.png",
        ImpactLogo: "https://s3.us-west-2.amazonaws.com/uploads.impactgiveback.org/default/d4/2c2d59aa7747618499744173e6b63a/Impact-logo-for-statements.png",
        selectedImage: '',

        actions: {
            register: function register() {

                var context = this;
                var selectedTypeIDs = context.get('selectedTypes').map(function (type) {
                    return type.id;
                });

                if (this.validate()) {
                    context.set('charitySaving', true);

                    _impactAdminUtilsApi['default'].post('/admin/register', {
                        organization_name: this.get('organization_name'),
                        email: this.get('email'),
                        first_name: this.get('first_name'),
                        last_name: this.get('last_name'),
                        type_ids: selectedTypeIDs,
                        logo: this.get('selectedImage') === 'DMF' ? this.get('DMFLogo') : this.get('selectedImage') === 'Impact' ? this.get('ImpactLogo') : ''
                    }, function (json) {
                        context.set('charitySaving', false);
                        if (json.error === undefined) {
                            this.growl.success('Group successfully added');
                        } else {
                            context.get('alerts').pushObject(json.error);
                        }
                    });
                }
                return false;
            },
            updateSelectedTypes: function updateSelectedTypes(newTypes) {
                if (newTypes.length <= 3) {
                    this.set('selectedTypes', newTypes);
                } else {
                    alert('Only 3 types allowed. Please remove one before adding another');
                }
            },
            setSelectedImage: function setSelectedImage(image) {
                this.set('selectedImage', image);
            },
            clearSelectedImage: function clearSelectedImage() {
                this.set('selectedImage', '');
            }
        },

        validate: function validate() {
            this.set('alerts', _ember['default'].A());
            var err = false;
            if (this.get('email') === undefined || this.get('email') === null || this.get('email') === "" || !new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/).test(this.get('email'))) {
                this.get('alerts').pushObject('Please enter a valid email address.');
                err = true;
            }
            if (this.get('organization_name') === undefined || this.get('organization_name') === null || this.get('organization_name') === "") {
                this.get('alerts').pushObject('Please enter organization name.');
                err = true;
            }
            return !err;
            //return this.get('alerts').length != 0;
        },

        reset: function reset() {
            this.set('email', null);
            this.set('organization_name', null);
            this.set('first_name', null);
            this.set('last_name', null);
        }
    });
});