define('impact-admin/routes/frame/goods', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/good-categories', 'impact-admin/models/good', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsGoodCategories, _impactAdminModelsGood, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.goods'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var charity_id = params.charity_id;
            var charity = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var goods = _impactAdminModelsGood['default'].find({
                organization_id: params.charity_id,
                removed: false
            });

            var goodTypes = _impactAdminModelsGoodCategories['default'].find();

            var org_goods_categories = _impactAdminUtilsApi['default'].get('/good_charity_categories/org/' + charity_id, {}, function () {}, true);

            return _ember['default'].RSVP.hash({
                charity: charity,
                charity_id: charity_id,
                goods: goods,
                goodTypes: goodTypes,
                org_goods_categories: org_goods_categories
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('charity', model.user);
            controller.set('skills', model.skills);
            controller.set('goods', model.goods);

            //Important to initialize the current good categories an org selected
            var goodTypes = model.goodTypes.map(function (t) {
                return { id: t.id, category: t.category };
            });
            controller.set('selectedGoods', model.org_goods_categories.result.map(function (st) {
                return goodTypes.find(function (t) {
                    return t.id === st.id;
                });
            }));
            controller.set('goodTypes', goodTypes);

            controller.set('oldGoods', model.org_goods_categories.result);
            controller.set('model', model);
        }

    });
});