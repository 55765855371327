define('impact-admin/controllers/frame/matching-codes/edit', ['exports', 'ember', 'impact-admin/models/code-matching-rules-organizations', 'impact-admin/models/code-matching-rules-types', 'impact-admin/utils/multi-select-updater', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsCodeMatchingRulesOrganizations, _impactAdminModelsCodeMatchingRulesTypes, _impactAdminUtilsMultiSelectUpdater, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({

        require_sharing_consent: true,
        single_code: true,
        match_types: [{ value: 'none', name: 'None' }, { value: 'dollarForDollar', name: 'Dollar for dollar' }],
        startDate: null,
        endDate: null,

        noMatchDollars: _ember['default'].computed('match_type', function () {
            return this.get('match_type').name !== 'Dollar for dollar';
        }),

        allowed_charities: _ember['default'].computed('model.oldOrgs', function () {
            return this.get('model.oldOrgs');
        }),

        allowed_charity_types: _ember['default'].computed('model.oldTypes', function () {
            return this.get('model.oldTypes');
        }),

        old_orgs: _ember['default'].computed('model.oldOrgs', function () {
            return this.get('oldOrgs');
        }),

        old_types: _ember['default'].computed('model.oldTypes', function () {
            return this.get('oldTypes');
        }),

        filterWatch: (function () {
            this.set('allowed_charities', this.get('model.oldOrgs'));
            this.set('allowed_charity_types', this.get('model.oldTypes'));
        }).observes('model.code_group.id', 'model.oldOrgs', 'model.oldTypes'),

        jqueryInit: (function () {

            _ember['default'].run.schedule("afterRender", this, function () {

                //jQuery for disabling the scrolling mechanic for number inputs, made the forms user error prone
                $('form').on('focus', 'input[type=number]', function () {
                    $(this).on('wheel.disableScroll', function (e) {
                        e.preventDefault();
                    });
                });
                $('form').on('blur', 'input[type=number]', function () {
                    $(this).off('wheel.disableScroll');
                });
            });
        }).on('init'),

        actions: {

            consoleLog: function consoleLog() {
                console.log(this.get('model.code_group'));
            },

            updateCode: function updateCode(code) {
                var self = this;
                // Collect input values
                console.log(code);

                _impactAdminUtilsApi['default'].post('/match/update-code', code, function (json) {
                    if (json.error || !json.data) {
                        console.error('Failed to retrieve donations. (' + json.error + ')');
                        console.log(json);
                        self.growl.error('Unique code already exists.');
                    } else {
                        console.log(json);
                        self.growl.success('Code successfully updated.');
                    }
                }, true);
            },

            save: function save() {
                var _this = this;

                // Collect input values
                console.log('Begin Saving');

                var oldOrgs = this.get('old_orgs');

                var oldOrgsIds = oldOrgs.map(function (s) {
                    return s.id;
                });

                var newOrgs = this.get('allowed_charities');

                var newOrgsIds = newOrgs.map(function (s) {
                    return s.id;
                });

                var oldTypes = this.get('old_types');

                var oldTypesIds = oldTypes.map(function (s) {
                    return s.id;
                });

                var newTypes = this.get('allowed_charity_types');

                var newTypesIds = newTypes.map(function (s) {
                    return s.id;
                });

                var code_group = this.get('model.code_group');
                var matchObject = this.get('match_type');
                code_group.set('match_type', matchObject.value);

                code_group.set('start_date', this.formatDate(this.get('start_date')));
                code_group.set('end_date', this.formatDate(this.get('end_date')));

                if (code_group.get('maxCartTotalDollars') && code_group.get('maxCartTotalDollars') >= 0) {
                    code_group.set('max_cart_total', code_group.get('maxCartTotalDollars') * 100);
                } else {
                    code_group.set('max_cart_total', null);
                }

                if (code_group.get('minCartTotalDollars') && code_group.get('minCartTotalDollars') >= 0) {
                    code_group.set('min_cart_total', code_group.get('minCartTotalDollars') * 100);
                } else {
                    code_group.set('min_cart_total', null);
                }

                if (code_group.get('minDonationSizeDollars') && code_group.get('minDonationSizeDollars') >= 0) {
                    code_group.set('min_donation_size', code_group.get('minDonationSizeDollars') * 100);
                } else {
                    code_group.set('min_donation_size', null);
                }

                if (code_group.get('maxDonationSizeDollars') && code_group.get('maxDonationSizeDollars') >= 0) {
                    code_group.set('max_donation_size', code_group.get('maxDonationSizeDollars') * 100);
                } else {
                    code_group.set('max_donation_size', null);
                }

                if (code_group.get('maxMatchPerCodeDollars') && code_group.get('maxMatchPerCodeDollars') >= 0) {
                    code_group.set('max_match_per_code', code_group.get('maxMatchPerCodeDollars') * 100);
                } else {
                    code_group.set('max_match_per_code', null);
                }

                if (this.validate(code_group)) {
                    console.log('Try saving');
                    code_group.save().then(function (json) {
                        var code_group_id = json.record.get('id');
                        console.log('Matching code has been updated with id ' + code_group_id + '.');
                        _this.growl.success('Matching code saved');

                        (0, _impactAdminUtilsMultiSelectUpdater['default'])(json.record, newOrgsIds, oldOrgsIds, _impactAdminModelsCodeMatchingRulesOrganizations['default'], 'organization_id', 'code_group_id', function () {
                            console.log('Yay!');
                        });

                        (0, _impactAdminUtilsMultiSelectUpdater['default'])(json.record, newTypesIds, oldTypesIds, _impactAdminModelsCodeMatchingRulesTypes['default'], 'type_id', 'code_group_id', function () {
                            console.log('Yay for types!');
                        });

                        _this.transitionToRoute('frame.matching-codes.matching-code-group', code_group_id);
                        //window.location.reload(true);
                    })['catch'](function (error) {
                        console.error(error);
                        _this.growl.error('An error occurred saving this matching code. Please try again');
                    });
                }
            },
            setVisibility: function setVisibility(visible) {
                this.setProperties({
                    single_code: visible === 'single_code',
                    multiple_code: visible === 'multiple_code',
                    multiple_code_email: visible === 'multiple_code_email'
                });
            },
            updateMaxUseInput: function updateMaxUseInput(single_use_code) {
                var max_uses = single_use_code ? "1" : null;
                this.setProperties({
                    max_uses: max_uses
                });
            },
            updateSingleUseCheckbox: function updateSingleUseCheckbox(max_uses) {
                var single_use_code = max_uses === "1";
                this.setProperties({
                    single_use_code: single_use_code
                });
            }
        },
        convertToCents: function convertToCents(dollars) {
            var value = this.numberOrNull(dollars);
            return value == null ? null : value * 100;
        },
        formatDate: function formatDate(date) {
            return date ? date.format('MM/DD/YYYY hh:mm:ss A') : null;
        },
        getNumberOfCodes: function getNumberOfCodes() {
            if (this.get('multiple_code')) {
                return this.numberOrNull(this.get('num_codes'));
            } else {
                return this.get('single_code') ? 1 : null;
            }
        },
        numberOrNull: function numberOrNull(numString) {
            var number = parseInt(numString, 10);
            return isNaN(number) ? null : number;
        },
        validate: function validate(code_group) {
            var isNullOrUndefinedOrEmpty = function isNullOrUndefinedOrEmpty(obj) {
                return obj === undefined || obj === null || obj === '';
            };

            this.set('errors', _ember['default'].A());
            var errors = this.get('errors');

            if (isNullOrUndefinedOrEmpty(code_group.group_name)) {
                errors.pushObject('Please enter a code group name.');
            }

            var num_codes = this.getNumberOfCodes();
            if ((isNullOrUndefinedOrEmpty(num_codes) || num_codes < 1) && isNullOrUndefinedOrEmpty(code_group.emails)) {
                errors.pushObject('Please enter the number of codes to generate or an email list.');
            }

            //return (errors.length === 0);
            return true;
        }

    });
});