define('impact-admin/controllers/frame/matching-codes/matching-code-group', ['exports', 'ember', 'impact-admin/utils/api', 'numeral'], function (exports, _ember, _impactAdminUtilsApi, _numeral) {
    exports['default'] = _ember['default'].Controller.extend({
        loading: false,
        selectedOrg: {},
        charities: _ember['default'].computed('model', function () {
            var charities = [];
            var organizations = this.get('model').charities;
            organizations.forEach(function (charity) {
                charities.push(charity);
            });
            charities.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
            charities.unshift({ id: 0, name: 'None' });
            return charities;
        }),
        validCharities: _ember['default'].computed('model', function () {
            var validOrgs = this.get('model.code_group.validOrganizations');
            var charities = this.get('model').allCharities;

            var filteredCharities = [];
            validOrgs.forEach(function (_ref) {
                var organization_id = _ref.organization_id;
                return filteredCharities.push(charities.find(function (it) {
                    return it.id === organization_id;
                }));
            });
            return filteredCharities;
        }),
        hasRemainingBalance: _ember['default'].computed('model', function () {
            return this.get('model').balance.remaining_balance >= 0;
        }),
        actions: {
            payout: function payout() {
                var _this = this;

                if (this.loading) {
                    return;
                }
                this.set('loading', true);

                var selectedOrg = this.get('selectedOrg');
                var overageOrgId = selectedOrg && selectedOrg.id === 0 ? null : selectedOrg.id;
                var codeGroupId = this.get('model').balance.id;
                var remainingBalance = (0, _numeral['default'])(this.get('model').balance.remaining_balance / 100).format('0,0.00');
                var originalBalance = (0, _numeral['default'])(this.get('model').balance.original_balance / 100).format('0,0.00');
                var message = 'This will payout the match code group to donated organizations and close the group. ';
                if (remainingBalance < 0) {
                    message += '$' + originalBalance + ' will be charged to DMF\'s bank account.';
                } else if (overageOrgId) {
                    message += 'The remaining balance of $' + remainingBalance + ' will be donated to ' + this.get('selectedOrg').name + '. A total of $' + originalBalance + ' will be charged to DMF\'s bank account.';
                } else {
                    var donatedAmount = (0, _numeral['default'])((this.get('model').balance.original_balance - this.get('model').balance.remaining_balance) / 100).format('0,0.00');
                    message += 'The remaining balance of $' + remainingBalance + ' will not be donated to any organization. A total of $' + donatedAmount + ' will be charged to DMF\'s bank account.';
                }

                if (!window.confirm(message)) {
                    this.set('loading', false);
                    return false;
                }

                _impactAdminUtilsApi['default'].post('/match/' + codeGroupId + '/payout', { overageOrgId: overageOrgId }, function (res) {
                    _this.set('loading', false);

                    if (res.error) {
                        _this.growl.error(res.error);
                        return;
                    }

                    _this.growl.success('Match group successfully paid out!');
                }, true);
            },

            toEdit: function toEdit() {
                this.transitionToRoute('frame.matching-codes.edit', this.get('model.code_group.id'));
            }
        }
    });
});