define('impact-admin/components/ghd-resource-info', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({
        isEdit: false,
        file: '',
        title: '',
        link: '',

        tempResource: {},

        init: function init() {
            this._super.apply(this, arguments);
            //copy data into tempResource so we can detect when the data has changed
            this.tempResource = JSON.parse(JSON.stringify(this.resource));
        },

        fileType: _ember['default'].computed('resource.file', function () {

            var file = undefined;

            if (this.get('resource.file')) {
                file = this.get('resource.file').substr(this.get('resource.file').lastIndexOf('.') + 1);
            }

            if (!file) {
                file = 'hyperlink';
            }

            return file;
        }),

        file_icon: (function () {
            var file_type = this.get('resource.file').substr(this.get('resource.file').lastIndexOf('.') + 1);
            if (file_type === "jpg") {
                return this.get('resource.file');
            }
            if (file_type === "png") {
                return this.get('resource.file');
            }
            if (file_type === "jpeg") {
                return this.get('resource.file');
            }
            return '../images/' + file_type + '_icon.png';
        }).property('resource.file'),

        file_type_name: (function () {
            var file_type = this.get('resource.file').substr(this.get('resource.file').lastIndexOf('.') + 1);
            return file_type.toUpperCase();
        }).property('resource.file'),

        download_name: (function () {
            var file_type = this.get('resource.file').substr(this.get('resource.file').lastIndexOf('.') + 1);
            return this.get('resource.title') + '.' + file_type;
        }).property('resource.file'),

        slicedLink: (function () {
            if (this.resource.link.length > 60) {
                return this.resource.link.slice(0, 60) + '...';
            } else {
                return this.resource.link;
            }
        }).property(),

        actions: {
            toggleEdit: function toggleEdit() {
                this.toggleProperty('isEdit');
            },

            toggleCancel: function toggleCancel(resource) {
                resource.revertRecord();
                this.toggleProperty('isEdit');
            },

            updateFile: function updateFile(tempFile) {
                this.set('resource.title', tempFile.title);
                this.set('resource.file', tempFile.url);
            },

            editResource: function editResource() {
                var _this = this;

                this.set('tempResource', JSON.parse(JSON.stringify(this.get('resource'))));
                this.get('resource').save().then(function () {
                    _this.growl.success('Resource edited!');
                    _this.toggleProperty('isEdit');
                })['catch'](function (reason) {
                    console.log(reason);
                    _this.growl.error('An error occurred saving the resource. Please try again.');
                });
            },

            deleteResource: function deleteResource(resource) {
                var _this2 = this;

                if (confirm('Are you sure you want to remove this resource?')) {
                    resource.deleteRecord().then(function () {
                        _this2.growl.success('Resource deleted!');
                        _this2.get('categoryResources').removeObject(resource);
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this2.growl.error('An error occurred deleting the resource. Please try again.');
                    });
                }
            },

            incrementDownloadCount: function incrementDownloadCount(resource) {
                _impactAdminUtilsApi['default'].put('/ghd_resources/increment_download_count/' + resource.id, {});
                //open the resource
                window.open(resource.file ? resource.file : resource.link, '_blank');
            }
        }
    });
});