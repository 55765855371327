define('impact-admin/controllers/home', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        password: '',
        alert: '',
        alert_type: '',
        application: _ember['default'].inject.controller(),
        auth: _ember['default'].inject.service('auth'),
        reset: function reset() {
            this.set('password', '');
            this.set('alert', '');
            this.set('alert_type', '');
        },

        actions: {
            login: function login() {
                this.get('auth').login();
            }
        }
    });
});