define('impact-admin/routes/frame/campaigns/new', ['exports', 'ember', 'impact-admin/models/organization-campaigns'], function (exports, _ember, _impactAdminModelsOrganizationCampaigns) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _impactAdminModelsOrganizationCampaigns['default'].createRecord({
                is_active: true
            });
        },

        setupController: function setupController(controller, model, transition) {
            controller.set('model', model);
            controller.set('charity_id', parseInt(transition.params['frame.campaigns'].charity_id, 10));
            controller.get('frame.campaigns').set('selectedCampaign', null);
        }
    });
});