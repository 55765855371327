define('impact-admin/controllers/frame/reports/admin-users', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        searching: false,
        selectedOrg: null,
        selectedType: null,
        search: function search() {
            var self = this;
            this.set('searching', true);
            var filteredUsers = _ember['default'].A([]);
            this.set('filteredUsers', filteredUsers);
            this.get('model.users').forEach(function (user) {
                if ((_ember['default'].isEmpty(self.selectedType) || user.type === self.selectedType) && (_ember['default'].isEmpty(self.selectedOrg) || user.organization_id === self.selectedOrg.id)) {
                    filteredUsers.pushObject(user);
                }
            });
            this.set('searching', false);
        },
        actions: {
            clear: function clear() {
                this.set('selectedOrg', null);
                this.set('selectedType', null);
                this.search();
            },
            searchUsers: function searchUsers() {
                this.search();
            }
        }
    });
});