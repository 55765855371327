define("impact-admin/helpers/decimal-to-percent", ["exports", "ember"], function (exports, _ember) {

    var helper = _ember["default"].Helper.helper(function (params) {
        var decimal = params[0];
        if (!decimal) {
            return "0";
        }
        var percent = decimal * 100;
        percent = percent.toFixed(0);
        return percent;
    });

    exports["default"] = helper;
});