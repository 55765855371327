define('impact-admin/routes/frame/prepaid-codes/index', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },
        model: function model() {
            // Send custom date parameters in the query
            var codeGroups = _impactAdminUtilsApi['default'].get('/prepaid_code_groups_year', { year: 2025 }, function () {}, true);
            return _ember['default'].RSVP.hash({
                codeGroups: codeGroups
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('codeGroups', _ember['default'].A(model.codeGroups.data));
        }
    });
});