define('impact-admin/routes/frame/forms/index', ['exports', 'ember', 'impact-admin/models/forms'], function (exports, _ember, _impactAdminModelsForms) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {

            var forms = _impactAdminModelsForms['default'].find();
            return _ember['default'].RSVP.hash({
                forms: forms
            });
        },

        setupController: function setupController(controller, model) {
            var sortedForms = model.forms.sortBy('created_on').reverse();
            var activeForms = model.forms.filter(function (form) {
                return form.is_active;
            });
            controller.set('forms', activeForms);
            controller.set('allForms', sortedForms);
        }

    });
});