define('impact-admin/models/admin-users', ['exports', 'ember'], function (exports, _ember) {

  var AdminUsers = _ember['default'].Model.extend({

    // As far as I can tell Ember is dumb and allows no inheritance of controllers,
    //models, or components, so We have this list in two places, oh well.
    tiers: [{
      label: 'Master',
      value: 'organization'
    }, {
      label: 'Donation Admin',
      value: 'organization-donation'
    }, {
      label: 'Volunteer Admin',
      value: 'organization-volunteer'
    }, {
      label: 'Admin',
      value: 'impact'
    }],

    userTiers: function userTiers() {
      return this.tiers;
    },

    tierLabel: (function () {
      var _this = this;

      return (this.tiers.find(function (tier) {
        return tier.value === _this.get('type');
      }) || {}).label || 'Error';
    }).property('type')
  });

  AdminUsers.table = 'admin_users';

  exports['default'] = AdminUsers;
});