define('impact-admin/helpers/charity-type-symbol', ['exports', 'ember'], function (exports, _ember) {
    exports.charityTypeSymbol = charityTypeSymbol;

    // converts an organizations type id to the respective symbol

    function charityTypeSymbol(params /*, hash*/) {
        // make sure the bootstrap tooltips are enabled at the application level!

        var id = params[0]; // type id
        var name = params[1] ? params[1] : ''; // name
        var showTooltip = params[2]; // boolean
        var symbol = '';
        switch (id) {
            case 0:
                symbol = 'icon-life-bouy';
                break;
            case 1:
                symbol = 'icon-paw';
                break;
            case 2:
                symbol = 'icon-paint-brush';
                break;
            case 3:
                symbol = "icon-cutlery";
                break;
            case 4:
                symbol = 'icon-life-bouy';
                break;
            case 5:
                symbol = 'icon-wheelchair';
                break;
            case 6:
                symbol = 'icon-graduation-cap';
                break;
            case 7:
                symbol = 'icon-faith';
                break;
            case 8:
                symbol = 'icon-stethoscope';
                break;
            case 9:
                symbol = 'icon-youth';
                break;
            case 10:
                symbol = 'icon-dmf';
                break;
            case 11:
                symbol = 'icon-impact';
                break;
            case 12:
                symbol = 'icon-seniorcare';
                break;
        }
        var html = '<span title="' + name + '" class="' + symbol + '"' + (showTooltip ? ' data-toggle="tooltip" data-placement="top"' : '') + '></span>';
        return html.htmlSafe();
    }

    exports['default'] = _ember['default'].Helper.helper(charityTypeSymbol);
});