define('impact-admin/models/prepaid-code-groups', ['exports', 'ember', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsOrganizations) {

  var PrepaidCodeGroups = _ember['default'].Model.extend({
    charity: _ember['default'].computed('overage_org_id', function () {
      return _impactAdminModelsOrganizations['default'].find(this.get('overage_org_id'));
    })
  });

  PrepaidCodeGroups.table = 'prepaid_code_groups';

  exports['default'] = PrepaidCodeGroups;
});