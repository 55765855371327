define('impact-admin/routes/frame/index', ['exports', 'ember', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminConfigEnvironment) {

    var FrameIndexRoute = _ember['default'].Route.extend({
        redirect: function redirect() {

            if (_impactAdminConfigEnvironment['default'].isImpactApp) {
                this.transitionTo('frame.dashboard');
            } else {
                this.transitionTo('frame.manage');
            }
        }
    });

    exports['default'] = FrameIndexRoute;
});