define('impact-admin/models/scheduled-donations', ['exports', 'ember', 'impact-admin/models/scheduled-transactions', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsScheduledTransactions, _impactAdminModelsOrganizations) {

  /*
   * Scheduled Donations Model
   */
  var ScheduledDonations = _ember['default'].Model.extend({
    scheduled_transaction: _ember['default'].computed('scheduled_transactions_id', function () {
      return _impactAdminModelsScheduledTransactions['default'].find(this.get('scheduled_transactions_id'));
    }),

    organization: _ember['default'].computed('organization_id', function () {
      return _impactAdminModelsOrganizations['default'].find(this.get('organization_id'));
    })
  });

  ScheduledDonations.table = 'scheduled_donations';

  exports['default'] = ScheduledDonations;
});