define('impact-admin/services/manage-ghd', ['exports', 'ember', 'impact-admin/config/environment', 'moment'], function (exports, _ember, _impactAdminConfigEnvironment, _moment) {
    exports['default'] = _ember['default'].Service.extend({
        config: _impactAdminConfigEnvironment['default'],

        showCountdown: function showCountdown() {
            var config = this.get('config');

            if (config.isGivingHeartsDay && !config.isDevelopment) {
                if ((0, _moment['default'])().unix() < config.startGivingHeartsDay) {
                    return true;
                }
            }

            return false;
        },

        isGHD: _ember['default'].computed('config.isGivingHeartsDay', 'config.startGivingHeartsDay', 'config.endGivingHeartsDay', function () {
            var config = this.get('config');

            if (config.isGivingHeartsDay) {
                if ((0, _moment['default'])().unix() < config.startGivingHeartsDay) {
                    return false;
                }
                if ((0, _moment['default'])().unix() > config.endGivingHeartsDay) {
                    return false;
                }
                return true;
            } else {
                return false;
            }
        }),

        startGHD: _ember['default'].computed('config.startGivingHeartsDay', function () {
            var config = this.get('config');
            return config.startGivingHeartsDay;
        }),

        isMatchAvailable: _ember['default'].computed('config.isMatchAvailable', function () {
            var config = this.get('config');
            return config.isMatchAvailable;
        }),

        isGHDCheckEntryAvailable: _ember['default'].computed('config', function () {
            var config = this.get('config');
            return config.isGHDCheckEntryAvailable;
        }),

        showGHDDonations: _ember['default'].computed('config', function () {
            var config = this.get('config');
            return config.showGHDDonations;
        }),

        getCurrentGHDYear: _ember['default'].computed('config', function () {
            var config = this.get('config');
            return config.currentGHDYear;
        }),

        getConfig: _ember['default'].computed('config', function () {
            var config = this.get('config');
            return config;
        })
    });
});