define('impact-admin/components/form-element-builder', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        element_types: ['header', 'divider', 'text', 'textarea', 'select', 'checkbox'],

        org_fields: ['name', 'street_street', 'street_city', 'street_state', 'street_postal_code', 'mailing_street', 'mailing_city', 'mailing_state', 'mailing_postal_code', 'website', 'summary', 'story'],

        selectMultipleOptions: _ember['default'].computed('data.type', function () {
            if (this.get('data.type') === 'multiselect') {
                return true;
            }
            return false;
        }),

        element_select_options: (function () {
            var current_options = this.get('data.select_options');
            if (!current_options) {
                return [];
            }
            if (Array.isArray(current_options)) {
                return current_options;
            } else {
                while (!Array.isArray(current_options)) {
                    current_options = JSON.parse(current_options);
                }
                return current_options;
            }
        }).property('data.select_options'),

        actions: {

            selectType: function selectType(type) {
                this.set('data.type', type);
            },

            toggleFormElementBuilderModal: function toggleFormElementBuilderModal(element) {
                //this.set('charity', null);
                if (element.isReallyDirty()) {
                    element.revertRecord();
                }
                this.toggleProperty('formElementBuilderModal');
            },

            saveElement: function saveElement(element) {
                this.sendAction('save', element);
            },

            updateElement: function updateElement(element) {
                this.sendAction('edit', element);
            },

            addSelectOption: function addSelectOption(url) {
                var options = this.get('data.select_options');

                if (!options) {
                    options = [];
                } else if (!Array.isArray(options)) {
                    while (!Array.isArray(options)) {
                        options = JSON.parse(options);
                    }
                }

                options.push(url);
                this.set('newOption', null);

                this.set('data.select_options', JSON.stringify(options));
            },

            delSelectOption: function delSelectOption(index) {

                var resp = confirm("Remove this link?");
                if (!resp) {
                    return;
                }
                var options = this.get('data.select_options');

                if (!options) {
                    options = [];
                } else if (!Array.isArray(options)) {
                    while (!Array.isArray(options)) {
                        options = JSON.parse(options);
                    }
                }

                options.splice(index, 1);

                this.set('data.select_options', JSON.stringify(options));
            },

            selectSetMultipleOptions: function selectSetMultipleOptions() {
                if (this.get('data.type') === 'select') {
                    this.set('data.type', 'multiselect');
                } else {
                    this.set('data.type', 'select');
                }
                console.log(this.get('data.type'));
            }

        }
    });
});