define('impact-admin/initializers/app-mode', ['exports', 'impact-admin/services/app-mode'], function (exports, _impactAdminServicesAppMode) {
    exports['default'] = {
        name: 'mode',
        initialize: function initialize(app) {
            app.register('mode:main', _impactAdminServicesAppMode['default']);
            app.inject('route', 'mode', 'mode:main');
            app.inject('controller', 'mode', 'mode:main');
            app.inject('component', 'mode', 'mode:main');
        }
    };
});