define('impact-admin/routes/frame/donations/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        setupController: function setupController(controller, model) {
            controller.set('token', this.controllerFor('application').get('token'));
            controller.set('model', model.donations);
            controller.set('charity', model.charity);
            controller.set('campaigns', model.campaigns);

            if (model.donations.content && model.donations.content.length > 0) {
                var num_donations = model.donations.content[0].full_count;
                controller.set('donations_count', num_donations);
            }

            controller.set('lowerLimit', 0);
            if (model.donations.content && model.donations.content.length < 50) {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', model.donations.content.length);
            } else {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', 50);
            }
            controller.set('isImpact', this.controllerFor('application').get('isImpact'));
        }
    });
});