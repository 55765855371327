define('impact-admin/routes/frame/processed-donations', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/donations', 'impact-admin/models/organization-campaigns'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsDonations, _impactAdminModelsOrganizationCampaigns) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.processed-donations'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var charity = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var donations = _impactAdminModelsDonations['default'].find({
                organization_id: params.charity_id,
                ghd_year: '2025'
            }, {
                sort: {
                    date: 'desc'
                },
                limit: 50,
                offset: 0
            });

            var campaigns = _impactAdminModelsOrganizationCampaigns['default'].find({
                organization_id: params.charity_id
            });

            return _ember['default'].RSVP.hash({
                charity: charity,
                donations: donations,
                campaigns: campaigns
            });
        },

        setupController: function setupController(controller, model) {

            controller.set('token', this.controllerFor('application').get('token'));
            controller.set('model', model.donations);
            controller.set('charity', model.charity);
            controller.set('campaigns', model.campaigns);

            if (model.donations.content && model.donations.content.length > 0) {
                var num_donations = model.donations.content[0].full_count;
                controller.set('donations_count', num_donations);
            }

            controller.set('lowerLimit', 0);
            if (model.donations.content && model.donations.content.length < 50) {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', model.donations.content.length);
            } else {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', 50);
            }
            controller.set('isImpact', this.controllerFor('application').get('isImpact'));
        }

    });
});