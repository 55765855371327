define('impact-admin/models/transactions', ['exports', 'ember', 'impact-admin/models/users'], function (exports, _ember, _impactAdminModelsUsers) {

  var Transactions = _ember['default'].Model.extend({
    donor: _ember['default'].computed('user_id', function () {
      return _impactAdminModelsUsers['default'].find(this.get('user_id'));
    })
  });

  Transactions.table = 'transactions';

  exports['default'] = Transactions;
});