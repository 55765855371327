define('impact-admin/controllers/frame/reports/online-prepaid-codes', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/models/prepaid-code-groups'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminModelsPrepaidCodeGroups) {
  exports['default'] = _ember['default'].Controller.extend({
    codes: [],
    activeId: null,
    group_name_filter: '',

    actions: {
      searchGroups: function searchGroups() {
        var _this = this;

        var name = this.get('group_name_filter');

        var groupFilter = {
          online_code: true
        };

        if (name) {
          groupFilter['name'] = {
            ilike: name + '%'
          };
        }

        _impactAdminModelsPrepaidCodeGroups['default'].find(groupFilter).then(function (json) {
          _this.set('nothingFound', false);
          if (json.content.length > 0) {
            _this.set('model', json);
          } else {
            _this.set('model', null);
            _this.set('nothingFound', true);
          }
          _this.set('searching', false);
        });
      },

      toggleInfo: function toggleInfo(id) {
        var self = this;

        _ember['default'].$(event.target).parents('tr').next(".donation-secondary").find('.hidden-info').slideToggle();
        if (this.get('activeId') && id !== this.get('activeId')) {
          var activeEl = $('.infoArrow' + this.get('activeId'));
          if (activeEl.hasClass('icon-arrow-down')) {
            _ember['default'].$(activeEl).parents('tr').next(".donation-secondary").find('.hidden-info').slideToggle();
            activeEl.removeClass('icon-arrow-down');
            activeEl.addClass('icon-arrow-right');
          }
        }

        var el = $('.infoArrow' + id);
        if (el.hasClass('icon-arrow-down')) {
          el.removeClass('icon-arrow-down');
          el.addClass('icon-arrow-right');
          self.set('activeId', null);
          self.set('codes', []);
        } else {
          _impactAdminUtilsApi['default'].get('/prepaid_code_groups/' + id + '/card_balances', {}, function (json) {
            if (json.error) {
              console.error('Failed to retrieve prepaid group codes. (' + json.error + ')');
              return;
            }

            el.removeClass('icon-arrow-right');
            el.addClass('icon-arrow-down');
            self.set('activeId', id);
            self.set('codes', json.data);
          }, true);
        }
      }
    }
  });
});