define('impact-admin/components/organization-document-upload', ['exports', 'ember', 'impact-admin/models/organization-documents'], function (exports, _ember, _impactAdminModelsOrganizationDocuments) {
  // import { template } from 'handlebars/handlebars.runtime';

  exports['default'] = _ember['default'].Component.extend({
    newPDF: {},
    saveDisabled: true,
    didInsertElement: function didInsertElement() {
      var self = this;
      var form = this.$().find('form');
      var template_id = self.get('template_id');

      form.transloadit({
        wait: true,
        autoSubmit: false,
        triggerUploadOnFileSelection: false,
        processZeroFiles: false,
        params: {
          "auth": {
            "key": "2fbd6575d1c844d2b664c17126d34e70"
          },
          "template_id": template_id
        },
        onFileSelect: function onFileSelect(filename) {
          // Remove weird filepath that Transloadit adds
          filename = filename.replace("C:\\fakepath\\", '');

          if (!self.validate(filename)) {
            _ember['default'].run.debounce(self, function () {
              self.growl.error('File is not a valid file type');
            }, 1000);
          } else {
            self.set('saveDisabled', false);
            form.submit();
          }
        },
        onStart: function onStart() {
          //console.log(assembly);
        },
        onCancel: function onCancel() {
          self.growl.warning('File upload cancelled');
        },
        onSuccess: function onSuccess(assembly) {
          console.log('sucessfully uploaded', assembly);
          if (assembly && assembly.results && assembly.results['public']) {
            var result = assembly.results['public'][0];
            var otherStuff = result.map(function (item) {
              return {
                document_url: item.url,
                document_name: item.name
              };
            });

            if (otherStuff && otherStuff.length) {
              //self.set('tempFileData', otherStuff[0]);
              self.send('save', otherStuff[0]);
            }
          } else {
            self.growl.warning('Invalid file type. File was not saved.');
          }
        },
        onError: function onError() {
          self.growl.warning('There was an error while uploading this file. Make sure that it is a proper file type.');
        }
      });
      this._super.apply(this, arguments);
    },
    actions: {
      save: function save(tempFileData) {
        var _this = this;

        var fileData = tempFileData;
        var charityId = this.get('charity_id');
        var documentName = this.get('document_name') || fileData.document_name;
        var documentUrl = fileData.document_url;

        if (!fileData) {
          this.growl.warning('You must select a file before attempting to save it');
          return;
        }

        var newDoc = _impactAdminModelsOrganizationDocuments['default'].createRecord({
          organization_id: charityId,
          document_url: documentUrl,
          document_name: documentName
        });

        this.set('saveDisabled', false);

        newDoc.save().then(function (json) {
          if (json.response.error) {
            _this.growl.error('Could not save ' + documentName + '. Please try again.');
          } else {
            _this.growl.success(documentName + ' saved');
            _this.tempFileData = {};
            _this.document_name = null;
          }
        }, function () {
          return _this.growl.error('An error occured.  Please try again.');
        });
      }
    },
    validate: function validate(file) {
      var ext = file.split('.').pop().toLowerCase();
      var type = false;
      var extOptions = this.get('extTypes') || ['pdf'];

      for (var i = 0; i < extOptions.length; i++) {
        if (extOptions[i] === ext) {
          type = true;
          break;
        }
      }

      return type;
    }
  });
});