define('impact-admin/routes/frame/links', ['exports', 'ember', 'impact-admin/models/links', 'impact-admin/mixins/table'], function (exports, _ember, _impactAdminModelsLinks, _impactAdminMixinsTable) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            if (this.modelFor('frame') === 'impact') {
                //can't reach into the common config... Will this do?

                var links = _impactAdminModelsLinks['default'].find({
                    removed: false
                });

                return _ember['default'].RSVP.hash({
                    links: links
                });
            }
        },

        setupController: function setupController(controller, model) {
            _impactAdminMixinsTable['default'].apply(controller);

            controller.set('links', model.links);
        }
    });
});