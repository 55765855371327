define('impact-admin/components/scheduled-donation-row', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'tr',
        loading: false,

        scheduledTotal: (function () {
            var prepaid_amount = this.get('donation.prepaid_amount_received') || 0;
            return this.get('donation.amount_received') + prepaid_amount;
        }).property('donation'),

        anonymous: _ember['default'].computed('donation.scheduled_transaction.anonymous', 'adminView', function () {

            if (this.get('adminView')) {
                return false;
            }

            return this.get('donation.scheduled_transaction.anonymous');
        }),

        actions: {
            showCancelScheduledDonationConfirmation: function showCancelScheduledDonationConfirmation(scheduled_donation) {
                var _this = this;

                if (confirm('Are you sure you would like to cancel this scheduled donation and all other scheduled donations with the transaction id ' + scheduled_donation.scheduled_transactions_id + '?')) {
                    this.set('loading', true);

                    _impactAdminUtilsApi['default'].post('/scheduled_transactions/cancel', {
                        scheduled_transaction_id: scheduled_donation.get('scheduled_transactions_id'),
                        subscription_id: scheduled_donation.get('subscription_id'),
                        customer_id: scheduled_donation.get('stripe_customer')
                    }, function (json) {
                        if (json.error) {
                            _this.growl.error(json.error);
                        } else {
                            _this.growl.success('Your scheduled donation has been canceled.');
                            scheduled_donation.set('status', 'canceled');
                        }
                        _this.set('loading', false);
                    }, true);
                }
            }
        }
    });
});