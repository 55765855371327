define('impact-admin/routes/frame/reports/recurring-donations', ['exports', 'ember', 'impact-admin/models/recurring-donations'], function (exports, _ember, _impactAdminModelsRecurringDonations) {

    var AdminReportsRecurringDonationsRoute = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            return _impactAdminModelsRecurringDonations['default'].find({
                status: {
                    '!=': 'canceled'
                }
            });
        }
    });

    exports['default'] = AdminReportsRecurringDonationsRoute;
});