define('impact-admin/utils/ghd-helper', ['exports', 'moment'], function (exports, _moment) {

    var ghdHelper = {

        calculateSecondDayOfMonth: function calculateSecondDayOfMonth(startDate, month, day) {
            var startOfMonth = (0, _moment['default'])(startDate).month(month).startOf('month');

            // Yes there's probably a safer moment() way, but this works and I didn't have time
            // to figure out heroku time and stripe time and why it is this way
            //6AM UTC = 6AM PST + 7 hrs
            var sixAM = 5 + 7;
            var firstThursdayOfWeek = (0, _moment['default'])(startOfMonth).month(month).day(day).hour(sixAM).minute(0).second(0).millisecond(0);
            var secondThursday = (0, _moment['default'])(firstThursdayOfWeek).add(2, 'weeks');

            if (firstThursdayOfWeek.month() === startOfMonth.month()) {
                // since day gets us the start of the week we could potentially go back a month (if the month starts on a Friday
                // for example). In this case we are in the same month so the second occurrence of that day only requires
                // us to add 1 week instead of 2
                secondThursday = secondThursday.subtract(1, 'week');
            }

            return secondThursday;
        },

        calculateNextGivingHeartsDay: function calculateNextGivingHeartsDay(startDate) {
            // GHD is the 2nd thursday of February
            var february = 1;
            var thursday = 4;
            var ghdCurrentYear = this.calculateSecondDayOfMonth((0, _moment['default'])(startDate), february, thursday);
            var ghdNextYear = this.calculateSecondDayOfMonth((0, _moment['default'])(startDate).add(1, 'year'), february, thursday);

            //if today is greater than 2nd thursday of current year then next GHD is next year, otherwise this year
            return (0, _moment['default'])(startDate).isAfter(ghdCurrentYear) ? ghdNextYear : ghdCurrentYear;
        },

        calculateNextGivingHeartsYear: function calculateNextGivingHeartsYear(startDate) {
            return ghdHelper.calculateNextGivingHeartsDay(startDate).format('YYYY');
        }
    };

    exports['default'] = ghdHelper;
});