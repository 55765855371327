define('impact-admin/helpers/processing-fee', ['exports', 'ember', 'numeral'], function (exports, _ember, _numeral) {

    //Converts from cents to dollars.
    var helper = _ember['default'].Helper.helper(function (params) {
        var cents = params[0];
        var transaction_id = params[1];
        if (_ember['default'].isEmpty(cents) || _ember['default'].isEmpty(transaction_id)) {
            return "0";
        }

        var fee = Math.round(cents * 0.0325) + 30;
        var dollars = fee / 100;
        dollars = (0, _numeral['default'])(dollars.toFixed(2)).format('0,0.00');
        return dollars.htmlSafe();
    });

    exports['default'] = helper;
});