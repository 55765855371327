define('impact-admin/app', ['exports', 'ember', 'impact-admin/resolver', 'ember-load-initializers', 'impact-admin/config/environment', 'browser-update/update.npm.js'], function (exports, _ember, _impactAdminResolver, _emberLoadInitializers, _impactAdminConfigEnvironment, _browserUpdateUpdateNpmJs) {

  var App = undefined;

  (0, _browserUpdateUpdateNpmJs['default'])({
    required: {
      e: 12,
      f: 59,
      o: 51,
      s: 7,
      c: 65
    },
    insecure: true,
    api: 2018.10,
    no_permanent_hide: true
  });

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _impactAdminConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _impactAdminConfigEnvironment['default'].podModulePrefix,
    Resolver: _impactAdminResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _impactAdminConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});