define('impact-admin/components/quill-editor', ['exports', 'ember', 'quill'], function (exports, _ember, _quill) {
    exports['default'] = _ember['default'].Component.extend({

        checkIfSameContainer: (function () {
            console.log(this.get('uniqueID'));
            if (this.get('uniqueID')) {
                this.get('quill').setContents(JSON.parse(this.get('deltaContent')));
            }
        }).observes('uniqueID'),

        didInsertElement: function didInsertElement() {
            //this._super(...arguments);
            //console.log('Initialize Quill');
            this._setupQuill();
        },

        _setupQuill: function _setupQuill() {
            var self = this;

            var toolbarOptions = [['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'link'],

            //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
            //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            //[{ 'direction': 'rtl' }],                         // text direction

            //[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            //[{ 'font': [] }],
            //[{ 'align': [] }],

            ['clean'] // remove formatting button
            ];

            var options = {
                modules: {
                    toolbar: toolbarOptions
                },
                placeholder: self.get('placeholder') || 'Compose something amazing...',
                theme: 'snow' // or 'bubble'
            };

            var quill = new _quill['default']('#' + self.get('uniqueID'), options);
            quill.on('text-change', function () {
                console.log(quill.getContents());
                self.set('deltaContent', JSON.stringify(quill.getContents()));
                self.set('htmlSafeContent', quill.root.innerHTML);
            });

            //console.log(self.getText());
            //console.log(JSON.parse(self.get('deltaContent')));
            if (self.get('deltaContent')) {
                quill.setContents(JSON.parse(self.get('deltaContent')));
            }

            self.set('quill', quill);
        }
    });
});