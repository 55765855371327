define('impact-admin/components/progress-bar', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        didInsertElement: function didInsertElement() {
            //make sure the percentage bars are set on page load.
            var percent = this.get('percent');
            if (percent > 100) {
                percent = 100;
            }
            this.$('.progress-bar').animate({
                'width': percent + '%'
            }, 'slow');
        },

        observeTotal: (function () {
            var percent = this.get('percent');
            if (percent > 100) {
                percent = 100;
            }
            this.$('.progress-bar').animate({
                'width': percent + '%'
            }, 'slow');
        }).observes('percent')
    });
});