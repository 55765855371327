define('impact-admin/components/ghd-countdown', ['exports', 'impact-admin/config/environment', 'ember'], function (exports, _impactAdminConfigEnvironment, _ember) {

    var CountdownComponent = _ember['default'].Component.extend({
        manageGhd: _ember['default'].inject.service('manage-ghd'),
        isGHD: (function () {
            console.log(this.get('manageGhd'));
            return this.get('manageGhd.startGHD') * 1000;
        }).property('application'),

        delta: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        _interval: null,
        startTime: null,

        timer: _ember['default'].computed('delta', function () {
            var self = this;
            if (this._interval === null) {
                this.set('startTime', this.get('manageGhd.startGHD') * 1000);
                this._interval = setInterval(function () {
                    var start = self.get('startTime');
                    if (start > Date.now()) {
                        self.set('delta', start - Date.now());

                        var seconds = (start - Date.now()) / 1000;

                        var d = Math.floor(seconds / (3600 * 24));
                        var h = Math.floor(seconds % (3600 * 24) / 3600);
                        var m = Math.floor(seconds % 3600 / 60);
                        var s = Math.floor(seconds % 60);
                        self.set('days', d);
                        self.set('hours', h);
                        self.set('minutes', m);
                        self.set('seconds', s);
                    }
                }, 500);
            }
            return this.get('delta');
        }),

        setupCountdown: (function () {
            //$('.nav').css('height', '52px').addClass('thankyou-nav').find('a').hide();
            this.get('manageGhd.startGHD');
            var self = this;
            var counter = $('#the_final_countdown').countdown(_impactAdminConfigEnvironment['default'].startGivingHeartsDay * 1000);
            counter.on('update.countdown', function (event) {
                var seconds = (_impactAdminConfigEnvironment['default'].startGivingHeartsDay * 1000 - Date.now()) / 1000;

                var d = Math.floor(seconds / (3600 * 24));

                var h = Math.floor(seconds % (3600 * 24) / 3600);
                var m = Math.floor(seconds % 3600 / 60);
                var s = Math.floor(seconds % 60);

                self.set('days', d);
                self.set('hours', h);
                self.set('minutes', m);
                self.set('seconds', s);

                if (event.strftime('%-D') > 0) {
                    $(this).html(event.strftime('%-D day%!D %H:%M:%S'));
                } else if (event.strftime('%H') > 0) {
                    $(this).html(event.strftime('%H:%M:%S'));
                } else if (event.strftime('%M') > 0) {
                    $(this).html(event.strftime('%M:%S'));
                } else {
                    $(this).html(event.strftime('%S'));
                }
            });
            /*
            counter.on('finish.countdown', function() {
                if (document.domain && document.domain.indexOf('impactgiveback') > -1) {
                    document.location = 'https://givingheartsday.org';
                } else {
                    $(this).html("...");
                    self.sendAction();
                    //$('.nav').css('height', '').removeClass('thankyou-nav').find('a').fadeIn();
                    document.location = '/';
                }
            });
              */
        }).on('didInsertElement')

    });

    exports['default'] = CountdownComponent;
});
/* global $ */