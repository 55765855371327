define('impact-admin/routes/frame/donations/scheduled', ['exports', 'ember', 'impact-admin/mixins/table', 'moment', 'impact-admin/utils/ghd-helper'], function (exports, _ember, _impactAdminMixinsTable, _moment, _impactAdminUtilsGhdHelper) {
    //TODO Delete as view is now under frame/donations/scheduled
    exports['default'] = _ember['default'].Route.extend({

        setupController: function setupController(controller, model) {
            console.log(model);
            _impactAdminMixinsTable['default'].apply(controller);
            model.scheduled_donations.forEach(function (trans) {
                return trans.set('date_created', (0, _moment['default'])(trans.get('date_created'), 'YYYY-MM-DD').add(1, 'months').format('YYYY-MM-DD'));
            });
            model.scheduled_donations = model.scheduled_donations.filter(function (d) {
                return (0, _moment['default'])(d.date_created, 'YYYY-MM-DD HH:mm:ss').isSame((0, _moment['default'])(_impactAdminUtilsGhdHelper['default'].calculateNextGivingHeartsYear((0, _moment['default'])()), 'YYYY-MM-DD'), 'year');
            });
            controller.set('model', model.scheduled_donations);
            controller.set('charity', model.charity);
            controller.set('transactions', model.scheduled_transactions);
            controller.set('isImpact', this.controllerFor('application').get('isImpact'));
        }
    });
});