define('impact-admin/controllers/frame/scheduled-donations', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        manageGhd: _ember['default'].inject.service(),
        loading: false,

        exportUrl: _ember['default'].computed('charity.id', function () {

            var charity_id = this.get('charity.id');
            console.log('Charity Id: ' + charity_id);
            var filter = {};

            if (charity_id) {
                filter['organization_id'] = charity_id;
            }

            filter['ghd_year'] = '2023';

            var url = '/scheduled_donations/csv/';
            url += '?filter=' + encodeURI(JSON.stringify(filter));

            return url;
        }),

        scheduledDonationsCount: _ember['default'].computed('scheduled_donations', function () {
            return this.get('scheduled_donations.length');
        }),

        setExportUrl: function setExportUrl() {
            var url = '/scheduled_donations/csv/'; //?org_id=' + this.get('charity.id');
            var filter = this.get('filter');

            url += '?filter=' + encodeURI(JSON.stringify(filter));
            this.set('exportUrl', url);
        },

        actions: {
            downloadFile: _impactAdminUtilsApi['default'].downloadFile
        }
    });
});