define('impact-admin/routes/frame/tools/config', ['exports', 'ember', 'impact-admin/models/ghd-configs'], function (exports, _ember, _impactAdminModelsGhdConfigs) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                ghdConfigs: _impactAdminModelsGhdConfigs['default'].find()
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('ghdConfigs', model.ghdConfigs.content);
        }
    });
});