define('impact-admin/routes/frame/reports/org-info', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/types', 'impact-admin/mixins/table'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsTypes, _impactAdminMixinsTable) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                organizations: _impactAdminModelsOrganizations['default'].find(),
                types: _impactAdminModelsTypes['default'].find()
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('selectedTypes', _ember['default'].A([]));
            _impactAdminMixinsTable['default'].apply(controller);
        }
    });
});