define('impact-admin/components/ghd-charity-stats-modal', ['exports', 'ember', 'impact-admin/utils/api', 'numeral'], function (exports, _ember, _impactAdminUtilsApi, _numeral) {

    var GhdCharityStatsModalComponent = _ember['default'].Component.extend({

        dedicationModal: false,

        cancelBtnText: (function () {
            return this.get('cancelText') || 'Cancel';
        }).property('cancelText'),

        actions: {
            saveProfile: function saveProfile() {
                this.saveDMFMatch().then(function (value) {
                    console.log(value);
                    // expected output: "Success!"
                });
            },

            toggleImpactCert: function toggleImpactCert(certificate) {
                this.toggleImpactCert(certificate);
            },

            saveDefaultMatch: function saveDefaultMatch() {
                var self = this;
                self.set('charity.dmf_match_dollars', 3000);
                console.log(this.get('charity.dmf_match_dollars'));
                self.saveDMFMatch().then(function (value) {
                    console.log(value);
                    self.set('charity', null);
                    self.toggleProperty('dedicationModal');
                });
            },

            save: function save() {
                this.saveDedication();
            },
            toggleDedicationModal: function toggleDedicationModal() {
                this.set('charity', null);
                this.toggleProperty('dedicationModal');
            },
            alertCharity: function alertCharity(email_type, org) {
                var self = this;
                var data = {
                    email_type: email_type,
                    id: org.id
                };

                if (confirm('Are you sure you want email ' + org.name + '?')) {
                    _impactAdminUtilsApi['default'].post('/account/send-unpaid-alert/', data, function (json) {
                        self.growl.success("Reminder email sent!");
                        return json;
                    }, true);
                }
            }

        },
        saveDMFMatch: function saveDMFMatch() {
            var _this = this;

            if (this.validate()) {
                var _self = this;
                var org = this.get('charity');

                org.set('dmf_match', Math.round((0, _numeral['default'])().unformat(org.get('dmf_match_dollars')) * 100));
                var match_complete = parseFloat((org.ghd_goal / 100).toFixed(2)) > 0 && (parseFloat((org.raised_match / 100).toFixed(2)) > 3000 || org.dmf_match);
                org.set('match_is_complete', match_complete);

                _self.set('charity', org);

                if (org.isReallyDirty()) {
                    var newRecord = org.save(); //this._super(org);

                    newRecord.then(function (json) {
                        if (json.response.error) {
                            _this.growl.error('DMF Match did not save. Please try again.');
                            console.log(json.response.error);
                        } else {
                            _this.growl.success('DMF Match updated.');
                        }
                    });
                }
            }
            return new Promise(function (resolve) {
                console.log("first function executed");
                resolve();
            });
        },
        toggleImpactCert: function toggleImpactCert(certificate) {
            var _this2 = this;

            if (this.validate()) {
                var _self2 = this;
                var org = this.get('charity');

                org.toggleProperty(certificate);

                _self2.set('charity', org);

                if (org.isReallyDirty()) {
                    var updatedRecord = org.save(); //this._super(org);

                    updatedRecord.then(function (json) {
                        if (json.response.error) {
                            _this2.growl.error('Impact Certificate did not save. Please try again.');
                            console.log(json.response.error);
                        } else {
                            _this2.growl.success('Impact Certificate Updated');
                        }
                    });
                }
            }
            return new Promise(function (resolve) {
                console.log("first function executed");
                resolve();
            });
        },
        validate: function validate() {
            return true;
        }

    });

    exports['default'] = GhdCharityStatsModalComponent;
});