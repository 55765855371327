define('impact-admin/controllers/frame/campaigns', ['exports', 'ember', 'impact-admin/models/organization-campaigns', 'moment'], function (exports, _ember, _impactAdminModelsOrganizationCampaigns, _moment) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        start_date: null,
        end_date: null,

        filteredModel: _ember['default'].computed('model.campaigns', 'searchTerm', function () {
            var _this = this;

            var searchTerm = this.get('searchTerm');
            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    _this.get('model.campaigns').forEach(function (volunteer_opportunity) {
                        if (volunteer_opportunity.get('title').toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(volunteer_opportunity);
                        }
                    }, _this);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            } else {
                return this.get('model.campaigns');
            }
        }),

        actions: {
            transitionToCampaign: function transitionToCampaign(campaign) {
                this.set('selectedCampaign', campaign);
                this.transitionToRoute('frame.campaigns.campaign', campaign.id);
            },
            addNew: function addNew() {
                this.set('newOpportunity', _impactAdminModelsOrganizationCampaigns['default'].createRecord().content);
            },
            saveOpportunity: function saveOpportunity(campaign, isNew) {
                var _this2 = this;

                if (isNew) {
                    campaign.set('start_date', this.get('start_date').format('YYYY-MM-DD'));
                    campaign.set('end_date', this.get('end_date').format('YYYY-MM-DD'));
                    campaign.set('organization_id', this.get('model.charity_id'));
                }

                if (!campaign.get('start_date') || !campaign.get('end_date')) {
                    this.growl.error('Please enter a start and end date.');
                } else {
                    if (campaign.start_date._isAMomentObject) {
                        campaign.set('start_date', campaign.start_date.format('YYYY-MM-DD'));
                    }

                    if (campaign.end_date._isAMomentObject) {
                        campaign.set('end_date', campaign.end_date.format('YYYY-MM-DD'));
                    }
                    campaign.setProperties({
                        start_date_epoch: (0, _moment['default'])(campaign.get('start_date'), 'YYYY-MM-DD').unix(),
                        end_date_epoch: (0, _moment['default'])(campaign.get('end_date'), 'YYYY-MM-DD').unix()
                    });

                    if (campaign.isReallyDirty()) {

                        campaign.save().then(function () {
                            _this2.growl.success('Campaign was saved.');
                            if (isNew) {
                                var campaignUpdated = _this2.get('model.campaigns');
                                campaignUpdated.push(campaign);
                                _this2.set('searchTerm', '');
                                _this2.set('filteredModel', campaignUpdated);
                                _this2.set('newOpportunity', null);
                            }
                        })['catch'](function (json) {
                            _this2.growl.error('Campaign could not be saved at this time. Please try again.');
                            console.log(json);
                        });
                    } else {
                        this.set('editMode', false);
                    }
                }
            },
            cancelAddNew: function cancelAddNew() {
                this.set('newOpportunity', null);
            },
            deleteOpportunity: function deleteOpportunity(opportunity) {
                var self = this;
                if (confirm('Are you sure you want to delete this designation?')) {
                    opportunity.set('archived', true);
                    opportunity.save().then(function () {
                        //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                        //let old = self.get('model.campaigns');
                        //let newList = old.filter(opportunity);
                        //self.set('model.campaigns', newList);
                        self.set('model.campaigns', self.get('model.campaigns').filterBy('archived', false));
                    });
                }
            },
            makePrivate: function makePrivate(opportunity) {
                opportunity.set('is_active', !opportunity.get('is_active'));
                opportunity.save().then(function () {
                    //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                    //this.get('model.submissions').removeObjects(submissionsHide);
                });
            },
            makePublic: function makePublic(opportunity) {
                if (confirm('Are you sure you want to make this designation active?')) {
                    opportunity.set('is_active', true);
                    opportunity.save().then(function () {
                        //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                        //this.get('model.submissions').removeObjects(submissionsHide);
                    });
                }
            }
        },

        returnID: function returnID() {
            return this.campaign_id;
        },
        reset: function reset() {
            this.set('campaign_id', null);
        }
    });
});