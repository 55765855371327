define('impact-admin/components/manage-event-icons', ['exports', 'ember', 'impact-admin/models/event-icons', 'impact-admin/models/events'], function (exports, _ember, _impactAdminModelsEventIcons, _impactAdminModelsEvents) {
    exports['default'] = _ember['default'].Component.extend({
        isAdding: false, icon: '', name: '',

        actions: {
            toggleCancel: function toggleCancel() {
                this.toggleProperty('isManagingIcons');
                this.reset();
            },

            updateFile: function updateFile(tempFile) {
                var _this = this;

                console.log(tempFile);
                // Assuming 'tempFile' is a File object
                var img = new Image();
                img.src = tempFile.url;

                img.onload = function () {
                    // Get the dimensions of the image
                    var width = img.width;
                    var height = img.height;

                    if (width > 45 || height > 45) {
                        _this.growl.error('Image uploaded cannot be bigger than 32x32!');
                        _this.set('hasError', "Please upload a smaller file");
                        return;
                    }
                    _this.set('hasError', null);

                    // Set other properties as needed
                    _this.set('name', tempFile.title);
                    _this.set('icon', tempFile.url);
                };
            },

            addIcon: function addIcon() {
                var _this2 = this;

                var record = {
                    name: this.get('name'),
                    icon: this.get('icon')
                };

                var newIcon = _impactAdminModelsEventIcons['default'].createRecord(record);
                newIcon.save().then(function () {
                    _this2.growl.success('Icon added!');
                    //this.get('categoryResources').pushObject(newIcon);
                    _this2.get('eventIcons').pushObject(newIcon);
                })['catch'](function (reason) {
                    console.log(reason);
                    _this2.growl.error('Failed to add icon.');
                });
            },
            deleteIcon: function deleteIcon(icon) {
                var _this3 = this;

                if (confirm('Are you sure you want to remove this icon?')) {
                    _impactAdminModelsEvents['default'].find().then(function (barrels) {
                        barrels = barrels.toArray();
                        var lowestID = 99999;
                        barrels = barrels.filter(function (barrel) {
                            if (barrel.icon < lowestID && barrel.icon !== icon.id) {
                                lowestID = barrel.icon;
                            }
                            return barrel.icon === icon.id;
                        });
                        _ember['default'].RSVP.Promise.all(barrels.map(function (barrel) {
                            _ember['default'].set(barrel, 'icon', lowestID);
                            return barrel.save().then(function () {
                                _this3.growl.success('Barrel Edited!');
                            })['catch'](function (reason) {
                                console.log(reason);
                                _this3.growl.error('An error occurred editing the barrel. Please try again.');
                            });
                        })).then(function () {
                            icon.deleteRecord().then(function () {
                                _this3.growl.success('Icon deleted!');
                                _this3.get('eventIcons').removeObject(icon);
                            })['catch'](function (reason) {
                                console.log(reason);
                                _this3.growl.error('An error occurred deleting the icon. Please try again.');
                            });
                        });
                    });
                }
            }
        },

        reset: function reset() {
            this.set('icon', '');
            this.set('name', '');
        }

    });
});