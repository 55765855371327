define('impact-admin/components/form-element-submission', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        saving: false,

        observeSaving: (function () {
            var self = this;

            var saving = self.get('saving');
            console.log(saving);

            if (saving) {
                self.$('.circle-loader').removeClass('load-complete');
                self.$('.circle-loader').css("transition", "none");
                self.$('.circle-loader').css("visibility", "visible");
                self.$('.circle-loader').css("opacity", "1");
                self.$('.checkmark').css("display", "none");
                self.$('.auto-save-text').css("display", "none");
            } else {
                setTimeout(function () {
                    self.$('.circle-loader').css("transition", "visibility 0s 2s, opacity 2s linear");
                    self.$('.circle-loader').css("visibility", "hidden");
                    self.$('.circle-loader').css("opacity", "0");
                    self.$('.circle-loader').addClass('load-complete');
                    self.$('.checkmark').css("display", "block");
                    self.$('.auto-save-text').css("display", "block");
                }, 1000);
            }
        }).observes('saving'),

        parseAndFormatCurrentSubmittedAnswers: (function () {
            if (this.get('data.type') === 'checkbox') {
                if (this.get('local_user_answer') === 'true') {
                    this.set('local_checkbox_answer', true);
                } else {
                    this.set('local_checkbox_answer', false);
                }
            }
            if (this.get('data.type') === 'multiselect') {
                if (this.get('local_user_answer')) {
                    this.set('local_multiselect_answer', JSON.parse(this.get('local_user_answer')));
                } else {
                    this.set('local_multiselect_answer', null);
                }
                //this.set('local_user_answer', this.get('local_checkbox_answer'));
            }
            if (this.get('data.type') === 'select') {

                if (this.get('local_user_answer')) {
                    this.set('local_select_answer', JSON.parse(this.get('local_user_answer')));
                } else {
                    this.set('local_select_answer', null);
                }
                //this.set('local_user_answer', this.get('local_checkbox_answer'));
            }
        }).on('init'),

        change: function change() {
            var self = this;

            if (this.get('data.type') === 'checkbox') {
                this.set('local_user_answer', this.get('local_checkbox_answer'));
            } else if (this.get('data.type') === 'multiselect') {
                //this.set('local_user_answer', this.get('local_checkbox_answer'));
            } else {
                    this.set('data.data', this.get('local_user_answer'));
                }
            if (this.get('data.type') !== 'multiselect') {
                var updatedFormSubmission = this.get('data');
                self.set('saving', true);
                if (updatedFormSubmission.isReallyDirty()) {
                    updatedFormSubmission.save();
                }
                self.set('saving', false);
            }
        },

        element_select_options: (function () {
            var current_options = this.get('data.select_options');

            if (!current_options) {
                return [];
            }
            if (Array.isArray(current_options)) {
                return current_options;
            } else {
                while (!Array.isArray(current_options)) {
                    current_options = JSON.parse(current_options);
                }
                return current_options;
            }
        }).property('data.select_options'),

        actions: {
            updateMultipleSelectData: function updateMultipleSelectData(selected) {
                this.set('local_multiselect_answer', selected);

                this.set('data.data', JSON.stringify(this.get('local_multiselect_answer')));

                var updatedFormSubmission = this.get('data');
                this.set('saving', true);
                if (updatedFormSubmission.isReallyDirty()) {
                    updatedFormSubmission.save();
                }
                this.set('saving', false);
            },

            updateSelectData: function updateSelectData(selected) {
                this.set('local_select_answer', selected);

                this.set('data.data', JSON.stringify(this.get('local_select_answer')));

                var updatedFormSubmission = this.get('data');
                this.set('saving', true);
                if (updatedFormSubmission.isReallyDirty()) {
                    updatedFormSubmission.save();
                } else {
                    console.log('Not Dirty Enough');
                }
                this.set('saving', false);
            }
        }

    });
});