define('impact-admin/controllers/frame/forms/form-builder', ['exports', 'ember', 'impact-admin/models/form-elements'], function (exports, _ember, _impactAdminModelsFormElements) {
    //import moment from 'moment';

    exports['default'] = _ember['default'].Controller.extend({

        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        serve_areas: ['header', 'divider', 'text', 'textarea', 'select', 'checkbox'],
        formElementBuilderModal: false,
        editFormDetails: false,

        actions: {
            addNew: function addNew(sort_order) {
                this.set('selectedFormElement', _impactAdminModelsFormElements['default'].createRecord().content);
                this.set('selectedFormElement.form_id', this.get('form.id'));
                this.set('isElementNew', true);
                this.set('selectedFormElement.sort_order', (sort_order || this.get('form_elements.length')) + 1);
                this.toggleProperty('formElementBuilderModal');
            },

            duplicate: function duplicate(element) {

                //Make sure this a new object when saved
                delete element.id;

                this.set('selectedFormElement', _impactAdminModelsFormElements['default'].createRecord(element).content);
                this.set('selectedFormElement.form_id', this.get('form.id'));

                this.set('isElementNew', true);
                this.set('selectedFormElement.sort_order', (element.sort_order || this.get('form_elements.length')) + 1);

                this.toggleProperty('formElementBuilderModal');
            },

            edit: function edit(element) {
                this.set('selectedFormElement', element);
                this.set('isElementNew', false);
                this.toggleProperty('formElementBuilderModal');
            },

            toggleEditFormDetails: function toggleEditFormDetails() {
                this.toggleProperty('editFormDetails');
            },

            updateFormDetails: function updateFormDetails(form) {
                var _this = this;

                if (form.isReallyDirty()) {
                    form.save().then(function () {
                        _this.growl.success('Form was updated.');
                        _this.set('editFormDetails', false);
                    })['catch'](function (json) {
                        _this.growl.error('Campaign could not be updated at this time. Please try again.');
                        console.log(json);
                    });
                } else {
                    this.set('editFormDetails', false);
                }
            },

            //The function is passed to the form-element component
            adjustSortOrder: function adjustSortOrder(element_current_sort_order, element_new_sort_order) {
                var _this2 = this;

                var self = this;

                if (element_current_sort_order < element_new_sort_order) {
                    self.form_elements.forEach(function (element) {

                        if (element.sort_order === element_current_sort_order) {
                            element.set('sort_order', element_new_sort_order);
                        } else if (element.sort_order > element_current_sort_order && element.sort_order <= element_new_sort_order) {
                            //console.log('ID: ' + element.id + ' --- ' + element.sort_order);
                            element.set('sort_order', element.sort_order - 1);
                        }
                    });
                } else if (element_current_sort_order > element_new_sort_order) {

                    self.form_elements.forEach(function (element) {

                        if (element.sort_order === element_current_sort_order) {
                            element.set('sort_order', element_new_sort_order);
                        } else if (element.sort_order < element_current_sort_order && element.sort_order >= element_new_sort_order) {
                            //console.log('ID: ' + element.id + ' --- ' + element.sort_order);
                            element.set('sort_order', element.sort_order + 1);
                        }
                    });
                } else {
                    this.growl.warning('Sort Order Issue');
                }

                self.set('form_elements', self.form_elements.sortBy('sort_order'));

                var updateSortOrder = self.form_elements.map(function (form_element) {
                    if (form_element.isReallyDirty()) {
                        return form_element.save();
                    }
                });

                _ember['default'].RSVP.Promise.all(updateSortOrder).then(function (result) {
                    var success = result;
                    console.log(success);
                    _this2.growl.success('Saved');
                });
            },

            removeElement: function removeElement(selectedElement) {
                var _this3 = this;

                var self = this;

                var element_current_sort_order = selectedElement.sort_order;

                console.log(element_current_sort_order);

                if (confirm('Are you sure you want to delete this form element?')) {
                    selectedElement.deleteRecord().then(function (json) {
                        if (json.response.error) {
                            _this3.growl.error('Error!');
                            console.log(json);
                        }
                    })['catch'](function (json) {
                        _this3.growl.error('Error Apparent!');
                        console.log(json);
                    });

                    self.form_elements.forEach(function (element) {

                        if (element.sort_order > element_current_sort_order) {
                            //console.log('ID: ' + element.id + ' --- ' + element.sort_order);
                            element.set('sort_order', element.sort_order - 1);
                        }
                    });
                    var updateSortOrder = self.form_elements.map(function (form_element) {
                        if (form_element.isReallyDirty()) {
                            return form_element.save();
                        }
                    });

                    _ember['default'].RSVP.Promise.all(updateSortOrder).then(function (result) {
                        if (result) {
                            _this3.growl.success('Deleted');

                            var updated_form_elements = self.form_elements.filter(function (element) {
                                return element.id !== selectedElement.id;
                            });

                            self.set('form_elements', updated_form_elements.sortBy('sort_order'));
                        }
                    });
                }
            },

            addNewFormElement: function addNewFormElement(form_element) {
                var _this4 = this;

                var self = this;
                console.log(form_element);

                if (form_element.isReallyDirty()) {
                    form_element.save().then(function () {

                        self.form_elements.forEach(function (element) {

                            if (element.sort_order >= form_element.sort_order) {
                                //console.log('ID: ' + element.id + ' --- ' + element.sort_order);
                                element.set('sort_order', element.sort_order + 1);
                            }
                        });
                        var updateSortOrder = self.form_elements.map(function (form_element) {
                            if (form_element.isReallyDirty()) {
                                return form_element.save();
                            }
                        });

                        _ember['default'].RSVP.Promise.all(updateSortOrder).then(function (result) {
                            if (result) {
                                _this4.console.log(result);
                            }
                        });

                        _this4.growl.success('Form was saved.');
                        _this4.set('newForm', null);
                        var updated_form_elements = self.form_elements;

                        updated_form_elements.push(form_element);

                        self.set('form_elements', updated_form_elements.sortBy('sort_order'));
                        _this4.toggleProperty('formElementBuilderModal');
                    })['catch'](function (json) {
                        if (json.constraint) {
                            _this4.growl.error('The "field name" you are trying apply is already in use. Please try again with a different name.');
                        } else {
                            _this4.growl.error('Campaign could not be saved at this time. Please try again.');
                        }
                        console.log(json);
                    });
                } else {
                    this.set('editMode', false);
                }
            },

            editFormElement: function editFormElement(form_element) {
                var _this5 = this;

                if (form_element.isReallyDirty()) {
                    form_element.save().then(function () {
                        _this5.growl.success('Form element was saved.');
                        _this5.set('newForm', null);
                        _this5.toggleProperty('formElementBuilderModal');
                    })['catch'](function (json) {
                        if (json.constraint) {
                            _this5.growl.error('The "field name" you are trying apply is already in use. Please try again with a different name.');
                        } else {
                            _this5.growl.error('Issue updating. Please try again');
                        }
                        console.log(json);
                    });
                } else {
                    this.growl.error('Issue updating. Please try again.');
                    this.set('editMode', false);
                }
            },

            cancelAddNew: function cancelAddNew() {
                this.set('newForm', null);
            }
        }

    });
});