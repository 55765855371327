define('impact-admin/routes/frame/reports/stripe', ['exports', 'ember', 'impact-admin/models/connect', 'impact-admin/utils/token', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsConnect, _impactAdminUtilsToken, _impactAdminUtilsApi) {

    var AdminReportsStripeReportRoute = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },
        model: function model() {
            return _impactAdminModelsConnect['default'].find();
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);

            if (_impactAdminUtilsToken['default'].get() !== null) {
                controller.set('stripe_info_url', _impactAdminUtilsApi['default'].url + '/organizations/all_stripe_info');
            }
        }
    });

    exports['default'] = AdminReportsStripeReportRoute;
});