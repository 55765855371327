define('impact-admin/controllers/frame/calendar-events', ['exports', 'ember', 'moment', 'impact-admin/models/calendar-events', 'impact-admin/utils/api'], function (exports, _ember, _moment, _impactAdminModelsCalendarEvents, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        filteredModel: _ember['default'].computed('calendar_events', 'searchTerm', function () {
            var _this = this;

            var searchTerm = this.get('searchTerm');
            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    _this.get('calendar_events').forEach(function (event) {
                        if (!event.get('removed') && event.get('title').toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(event);
                        }
                    }, _this);

                    return {
                        v: matches.sort(function (a, b) {
                            return new Date(a.get('start_date')) - new Date(b.get('start_date'));
                        })
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            } else {
                return this.get('calendar_events').sort(function (a, b) {
                    return new Date(a.get('start_date')) - new Date(b.get('start_date'));
                });
            }
        }),

        newStartDate: null,
        newEndDate: null,
        newStartTime: null,
        newEndTime: null,

        actions: {
            validateProperty: function validateProperty(changeset, property) {
                return changeset.validate(property);
            },

            save: function save(event, isNew) {
                var _this2 = this;

                var self = this;
                if (isNew) {
                    var startDate = this.get('newStartDate') ? (0, _moment['default'])(this.get('newStartDate')).format('YYYY-MM-DD') : null;
                    var startTime = this.get('newStartTime') ? (0, _moment['default'])(this.get('newStartTime')).format('HH:mm:ss') : null;
                    var endDate = this.get('newEndDate') ? (0, _moment['default'])(this.get('newEndDate')).format('YYYY-MM-DD') : null;
                    var endTime = this.get('newEndTime') ? (0, _moment['default'])(this.get('newEndTime')).format('HH:mm:ss') : null;

                    event.set('content.start_date', startDate);
                    event.set('content.start_time', startTime);
                    event.set('content.end_date', endDate);
                    event.set('content.end_time', endTime);

                    if (startDate) {
                        event.save().then(function (json) {
                            if (json.response.error) {
                                _this2.growl.error('There was an error saving your changes. ' + json.response.error);
                            } else {
                                _this2.growl.success('Changes Saved');
                                _this2.set('calendar_events', self.get('calendar_events').concat([_this2.get('newEvent.content')]));
                                self.refreshEvents();
                                _this2.set('newEvent', null);
                            }
                        });
                    } else {
                        alert('Please include at minimum a start date!');
                    }
                } else {

                    if (event.get('start_time') === 'Invalid date') {
                        event.set('start_time', null);
                    }

                    if (event.get('end_time') === 'Invalid date') {
                        event.set('end_time', null);
                    }

                    event.save().then(function (json) {
                        if (json.response.error) {
                            _this2.growl.error('There was an error saving your changes. ' + json.response.error);
                        } else {

                            _this2.growl.success('Changes Saved');
                            self.refreshEvents();
                        }
                    });
                }
                this.set('isDuplicate', false);
            },
            addNew: function addNew() {
                this.set('newEvent', _impactAdminModelsCalendarEvents['default'].createRecord());
            },
            cancelAddNew: function cancelAddNew() {
                this.set('newEvent', null);
            },
            remove: function remove(event) {
                var _this3 = this;

                var self = this;
                if (confirm('Are you sure you want to delete this calendar entry?')) {
                    event.set('removed', true);
                    event.save().then(function (json) {
                        if (json.response.error) {
                            _this3.growl.error('There was an error removing the event. ' + json.response.error);
                        } else {

                            _this3.growl.success('Event Removed');
                            self.refreshEvents();
                        }
                    });
                }
            },
            publishOpp: function publishOpp(event) {
                event.save();
            },
            setActive: function setActive(event) {
                var self = this;
                event.set('active', !event.get('active'));
                event.save().then(function () {
                    self.refreshEvents();
                });
            }
        },

        refreshEvents: function refreshEvents() {
            var self = this;
            _impactAdminUtilsApi['default'].get('/calendar_events/upcoming', {}, function (json) {
                if (json.error) {
                    self.growl.error(json.error);
                } else {
                    self.set('upcoming_events', json.result.upcoming_events);
                }
            }, true);
            _impactAdminModelsCalendarEvents['default'].find({
                removed: false
            }).then(function (json) {
                self.set('calendar_events', json.content);
            });
        }
    });
});