define('impact-admin/controllers/frame/donations/scheduled', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        manageGhd: _ember['default'].inject.service(),
        loading: false,

        noActiveTransactions: _ember['default'].computed('transactions', function () {
            return this.get('transactions.content').length === 0;
        }),

        activeDonations: _ember['default'].computed('transactions', 'model', function () {
            var activeTransactions = this.get('transactions').map(function (t) {
                return t.id;
            });
            return this.get('model').filter(function (d) {
                return activeTransactions.indexOf(d.get('scheduled_transactions_id')) > -1;
            });
        }),

        actions: {}
    });
});