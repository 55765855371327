define('impact-admin/controllers/frame/donations-by-donor', ['exports', 'ember', 'impact-admin/models/donations'], function (exports, _ember, _impactAdminModelsDonations) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        donations: [],
        filter: _ember['default'].computed('first_name_filter', 'last_name_filter', 'buesiness_name_filter', 'email_filter', 'donation_id_filter', 'transaction_id_filter', 'start_date', 'end_date', function () {
            var first_name = this.get('first_name_filter');
            var last_name = this.get('last_name_filter');
            var business_name = this.get('business_name_filter');
            var email = this.get('email_filter');
            var donation_id = this.get('donation_id_filter');
            var transaction_id = this.get('transaction_id_filter');
            var start_date = this.get('start_date');
            var end_date = this.get('end_date');

            var filter = {};

            if (first_name) {
                filter['both_first_names'] = {
                    ilike: '%' + first_name + '%'
                };
            }

            if (last_name) {
                filter['last_name'] = {
                    ilike: '%' + last_name + '%'
                };
            }

            if (business_name) {
                filter['business_name'] = {
                    ilike: '%' + business_name + '%'
                };
            }

            if (email) {
                filter['email'] = {
                    ilike: '%' + email + '%'
                };
            }

            if (transaction_id) {
                filter['transaction_id'] = transaction_id;
            }

            if (start_date) {
                filter['start_date'] = {
                    '>=': start_date.startOf('day').unix()
                };
            }

            if (end_date) {
                filter['end_date'] = {
                    '<=': end_date.endOf('day').unix()
                };
            }

            if (donation_id) {
                filter['id'] = donation_id;
            }

            return JSON.parse(JSON.stringify(filter));
        }),
        actions: {
            searchDonors: function searchDonors() {
                var first_name = this.get('first_name_filter');
                var last_name = this.get('last_name_filter');
                var business_name = this.get('business_name_filter');
                var email = this.get('email_filter');
                var donation_id = this.get('donation_id_filter');
                var transaction_id = this.get('transaction_id_filter');
                var start_date = this.get('start_date');
                var end_date = this.get('end_date');

                var filter = {};

                if (first_name) {
                    filter['both_first_names'] = {
                        ilike: '%' + first_name + '%'
                    };
                }

                if (last_name) {
                    filter['last_name'] = {
                        ilike: '%' + last_name + '%'
                    };
                }

                if (business_name) {
                    filter['business_name'] = {
                        ilike: '%' + business_name + '%'
                    };
                }

                if (email) {
                    filter['email'] = {
                        ilike: '%' + email + '%'
                    };
                }

                if (transaction_id) {
                    filter['transaction_id'] = transaction_id;
                }

                if (donation_id) {
                    filter['id'] = donation_id;
                }

                if (start_date) {
                    filter['start_date'] = {
                        '>=': start_date.startOf('day').unix()
                    };
                }

                if (end_date) {
                    filter['end_date'] = {
                        '<=': end_date.endOf('day').unix()
                    };
                }
                this.set('searching', true);

                var self = this;
                this.set('lowerLimit', 1);

                var filterDeepCopy = JSON.parse(JSON.stringify(filter));
                try {
                    _impactAdminModelsDonations['default'].find(filterDeepCopy, {
                        sort: {
                            date: 'desc'
                        },
                        limit: 50,
                        offset: 0
                    }).then(function (results) {

                        self.set('upperLimit', results.get('content.length') || 0);
                        self.set('model', results.get('content'));
                        self.set('donations_count', results.get('content').get('firstObject.full_count') || 0);

                        self.set('searching', false);
                        self.set('nothingFound', false);
                    });
                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    });
});