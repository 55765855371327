define('impact-admin/controllers/frame/card-reader', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/models/prepaid-code-groups', 'impact-admin/models/code-groups', 'impact-admin/models/products'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminModelsPrepaidCodeGroups, _impactAdminModelsCodeGroups, _impactAdminModelsProducts) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports['default'] = _ember['default'].Controller.extend({

        userEmail: '',
        userName: '',
        userLastName: '',
        userBusiness: '',

        newProduct: {
            name: null,
            description: null,
            price: 0,
            formattedPrice: 0
        },

        readers: null,

        processingFeeActive: true,

        awaitingPaymentMethod: false,

        allAvailableTerminalReaders: [],

        connectingToTerminal: false,

        selectedGiftCards: [],

        selectedMatchCodes: [],

        selectedProducts: [],

        selectedSection: 'product',

        isGiftCard: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'giftCard';
        }),

        isMatchCode: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'matchCode';
        }),

        isProduct: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'product';
        }),

        terminal_connected: false,

        total: 0,

        cartIsEmpty: true,

        totalToCharge: _ember['default'].computed('total', 'processingFeeActive', function () {
            var self = this;
            return this.get('processingFeeActive') ? self.get('total') + (Math.round(self.get('total') * 0.0325) + 30) : self.get('total');
        }),

        readerInit: (function () {
            //Schedule to run initial scan for readers after the template is rendered
            _ember['default'].run.schedule("afterRender", this, function () {
                this.findReaders();
            });
        }).on('init'),

        totalObserver: (function () {

            var totalTransaction = 0;
            var newCardGroups = this.get('selectedGiftCards');
            newCardGroups.forEach(function (cardGroup) {
                cardGroup.cards.forEach(function (card) {
                    totalTransaction += card.original_balance;
                });
            });

            var newMatchCodeGroups = this.get('selectedMatchCodes');
            newMatchCodeGroups.forEach(function (matchCodeGroup) {
                totalTransaction += matchCodeGroup.match_donations_sum;
            });

            var newProducts = this.get('selectedProducts');
            newProducts.forEach(function (product) {
                totalTransaction += product.price;
            });

            this.set('total', totalTransaction);
        }).observes('selectedGiftCards', 'selectedMatchCodes', 'processingFeeActive', 'selectedProducts'),

        cartObserver: (function () {

            var newCardGroups = this.get('selectedGiftCards');

            var newMatchCodeGroups = this.get('selectedMatchCodes');

            var newProducts = this.get('selectedProducts');

            if (newCardGroups || newMatchCodeGroups || newProducts) {
                this.set('cartIsEmpty', false);
            } else {
                this.set('cartIsEmpty', true);
            }
        }).observes('selectedGiftCards', 'selectedMatchCodes', 'selectedProducts'),

        processingFeeObserver: (function () {

            this.updateCardReaderDisplay();
        }).observes('processingFeeActive'),

        actions: {

            createProduct: function createProduct(product) {
                var _this = this;

                var self = this;

                var newProduct = _impactAdminModelsProducts['default'].createRecord(product);
                newProduct.set('price', parseInt(newProduct.get('formattedPrice')) * 100);

                newProduct.save().then(function (json) {
                    console.log(json);
                    _this.growl.success('Product Created');
                    self.set('newProduct', {
                        name: null,
                        description: null,
                        price: 0,
                        formattedPrice: 0
                    });
                })['catch'](function () {
                    _this.growl.error('An error occurred trying to save this product. Please try again.');
                });
            },

            updateProduct: function updateProduct(product) {
                var _this2 = this;

                product.set('price', parseInt(product.get('formattedPrice')) * 100);

                product.save().then(function () {
                    _this2.growl.success('Product Updated');
                })['catch'](function () {
                    _this2.growl.error('An error occurred trying to save this product. Please try again.');
                });
            },

            deleteProduct: function deleteProduct(product) {
                var _this3 = this;

                if (confirm('Are you sure you would like to delete this product?')) {
                    product.deleteRecord().then(function () {
                        _this3.growl.success('Product was deleted.');
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this3.growl.error('There was an error when trying to delete this location. Please try again.');
                    });
                }
            },

            editProductsToggle: function editProductsToggle() {
                this.toggleProperty('editProducts');
            },

            addProductToCart: function addProductToCart(selectedProduct) {
                var newSelectedProducts = [].concat(_toConsumableArray(this.get('selectedProducts')));

                newSelectedProducts.push(selectedProduct);

                this.set('selectedProducts', newSelectedProducts);
                this.updateCardReaderDisplay();
            },

            removeProductFromCart: function removeProductFromCart(selectedCardGroup) {
                var newSelectedProducts = [].concat(_toConsumableArray(this.get('selectedProducts')));

                var indexToRemove = newSelectedProducts.findIndex(function (x) {
                    return x.id === selectedCardGroup.id;
                });

                newSelectedProducts.splice(indexToRemove, 1);

                this.set('selectedProducts', newSelectedProducts);
                this.updateCardReaderDisplay();
            },

            addCardGroupToCart: function addCardGroupToCart(selectedCardGroup) {
                var newCardGroups = [].concat(_toConsumableArray(this.get('selectedGiftCards')));

                newCardGroups.push(selectedCardGroup);

                this.set('selectedGiftCards', newCardGroups);
                this.updateCardReaderDisplay();
            },

            removeCardGroupFromCart: function removeCardGroupFromCart(selectedCardGroup) {
                var newCardGroups = [].concat(_toConsumableArray(this.get('selectedGiftCards')));

                var indexToRemove = newCardGroups.findIndex(function (x) {
                    return x.id === selectedCardGroup.id;
                });

                newCardGroups.splice(indexToRemove, 1);

                this.set('selectedGiftCards', newCardGroups);
                this.updateCardReaderDisplay();
            },

            addMatchCodeGroupToCart: function addMatchCodeGroupToCart(selectedMatchCodeGroup) {
                var newMatchCodeGroups = [].concat(_toConsumableArray(this.get('selectedMatchCodes')));

                newMatchCodeGroups.push(selectedMatchCodeGroup);

                this.set('selectedMatchCodes', newMatchCodeGroups);
                this.updateCardReaderDisplay();
            },

            removeMatchCodeGroupFromCart: function removeMatchCodeGroupFromCart(selectedMatchCodeGroup) {
                var newMatchCodeGroups = [].concat(_toConsumableArray(this.get('selectedMatchCodes')));

                var indexToRemove = newMatchCodeGroups.findIndex(function (x) {
                    return x.id === selectedMatchCodeGroup.id;
                });

                newMatchCodeGroups.splice(indexToRemove, 1);

                this.set('selectedMatchCodes', newMatchCodeGroups);
                this.updateCardReaderDisplay();
            },

            selectSection: function selectSection(section) {
                this.set('selectedSection', section);
            },

            connectReaderHandlerInit: function connectReaderHandlerInit() {
                this.findReaders();
            },

            resetCart: function resetCart() {
                this.clearCart();
            },

            clearCurrentCard: function clearCurrentCard() {
                this.clearPaymentMethod();
            },

            selectReader: function selectReader(selectedReader) {

                var self = this;

                //terminal connection is created in routes/card-reader.js

                var terminal = this.get('terminal');
                //var config = {simulated: true};
                this.set('connectingToTerminal', true);
                terminal.connectReader(selectedReader).then(function (connectResult) {
                    if (connectResult.error) {
                        self.set('connectingToTerminal', false);
                        console.log('Failed to connect: ', connectResult.error);
                        self.growl.error('Failed to connect');
                    } else {
                        self.set('connectingToTerminal', false);
                        console.log('Connected to reader: ', connectResult.reader.label);
                        self.growl.success('Connected to reader: ' + connectResult.reader.label);
                        self.set('terminal_connected', true);
                        if (!self.cartIsEmpty) {
                            self.updateCardReaderDisplay();
                        }
                    }
                });
            },

            checkout: function checkout() {
                var self = this;

                self.set('awaitingPaymentMethod', true);
                var giftCards = this.get('selectedGiftCards');
                var matchCodes = this.get('selectedMatchCodes');
                var products = this.get('selectedProducts');
                var email = self.get('userEmail');
                var firstName = self.get('userName');
                var lastName = self.get('userLastName');
                var business = self.get('userBusiness');

                var giftCardIds = giftCards.map(function (giftCardGroup) {
                    return giftCardGroup.id;
                });
                var matchCodeIds = matchCodes.map(function (matchCodeGroup) {
                    return matchCodeGroup.id;
                });
                //Map names of products then filter to be unique
                var productNames = products.map(function (product) {
                    return product.name;
                }).filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });

                var totalToCharge = this.get('processingFeeActive') ? self.get('total') + (Math.round(self.get('total') * 0.0325) + 30) : self.get('total');

                _impactAdminUtilsApi['default'].post('/admin_terminal/create_payment_intent', {
                    total: totalToCharge
                }, function (json) {
                    if (json.error === undefined && json.client_secret) {
                        return json;
                    } else {
                        console.log(json.error);
                    }
                }).then(function (json) {
                    var terminal = self.get('terminal');
                    var clientSecret = json.client_secret;
                    //terminal.setSimulatorConfiguration({testCardNumber: '4242424242424242'});
                    terminal.collectPaymentMethod(clientSecret).then(function (result) {
                        if (result.error) {
                            // Placeholder for handling result.error
                        } else {
                                // Here we are processing result.paymentIntent
                                terminal.processPayment(result.paymentIntent).then(function (result) {
                                    if (result.error) {
                                        // Placeholder for handling result.error
                                    } else if (result.paymentIntent) {
                                            // Placeholder for notifying your backend to capture result.paymentIntent.id

                                            if (giftCardIds) {
                                                result.giftCardIds = giftCardIds;
                                            }

                                            if (matchCodeIds) {
                                                result.matchCodeIds = matchCodeIds;
                                            }

                                            if (productNames) {
                                                result.productNames = productNames;
                                            }

                                            if (email) {
                                                result.email = email;
                                            }

                                            if (firstName) {
                                                result.firstName = firstName;
                                            }

                                            if (lastName) {
                                                result.lastName = lastName;
                                            }

                                            if (business) {
                                                result.business = business;
                                            }

                                            result.totalToCharge = self.get('processingFeeActive') ? self.get('total') + (Math.round(self.get('total') * 0.0325) + 30) : self.get('total');

                                            result.processingFee = self.get('processingFeeActive') ? Math.round(self.get('total') * 0.0325) + 30 : 0;

                                            _impactAdminUtilsApi['default'].post('/admin_terminal/capture_payment_intent', result, function (result) {
                                                if (result.error === undefined && result) {
                                                    self.growl.success('Paid');
                                                    self.set('userEmail', '');
                                                    self.set('userName', '');
                                                    self.set('userLastName', '');
                                                    self.set('userBusiness', '');
                                                    self.set('awaitingPaymentMethod', false);
                                                    self.clearCart();
                                                    self.retrieveCurrentUnpaidOrders();
                                                } else {
                                                    console.log(result.error);
                                                }
                                            });
                                        }
                                });
                            }
                    });
                });
            }

        },
        clearCart: function clearCart() {
            this.clearVirtualDisplay();
            this.clearCardReaderDisplay();
        },

        clearVirtualDisplay: function clearVirtualDisplay() {
            this.set('selectedGiftCards', []);
            this.set('selectedMatchCodes', []);
            this.set('selectedProducts', []);
            this.set('cartIsEmpty', true);
        },

        clearCardReaderDisplay: function clearCardReaderDisplay() {
            var terminal = this.get('terminal');
            this.clearPaymentMethod();
            terminal.clearReaderDisplay();
        },

        clearPaymentMethod: function clearPaymentMethod() {
            var self = this;
            var terminal = self.get('terminal');
            terminal.cancelCollectPaymentMethod().then(function (connectResult) {
                if (connectResult.error) {
                    console.log('Failed to connect: ', connectResult.error);
                    self.growl.error('Failed to connect');
                } else {
                    self.set('awaitingPaymentMethod', false);
                    self.updateCardReaderDisplay();
                    self.growl.success('Cleared payment');
                }
            });
        },

        retrieveCurrentUnpaidOrders: function retrieveCurrentUnpaidOrders() {
            var self = this;
            try {
                _impactAdminModelsCodeGroups['default'].find({ payment_received: false }, {}).then(function (results) {
                    self.set('matchCodeGroups', results.get('content'));
                });
            } catch (ex) {
                console.log(ex);
            }
            try {
                _impactAdminModelsPrepaidCodeGroups['default'].find({ payment_received: false }, {}).then(function (results) {
                    self.set('prepaidCodeGroups', results.get('content'));
                });
            } catch (ex) {
                console.log(ex);
            }
        },

        updateCardReaderDisplay: function updateCardReaderDisplay() {

            var self = this;

            var reader_line_items = [];

            if (this.get('terminal').connectionStatus !== 'not_connected') {

                if (self.get('terminal').collectPaymentMethodAttempt) {
                    self.set('awaitingPaymentMethod', false);
                    self.get('terminal').cancelCollectPaymentMethod().then(function (connectResult) {
                        if (connectResult.error) {
                            self.growl.error('Failed to connect');
                        } else {
                            self.growl.success('Updated payment');
                            self.updateCardReaderDisplay();
                        }
                    });
                }

                var newCardGroups = self.get('selectedGiftCards');
                newCardGroups.forEach(function (cardGroup) {
                    var card_total = 0;
                    cardGroup.cards.forEach(function (card) {
                        card_total += card.original_balance;
                    });
                    reader_line_items.push({
                        description: cardGroup.name + ' (Gift Cards)',
                        amount: card_total,
                        quantity: 1
                    });
                });

                var newMatchCodeGroups = self.get('selectedMatchCodes');
                newMatchCodeGroups.forEach(function (codeGroup) {
                    var match_code_total = 0;
                    match_code_total += codeGroup.match_donations_sum;
                    reader_line_items.push({
                        description: codeGroup.name + ' (Match Codes)',
                        amount: match_code_total,
                        quantity: 1
                    });
                });

                var newProducts = this.get('selectedProducts');
                newProducts.forEach(function (product) {
                    reader_line_items.push({
                        description: product.name,
                        amount: product.price,
                        quantity: 1
                    });
                });

                var totalToCharge = this.get('processingFeeActive') ? self.get('total') + (Math.round(self.get('total') * 0.0325) + 30) : self.get('total');

                if (this.get('processingFeeActive')) {
                    reader_line_items.push({
                        description: 'Processing Fee',
                        amount: Math.round(self.get('total') * 0.0325) + 30,
                        quantity: 1
                    });
                }

                var terminal = this.get('terminal');
                terminal.setReaderDisplay({
                    type: 'cart',
                    cart: {
                        line_items: reader_line_items,
                        tax: 0,
                        total: totalToCharge,
                        currency: 'usd'
                    }
                });
            } else {
                console.log('No terminal connected');
            }
        },

        findReaders: function findReaders() {
            var self = this;

            //terminal connection is created in routes/card-reader.js

            var terminal = this.get('terminal');
            //var config = {simulated: true};
            var config = { simulated: false };
            terminal.discoverReaders(config).then(function (discoverResult) {
                if (discoverResult.error) {
                    console.log('Failed to discover: ', discoverResult.error);
                    self.growl.error('Failed to discover: ' + discoverResult.error);
                } else if (discoverResult.discoveredReaders.length === 0) {
                    console.log('No available readers.');
                    self.growl.error('No readers found.');
                } else {
                    // Just select the first reader here.
                    console.log(discoverResult);

                    // var selectedReader = discoverResult.discoveredReaders[0];

                    self.set('allAvailableTerminalReaders', discoverResult.discoveredReaders);
                    /**
                     terminal.connectReader(selectedReader).then(function (connectResult) {
                            if (connectResult.error) {
                                console.log('Failed to connect: ', connectResult.error);
                                self.growl.error('Failed to connect');
                            } else {
                                console.log('Connected to reader: ', connectResult.reader.label);
                                self.growl.success('Connected to reader: ' + connectResult.reader.label);
                                self.set('terminal_connected', true);
                            }
                        });
                     **/
                }
            });
        }
    });
});