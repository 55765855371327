define('impact-admin/components/form-element', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        attributeBindings: ['draggable', 'src'],
        draggable: 'true',
        dragStart: function dragStart(event) {
            event.dataTransfer.setData('text/plain', JSON.stringify(this.get('data')));
        },

        classNames: ['draggable-dropzone'],
        classNameBindings: ['dragClass'],
        dragClass: 'hideDropLocation',
        dragLeave: function dragLeave(event) {
            event.preventDefault();
            this.set('dragClass', 'hideDropLocation');
        },
        dragOver: function dragOver(event) {
            event.preventDefault();
            this.set('dragClass', 'showDropLocation');
        },
        drop: function drop(event) {
            this.set('dragClass', 'hideDropLocation');
            var data = JSON.parse(event.dataTransfer.getData('text/plain'));

            console.log('Drop at: ' + (parseInt(this.get('index')) + 1));
            console.log(data);
            var new_sort_order = parseInt(this.get('index')) + 1;
            var current_sort_order = data.sort_order;

            console.log('New Position: ' + new_sort_order);
            console.log('Old Position: ' + current_sort_order);

            if (new_sort_order === current_sort_order) {
                this.growl.success('Element has not changed position');
            } else {
                this.sendAction('adjustOrder', current_sort_order, new_sort_order);
            }
        },

        parseAndFormatCurrentSubmittedAnswers: (function () {
            if (this.get('data.type') === 'checkbox') {
                if (this.get('local_user_answer') === 'true') {
                    this.set('local_checkbox_answer', true);
                } else {
                    this.set('local_checkbox_answer', false);
                }
            }
            if (this.get('data.type') === 'multiselect') {

                console.log('Multiselect:');
                console.log(this.get('local_user_answer'));
                if (this.get('local_user_answer')) {
                    this.set('local_multiselect_answer', JSON.parse(this.get('local_user_answer')));
                } else {
                    this.set('local_multiselect_answer', null);
                }
                console.log(this.get('local_multiselect_answer'));
                //this.set('local_user_answer', this.get('local_checkbox_answer'));
            }
        }).on('init'),

        element_select_options: (function () {
            var current_options = this.get('data.select_options');
            if (!current_options) {
                return [];
            }
            if (Array.isArray(current_options)) {
                return current_options;
            } else {
                while (!Array.isArray(current_options)) {
                    current_options = JSON.parse(current_options);
                }
                return current_options;
            }
        }).property('data.select_options'),

        actions: {
            deleteFormElement: function deleteFormElement(element) {
                this.sendAction('delete', element);
            },

            addNew: function addNew(element) {
                this.sendAction('add', element);
            },

            editElement: function editElement(element) {
                console.log(element);
                this.sendAction('edit', element);
            },

            duplicateElement: function duplicateElement(element) {
                console.log(element);
                var deepCopy = _ember['default'].Object.create(JSON.parse(JSON.stringify(element)));
                deepCopy.set('unique_field', null);
                this.sendAction('duplicate', deepCopy);
            }
        }
    });
});