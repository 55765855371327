define('impact-admin/models/good', ['exports', 'ember', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsOrganizations) {
    //import TimeCommitments from './volunteer_time_commitment';
    //import Opportunity_Skills from './volunteer_opp_skills';

    /*
     *Users Model
     */
    var Good = _ember['default'].Model.extend({
        organization: _ember['default'].computed('organization_id', function () {
            return _impactAdminModelsOrganizations['default'].find(this.get('organization_id'));
        })
    });

    Good.table = 'good';

    exports['default'] = Good;
});