define('impact-admin/controllers/frame/matching-codes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        /**
         * Current filter object
         */
        filters: {},

        setFilterFirstTime: (function () {
            this.set('filter', { endDate: "2023" });
        }).on('init'),

        descOrderSort: ['id:desc'],
        codeGroupsSorted: _ember['default'].computed.sort('codeGroups', 'descOrderSort'),

        codes: (function () {
            var _this = this;

            var codes = this.get('codeGroupsSorted'),
                filters = this.get('filters'),
                result = codes;
            _.each(filters, function (value, key) {
                var resolver = 'match' + key.capitalize();

                if (_.isFunction(_this[resolver])) {
                    result = result.filter(_this[resolver], {
                        filterBy: value
                    });
                } else {
                    console.warn('No function found', resolver);
                }
            });
            return result;
        }).property('filters', 'codeGroupsSorted'),

        /**
         * Compares the startDate to a list item
         * @param item The individual item in the list to filter
         * @returns {boolean}
         */
        matchEndDate: function matchEndDate(item) {
            if (this.filterBy === 'all') {
                return true;
            }

            var sanitizedFilterBy = Number(this.filterBy);

            return item && item.end_date && new Date(item.end_date).getFullYear() === sanitizedFilterBy;
        },

        actions: {
            newCode: function newCode() {
                return this.transitionToRoute('frame.matching-codes.new');
            },
            transitionToReport: function transitionToReport(id) {
                return this.transitionToRoute('frame.matching-codes.matching-code-group', id);
            },

            updateFilters: function updateFilters(filters) {
                this.set('filters', filters || {});
            }
        }
    });
});
/* global _ */