define('impact-admin/models/locations', ['exports', 'ember'], function (exports, _ember) {

  /*
   *Locations Model
   */
  var Locations = _ember['default'].Model.extend({
    isSelected: false
  });
  Locations.table = 'locations';

  exports['default'] = Locations;
});