define('impact-admin/components/prepaid-code-totals', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        totalAmount: _ember['default'].computed('giftCardGroup.cards', function () {

            var total_balance = 0;

            this.get('giftCardGroup.cards').forEach(function (card) {
                total_balance += card.original_balance;
            });

            return total_balance;
        })

    });
});