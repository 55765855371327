define('impact-admin/controllers/contact', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        full_name: '',
        email: '',
        phone_number: '',
        organization: '',
        question_comment: '',
        errors: _ember['default'].A(),
        success: null,

        actions: {
            send: function send() {
                var self = this;
                _impactAdminUtilsApi['default'].post('/giving/contact', {
                    full_name: this.get('full_name'),
                    email: this.get('email'),
                    phone_number: this.get('phone_number'),
                    organization: this.get('organization'),
                    question_comment: this.get('question_comment')
                }, function (response) {
                    if (response.error !== undefined) {
                        self.set('success', null);
                        self.set('errors', _ember['default'].A());
                        self.get('errors').pushObject(response.error);
                    } else {
                        self.set('success', response.result);
                        self.set('full_name', '');
                        self.set('email', '');
                        self.set('phone_number', '');
                        self.set('organization', '');
                        self.set('question_comment', '');
                    }
                });
            },
            resetForm: function resetForm() {
                this.reset();
            }
        },

        reset: function reset() {
            this.set('full_name', '');
            this.set('email', '');
            this.set('phone_number', '');
            this.set('organization', '');
            this.set('question_comment', '');
            this.set('success', null);
        }
    });
});