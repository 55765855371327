define('impact-admin/controllers/frame/reports/recurring-donations', ['exports', 'ember', 'impact-admin/models/recurring-donations'], function (exports, _ember, _impactAdminModelsRecurringDonations) {
    exports['default'] = _ember['default'].Controller.extend({

        actions: {
            searchDonations: function searchDonations() {
                var _this = this;

                var charity_name = this.get('charity_name_filter');
                var first_name = this.get('first_name_filter');
                var last_name = this.get('last_name_filter');

                var recurringFilter = {
                    status: {
                        '!=': 'canceled'
                    }
                };

                if (first_name) {
                    recurringFilter['donor.first_name'] = {
                        ilike: first_name + '%'
                    };
                }

                if (last_name) {
                    recurringFilter['donor.last_name'] = {
                        ilike: last_name + '%'
                    };
                }

                if (charity_name) {
                    recurringFilter['charity.name'] = {
                        ilike: charity_name + '%'
                    };
                }

                _impactAdminModelsRecurringDonations['default'].find(recurringFilter).then(function (json) {
                    _this.set('nothingFound', false);
                    if (json.content.length > 0) {
                        _this.set('model', json);
                    } else {
                        _this.set('model', null);
                        _this.set('nothingFound', true);
                    }
                    _this.set('searching', false);
                });
            },
            //Toggles the hidden info associated with this row.
            toggleInfo: function toggleInfo(id) {
                _ember['default'].$(event.target).parents('tr').next(".donation-secondary").find('.hidden-info').slideToggle();

                var el = $('.infoArrow' + id);
                if (el.hasClass('icon-arrow-down')) {
                    console.log('set right', id);
                    el.removeClass('icon-arrow-down');
                    el.addClass('icon-arrow-right');
                } else {
                    console.log('set down', id);
                    el.removeClass('icon-arrow-right');
                    el.addClass('icon-arrow-down');
                }
            }
        }
    });
});
/* global $ */