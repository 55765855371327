define('impact-admin/models/organizations', ['exports', 'ember', 'impact-admin/models/locations', 'impact-admin/models/types', 'impact-admin/models/good-categories', 'impact-admin/utils/api', 'impact-admin/models/organization-donation-suggestions', 'impact-admin/models/organization-keywords', 'impact-admin/models/organization-profile-statuses', 'impact-admin/models/organization-permissions'], function (exports, _ember, _impactAdminModelsLocations, _impactAdminModelsTypes, _impactAdminModelsGoodCategories, _impactAdminUtilsApi, _impactAdminModelsOrganizationDonationSuggestions, _impactAdminModelsOrganizationKeywords, _impactAdminModelsOrganizationProfileStatuses, _impactAdminModelsOrganizationPermissions) {

    /*
     *Organizations Model
     */
    var Organizations = _ember['default'].Model.extend({
        donationAdded: false,
        init: function init() {
            // var self = this;
            // var org_id = this.get('id');
            // if (org_id) {
            //     (function recurse() {
            //         var impactRoom = Ember.SocketIO.join('impact_admin_' + org_id);
            //         if (impactRoom && impactRoom.bind) {
            //             impactRoom.bind('/giving/donations/' + org_id, function(data) {
            //                 self.set('total', data.total_donations);
            //             });
            //         } else {
            //             setTimeout(function() {
            //                 recurse();
            //             }, 100);
            //         }
            //     })();
            // }
        },

        https_banner: _ember['default'].computed('banner', function () {
            if (this.get('banner') === 'red') {
                return 'images/GHD-Charity-Banner-Red.jpg';
            } else if (this.get('banner') === 'blue') {
                return 'images/GHD-Charity-Banner-Blue.jpg';
            } else if (this.get('banner')) {
                return this.get('banner');
            }

            return 'images/GHD-Charity-Banner-Red.jpg';
        }),

        operating_budget_label: _ember['default'].computed('operating_budget', function () {
            if (this.get('operating_budget') === 'under_five_hundred_thousand') {
                return 'Under five-hundred thousand';
            } else if (this.get('operating_budget') === 'over_five_hundred_thousand') {
                return 'Over five-hundred thousand';
            } else {
                return '';
            }
        }),

        matchSum: (function () {
            return (this.get('dmf_match') || 0) + (this.get('raised_match') || 0) + (this.get('lah_match_amount') || 0);
        }).property('dmf_match', 'raised_match'),
        isApproved: (function () {
            return this.get('status') === 'approved';
        }).property('status'),
        isPending: (function () {
            return this.get('status') === 'pending';
        }).property('status'),
        isDeactivated: (function () {
            return this.get('status') === 'deactivated';
        }).property('status'),
        isFreeMember: (function () {
            return this.get('status') === 'freemember';
        }).property('status'),

        percent_to_goal: (function () {
            var goal = parseInt(this.get('ghd_goal'), 10);

            if (goal > 0) {
                var total = parseInt(this.get('total_with_match'), 10);

                var percent = total / goal * 100;
                percent = percent.toFixed(1);

                if (percent) {
                    if (!isNaN(percent)) {
                        return percent;
                    }
                    return 0;
                } else {
                    return 0;
                }
            }
            return 100;
        }).property('total_with_match', 'ghd_goal'),

        total_with_business_match: _ember['default'].computed('match_used', 'total_with_match', function () {
            //This is overall total from server
            var total_with_match = parseInt(this.get('total_with_match'));

            //This is calculated total that does not have biz match
            var match_used = parseInt(this.get('match_used'));

            return total_with_match - match_used > 0 ? total_with_match - match_used : 0;
        }),

        //A lot of unnecessary client side code, make sure this matches all the places we are calculating match in the impact API
        //calculate_match(total + code_group_match_total, dmf_match, raised_match, id)

        match_used: _ember['default'].computed('total', 'code_group_match_total', 'donations_total_with_match', function () {
            var total = parseInt(this.get('total')) + parseInt(this.get('code_group_match_total'));
            var with_match = parseInt(this.get('donations_total_with_match'));

            return with_match - total > 0 ? with_match - total : 0;
        }),

        donations_total_with_match: (function () {

            return this.get('total_with_match');
        }).property('total_with_match'),

        isVideoYoutube: (function () {
            var video = this.get('video');
            return video;
        }).property('video'),
        isVideoVimeo: (function () {
            var video = this.get('video');
            return video;
        }).property('video'),

        addBoardMember: function addBoardMember(name, title) {
            var board_members = this.get('board_members');

            if (board_members === null) {
                board_members = [];
            } else if (!Array.isArray(board_members)) {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
            }

            board_members.push({
                name: name,
                title: title
            });
            var newArr = [];
            for (var i = 0; i < board_members.length; i++) {
                newArr.push({
                    name: board_members[i].name,
                    title: board_members[i].title
                });
            }

            this.set('board_members', newArr);
        },
        moveBoardMemberUp: function moveBoardMemberUp(idx) {
            //don't do anything if it's already the first element in the array
            if (idx <= 0) {
                return;
            }
            var board_members = this.get('board_members');
            if (board_members === null) {
                board_members = [];
            } else if (!Array.isArray(board_members)) {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
            }
            var boardMemberCopy = JSON.parse(JSON.stringify(board_members));
            var memberToSwap = boardMemberCopy[idx - 1];
            boardMemberCopy[idx - 1] = boardMemberCopy[idx];
            boardMemberCopy[idx] = memberToSwap;

            this.set('board_members', boardMemberCopy);
        },
        moveBoardMemberDown: function moveBoardMemberDown(idx) {
            var board_members = this.get('board_members');
            if (board_members === null) {
                board_members = [];
            } else if (!Array.isArray(board_members)) {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
            }

            //don't do anything if it's already the last element in the array
            if (idx >= board_members.length - 1) {
                return;
            }
            var boardMemberCopy = JSON.parse(JSON.stringify(board_members));
            var memberToSwap = boardMemberCopy[idx + 1];
            boardMemberCopy[idx + 1] = boardMemberCopy[idx];
            boardMemberCopy[idx] = memberToSwap;

            this.set('board_members', boardMemberCopy);
        },
        editBoardMember: function editBoardMember(name, title, idx) {
            console.log(name, title);
            var board_members = this.get('board_members');
            if (board_members === null) {
                board_members = [];
                this.set('board_members', board_members);
                return;
            } else if (!Array.isArray(board_members)) {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
            }

            board_members = board_members.map(function (member, index) {
                if (index === idx) {
                    return {
                        name: name,
                        title: title
                    };
                }
                return member;
            });
            this.set('board_members', JSON.parse(JSON.stringify(board_members)));
        },
        delBoardMember: function delBoardMember(idx) {

            var resp = confirm("Remove this Board Member?");
            if (!resp) {
                return;
            }
            var board_members = this.get('board_members');

            if (board_members === null) {
                board_members = [];
                this.set('board_members', board_members);
                return;
            } else if (!Array.isArray(board_members)) {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
            }

            board_members.splice(idx, 1);
            var newArr = [];
            for (var i = 0; i < board_members.length; i++) {
                newArr.push({
                    name: board_members[i].name,
                    title: board_members[i].title
                });
            }

            this.set('board_members', newArr);
        },

        allBoardMembers: (function () {
            var board_members = this.get('board_members');
            if (!board_members) {
                return [];
            }
            if (Array.isArray(board_members)) {
                return board_members;
            } else {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
                return board_members;
            }
        }).property('board_members'),

        addWishlist: function addWishlist(name, link) {
            var board_members = this.get('good_wishlist_url');

            if (board_members === null) {
                board_members = [];
            } else if (!Array.isArray(board_members)) {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
            }

            board_members.push({
                name: name,
                link: link
            });
            var newArr = [];
            for (var i = 0; i < board_members.length; i++) {
                newArr.push({
                    name: board_members[i].name,
                    link: board_members[i].link
                });
            }

            this.set('good_wishlist_url', newArr);
        },

        delWishlist: function delWishlist(idx) {

            var resp = confirm("Remove this Wishlist URL?");
            if (!resp) {
                return;
            }
            var board_members = this.get('good_wishlist_url');

            if (board_members === null) {
                board_members = [];
                this.set('good_wishlist_url', board_members);
                return;
            } else if (!Array.isArray(board_members)) {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
            }

            board_members.splice(idx, 1);
            var newArr = [];
            for (var i = 0; i < board_members.length; i++) {
                newArr.push({
                    name: board_members[i].name,
                    link: board_members[i].link
                });
            }

            this.set('good_wishlist_url', newArr);
        },

        wishlistParsed: (function () {
            var board_members = this.get('good_wishlist_url');
            if (!board_members) {
                return [];
            }
            if (Array.isArray(board_members)) {
                return board_members;
            } else {
                while (!Array.isArray(board_members)) {
                    board_members = JSON.parse(board_members);
                }
                return board_members;
            }
        }).property('good_wishlist_url'),

        locationObjects: (function () {
            var locations = this.get('locations');
            if (locations && locations.length > 0) {
                var location_ids = _.pluck(locations, 'id');

                return _impactAdminModelsLocations['default'].find({
                    id: location_ids
                });
            } else {
                return [];
            }
        }).property('locations.[]'),

        typeObjects: (function () {
            var types = this.get('types');
            if (types) {
                var type_ids = _.pluck(types, 'id');

                return _impactAdminModelsTypes['default'].find({
                    id: type_ids
                });
            } else {
                return [];
            }
        }).property('types.[]'),

        goodObjects: (function () {
            var types = this.get('good_charity_categories');
            if (types) {
                var type_ids = _.pluck(types, 'id');

                return _impactAdminModelsGoodCategories['default'].find({
                    id: type_ids
                });
            } else {
                return [];
            }
        }).property('goodCharityCategories.[]'),

        stripe_url: (function () {
            return 'https://connect.stripe.com/oauth/authorize?response_type=code&redirect_uri=' + _impactAdminUtilsApi['default'].stripeReturn + '&client_id=' + _impactAdminUtilsApi['default'].stripeClient + '&scope=read_write&state=' + this.get('id');
        }).property('org_id'),

        //Convert this amount to dollars since it comes in cents
        ghd_goal_dollars: _ember['default'].computed('ghd_goal', function () {
            if (this.get('ghd_goal') && this.get('ghd_goal') > 0) {
                return this.get('ghd_goal') / 100;
            } else {
                return 0;
            }
        }),

        //Convert this amount to dollars since it comes in cents
        dmf_match_dollars: _ember['default'].computed('dmf_match', function () {
            if (this.get('dmf_match') && this.get('dmf_match') > 0) {
                return this.get('dmf_match') / 100;
            } else {
                return 0;
            }
        }),

        scheduled_goal_dollars: _ember['default'].computed('scheduled_goal', function () {
            if (this.get('scheduled_goal') && this.get('scheduled_goal') > 0) {
                return this.get('scheduled_goal') / 100;
            } else {
                return 0;
            }
        }),

        //Convert this amount to dollars since it comes in cents
        raised_match_dollars: _ember['default'].computed('raised_match', function () {
            if (this.get('raised_match') && this.get('raised_match') > 0) {
                return this.get('raised_match') / 100;
            } else {
                return 0;
            }
        }),

        donation_suggestions: _ember['default'].computed('id', function () {
            return _impactAdminModelsOrganizationDonationSuggestions['default'].find({
                organization_id: this.get('id')
            });
        }),

        keywords_objects: _ember['default'].computed('id', function () {
            return _impactAdminModelsOrganizationKeywords['default'].find({
                organization_id: this.get('id')
            });
        }),

        general_donation_suggestions: _ember['default'].computed.filter('donation_suggestions', function (suggestion) {
            return suggestion.get('type') === 'general';
        }),

        ghd_donation_suggestions: _ember['default'].computed.filter('donation_suggestions', function (suggestion) {
            return suggestion.get('type') === 'ghd';
        }),

        profile_statuses: _ember['default'].computed('id', function () {
            return _impactAdminModelsOrganizationProfileStatuses['default'].findFirst({
                organization_id: this.get('id')
            });
        }),

        permissions: _ember['default'].computed('id', function () {
            return _impactAdminModelsOrganizationPermissions['default'].findFirst({
                organization_id: this.get('id')
            });
        }),

        isDmfOrImpact: _ember['default'].computed('name', function () {
            var name = this.get('name').toUpperCase();
            //This is the convention for naming DMF and Impact funds
            return name.indexOf('DMF - ') > -1 || name.indexOf('IMPACT - ') > -1;
        })

    });

    Organizations.table = 'organizations';

    Organizations.statuses = [{
        label: 'Active',
        value: 'active'
    }, {
        label: 'Deactivated',
        value: 'deactivated'
    }];

    exports['default'] = Organizations;
});
/* globals _ */