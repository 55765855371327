define('impact-admin/models/recurring-donations', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/users', 'impact-admin/models/donations', 'moment'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsUsers, _impactAdminModelsDonations, _moment) {

    /*
     * Recurring Donations Model
     */
    var RecurringDonations = _ember['default'].Model.extend({
        charge_amount: _ember['default'].computed('quantity', 'plan_amount', function () {
            var amount = this.get('quantity') * this.get('plan_amount');
            return amount;
        }),

        dollarAmount: _ember['default'].computed('amount', function () {
            return (this.get('amount') / 100).toFixed(2);
        }),

        donor: _ember['default'].computed('user_id', function () {
            return _impactAdminModelsUsers['default'].find(this.get('user_id'));
        }),

        charity: _ember['default'].computed('organization_id', function () {
            return _impactAdminModelsOrganizations['default'].find(this.get('organization_id'));
        }),

        isYearly: _ember['default'].computed('plan_interval', function () {
            return this.get('plan_interval') === 'year';
        }),

        isMonthly: _ember['default'].computed('plan_interval', function () {
            return this.get('plan_interval') === 'month';
        }),

        nextGiftDate: _ember['default'].computed('current_period_end', function () {
            var date = _moment['default'].unix(this.get('current_period_end'));

            date.add(1, 'days');

            return date.unix();
        }),
        startDate: _ember['default'].computed("trial_start", function () {
            var date = _moment['default'].unix(this.get('trial_start'));

            date.add(1, 'days');

            return date.format("LL");
        }),

        endDate: _ember['default'].computed("ended_at", function () {
            var date = null;
            var isEnded = this.get('ended_at');
            if (!isEnded) {
                date = _moment['default'].unix(this.get('start'));
                date.add(1, 'years');
            } else {
                date = _moment['default'].unix(this.get('ended_at'));
                date.add(1, 'days');
            }
            if (date) {
                return date.format("LL");
            } else {
                return '';
            }
        }),

        statusLabel: _ember['default'].computed('status', function () {
            var status = this.get('status');

            if (status === 'trialing' || status === 'active') {
                return 'active';
            } else if (status === 'canceled') {
                var subStatus = this.get('sub_status');

                return [status, subStatus].filter(Boolean).join(" - ");
            } else {
                return this.get('status');
            }
        }),

        isCanceled: _ember['default'].computed('status', function () {
            return this.get('status') === 'canceled';
        }),

        allDonations: _ember['default'].computed('id', function () {
            return _impactAdminModelsDonations['default'].find({
                recurring_id: this.get('id')
            });
        })

    });

    RecurringDonations.table = 'recurring_donations';

    exports['default'] = RecurringDonations;
});