define('impact-admin/services/growl-notifications', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        notifications: _ember['default'].A(),

        error: function error(context, opts) {
            opts = opts || {};
            opts.type = 'error';
            this._notify.call(this, context, opts);
        },
        warning: function warning(context, opts) {
            opts = opts || {};
            opts.type = 'warning';
            this._notify.call(this, context, opts);
        },
        info: function info(context, opts) {
            opts = opts || {};
            opts.type = 'info';
            this._notify.call(this, context, opts);
        },
        success: function success(context, opts) {
            opts = opts || {};
            opts.type = 'success';
            this._notify.call(this, context, opts);
        },

        _notify: function _notify(context, opts) {
            // default options
            var options = {
                type: 'error',
                fadeIn: true,
                closeIn: 5000, // automatically close in 5 seconds.
                clickToDismiss: false
            };

            _ember['default'].merge(options, opts);

            //This is helpful for not showing the same notifications twice
            //var existing = this.get('notifications').findBy('content', context);
            //if (existing) {
            //    return;
            //}

            var notification = _ember['default'].ObjectProxy.extend({
                content: context,
                options: options,
                updated: 0,
                isInfo: (function () {
                    return options.type === 'info';
                }).property(),
                isAlert: (function () {
                    return options.type === 'warning';
                }).property(),
                isError: (function () {
                    return options.type === 'error';
                }).property(),
                isSuccess: (function () {
                    return options.type === 'success';
                }).property()
            }).create();

            this.get('notifications').pushObject(notification);
        }
    });
});