define('impact-admin/helpers/limit-length', ['exports', 'ember'], function (exports, _ember) {
    exports.limitLength = limitLength;

    function limitLength(params) {
        var string = params[0];
        var length = params[1];
        if (string == null) {
            return string;
        }
        var newString = '';
        if (string.length > length) {
            newString = string.slice(0, length - 3);
            newString += '...';
            return newString;
        }
        return string;
    }

    exports['default'] = _ember['default'].Helper.helper(limitLength);
});