define('impact-admin/controllers/frame/links', ['exports', 'ember', 'impact-admin/models/links'], function (exports, _ember, _impactAdminModelsLinks) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        filteredModel: _ember['default'].computed('links', 'searchTerm', function () {
            var _this = this;

            var searchTerm = this.get('searchTerm');
            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    _this.get('links').forEach(function (link) {
                        if (!link.get('removed') && link.get('title').toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(link);
                        }
                    }, _this);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            } else {
                return this.get('links');
            }
        }),

        linksPreview: _ember['default'].computed('links', function () {
            var matches = [];
            this.get('links').forEach(function (link) {
                if (!link.get('removed') && link.get('active')) {
                    matches.push(link);
                }
            }, this);

            return matches;
        }),

        actions: {
            validateProperty: function validateProperty(changeset, property) {
                return changeset.validate(property);
            },

            save: function save(link, isNew) {
                var _this2 = this;

                var self = this;
                if (isNew) {

                    link.save().then(function (json) {
                        if (json.response.error) {
                            _this2.growl.error('There was an error saving your changes. ' + json.response.error);
                        } else {
                            _this2.growl.success('Changes Saved');
                            self.set('links', self.get('links.content').concat([link.content]));
                            self.set('newLink', null);
                        }
                    });
                } else {
                    link.save().then(function (json) {
                        if (json.response.error) {
                            _this2.growl.error('There was an error saving your changes. ' + json.response.error);
                        } else {
                            if (isNew) {
                                _this2.set('newLink', null);
                            }
                            _this2.growl.success('Changes Saved');
                        }
                    });
                }
                this.set('isDuplicate', false);
            },
            addNew: function addNew() {
                this.set('newLink', _impactAdminModelsLinks['default'].createRecord());
            },
            cancelAddNew: function cancelAddNew() {
                this.set('newLink', null);
            },
            remove: function remove(link) {
                if (confirm('Are you sure you want to delete this calendar entry?')) {
                    link.set('removed', true);
                    link.save().then(function () {});
                }
            },
            publishOpp: function publishOpp(link) {
                link.save();
            },
            setActive: function setActive(link) {
                link.set('active', !link.get('active'));
                link.save().then(function () {
                    //growl?
                });
            }
        }
    });
});