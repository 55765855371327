define('impact-admin/controllers/frame/tools/contest-winners', ['exports', 'ember', 'impact-admin/models/winners', 'impact-admin/utils/api', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsWinners, _impactAdminUtilsApi, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Controller.extend({
        ghdYear: '2025',
        limit: 1,
        query: 'all',
        yearOptions: ['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025'].reverse(),
        queryOptions: ['all', 'donors', 'goods/volunteer'],
        winners: [],
        winner: {},

        winnerToEdit: null,
        description: '',
        selectedOrg: null,
        sortedCharities: (function () {
            var charities = _ember['default'].A([]);
            var isImpact = this.get('mode.is_impact_app');
            this.get('model').forEach(function (charity) {
                if (charity.ghd_access || !isImpact) {
                    charities.pushObject(charity);
                }
            });
            return charities.sortBy('name');
        }).property('model'), // Ember.computed.sort('model', 'sortProps'),

        actions: {
            yearChange: function yearChange(year) {
                this.set('ghdYear', year.target.value);
            },
            queryChange: function queryChange(query) {
                this.set('query', query.target.value);
            },
            getWinner: function getWinner() {
                var _this = this;

                _impactAdminUtilsApi['default'].get('/winners/draw-random-winners', { limit: this.get('limit'), ghd_year: this.get('ghdYear'), query: this.get('query') }, function (json) {
                    if (json.error) {
                        _this.growl.error('There was an error retrieving winners');
                        return;
                    }
                    _this.set('winner', json.result[0]);
                    _this.growl.success('Winners Chosen!');
                });
            },
            unattachWinner: function unattachWinner() {
                _ember['default'].set(this.get('winner'), 'first_name', null);
                _ember['default'].set(this.get('winner'), 'email', null);
            },
            saveWinners: function saveWinners() {
                var _this2 = this;

                var winner = this.get('winner');
                var selectedOrg = this.get('selectedOrg');
                if (winner.name) {
                    _ember['default'].set(winner, 'org_name', winner.name);
                } else {
                    _ember['default'].set(winner, 'org_name', selectedOrg.name);
                    _ember['default'].set(winner, 'org_id', selectedOrg.id);
                }
                if (!winner.description) {
                    this.growl.error('You must add a description before saving!');
                    return;
                } else if (!winner.amount) {
                    this.growl.error('You must add an amount before saving!');
                    return;
                }
                _ember['default'].set(winner, 'amount', Math.floor(winner.amount));
                var ghdYear = this.get('ghdYear');
                var savePromises = function savePromises(winner) {
                    if (winner.name) {
                        winner.org_name = winner.name;
                    }
                    if (ghdYear) {
                        winner.ghd_year = ghdYear;
                    }
                    var newWinner = _impactAdminModelsWinners['default'].createRecord(winner);
                    return newWinner.save();
                };
                savePromises(winner).then(function () {
                    _this2.growl.success('Records saved successfully.');
                    //this.actions.downloadCSV(winners);
                })['catch'](function (reason) {
                    _this2.growl.error('There was an error saving one or more records.');
                    console.error(reason);
                });
            },
            toggleDisplayed: function toggleDisplayed(winner) {
                var _this3 = this;

                _ember['default'].set(winner, 'display', !winner.display);
                winner.save().then(function () {
                    if (winner.display) {
                        _this3.growl.success("Record will now be displayed");
                    } else {
                        _this3.growl.success("Record will no longer be displayed");
                    }
                })['catch'](function () {
                    _this3.growl.error("There was an error toggling display");
                });
            },
            deleteWinner: function deleteWinner(winner) {
                var _this4 = this;

                if (confirm('Are you sure you want to delete the ticket \'' + winner.description + '\'?')) {
                    winner.deleteRecord().then(function () {
                        _this4.growl.success("Record Deleted!");
                    })['catch'](function (error) {
                        _this4.growl.error("Something went wrong deleting the record.");
                        console.log(error);
                    });
                }
            },
            editWinner: function editWinner() {
                var _this5 = this;

                var selectedOrg = this.get('selectedOrg');
                var winnerToEdit = this.get('winnerToEdit');
                _ember['default'].set(winnerToEdit, 'org_name', selectedOrg.name);
                _ember['default'].set(winnerToEdit, 'org_id', selectedOrg.id);
                _ember['default'].set(winnerToEdit, 'amount', Math.floor(winnerToEdit.amount));
                winnerToEdit.save().then(function () {
                    _this5.set('isEditingWinner', false);
                    _this5.growl.success('Winner Edited!');
                })['catch'](function () {
                    _this5.growl.error('Error changing Record.');
                });
            },
            setWinnerToEdit: function setWinnerToEdit(winner) {
                var _this6 = this;

                this.set('winnerToEdit', winner);
                _impactAdminModelsOrganizations['default'].find(winner.org_id).then(function (org) {
                    _this6.set('selectedOrg', org);
                });
                this.toggleProperty('isEditingWinner');
            },
            cancelEdit: function cancelEdit() {
                this.set('isEditingWinner', false);
            },
            downloadCSV: function downloadCSV(winners) {
                var rows = [['Organization', 'Donor Business', 'First Name', 'Last Name', 'Email', 'GHD Year', 'Is Winner', 'Description']];

                winners.map(function (winner, index) {
                    rows[index + 1] = [winner.name, winner.business, winner.first_name, winner.last_name, winner.email, winner.ghd_year, winner.is_winner, winner.description];
                });

                var content = '',
                    newLine = '\r\n';
                for (var i = 0; i < rows.length; i++) {
                    var line = rows[i];
                    var j = 0;
                    for (var x = 0; x < line.length; x++) {
                        var item = line[x];
                        var it = item.replace(/"/g, '""');
                        if (it.search(/("|,|\n)/g) >= 0) {
                            it = '"' + it + '"';
                        }
                        content += (j > 0 ? ',' : '') + it;
                        ++j;
                    }
                    content += newLine;
                }
                var BOM = '﻿';
                var blob = new Blob([BOM + content], { type: 'text/csv;charset=utf-8' });

                var url = window.URL.createObjectURL(blob);
                var linkElem = document.createElement('a');
                linkElem.href = url;
                //set the file name
                console.log(new Date());
                linkElem.setAttribute('download', 'donations_raffle_' + this.get('ghdYear'));
                linkElem.click();
            }
        }

    });
});