define('impact-admin/routes/frame/profile', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/organization-locations', 'impact-admin/models/types', 'impact-admin/models/good-categories', 'impact-admin/models/organization-profile-statuses', 'impact-admin/models/organization-permissions', 'impact-admin/models/organization-documents'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsOrganizationLocations, _impactAdminModelsTypes, _impactAdminModelsGoodCategories, _impactAdminModelsOrganizationProfileStatuses, _impactAdminModelsOrganizationPermissions, _impactAdminModelsOrganizationDocuments) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.profile'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var organization = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            console.log(organization);
            var locations = _impactAdminModelsOrganizationLocations['default'].find({
                organization_id: params.charity_id
            });
            var types = _impactAdminModelsTypes['default'].find();
            var goodTypes = _impactAdminModelsGoodCategories['default'].find();
            var profile_statuses = _impactAdminModelsOrganizationProfileStatuses['default'].findFirst({
                organization_id: params.charity_id
            });
            var permissions = _impactAdminModelsOrganizationPermissions['default'].findFirst({
                organization_id: params.charity_id
            });

            var pdfDocuments = _impactAdminModelsOrganizationDocuments['default'].find({
                organization_id: params.charity_id
            });
            return _ember['default'].RSVP.hash({
                organization: organization,
                locations: locations,
                types: types,
                goodTypes: goodTypes,
                profile_statuses: profile_statuses,
                permissions: permissions,
                pdfDocuments: pdfDocuments
            });
        }
    });
});