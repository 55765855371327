define('impact-admin/routes/frame/donations/recurring', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        setupController: function setupController(controller, model) {

            controller.set('charity', model.charity);
            controller.set('isImpact', this.controllerFor('application').get('isImpact'));
            controller.set('model', model.recurring_donations.content.reverse());
        }
    });
});