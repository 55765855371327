define('impact-admin/models/organization-match', ['exports', 'ember'], function (exports, _ember) {

  /*
   *Users Model
   */
  var OrganizationMatch = _ember['default'].Model.extend({});

  OrganizationMatch.table = 'organization_match';

  exports['default'] = OrganizationMatch;
});