define('impact-admin/helpers/total-of-array', ['exports', 'ember'], function (exports, _ember) {

    //Converts from cents to dollars.
    var helper = _ember['default'].Helper.helper(function (params) {
        var fieldToAddUp = params[0];
        var arrayToLoopThrough = params[1];
        if (!fieldToAddUp || !arrayToLoopThrough) {
            return false;
        }

        var total = 0;

        var field = fieldToAddUp.toString();

        arrayToLoopThrough.forEach(function (arrayItem) {
            total += arrayItem[field];
        });

        return total;
    });

    exports['default'] = helper;
});