define('impact-admin/routes/frame/scheduled-donations', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/donations', 'impact-admin/models/organization-campaigns', 'impact-admin/models/scheduled-donations', 'impact-admin/mixins/table'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsDonations, _impactAdminModelsOrganizationCampaigns, _impactAdminModelsScheduledDonations, _impactAdminMixinsTable) {
    exports['default'] = _ember['default'].Route.extend({

        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.scheduled-donations'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var charity = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var donations = _impactAdminModelsDonations['default'].find({
                organization_id: params.charity_id
            }, {
                sort: {
                    date: 'desc'
                },
                limit: 100,
                offset: 0
            });

            var campaigns = _impactAdminModelsOrganizationCampaigns['default'].find({
                organization_id: params.charity_id
            });

            var scheduled_donations = _impactAdminModelsScheduledDonations['default'].find({
                organization_id: params.charity_id,
                ghd_year: '2023'
            }, {
                sort: {
                    date_created: 'desc'
                },
                limit: 50,
                offset: 0
            });

            return _ember['default'].RSVP.hash({
                charity: charity,
                donations: donations,
                campaigns: campaigns,
                scheduled_donations: scheduled_donations
            });
        },

        setupController: function setupController(controller, model) {

            if (model.scheduled_donations.content && model.scheduled_donations.content.length > 0) {
                var num_donations = model.scheduled_donations.content[0].full_count;
                controller.set('donations_count', num_donations);
            } else {
                controller.set('donations_count', 0);
            }

            controller.set('lowerLimit', 0);
            if (model.scheduled_donations.content && model.scheduled_donations.content.length < 50) {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', model.scheduled_donations.content.length);
            } else {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', 50);
            }

            controller.set('charity', model.charity);
            //controller.set('donations', model.donations);
            controller.set('campaigns', model.campaigns);
            console.log(model.scheduled_donations);
            controller.set('scheduled_donations', model.scheduled_donations);
            controller.set('scheduled_transactions', model.scheduled_transactions);

            _impactAdminMixinsTable['default'].apply(controller);
        }

    });
});