define('impact-admin/routes/frame/goods-pledges', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/volunteer', 'impact-admin/models/volunteer-skills', 'impact-admin/models/volunteer-submissions', 'impact-admin/models/good-categories', 'impact-admin/models/good', 'impact-admin/models/good-charity-submission'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsVolunteer, _impactAdminModelsVolunteerSkills, _impactAdminModelsVolunteerSubmissions, _impactAdminModelsGoodCategories, _impactAdminModelsGood, _impactAdminModelsGoodCharitySubmission) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.goods-pledges'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var charity = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var volunteer = _impactAdminModelsVolunteer['default'].find({
                organization_id: params.charity_id,
                removed: false
            });
            var goods = _impactAdminModelsGood['default'].find({
                organization_id: params.charity_id,
                removed: false
            });

            var goodTypes = _impactAdminModelsGoodCategories['default'].find();
            var skills = _impactAdminModelsVolunteerSkills['default'].find();
            var charity_id = params.charity_id;

            return _ember['default'].RSVP.hash({
                charity: charity,
                volunteer: volunteer,
                skills: skills,
                charity_id: charity_id,
                goods: goods,
                goodTypes: goodTypes
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model.volunteer);
            controller.set('charity', model.user);
            controller.set('skills', model.skills);
            controller.set('goods', model.goods);
            controller.set('goodTypes', model.goodTypes);

            var volunteer_ids = model.volunteer.getEach('id');
            model.submissions = _impactAdminModelsVolunteerSubmissions['default'].find({
                volunteer_id: volunteer_ids
            });

            model.submissions = _impactAdminModelsGoodCharitySubmission['default'].find({
                organization_id: model.charity.id
            });
        }
    });
});