define('impact-admin/routes/frame/form', ['exports', 'ember', 'impact-admin/models/forms', 'impact-admin/models/form-submissions', 'impact-admin/models/form-elements', 'impact-admin/models/form-submission-data', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsForms, _impactAdminModelsFormSubmissions, _impactAdminModelsFormElements, _impactAdminModelsFormSubmissionData, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model(params) {
            var user_org_id = this.controllerFor('application').get('user.organization_id');

            var form_id = params.form_id;

            var forms = _impactAdminModelsForms['default'].find({
                id: form_id
            });

            var form_submissions = _impactAdminModelsFormSubmissions['default'].find({
                form_id: form_id,
                organization_id: user_org_id
            });

            var form_elements = _impactAdminModelsFormElements['default'].find({
                form_id: form_id
            });

            var form_submission_data = _impactAdminModelsFormSubmissionData['default'].find({
                form_id: form_id,
                organization_id: user_org_id
            });

            var organization = _impactAdminModelsOrganizations['default'].find({
                id: user_org_id
            });

            return _ember['default'].RSVP.hash({
                forms: forms,
                form_submissions: form_submissions,
                form_elements: form_elements,
                form_submission_data: form_submission_data,
                organization: organization
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('form', model.forms.content[0]);
            controller.set('form_submission', model.form_submissions.content[0]);
            controller.set('form_submission_data', model.form_submission_data.content.sortBy('sort_order'));
            controller.set('form_elements', model.form_elements.content);
            controller.set('organization', model.organization.content[0]);
        }

    });
});