define('impact-admin/routes/frame/reports/emails', ['exports', 'ember', 'impact-admin/models/users'], function (exports, _ember, _impactAdminModelsUsers) {

    var emailOptInReport = _ember['default'].Route.extend({
        model: function model() {
            return _impactAdminModelsUsers['default'].find({
                ghd_email_opt_in: true
            });
        }
    });

    exports['default'] = emailOptInReport;
});