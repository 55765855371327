define('impact-admin/routes/frame/manage', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/types', 'impact-admin/models/organization-profile-statuses', 'impact-admin/models/good-categories'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsTypes, _impactAdminModelsOrganizationProfileStatuses, _impactAdminModelsGoodCategories) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            return _ember['default'].RSVP.hash({
                organizations: _impactAdminModelsOrganizations['default'].find({
                    status: ['active', 'pending']
                }),
                archived_organizations: _impactAdminModelsOrganizations['default'].find({
                    status: 'deactivated'
                }),
                types: _impactAdminModelsTypes['default'].find(),
                profile_statuses: _impactAdminModelsOrganizationProfileStatuses['default'].find(),
                good_categories: _impactAdminModelsGoodCategories['default'].find()
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('archived_organizations', model.archived_organizations);
            controller.set('organizations', model.organizations);
            controller.set('types', model.types);
            controller.set('good_categories', model.good_categories);
        }
    });
});