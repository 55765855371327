define('impact-admin/components/ghd-resource-document-upload', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    newPDF: {},
    saveDisabled: true,
    didInsertElement: function didInsertElement() {
      var self = this;
      var form = this.$().find('form');
      var template_id = self.get('template_id');

      form.transloadit({
        wait: true,
        autoSubmit: false,
        triggerUploadOnFileSelection: false,
        processZeroFiles: false,
        params: {
          "auth": {
            "key": "2fbd6575d1c844d2b664c17126d34e70"
          },
          "template_id": template_id
        },
        onFileSelect: function onFileSelect(filename) {
          // Remove weird filepath that Transloadit adds
          filename = filename.replace("C:\\fakepath\\", '');
          form.submit();

          // if (!self.validate(filename)) {
          //   Ember.run.debounce(self, function () {
          //     self.growl.error('File is not a valid file type');
          //   }, 1000);
          // } else {
          //   self.set('saveDisabled', false);
          //   form.submit();
          // }
        },
        onStart: function onStart() {
          //console.log(assembly);
        },
        onCancel: function onCancel() {
          self.growl.warning('File upload cancelled');
        },
        onSuccess: function onSuccess(assembly) {
          console.log('sucessfully uploaded', assembly);
          if (assembly && assembly.results && assembly.results['public']) {
            var result = assembly.results['public'][0];

            if (result) {
              var tempFileData = {
                url: result.ssl_url,
                title: result.name
              };
              self.sendAction("onUpload", tempFileData);
            } else {
              self.growl.warning('You must select a file before attempting to save it');
              return;
            }
          } else {
            self.growl.warning('Invalid file type. File was not saved.');
          }
        },
        onError: function onError() {
          self.growl.warning('There was an error while uploading this file. Make sure that it is a proper file type.');
        }
      });
      this._super.apply(this, arguments);
    },

    validate: function validate(file) {
      // Not sure if there's any validation we need for resource uploading
      var ext = file.split('.').pop().toLowerCase();
      var type = false;
      var extOptions = this.get('extTypes') || ['pdf'];

      for (var i = 0; i < extOptions.length; i++) {
        if (extOptions[i] === ext) {
          type = true;
          break;
        }
      }

      return type;
    }
  });
});