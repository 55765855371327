define('impact-admin/helpers/format-dollars-no-cents', ['exports', 'ember', 'numeral'], function (exports, _ember, _numeral) {
  exports.formatDollarsNoCents = formatDollarsNoCents;

  function formatDollarsNoCents(params) {
    var dollars = params[0];
    if (!dollars) {
      return "0";
    }
    dollars = (0, _numeral['default'])(Math.round(dollars)).format('0,0');
    return _ember['default'].String.htmlSafe(dollars);
  }

  exports['default'] = _ember['default'].Helper.helper(formatDollarsNoCents);
});