define('impact-admin/controllers/frame/reports', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        selectedReport: 'donations-by-type',

        reports: [{ title: 'Donations By Focus', value: 'donations-by-type' }, { title: 'Stripe Report', value: 'stripe' }, { title: 'Recurring Donations', value: 'recurring-donations' }, { title: 'Scheduled Donations', value: 'scheduled-donations' }, { title: 'Online Gift Card Purchases', value: 'online-prepaid-codes' }, { title: 'Admin Users', value: 'admin-users' }, { title: 'Email Opt Ins', value: 'emails' }, { title: 'Charity Contact Info', value: 'orgInfo' }, { title: 'Volunteer Opps Report', value: 'volunteer-opps-report' }],

        actions: {
            setSelectedReport: function setSelectedReport(report) {
                this.set('selectedReport', report);
                this.transitionToRoute('frame.reports.' + report);
            }
        }
    });
});