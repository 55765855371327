define('impact-admin/controllers/frame/reports/campaigns/new', ['exports', 'ember', 'impact-admin/models/campaigns'], function (exports, _ember, _impactAdminModelsCampaigns) {
    exports['default'] = _ember['default'].Controller.extend({
        needs: ["AdminProfileCampaigns"],

        title: '',
        fancy_start_date: null,
        fancy_end_date: null,
        description: '',
        org_id: null,

        actions: {
            save: function save() {

                this.set('org_id', this.controllerFor("admin.profile.campaigns").get('model.org_id'));

                var model = _impactAdminModelsCampaigns['default'].createRecord({
                    description: this.get('description'),
                    start_date: this.get('fancy_start_date'),
                    end_date: this.get('fancy_end_date'),
                    organization_id: this.get('org_id'),
                    title: this.get('title')
                });

                var newRecord = model.save();
                var self = this;

                newRecord.then(function (res) {
                    if (res.response.error === null) {
                        self.controllerFor("admin.profile.Campaigns").set("campaign_id", res.response.result[0].id);
                        self.transitionToRoute("admin.profile.campaigns.campaign", res.response.result[0].id);
                    } else {
                        self.growl.error("Failed to create record");
                        console.log(res.response.error);
                    }
                });
            }
        }
    });
});