define('impact-admin/components/donations-tracker', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        manageGhd: _ember['default'].inject.service('manage-ghd'),
        currentGHDYear: (function () {
            return this.get('manageGhd.getCurrentGHDYear');
        }).property('application'),
        isExpanded: false,

        actions: {
            setExpand: function setExpand() {
                var isExp = this.get('isExpanded');
                this.set('isExpanded', !isExp);
            }
        }
    });
});