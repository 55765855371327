define('impact-admin/routes/frame/add-donation', ['exports', 'ember', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            var _this = this;

            if (!this.controllerFor('application').get('isImpact')) {
                _impactAdminModelsOrganizations['default'].find(this.controllerFor('application').get('user.organization_id')).then(function (org) {
                    if (org.get('status') !== 'active') {
                        _this.growl.error('Your Organization must be active in order to add a donation.');
                    }
                })['catch'](function () {
                    _this.growl.error('Error fetching your organization.');
                });
            }
        },
        model: function model() {
            if (!this.controllerFor('application').get('isImpact')) {
                return _impactAdminModelsOrganizations['default'].find({
                    id: this.controllerFor('application').get('user.organization_id'),
                    status: 'active'
                });
            } else {
                return _impactAdminModelsOrganizations['default'].find({
                    status: 'active'
                });
            }
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('isImpact', this.controllerFor('application').get('isImpact'));
            if (!controller.get('isImpact')) {
                controller.set('selectedOrg', model.toArray()[0]);
            } else {
                controller.set('selectedOrg', null);
            }
        }
    });
});