define('impact-admin/utils/api', ['exports', 'impact-admin/config/environment', 'impact-admin/utils/token', 'ember'], function (exports, _impactAdminConfigEnvironment, _impactAdminUtilsToken, _ember) {

    /* global $ */

    /*
     * Jquery REST method overrides
     */

    // $.ajaxSetup({
    //     cache: false
    // });

    //TODO: Refactor into proper service so Token is from auth.js instead of a one-off
    var api = {
        stripeClient: _impactAdminConfigEnvironment['default'].stripeClient,
        stripeReturn: _impactAdminConfigEnvironment['default'].stripeReturn,
        url: _impactAdminConfigEnvironment['default'].api.url,
        //stripeClient: null,
        //url: "https://impact-api-staging.herokuapp.com",
        //token: null,
        downloadFile: function downloadFile(url, filename, fileType) {
            var cb = function cb(data) {
                var blob = new Blob([data]);
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename + '_' + new Date().toDateString() + '.' + fileType);
                } else {
                    var a = window.document.createElement('a');
                    a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
                    a.download = filename + '_' + new Date().toDateString() + '.' + fileType;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };
            api.get(url, {}, cb, true, false, 'text');
        },

        // Unable to use downloadFile due to issues with downloaded file being corrupted
        downloadPdf: function downloadPdf(url, fileName) {
            var req = new XMLHttpRequest();
            req.open('GET', url, true);
            req.setRequestHeader('Authorization', ' Bearer ' + _impactAdminUtilsToken['default'].getAuth0Tokens().access_token);
            req.responseType = 'blob';
            req.onreadystatechange = function () {
                if (req.readyState === 4 && req.status === 200) {
                    var fullFileName = fileName + '_' + new Date().toDateString() + '.pdf';

                    // test for IE
                    if (typeof window.navigator.msSaveBlob === 'function') {
                        window.navigator.msSaveBlob(req.response, fullFileName);
                    } else {
                        var blob = req.response;
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fullFileName;

                        // append the link to the document body

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            };
            req.send();
        },

        get: function get(url, data, callback, auth, file, dataType) {
            return api.call('GET', url, data, callback, auth, file, dataType);
        },
        post: function post(url, data, callback, auth, file, dataType) {
            data._method = 'POST';
            return api.call('POST', url, data, callback, auth, file, dataType);
        },
        put: function put(url, data, callback, auth, file, dataType) {
            data._method = 'PUT';
            return api.call('PUT', url, data, callback, auth, file, dataType);
        },
        del: function del(url, data, callback, auth, file, dataType) {
            data._method = 'DELETE';
            return api.call('DELETE', url, data, callback, auth, file, dataType);
        },

        //TODO: we just add auth to everything now, so could rip out the auth parameter
        call: function call(type, url, data, callback, auth, file, dataType) {
            // Add Authentication
            var headers = {
                Authorization: ' Bearer ' + _impactAdminUtilsToken['default'].getAuth0Tokens().access_token
            };

            var method;

            url = api.url + url;

            if (file) {
                //TODO: Verify, is this even used???
                return $.ajax({
                    url: url,
                    type: 'POST'
                }).done(callback);
            } else {

                if ($('html').is('.ie8')) {
                    method = 'GET';
                    // Url
                    url = url + '?callback=?';
                } else if ($('html').is('.ie9')) {
                    method = 'GET';
                    url = url + '?callback=?';
                } else {
                    method = type;
                }

                // Call
                return $.ajax({
                    type: method,
                    url: url,
                    data: data,
                    dataType: dataType || 'json',
                    cache: false,
                    headers: headers,

                    //When crossDomain set to true it will force PUT/POST
                    // to be a GET with all data in the URL.
                    // this caused issues since GET URL's cannot be too large
                    crossDomain: false,

                    success: function success(json) {
                        if (json.error !== undefined && json.code === 500) {
                            _ember['default'].get('Router.router').transitionTo('logout');
                        } else {
                            callback(json);
                        }
                    }
                });

                //return $.getJSON(url, data, function(json) {
                //    // Force Logout
                //    if (json.error !== undefined && json.code === 500) {
                //        //debugger;
                //        App.get('Router.router').transitionTo('logout');
                //        //this.transitionTo('logout');
                //    } else {
                //        callback(json);
                //    }
                //});
            }
        }
    };

    // Ember.RestAdapter.reopen({
    //     queryFailed: function(record, json) {
    //         if (record.error === "Invalid token sent") {
    //             App.get('Router.router').transitionTo('logout');
    //             App.Growl.info('Session expired. Please log in again.');
    //         }
    //     }
    // });

    exports['default'] = api;
});
/*
 *  Object to communicate with the API
 */