define('impact-admin/routes/frame/campaigns', ['exports', 'ember', 'impact-admin/models/organization-campaigns', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsOrganizationCampaigns, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.campaigns'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            return _ember['default'].RSVP.hash({
                campaigns: _impactAdminModelsOrganizationCampaigns['default'].find({
                    organization_id: params.charity_id,
                    archived: false
                }),
                organization: _impactAdminModelsOrganizations['default'].find(params.charity_id),
                charity_id: params.charity_id
            });
        },

        setupController: function setupController(controller, model) {
            if (model.campaigns) {
                model.campaigns = model.campaigns.sortBy('title');
            }

            controller.set('model', model);
            controller.reset();
        }
    });
});