define('impact-admin/controllers/frame/volunteer/index', ['exports', 'ember', 'impact-admin/utils/multi-select-updater', 'impact-admin/models/volunteer-opportunity-skills', 'impact-admin/models/volunteer', 'impact-admin/validations/volunteer-opportunity'], function (exports, _ember, _impactAdminUtilsMultiSelectUpdater, _impactAdminModelsVolunteerOpportunitySkills, _impactAdminModelsVolunteer, _impactAdminValidationsVolunteerOpportunity) {
    exports['default'] = _ember['default'].Controller.extend({
        VolunteerValidations: _impactAdminValidationsVolunteerOpportunity['default'],
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        filteredModel: _ember['default'].computed('model.volunteer', 'searchTerm', function () {
            var _this = this;

            var searchTerm = this.get('searchTerm');
            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    _this.get('model.volunteer').forEach(function (volunteer_opportunity) {
                        if (volunteer_opportunity.get('title').toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(volunteer_opportunity);
                        }
                    }, _this);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            } else {
                return this.get('model.volunteer');
            }
        }),

        actions: {
            duplicateOpportunity: function duplicateOpportunity(opportunity) {
                this.set('newOpportunity', _impactAdminModelsVolunteer['default'].createRecord({
                    organization_id: opportunity.organization_id,
                    title: opportunity.title,
                    short_description: opportunity.short_description,
                    description: opportunity.description,
                    address: opportunity.address,
                    city: opportunity.city,
                    zip: opportunity.zip,
                    state: opportunity.state,
                    ongoing: opportunity.ongoing,
                    start_time: opportunity.start_time,
                    end_time: opportunity.end_time,
                    notes: opportunity.notes,
                    requirements: opportunity.requirements,
                    contact_name: opportunity.contact_name,
                    contact_phone: opportunity.contact_phone,
                    contact_email: opportunity.contact_email,
                    start_date: opportunity.start_date,
                    end_date: opportunity.end_date
                }));

                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    _ember['default'].$('html, body').animate({
                        scrollTop: $('#new-opportunity').offset().top
                    }, 800);
                });
            },

            saveOpportunity: function saveOpportunity(opportunity, newSkills, oldSkills, isNew) {
                var _this2 = this;

                opportunity.set('organization_id', this.get('model.charity_id'));
                if (isNew) {
                    opportunity.save().then(function (json) {
                        if (json.response.error) {
                            _this2.growl.error('There was an error saving your changes. ' + json.response.error);
                        } else {
                            _this2.set('newOpportunity', null);
                            _this2.growl.success('Changes Saved');
                            //Save the skills
                            (0, _impactAdminUtilsMultiSelectUpdater['default'])(opportunity, newSkills, oldSkills, _impactAdminModelsVolunteerOpportunitySkills['default'], 'skill_id', 'volunteer_id', function () {});
                        }
                    });
                } else {
                    opportunity.save().then(function (json) {
                        if (json.response.error) {
                            _this2.growl.error('There was an error saving your changes. ' + json.response.error);
                        } else {
                            if (isNew) {
                                _this2.set('newOpportunity', null);
                            }
                            _this2.growl.success('Changes Saved');
                        }
                    });
                    //Save the skills
                    (0, _impactAdminUtilsMultiSelectUpdater['default'])(opportunity, newSkills, oldSkills, _impactAdminModelsVolunteerOpportunitySkills['default'], 'skill_id', 'volunteer_id', function () {});
                }
                this.set('isDuplicate', false);
            },
            addNew: function addNew() {
                this.set('newOpportunity', _impactAdminModelsVolunteer['default'].createRecord());

                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    _ember['default'].$('html, body').animate({
                        scrollTop: $('#new-opportunity').offset().top
                    }, 800);
                });
            },
            cancelAddNew: function cancelAddNew() {
                this.set('newOpportunity', null);
            },
            remove: function remove(opportunity) {
                var _this3 = this;

                opportunity.set('organization_id', this.get('model.charity_id'));
                if (confirm('Are you sure you want to delete this volunteer opportunity?')) {
                    opportunity.set('removed', true);
                    opportunity.save().then(function () {
                        var submissionsHide = _this3.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                        _this3.get('model.submissions').removeObjects(submissionsHide);
                    });
                }
            },
            publishOpp: function publishOpp(opportunity) {
                opportunity.save();
            },
            makePrivate: function makePrivate(opportunity) {
                opportunity.set('organization_id', this.get('model.charity_id'));
                opportunity.set('is_public', !opportunity.get('is_public'));
                opportunity.save().then(function () {
                    //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                    //this.get('model.submissions').removeObjects(submissionsHide);
                });
            },
            makePublic: function makePublic(opportunity) {
                opportunity.set('organization_id', this.get('model.charity_id'));
                if (confirm('Are you sure you want to make this volunteer opportunity public?')) {
                    opportunity.set('is_public', true);
                    opportunity.save().then(function () {
                        //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                        //this.get('model.submissions').removeObjects(submissionsHide);
                    });
                }
            },
            hideGenericOpp: function hideGenericOpp(org) {
                var organization = org;
                organization.set('show_generic_volunteer', !organization.get('show_generic_volunteer'));
                organization.save().then(function () {
                    //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                    //this.get('model.submissions').removeObjects(submissionsHide);
                });
            },
            hideAllVolunteers: function hideAllVolunteers(org) {
                var organization = org;
                organization.set('show_volunteer', !organization.get('show_volunteer'));
                organization.save().then(function () {
                    //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                    //this.get('model.submissions').removeObjects(submissionsHide);
                });
            },
            showAllVolunteers: function showAllVolunteers(org) {
                var organization = org;
                if (confirm('Are you sure you want to enable the volunteer opportunities?')) {
                    organization.set('show_volunteer', true);
                    organization.save().then(function () {
                        //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                        //this.get('model.submissions').removeObjects(submissionsHide);
                    });
                }
            }
        }
    });
});
/* globals $ */