define('impact-admin/routes/frame/volunteer-applicants', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/volunteer', 'impact-admin/models/volunteer-skills', 'impact-admin/models/volunteer-submissions'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsVolunteer, _impactAdminModelsVolunteerSkills, _impactAdminModelsVolunteerSubmissions) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.volunteer-applicants'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var charity = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var volunteer = _impactAdminModelsVolunteer['default'].find({
                organization_id: params.charity_id,
                removed: false
            });
            var skills = _impactAdminModelsVolunteerSkills['default'].find();
            var charity_id = params.charity_id;

            return _ember['default'].RSVP.hash({
                charity: charity,
                volunteer: volunteer,
                skills: skills,
                charity_id: charity_id
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model.volunteer);
            controller.set('charity', model.user);
            controller.set('skills', model.skills);

            var volunteer_ids = model.volunteer.getEach('id');
            volunteer_ids.push(-1);
            model.submissions = _impactAdminModelsVolunteerSubmissions['default'].find({
                volunteer_id: volunteer_ids
            });
        }
    });
});