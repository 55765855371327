define('impact-admin/components/html-parser', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        updateHTML: (function () {
            var self = this;
            $('#' + self.get('uniqueID')).html(self.get('htmlSafeContent'));
        }).observes('htmlSafeContent'),

        didInsertElement: function didInsertElement() {
            this._displayHTML();
        },

        _displayHTML: function _displayHTML() {
            var self = this;
            $('#' + self.get('uniqueID')).html(self.get('htmlSafeContent'));
        }
    });
});