define('impact-admin/controllers/frame/goods-pledges/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        queryParams: ['good_id'],
        good_id: 0,
        sortProperties: ['submitted:desc'],

        sortedModel: _ember['default'].computed.sort('filteredModel', 'sortProperties'),

        filteredModel: _ember['default'].computed('model.submissions.[]', 'good_id', 'searchTerm', function () {
            var _this = this;

            var _getProperties = this.getProperties('searchTerm', 'good_id');

            var searchTerm = _getProperties.searchTerm;
            var good_id = _getProperties.good_id;

            var submissions = this.get('model.submissions');

            if (good_id) {
                //submissions = submissions.filterBy('good_id', good_id);
            }

            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    submissions.forEach(function (submission) {
                        if ((submission.get('first_name') + submission.get('last_name')).toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(submission);
                        }
                    }, _this);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            }

            return submissions;
        }),

        hasFilters: _ember['default'].computed.or('good_id', 'searchTerm'),

        actions: {
            clearFilters: function clearFilters() {
                this.setProperties({
                    searchTerm: null,
                    good_id: 0
                });
            },
            deleteSubmission: function deleteSubmission(submission) {
                var _this2 = this;

                if (confirm('Are you sure you want to delete this submission? \nThis cannot be undone.')) {
                    submission.deleteRecord().then(function () {
                        _this2.growl.success('Submission deleted');
                    })['catch'](function (json) {
                        console.log(json);
                        _this2.growl.error('Could not delete this submissions at this time. Please try again.');
                    });
                }
            },

            exportResults: function exportResults(donorSubmissions) {
                var self = this;

                var dataToExport = [['First Name', 'Last Name', 'Phone', 'Email', 'Business', 'Submitted']];
                donorSubmissions.forEach(function (donor) {

                    var epochTime = new Date(donor.submitted * 1000);

                    var formattedDate = self.formatDate(epochTime);

                    var donorArray = [donor.first_name, donor.last_name, donor.phone, donor.email, donor.business_name, formattedDate];

                    dataToExport.push(donorArray);
                });

                this.exportArrayToFile(dataToExport, "Goods Pledges");
            }
        },

        exportArrayToFile: function exportArrayToFile(data, org_opp) {
            var csvContent = '';
            data.forEach(function (infoArray, index) {

                var dataString = '"' + infoArray.join('","') + '"';
                csvContent += index < data.length ? dataString + '\n' : dataString;
            });

            // The download function takes a CSV string, the filename and mimeType as parameters
            // Scroll/look down at the bottom of this snippet to see how download is called
            var download = function download(content, fileName, mimeType) {
                var a = document.createElement('a');
                mimeType = mimeType || 'application/octet-stream';

                if (navigator.msSaveBlob) {
                    // IE10
                    navigator.msSaveBlob(new Blob([content], {
                        type: mimeType
                    }), fileName);
                } else if (URL && 'download' in a) {
                    //html5 A[download]
                    a.href = URL.createObjectURL(new Blob([content], {
                        type: mimeType
                    }));
                    a.setAttribute('download', fileName);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
                }
            };

            var currentTime = new Date();

            var timestamp = currentTime.getMonth() + 1 + '-' + currentTime.getDate() + '-' + currentTime.getFullYear();
            download(csvContent, org_opp + ' ' + timestamp + '.csv', 'text/csv;encoding:utf-8');
        },
        formatDate: function formatDate(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
        }
    });
});