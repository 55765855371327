define('impact-admin/models/code-groups', ['exports', 'ember', 'impact-admin/models/code-matching-rules-organizations', 'impact-admin/models/code-matching-rules-types'], function (exports, _ember, _impactAdminModelsCodeMatchingRulesOrganizations, _impactAdminModelsCodeMatchingRulesTypes) {

    var CodeGroups = _ember['default'].Model.extend({
        //org.set('dmf_match', Math.round(numeral().unformat(org.get('dmf_match_dollars')) * 100));
        //max_cart_total

        maxCartTotalDollars: _ember['default'].computed('max_cart_total', function () {
            var max_cart_total = this.get('max_cart_total');

            if (max_cart_total && max_cart_total > 0) {
                return max_cart_total / 100;
            } else {
                return null;
            }
        }),

        minCartTotalDollars: _ember['default'].computed('min_cart_total', function () {
            var min_cart_total = this.get('min_cart_total');

            if (min_cart_total && min_cart_total > 0) {
                return min_cart_total / 100;
            } else {
                return null;
            }
        }),

        minDonationSizeDollars: _ember['default'].computed('min_donation_size', function () {
            var min_donation_size = this.get('min_donation_size');

            if (min_donation_size && min_donation_size > 0) {
                return min_donation_size / 100;
            } else {
                return null;
            }
        }),

        maxDonationSizeDollars: _ember['default'].computed('max_donation_size', function () {
            var max_donation_size = this.get('max_donation_size');

            if (max_donation_size && max_donation_size > 0) {
                return max_donation_size / 100;
            } else {
                return null;
            }
        }),

        maxMatchPerCodeDollars: _ember['default'].computed('max_match_per_code', function () {
            var max_match_per_code = this.get('max_match_per_code');

            if (max_match_per_code && max_match_per_code > 0) {
                return max_match_per_code / 100;
            } else {
                return null;
            }
        }),

        validOrganizations: _ember['default'].computed('id', function () {
            var code_group_id = this.get('id');
            return _impactAdminModelsCodeMatchingRulesOrganizations['default'].find({ code_group_id: code_group_id });
        }),
        validTypes: _ember['default'].computed('id', function () {
            var code_group_id = this.get('id');
            return _impactAdminModelsCodeMatchingRulesTypes['default'].find({ code_group_id: code_group_id });
        })
    });
    CodeGroups.table = 'code_groups';

    exports['default'] = CodeGroups;
});