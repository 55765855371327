define('impact-admin/controllers/frame/reports/volunteer-opps-report', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({

        volunteer_report_url: _ember['default'].computed('api', function () {
            return '/volunteer/csv';
        }),
        actions: {
            downloadFile: _impactAdminUtilsApi['default'].downloadFile
        }

    });
});