define('impact-admin/routes/frame/volunteer/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        //setupController: function (controller, model) {

        //controller.set('model', model);
        // let skillTypes = model.skills.map(function (t) {
        //    return {id: t.id, skill: t.skill};
        //});

        //controller.set('skillTypes', skillTypes);
        //controller.set('selectedSkills', model.opportunity.map(function (st) {
        //  return skillTypes.find(function (t) {
        //      return t.id === st.id;
        // });
        // }));
        //}
    });
});