define('impact-admin/utils/token', ['exports'], function (exports) {
    var _this = this;

    /* globals _, simpleStorage */

    /*
     *  Manage user token persistence
     */

    //TODO: Refactor into proper service to just use auth.js
    var token = {
        get: function get() {
            if (!this.value) {
                this.value = simpleStorage.get('token');
            }
            return this.value;
        },
        set: function set(token) {
            this.value = token;
            simpleStorage.set('token', token);
        },

        //TODO: so... there's a memory copy here, and also in auth.js :facepalm:
        //      it is basically just a hack because api.js is not a service...
        getAuth0Tokens: function getAuth0Tokens() {
            if (_.isEmpty(_this.auth0_tokens)) {
                _this.auth0_tokens = simpleStorage.get('auth0_tokens') || {};
            }
            return _this.auth0_tokens;
        },

        setAuth0Tokens: function setAuth0Tokens(tokens) {
            _this.auth0_tokens = tokens;
            simpleStorage.set('auth0_tokens', tokens);
        }
    };

    exports['default'] = token;
});