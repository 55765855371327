define('impact-admin/helpers/object-is-in-array', ['exports', 'ember'], function (exports, _ember) {

    var helper = _ember['default'].Helper.helper(function (params) {
        var objectToCheck = params[0];
        var arrayToCheck = params[1];
        if (!objectToCheck || !arrayToCheck) {
            return false;
        }

        var foundSelected = arrayToCheck.find(function (x) {
            return x.id === objectToCheck.id;
        });
        if (foundSelected) {
            return true;
        }

        return false;
    });

    exports['default'] = helper;
});