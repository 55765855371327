define('impact-admin/routes/frame/profile/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        setupController: function setupController(controller, model) {
            controller.set('model', model.organization);
            controller.get('model').get('keywords_objects');
            controller.set('locations', model.locations);
            controller.set('types', model.types);
            controller.set('permissions', model.permissions);
            controller.set('profile_statuses', model.profile_statuses);
            controller.set('pdfDocuments', model.pdfDocuments);
            controller.set('isImpact', this.controllerFor('application').get('isImpact'));

            var types = model.types.filter(function (t) {
                return { id: t.id, name: t.name };
            });

            controller.set('types', types);

            controller.set('selectedTypes', model.organization.types.map(function (st) {
                return types.find(function (t) {
                    return t.id === st.id;
                });
            }));
        }
    });
});