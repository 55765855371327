define('impact-admin/components/growl-notification', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        open: (function () {
            this.$().hide().fadeIn();

            if (!this.get('notification.options.clickToDismiss')) {
                _ember['default'].run.later(this, this.destroyAlert, this.get('notification.options.closeIn'));
            }
        }).on('didInsertElement'),

        click: function click() {
            if (this.get('notification.options.clickToDismiss')) {
                this.destroyAlert();
            }
        },

        type: (function () {
            return this.get('notification.options.type');
        }).property(),

        alertType: (function () {
            var type = this.get('type');
            if (type === 'error') {
                type = 'danger';
            }
            return 'alert-' + type;
        }).property('type'),

        destroyAlert: function destroyAlert() {
            var self = this;
            if (this.$()) {
                this.$().fadeOut(_ember['default'].run(this, function () {
                    // send the action on up so the manager can remove this item from array
                    self.sendAction('action', self.get('notification'));
                }));
            } else {
                self.sendAction('action', self.get('notification'));
            }
        }
    });
});