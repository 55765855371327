define('impact-admin/models/ghd-resources', ['exports', 'ember'], function (exports, _ember) {

  /*
   *Donations Model
   */
  var GhdResources = _ember['default'].Model.extend({});

  GhdResources.table = 'ghd_resources';

  exports['default'] = GhdResources;
});