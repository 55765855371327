define('impact-admin/routes/frame/matching-codes/new', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/types', 'impact-admin/models/codes'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsTypes, _impactAdminModelsCodes) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            var charities = _impactAdminModelsOrganizations['default'].find();
            var charity_types = _impactAdminModelsTypes['default'].find();
            return _ember['default'].RSVP.hash({
                charities: charities,
                charity_types: charity_types
            });
        },

        setupController: function setupController(controller, model) {
            controller.reset();
            controller.set('charities', model.charities.sortBy('name'));
            controller.set('charity_types', model.charity_types);
            var codes = _impactAdminModelsCodes['default'].find();
            controller.set('codes', codes);
        }
    });
});