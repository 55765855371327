define('impact-admin/initializers/label', ['exports', 'impact-admin/services/labels'], function (exports, _impactAdminServicesLabels) {
    exports['default'] = {
        name: 'LABELS',
        initialize: function initialize(app) {
            app.register('LABELS:main', _impactAdminServicesLabels['default']);
            app.inject('route', 'LABELS', 'LABELS:main');
            app.inject('controller', 'LABELS', 'LABELS:main');
            app.inject('component', 'LABELS', 'LABELS:main');
        }
    };
});