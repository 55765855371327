define('impact-admin/controllers/frame/account/index', ['exports', 'ember', 'impact-admin/models/admin-users', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsAdminUsers, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        notificationTypes: _ember['default'].computed('admin_user_notifications.new_donation_notification', 'admin_user_notifications.new_volunteer_application_notification', 'admin_user_notifications.impact_notification', function () {
            var userNotifications = this.get('admin_user_notifications');
            var notificationArray = [{
                label: 'New Donation',
                value: 'new_donation_notification',
                selected: userNotifications.get('new_donation_notification')
            }, {
                label: 'New Volunteer Application',
                value: 'new_volunteer_application_notification',
                selected: userNotifications.get('new_volunteer_application_notification')
            }, {
                label: 'Giving Hearts Day Notifications',
                value: 'impact_notification',
                selected: userNotifications.get('impact_notification')
            }];
            console.log('Notifications Changed');

            return notificationArray;
        }),

        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        invoice_url: _ember['default'].computed('model.invoice', 'organization_id', function () {
            return _impactAdminUtilsApi['default'].url + '/membership/invoice/' + this.get('org_id');
        }),

        selectedSection: 'team',

        isTeamTab: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'team';
        }),

        isUserTab: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'user';
        }),

        isEmailTab: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'emails';
        }),

        actions: {
            changePassword: function changePassword() {
                var self = this;
                if (window.confirm("Are you sure you want to reset your password?\nClick OK to proceed.")) {
                    self.set('errors', _ember['default'].A());

                    _impactAdminUtilsApi['default'].post("/account/resetPassword", {}, function (res) {
                        if (res.error === undefined) {
                            self.growl.success("Reset Password email sent!");
                        } else {
                            self.get('errors').pushObject(res.error);
                        }
                    }, true);
                }
            },

            selectSection: function selectSection(section) {
                this.set('selectedSection', section);
            },
            disconnectStripe: function disconnectStripe() {
                var _this = this;

                var res = confirm('Are you sure you want to disconnect their Stripe account?');
                if (res) {
                    (function () {
                        var self = _this;
                        _this.set('loading', true);
                        //Stripe Customer ID:  cus_9JFqXDFdC9gUH9
                        //Stripe Subscription ID:  sub_9JFqu2d8MpCPs3
                        //TODO Talk through "Canceling" new membership charges
                        _impactAdminUtilsApi['default'].post('/membership/cancel', {
                            charity_id: _this.get('model.organization.id'),
                            membership_id: _this.get('model.organization.membership_id')
                        }, function (json) {
                            if (json.error) {
                                console.log(json);
                                self.growl.error('There was an error while processing your request. Please try again.');
                            } else {
                                self.growl.success('Membership has been successfully canceled.');
                                _impactAdminUtilsApi['default'].post('/account/disconnect-stripe', {
                                    id: self.get('model.organization.id')
                                }, function (json) {
                                    if (json.error) {
                                        console.log(json);
                                        self.growl.error('There was an error while processing your request. Please try again.');
                                    } else {
                                        console.log(json);
                                        self.growl.success('Stripe was disconnected.');
                                    }
                                    self.set('loading', false);
                                }, true);
                            }
                        }, true);
                    })();
                }
            },
            setPermission: function setPermission(value, component) {
                var _this2 = this;

                this.set('isSavingPerms', true);
                var permissionType = component.get('permissionType');
                var permissions = this.get('permissions');
                var statuses = this.get('statuses');

                var prms = null;
                if (permissionType === 'ghd_access' && !value && statuses.get('ghd_profile_approved')) {
                    // save the profile status too
                    statuses.set('ghd_profile_approved', false);
                    prms = statuses.save();
                }

                permissions.set(permissionType, value);
                _ember['default'].RSVP.Promise.all([permissions.save(), prms]).then(function () {
                    _this2.set('isSavingPerms', false);
                    _this2.growl.success('Permission saved');
                })['catch'](function (error) {
                    console.log(error);
                    _this2.set('isSavingPerms', false);
                    _this2.growl.error('An error occurred trying to set this permission. Please try again.');
                });
            },
            removeUser: function removeUser(user) {
                var _this3 = this;

                var length = this.get('users.length');
                if (length > 1) {
                    if (confirm('Are you sure you want to delete this user?')) {
                        user.deleteRecord().then(function () {
                            _this3.growl.success('User deleted');
                        })['catch'](function (reason) {
                            console.log(reason);
                            _this3.growl.error('An error occurred deleting this user. Please try again.');
                        });
                    }
                } else {
                    this.growl.error('You cannot delete the last user for this ' + this.LABELS.CHARITY.toLowerCase() + '.');
                }
            },
            addUser: function addUser() {
                this.set('addingUser', true);
            },
            cancelAddUser: function cancelAddUser() {
                this.setProperties({
                    userEmail: '',
                    userName: '',
                    userLastName: '',
                    addingUser: false
                });
            },
            saveUser: function saveUser() {
                var _this4 = this;

                var _getProperties = this.getProperties('userEmail', 'userName', 'userLastName');

                var userEmail = _getProperties.userEmail;
                var userName = _getProperties.userName;
                var userLastName = _getProperties.userLastName;

                var record = {
                    email: userEmail,
                    organization_id: this.get('organization.id'),
                    username: userEmail,
                    type: this.get('tier') || 'organization',
                    first_name: userName,
                    last_name: userLastName
                };

                var newUser = _impactAdminModelsAdminUsers['default'].createRecord(record);

                newUser.save().then(function () {
                    _this4.growl.success('New user has been added');
                    _this4.setProperties({
                        userEmail: '',
                        userName: '',
                        userLastName: '',
                        addingUser: false
                    });
                })['catch'](function (error) {
                    _this4.growl.error(error);
                    console.log(error);
                });
            },
            changeCharityStatus: function changeCharityStatus(newStatus) {
                var _this5 = this;

                var organization = this.get('organization');
                if (confirm('Are you sure you want to change the ' + this.LABELS.CHARITY.toLowerCase() + ' status?') && organization.get('status') !== newStatus) {
                    organization.set('status', newStatus);
                    organization.save().then(function () {
                        _this5.growl.success('This ' + _this5.LABELS.CHARITY.toLowerCase() + ' has been set to ' + newStatus + '.');
                        if (newStatus === "deleted") {
                            _this5.get('users').forEach(function (user) {
                                user.deleteRecord().then(function () {
                                    _this5.growl.success('User deleted');
                                })['catch'](function (reason) {
                                    console.log(reason);
                                    _this5.growl.error('An error occurred deleting this user.');
                                });
                            });
                        }
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this5.growl.error('There was an error updating this ' + _this5.LABELS.CHARITY.toLowerCase() + '. Please try again.');
                    });
                }
            },
            setNotification: function setNotification(notification) {
                var _this6 = this;

                var notificationType = notification.value;
                var newValue = !notification.selected;
                this.set('admin_user_notifications.' + notificationType, newValue);
                this.get('admin_user_notifications').save().then(function () {
                    _this6.growl.success('Preference saved');
                })['catch'](function (reason) {
                    console.log(reason);
                    _this6.growl.error('Could not save your preferences. Please try again.');
                });
            },

            downloadFile: _impactAdminUtilsApi['default'].downloadPdf
        }
    });
});