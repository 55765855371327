define('impact-admin/controllers/frame/permissions', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        actions: {
            changeCharityStatus: function changeCharityStatus(newStatus) {
                var _this = this;

                var organization = this.get('model.organization');
                if (confirm('Are you sure you want to change the ' + this.LABELS.CHARITY.toLowerCase() + ' status?') && organization.get('status') !== newStatus) {
                    organization.set('status', newStatus);
                    organization.save().then(function () {
                        _this.growl.success('This ' + _this.LABELS.CHARITY.toLowerCase() + ' has been set to ' + newStatus + '.');
                        if (newStatus === 'deleted') {
                            _this.get('model.users').forEach(function (user) {
                                user.deleteRecord().then(function () {
                                    _this.growl.success('User deleted');
                                })['catch'](function (reason) {
                                    console.log(reason);
                                    _this.growl.error('An error occurred deleting this user.');
                                });
                            });
                        }
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this.growl.error('There was an error updating this ' + _this.LABELS.CHARITY.toLowerCase() + '. Please try again.');
                    });
                }
            }
        }
    });
});