define('impact-admin/mixins/table', ['exports', 'ember', 'impact-admin/mixins/sort-by-moment-date', 'table2csv'], function (exports, _ember, _impactAdminMixinsSortByMomentDate, _table2csv) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    //We can use this view when we want to give a table the ability to sort itself.
    var TableMixin = _ember['default'].Mixin.create({
        init: function init() {
            this._super();
            this.set("defaultSort", "");
            this.set("defaultSortAction", "sort");
            this.set("defaultSortOrder", "asc");

            if (this.get('defaultSort') !== "") {
                this.send(this.get('defaultSortAction'), this.get('defaultSort'), undefined, this.get('defaultSortOrder'));
            }
        },

        // didInsertElement: function() {
        //     var defaultSort = this.get('defaultSort');
        //     var defaultSortAction = this.get('defaultSortAction');
        //     var defaultSortOrder = this.get('defaultSortOrder');

        //     if (defaultSort !== "") {
        //         this.send(defaultSortAction, defaultSort, undefined, defaultSortOrder);
        //     }
        // },
        actions: {

            _realSort: function _realSort(names, controller) {
                var _controller$get;

                var namesArray = names.split(',');
                namesArray = namesArray.map(function (thing) {
                    return thing.trim();
                });

                var sortedModel = (_controller$get = controller.get('model')).sortBy.apply(_controller$get, _toConsumableArray(namesArray));

                var ascending = controller.get('ascending');
                if (ascending) {
                    controller.set('ascending', false);
                    sortedModel.reverse();
                } else {
                    controller.set('ascending', true);
                }

                //If a sort order was specified, DO IT!!
                //if (typeof order === "string") {
                //    if (order.toLowerCase() === "asc") {
                //        controller.set("sortAscending", true);
                //    } else if (order.toLowerCase() === "desc") {
                //        debugger;
                //        sortedModel.reverse();
                //        controller.set("sortAscending", false);
                //    }
                //}
                controller.set('model', sortedModel);
                controller.set("sortProperties", namesArray);

                //The following code adds a chevron next to the text in <TH> that was clicked to indicate sort order.
                var col = $(event.target);

                if (col.prop('tagName') !== "TH") {
                    col = col.parents('th');
                }
                var table = col.parents('table');
                table.find('#sortArrow').remove();
                if (controller.get("ascending")) {
                    col.append(' <span id="sortArrow" class="icon-arrow-down"></span>');
                } else {
                    col.append(' <span id="sortArrow" class="icon-arrow-up"></span>');
                }
            },

            sort: function sort(names, controller, order) {
                if (controller === undefined) {
                    controller = this;
                } else {}

                //Regular old vanilla sort function.  We reset it here in case it was previously changed by sortDate.
                controller.set('sortFunction', _ember['default'].compare);
                controller.set('specialOrder', false);

                this.send('_realSort', names, controller, order);
            },

            //IF we're soring by a date column (e.g.: mm/dd/yyyy), regular lexicograpfic sorting
            //doesn't work.  So we quick switch the sort function to deal with it.
            sortDate: function sortDate(names, controller, order) {
                if (controller === undefined) {
                    controller = this;
                } else {
                    //controller = this.get(controller);
                }

                controller.set('sortFunction', _impactAdminMixinsSortByMomentDate['default']);
                controller.set('specialOrder', false);

                this.send('_realSort', names, controller, order);
            },

            sortSpecial: function sortSpecial(controller) {
                if (controller === undefined) {
                    controller = this;
                } else {}
                //controller = this.get(controller);

                //Regular old vanilla sort function.  We reset it here in case it was previously changed by sortDate.
                controller.set('sortFunction', _ember['default'].compare);
                controller.set('specialOrder', true);

                controller.toggleProperty("sortAscending");
                controller.propertyDidChange("sortProperties");
            },

            exportTable: function exportTable(tableId, pageName, tableName) {
                $('#' + tableId).table2csv({
                    filename: pageName + ' ' + tableName + '.csv'
                });
            }
        }
    });

    exports['default'] = TableMixin;
});
/* globals $ */