define('impact-admin/models/event-icons', ['exports', 'ember'], function (exports, _ember) {

  /*
   *Donations Model
   */
  var EventIcons = _ember['default'].Model.extend({});

  EventIcons.table = 'event_icons';

  exports['default'] = EventIcons;
});