define('impact-admin/components/state-select', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        overrideClass: '',
        statesList: [{
            value: 'ND',
            label: 'ND'
        }, {
            value: 'MN',
            label: 'MN'
        }, {
            value: 'SD',
            label: 'SD'
        }, {
            value: 'AL',
            label: 'AL'
        }, {
            value: 'AK',
            label: 'AK'
        }, {
            value: 'AZ',
            label: 'AZ'
        }, {
            value: 'AR',
            label: 'AR'
        }, {
            value: 'CA',
            label: 'CA'
        }, {
            value: 'CO',
            label: 'CO'
        }, {
            value: 'CT',
            label: 'CT'
        }, {
            value: 'DE',
            label: 'DE'
        }, {
            value: 'DC',
            label: 'DC'
        }, {
            value: 'FL',
            label: 'FL'
        }, {
            value: 'GA',
            label: 'GA'
        }, {
            value: 'HI',
            label: 'HI'
        }, {
            value: 'ID',
            label: 'ID'
        }, {
            value: 'IL',
            label: 'IL'
        }, {
            value: 'IN',
            label: 'IN'
        }, {
            value: 'IA',
            label: 'IA'
        }, {
            value: 'KS',
            label: 'KS'
        }, {
            value: 'KY',
            label: 'KY'
        }, {
            value: 'LA',
            label: 'LA'
        }, {
            value: 'ME',
            label: 'ME'
        }, {
            value: 'MD',
            label: 'MD'
        }, {
            value: 'MA',
            label: 'MA'
        }, {
            value: 'MI',
            label: 'MI'
        }, {
            value: 'MS',
            label: 'MS'
        }, {
            value: 'MO',
            label: 'MO'
        }, {
            value: 'MT',
            label: 'MT'
        }, {
            value: 'NE',
            label: 'NE'
        }, {
            value: 'NV',
            label: 'NV'
        }, {
            value: 'NH',
            label: 'NH'
        }, {
            value: 'NJ',
            label: 'NJ'
        }, {
            value: 'NM',
            label: 'NM'
        }, {
            value: 'NY',
            label: 'NY'
        }, {
            value: 'NC',
            label: 'NC'
        }, {
            value: 'OH',
            label: 'OH'
        }, {
            value: 'OK',
            label: 'OK'
        }, {
            value: 'OR',
            label: 'OR'
        }, {
            value: 'PA',
            label: 'PA'
        }, {
            value: 'RI',
            label: 'RI'
        }, {
            value: 'SC',
            label: 'SC'
        }, {
            value: 'TN',
            label: 'TN'
        }, {
            value: 'TX',
            label: 'TX'
        }, {
            value: 'UT',
            label: 'UT'
        }, {
            value: 'VT',
            label: 'VT'
        }, {
            value: 'VA',
            label: 'VA'
        }, {
            value: 'WA',
            label: 'WA'
        }, {
            value: 'WV',
            label: 'WV'
        }, {
            value: 'WI',
            label: 'WI'
        }, {
            value: 'WY',
            label: 'WY'
        }],

        statesFullLabelList: [{
            value: 'Alabama',
            label: 'Alabama'
        }, {
            value: 'Alaska',
            label: 'Alaska'
        }, {
            value: 'Arizona',
            label: 'Arizona'
        }, {
            value: 'Arkansas',
            label: 'Arkansas'
        }, {
            value: 'California',
            label: 'California'
        }, {
            value: 'Colorado',
            label: 'Colorado'
        }, {
            value: 'Connecticut',
            label: 'Connecticut'
        }, {
            value: 'Delaware',
            label: 'Delaware'
        }, {
            value: 'DC',
            label: 'DC'
        }, {
            value: 'Florida',
            label: 'Florida'
        }, {
            value: 'Georgia',
            label: 'Georgia'
        }, {
            value: 'Hawaii',
            label: 'Hawaii'
        }, {
            value: 'Idaho',
            label: 'Idaho'
        }, {
            value: 'Illinois',
            label: 'Illinois'
        }, {
            value: 'Indiana',
            label: 'Indiana'
        }, {
            value: 'Iowa',
            label: 'Iowa'
        }, {
            value: 'Kansas',
            label: 'Kansas'
        }, {
            value: 'Kentucky',
            label: 'Kentucky'
        }, {
            value: 'Louisiana',
            label: 'Louisiana'
        }, {
            value: 'Maine',
            label: 'Maine'
        }, {
            value: 'Maryland',
            label: 'Maryland'
        }, {
            value: 'Massachusetts',
            label: 'Massachusetts'
        }, {
            value: 'Michigan',
            label: 'Michigan'
        }, {
            value: 'Minnesota',
            label: 'Minnesota'
        }, {
            value: 'Mississippi',
            label: 'Mississippi'
        }, {
            value: 'Missouri',
            label: 'Missouri'
        }, {
            value: 'Montana',
            label: 'Montana'
        }, {
            value: 'Nebraska',
            label: 'Nebraska'
        }, {
            value: 'Nevada',
            label: 'Nevada'
        }, {
            value: 'New Hampshire',
            label: 'New Hampshire'
        }, {
            value: 'New Jersey',
            label: 'New Jersey'
        }, {
            value: 'New Mexico',
            label: 'New Mexico'
        }, {
            value: 'New York',
            label: 'New York'
        }, {
            value: 'North Carolina',
            label: 'North Carolina'
        }, {
            value: 'North Dakota',
            label: 'North Dakota'
        }, {
            value: 'Ohio',
            label: 'Ohio'
        }, {
            value: 'Oklahoma',
            label: 'Oklahoma'
        }, {
            value: 'Oregon',
            label: 'Oregon'
        }, {
            value: 'Pennsylvania',
            label: 'Pennsylvania'
        }, {
            value: 'Rhode Island',
            label: 'Rhode Island'
        }, {
            value: 'South Carolina',
            label: 'South Carolina'
        }, {
            value: 'South Dakota',
            label: 'South Dakota'
        }, {
            value: 'Tennessee',
            label: 'Tennessee'
        }, {
            value: 'Texas',
            label: 'Texas'
        }, {
            value: 'Utah',
            label: 'Utah'
        }, {
            value: 'Vermont',
            label: 'Vermont'
        }, {
            value: 'Virginia',
            label: 'Virginia'
        }, {
            value: 'Washington',
            label: 'Washington'
        }, {
            value: 'West Virginia',
            label: 'West Virginia'
        }, {
            value: 'Wisconsin',
            label: 'Wisconsin'
        }, {
            value: 'Wyoming',
            label: 'Wyoming'
        }],

        didInsertElement: function didInsertElement() {
            if (!this.get('overrideClass')) {
                this.set('overrideClass', 'form-control');
            }
        },

        actions: {
            selectState: function selectState(selection) {
                this.set('selectedState', selection);
                if (this.get('onchange')) {
                    this.get('onchange')(selection);
                }
            }
        }
    });
});