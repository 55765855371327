define('impact-admin/components/organization-document-edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isEdit: false,
    saveDisabled: false,
    actions: {
      editPDF: function editPDF() {
        this.set('isEdit', true);
      },
      savePDF: function savePDF(pdf) {
        var _this = this;

        this.set('saveDisabled', true);
        if (!pdf.isReallyDirty()) {
          return;
        }
        pdf.save().then(function (json) {
          if (json.response.error) {
            _this.growl.error('Could not save ' + pdf.document_name + '. Please try again.');
          } else {
            _this.growl.success(pdf.document_name + ' saved.');
          }
        }, function () {
          _this.growl.error('An error has occured');
        });
        this.set('saveDisabled', false);
        this.set('isEdit', false);
      },
      removePDF: function removePDF(pdf) {
        var _this2 = this;

        if (confirm('Are you sure you want to remove ' + pdf.document_name + '?')) {
          pdf.deleteRecord().then(function (json) {
            if (json.response.error) {
              _this2.growl.error('Could not delete ' + pdf.document_name + '. Please try again.');
            } else {
              _this2.growl.success(pdf.document_name + ' deleted');
            }
          }, function () {
            _this2.growl.error('An error has occured');
          });
        }
      }
    }
  });
});