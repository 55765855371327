define('impact-admin/routes/frame/account', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/organization-profile-statuses', 'impact-admin/models/admin-users', 'impact-admin/models/organization-permissions', 'impact-admin/models/admin-users-notifications'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsOrganizationProfileStatuses, _impactAdminModelsAdminUsers, _impactAdminModelsOrganizationPermissions, _impactAdminModelsAdminUsersNotifications) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.account'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var organization = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var statuses = _impactAdminModelsOrganizationProfileStatuses['default'].findFirst({
                organization_id: params.charity_id
            });
            var users = _impactAdminModelsAdminUsers['default'].find({
                organization_id: params.charity_id
            });
            var permissions = _impactAdminModelsOrganizationPermissions['default'].findFirst({
                organization_id: params.charity_id
            });

            var hash = {
                organization: organization,
                statuses: statuses,
                users: users,
                permissions: permissions
            };

            if (!this.controllerFor('application').get('isImpact')) {
                var admin_user_notifications = _impactAdminModelsAdminUsersNotifications['default'].findFirst({
                    admin_user_id: this.controllerFor('application').get('user.id')
                });
                hash.admin_user_notifications = admin_user_notifications;
            }

            return _ember['default'].RSVP.hash(hash);
        }
    });
});