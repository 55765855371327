define('impact-admin/components/admin-users-row', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: '',

        actions: {
            toggleEditing: function toggleEditing() {
                this.toggleProperty('editing');
            },
            save: function save() {
                var _this2 = this;

                var user = this.get('user');
                if (user.auth0_id == null) {
                    _ember['default'].set(user, 'auth0_id', null);
                }
                this.get('user').save().then(function () {
                    _this2.growl.success('User saved');
                    _this2.set('editing', false);
                })['catch'](function () {
                    _this2.growl.error('An error occurred trying to save this user. Please try again.');
                    _this2.set('editing', false);
                });
            },
            removeUser: function removeUser() {
                this.sendAction('removeUser', this.get('user'));
            },
            resetPassword: function resetPassword() {
                var email = this.get('user.email');
                var _this = this;
                this.set('resettingPassword', true);
                _impactAdminUtilsApi['default'].post('/account/adminRecoverPassword', {
                    email: email
                }, function (json) {
                    if (json.result === 'Success') {
                        _this.growl.success('A reset password link has been emailed to the user.');
                    } else {
                        console.log(json);
                        _this.growl.error('An error occurred while resetting this password. Please try again.');
                    }
                    _this.set('resettingPassword', false);
                }, true);
            }
        }
    });
});