define('impact-admin/controllers/frame/forms/submissions', ['exports', 'ember', 'impact-admin/models/form-submissions', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsFormSubmissions, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({

        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        form_submission_report: _ember['default'].computed('form.[]', function () {
            var form = this.get('form').id;

            return '/submissions_by_form/csv?form_id=' + form;
        }),

        allIncompleteSubmissions: _ember['default'].computed('form_submissions.[]', function () {
            var all_submissions = this.get('form_submissions');
            var incomplete_submissions = [];

            all_submissions.forEach(function (submission) {
                if (submission.status !== 'complete') {
                    incomplete_submissions.push(submission);
                }
            });

            return incomplete_submissions;
        }),

        allCharities: _ember['default'].computed('current_ghd_organizations.[]', function () {
            var all_charities = this.get('current_ghd_organizations');
            var filtered_charities = [];

            all_charities.forEach(function (charity) {

                var isNotAFund = true;

                charity.types.forEach(function (type) {
                    if (type.name === 'Impact Funds' || type.name === 'DMF Funds') {
                        isNotAFund = false;
                    }
                });

                if (isNotAFund) {
                    filtered_charities.push(charity);
                }
            });

            return filtered_charities;
        }),

        charitiesBySearch: _ember['default'].computed.filter('current_ghd_organizations', function (charity) {
            var searchFilter = this.get('nameSearch');
            var regex = new RegExp(searchFilter, 'i');
            return charity.name.match(regex);
        }).property('current_ghd_organizations', 'nameSearch'),

        charitiesBySearchResult: _ember['default'].computed('charitiesBySearch', function () {
            return this.get('charitiesBySearch').splice(0, 2);
        }),

        impactFunds: _ember['default'].computed('current_ghd_organizations.[]', function () {
            var all_charities = this.get('current_ghd_organizations');
            var impact_funds = [];

            all_charities.forEach(function (charity) {

                var isImpactFund = false;

                charity.types.forEach(function (type) {
                    if (type.name === 'Impact Funds') {
                        isImpactFund = true;
                    }
                });

                if (isImpactFund) {
                    impact_funds.push(charity);
                }
            });

            return impact_funds;
        }),

        dmfFunds: _ember['default'].computed('current_ghd_organizations.[]', function () {
            var all_charities = this.get('current_ghd_organizations');
            var dmf_funds = [];

            all_charities.forEach(function (charity) {

                var isDmfFund = false;

                charity.types.forEach(function (type) {
                    if (type.name === 'DMF Funds') {
                        isDmfFund = true;
                    }
                });

                if (isDmfFund) {
                    dmf_funds.push(charity);
                }
            });

            return dmf_funds;
        }),

        actions: {
            downloadFile: _impactAdminUtilsApi['default'].downloadFile,

            addNew: function addNew(org_id) {
                var _this = this;

                var self = this;
                var assignOrgForm = _impactAdminModelsFormSubmissions['default'].createRecord({
                    form_id: self.get('form.id'),
                    organization_id: org_id
                });

                if (assignOrgForm.isReallyDirty()) {
                    assignOrgForm.save().then(function () {
                        _this.growl.success('Form assigned.');
                        self.refreshFormSubmissions();
                    })['catch'](function (json) {
                        _this.growl.error('Campaign could not be saved at this time. Please try again.');
                        console.log(json);
                    });
                } else {
                    this.set('editMode', false);
                }
            },

            bulkAddNew: function bulkAddNew(orgs) {
                var self = this;
                if (window.confirm("Are you sure you want to add " + orgs.length + " organizations to this form?")) {
                    var formAssignments = orgs.map(function (org) {

                        var assignFormSubmission = _impactAdminModelsFormSubmissions['default'].createRecord({
                            form_id: self.get('form.id'),
                            organization_id: org.id
                        });

                        if (assignFormSubmission.isReallyDirty()) {
                            return assignFormSubmission.save();
                        }
                    });

                    _ember['default'].RSVP.Promise.all(formAssignments).then(function (result) {
                        var success = result;
                        console.log(success);
                        self.refreshFormSubmissions();
                    });
                }
            },

            remindOrgsToCompleteForm: function remindOrgsToCompleteForm(form_submissions) {
                var _this2 = this;

                if (confirm('Are you sure you remind all incomplete submissions?')) {
                    var allOrgsToRemind = form_submissions.map(function (form_submission) {
                        if (form_submission.reminder_message_enabled) {
                            return _impactAdminUtilsApi['default'].get('/form_submissions/' + form_submission.organization_id + '/send_reminder/', {}, function (result) {
                                console.log(result);
                            });
                        }
                    });

                    _ember['default'].RSVP.Promise.all(allOrgsToRemind).then(function (result) {
                        var success = result;
                        console.log(success);
                        _this2.growl.success('Reminded');
                    });
                }
            }

        },

        refreshFormSubmissions: function refreshFormSubmissions() {
            var self = this;
            _impactAdminModelsFormSubmissions['default'].find({
                form_id: self.form.id
            }).then(function (json) {
                console.log(json);
                self.set('form_submissions', json.content);
            });
        }

    });
});