define('impact-admin/routes/frame/reports/donations-by-type', ['exports', 'ember', 'impact-admin/models/donations-by-org-type'], function (exports, _ember, _impactAdminModelsDonationsByOrgType) {

    var AdminReportsDonationsByOrgTypeRoute = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },
        model: function model() {
            return _impactAdminModelsDonationsByOrgType['default'].find();
        }
    });

    exports['default'] = AdminReportsDonationsByOrgTypeRoute;
});