define('impact-admin/components/calendar-event', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Component.extend({
        editing: false,

        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,

        setupDateTime: (function () {
            var calendarEvent = this.get('calendarEvent');
            if (calendarEvent.get('start_date')) {
                this.set('startDate', (0, _moment['default'])(calendarEvent.get('start_date'), 'YYYY-MM-DD'));
            } else {
                this.set('startDate', null);
            }
            if (calendarEvent.get('start_time')) {
                this.set('startTime', (0, _moment['default'])(calendarEvent.get('start_time'), 'HH:mm:ss'));
            } else {
                this.set('startTime', null);
            }
            if (calendarEvent.get('end_date')) {
                this.set('endDate', (0, _moment['default'])(calendarEvent.get('end_date'), 'YYYY-MM-DD'));
            } else {
                this.set('endDate', null);
            }
            if (calendarEvent.get('end_time')) {
                this.set('endTime', (0, _moment['default'])(calendarEvent.get('end_time'), 'HH:mm:ss'));
            } else {
                this.set('endTime', null);
            }
        }).on('init'),

        actions: {

            validateProperty: function validateProperty(changeset, property) {
                return changeset.validate(property);
            },
            edit: function edit() {
                this.reset();
                this.setupDateTime();
                this.set('editing', true);
            },
            cancelEdit: function cancelEdit() {
                if (this.get('isNew')) {
                    this.sendAction('cancel');
                } else {
                    this.set('editing', false);
                }
            },
            save: function save(calendarEvent) {

                var startDate = (0, _moment['default'])(this.get('startDate')).format('YYYY-MM-DD');
                var startTime = this.get('startTime') ? (0, _moment['default'])(this.get('startTime')).format('HH:mm:ss') : null;
                var endDate = this.get('endDate') ? (0, _moment['default'])(this.get('endDate')).format('YYYY-MM-DD') : null;
                var endTime = this.get('endTime') ? (0, _moment['default'])(this.get('endTime')).format('HH:mm:ss') : null;

                calendarEvent.set('start_date', startDate);
                calendarEvent.set('start_time', startTime);
                calendarEvent.set('end_date', endDate);
                calendarEvent.set('end_time', endTime);

                this.sendAction('save', calendarEvent, false);
            },
            duplicate: function duplicate(calendarEvent) {
                this.sendAction('duplicate', calendarEvent);
            },
            remove: function remove(calendarEvent) {
                this.get('remove')(calendarEvent);
            }
        },
        reset: function reset() {}

    });
});