define('impact-admin/components/video-frame', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        videoUrl: _ember['default'].computed('source', function () {
            var url = this.get('source');
            var r = /youtube|vimeo|youtu.be/g;
            if (url !== null && url !== "") {
                if (url.match(r) !== null && url.match(r).length > 0) {
                    //check if is youtube
                    if (this.get('isYoutube')) {
                        // check if url is youtube's mobile url
                        if (url.indexOf('youtu.be') >= 0) {
                            return 'https://www.youtube.com/embed/' + url.substring(url.indexOf('.be/') + 4);
                        } else {
                            return 'https://www.youtube.com/embed/' + url.substring(url.indexOf('?v=') + 3);
                        }
                    } else if (this.get('isVimeo')) {
                        return 'https://player.vimeo.com/video' + url.substring(url.indexOf('.com/') + 4);
                    }
                }
            }

            return 'Cannot find url';
        }),

        isYoutube: _ember['default'].computed('source', function () {
            var r = /youtube|youtu.be/g;
            var url = this.get('source');

            if (url !== null && url !== "") {
                if (url.match(r) != null && url.match(r).length > 0) {
                    return true;
                }
            }

            return false;
        }),

        isVimeo: _ember['default'].computed('source', function () {
            var r = /vimeo/g;
            var url = this.get('source');

            if (url !== null && url !== "") {
                if (url.match(r) != null && url.match(r).length > 0) {
                    return true;
                }
            }

            return false;
        })
    });
});