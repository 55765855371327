define('impact-admin/controllers/frame/tools/config', ['exports', 'ember', 'impact-admin/models/ghd-configs'], function (exports, _ember, _impactAdminModelsGhdConfigs) {
    exports['default'] = _ember['default'].Controller.extend({

        inactiveConfigs: _ember['default'].computed('ghdConfigs.[]', function () {
            var configs = this.get('ghdConfigs');

            return configs.filter(function (item) {
                return !item.active;
            });
        }),
        activeConfig: _ember['default'].computed('ghdConfigs.[]', function () {
            var configs = this.get('ghdConfigs');

            return configs.filter(function (item) {
                return item.active;
            })[0];
        }),

        newConfig: null,

        actions: {
            setActive: function setActive(config) {

                var message = "Are you sure you want to make \"" + config.title + "\" the active config?";

                if (!window.confirm(message)) {
                    return;
                }

                var self = this;
                var status = config.get('active');

                if (!status) {
                    config.set('active', true);
                    self.saveConfig(config, 'Set to ACTIVE!');
                }
            },

            startNewConfig: function startNewConfig() {
                var newConfig = _impactAdminModelsGhdConfigs['default'].createRecord({
                    title: 'New Config'
                });

                this.saveConfig(newConfig, 'New Row Created!');
            },

            update: function update(config) {
                var self = this;

                self.saveConfig(config, 'Changes Saved!');
            },

            'delete': function _delete(config) {
                var self = this;

                var message = "Are you sure you want to delete the config \"" + config.title + "\", this is a permanent action?";

                if (!window.confirm(message)) {
                    return;
                }

                config.deleteRecord().then(function (json) {
                    if (json.response.error) {
                        self.growl.error('There was an error saving your changes. ' + json.response.error);
                    } else {
                        self.growl.success('Deleted: ' + config.title);
                        self.refreshConfigList();
                    }
                });
            }
        },

        refreshConfigList: function refreshConfigList() {
            var updatedConfigs = _impactAdminModelsGhdConfigs['default'].find();
            this.set('ghdConfigs', updatedConfigs.content);
        },

        saveConfig: function saveConfig(config, growlMessage) {
            var self = this;

            var successMessage = growlMessage || 'Config Saved Successfully';

            config.save().then(function (json) {
                if (json.response.error) {
                    self.growl.error('There was an error saving your changes. ' + json.response.error);
                } else {
                    self.growl.success(successMessage);
                    self.refreshConfigList();
                }
            });
        }

    });
});