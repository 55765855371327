define('impact-admin/components/volunteer-tracker', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Component.extend({

        dateRange: 8,

        currentTime: _ember['default'].computed('dateRange', function () {
            var dateRange = this.get('dateRange');
            if (!dateRange) {
                return 0;
            }
            return (0, _moment['default'])().subtract(dateRange, 'd').unix();
        }),

        volunteerCount: _ember['default'].computed('volunteer', function () {
            var count = 0;
            this.get('volunteer').forEach(function (volunteerOpp) {
                if (volunteerOpp) {
                    console.log(volunteerOpp);
                    count += 1;
                }
            });
            return count;
        }),
        volunteerSubmissionCount: _ember['default'].computed('volunteer_submissions', 'currentTime', function () {
            var count = 0;
            var dateRange = this.get('currentTime');
            this.get('volunteer_submissions').forEach(function (submission) {
                if (submission.submitted > dateRange) {
                    count += 1;
                }
            });
            return count;
        })
    });
});