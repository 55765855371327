define('impact-admin/helpers/add', ['exports', 'ember'], function (exports, _ember) {
    exports.add = add;

    function add(params /*, hash*/) {
        var result = 0;
        if (params) {
            params.forEach(function (param) {
                result = result + param;
            });
        }
        return result;
    }

    exports['default'] = _ember['default'].Helper.helper(add);
});