define('impact-admin/components/add-ghd-resource', ['exports', 'ember', 'impact-admin/models/ghd-resources'], function (exports, _ember, _impactAdminModelsGhdResources) {
    exports['default'] = _ember['default'].Component.extend({
        isAdding: false, file: '', title: '', link: '',

        actions: {
            toggleAdd: function toggleAdd() {
                this.toggleProperty('isAdding');
                this.reset();
            },

            toggleCancel: function toggleCancel() {
                this.toggleProperty('isAdding');
            },

            updateFile: function updateFile(tempFile) {
                this.set('title', tempFile.title);
                this.set('file', tempFile.url);
            },

            addResource: function addResource() {
                var _this = this;

                var record = {
                    title: this.get('title'),
                    file: this.get('file'),
                    link: this.get('link'),
                    category: this.get('category')
                };

                var newResource = _impactAdminModelsGhdResources['default'].createRecord(record);
                newResource.save().then(function () {
                    _this.growl.success('Resource added!');
                    _this.get('categoryResources').pushObject(newResource);
                    _this.toggleProperty('isAdding');
                    _this.reset();
                })['catch'](function (reason) {
                    console.log(reason);
                    _this.growl.error('Failed to add resource.');
                });
            }
        },

        reset: function reset() {
            this.set('file', '');
            this.set('title', '');
        }

    });
});