define('impact-admin/routes/frame/tools', ['exports', 'ember', 'impact-admin/models/admin-users', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsAdminUsers, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                user: this.controllerFor('application').get('user'),
                admin_users: _impactAdminModelsAdminUsers['default'].find({
                    type: 'impact'
                }),
                activeGhdOrgs: _impactAdminModelsOrganizations['default'].find({
                    ghd_access: true
                }, {
                    sort: {
                        id: 'DESC'
                    }
                })
            });
        }
    });
});