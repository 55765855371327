define('impact-admin/models/types', ['exports', 'ember'], function (exports, _ember) {

    /*
     *Types Model
     */
    var Types = _ember['default'].Model.extend({
        iconClass: (function () {
            switch (this.get('name')) {
                case 'Animals':
                    return 'icon-paw';
                case 'Art & Culture':
                    return 'icon-paint-brush';
                case 'Basic Needs':
                    return 'icon-cutlery';
                case 'Crisis Intervention':
                    return 'icon-life-bouy';
                case 'Disability Services & Resources':
                    return 'icon-wheelchair';
                case 'Education':
                    return 'icon-graduation-cap';
                case 'Faith Based':
                    return 'icon-faith';
                case 'Health & Wellness':
                    return 'icon-stethoscope';
                case 'Youth':
                    return 'icon-youth';
                case 'DMF Funds':
                    return 'icon-dmf';
                case 'Impact Funds':
                    return 'icon-impact';
                case 'Senior Services':
                    return 'icon-seniorcare';
                default:
                    return '';
            }
        }).property('name'),

        //These two properties are used for the type select 2
        description: _ember['default'].computed.alias('name'),

        text: _ember['default'].computed('iconClass', function () {
            var html = '<span class="' + this.get('iconClass') + '"></span>';
            return html.htmlSafe();
        })
    });

    Types.table = 'types';

    exports['default'] = Types;
});