define('impact-admin/models/email-notifications', ['exports', 'ember'], function (exports, _ember) {

    var EmailNotifications = _ember['default'].Model.extend({
        title: _ember['default'].computed('type', function () {
            var arr = this.get('type').split('_');
            arr.forEach(function (word) {
                word = word.charAt(0).toUpperCase() + word.slice(1);
            });
            return arr.join(' ');
        })
    });

    EmailNotifications.table = 'email_notifications';

    exports['default'] = EmailNotifications;
});