define('impact-admin/components/email-text-panel', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'div',
        classNames: ['panel', 'panel-default'],
        notEditing: _ember['default'].computed.not('editing'),
        emailModalVisible: false,

        setOldValue: (function () {
            this.set('oldValue', this.get('email_text'));
        }).on('init'),

        actions: {
            edit: function edit() {
                this.set('editing', true);
                _ember['default'].run.later(this, function () {
                    this.$('textarea').focus();
                }, 10);
            },
            save: function save() {
                this.set('editing', false);
                this.set('oldValue', this.get('email_text'));
                this.sendAction('action', this.get('email_text'), this.get('column'));
            },
            cancel: function cancel() {
                this.set('editing', false);
                this.set('email_text', this.get('oldValue'));
            },
            preview: function preview() {
                this.set('editing', false);
                this.set('emailModalVisible', true);
                var tag_id = document.getElementById("email_render_" + this.get('panel_id'));
                tag_id.innerHTML = this.get('email_text');
            },
            closeEmailModal: function closeEmailModal() {
                this.set('emailModalVisible', false);
            },
            toggleViewReceipt: function toggleViewReceipt() {
                this.sendAction('toggleViewReceipt');
            }
        }
    });
});