define('impact-admin/components/ghd-export', ['exports', 'ember', 'impact-admin/utils/api', 'moment'], function (exports, _ember, _impactAdminUtilsApi, _moment) {
    var observer = _ember['default'].observer;
    exports['default'] = _ember['default'].Component.extend({

        manageGhd: _ember['default'].inject.service(),
        donationsList: null,
        lowerLimit: 1,
        upperLimit: 0,

        //search field properties:
        ghd_year: null,

        exportUlr: null,
        filter: null,

        filter_years: _ember['default'].computed('', function () {
            var m = (0, _moment['default'])();
            var years = [];
            for (var n = m.year(); n >= 2015; n--) {
                years.push(n);
            }
            return years;
        }),

        urlAndFilterObserver: observer('ghd_year', 'charity.id', function () {
            this.setFilter();
            this.setExportUrl();
        }),

        setExportUrl: function setExportUrl() {
            var url = '/donations/csv/'; //?org_id=' + this.get('charity.id');
            var filter = this.get('filter');
            url += '?filter=' + encodeURI(JSON.stringify(filter));
            this.set('exportUrl', url);
        },

        actions: {

            clearFilters: function clearFilters() {
                this.setProperties({
                    ghd_year: null
                });
            },

            year_change: function year_change(year) {
                this.set('ghd_year', year.target.value);
            },

            downloadFile: _impactAdminUtilsApi['default'].downloadFile
        },

        setFilter: function setFilter() {
            var charity_id = this.get('charity.id');

            var filter_obj = {};

            if (charity_id) {
                filter_obj['organization_id'] = charity_id;
            }

            if (this.get('ghd_year')) {
                filter_obj['ghd_year'] = this.get('ghd_year');
            }

            this.set('filter', filter_obj);
        }

    });
});