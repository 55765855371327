define('impact-admin/controllers/frame/volunteer-applicants/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        frame: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        queryParams: ['volunteer_id'],
        volunteer_id: 0,
        sortProperties: ['submitted:desc'],

        sortedModel: _ember['default'].computed.sort('filteredModel', 'sortProperties'),

        filteredModel: _ember['default'].computed('model.submissions.[]', 'volunteer_id', 'searchTerm', function () {
            var _this = this;

            var _getProperties = this.getProperties('searchTerm', 'volunteer_id');

            var searchTerm = _getProperties.searchTerm;
            var volunteer_id = _getProperties.volunteer_id;

            var submissions = this.get('model.submissions');

            submissions = submissions.filterBy('organization_id', parseInt(this.get('model.charity_id'), 10));

            if (volunteer_id) {
                submissions = submissions.filterBy('volunteer_id', volunteer_id);
            }

            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    submissions.forEach(function (submission) {
                        if ((submission.get('first_name') + submission.get('last_name')).toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(submission);
                        }
                    }, _this);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            }

            return submissions;
        }),

        totalUnread: _ember['default'].computed('frame.volunteer_submission_count', function () {
            return this.get('frame.volunteer_submission_count');
        }),

        hasFilters: _ember['default'].computed.or('volunteer_id', 'searchTerm'),

        actions: {
            markAsRead: function markAsRead(submission) {
                var _this2 = this;

                submission.set('seen_by_admin', true);
                submission.save().then(function () {
                    _this2.growl.success('Marked As Read');
                    var totalUnread = _this2.get('frame.volunteer_submission_count');
                    _this2.set('frame.volunteer_submission_count', totalUnread - 1);
                })['catch'](function (reason) {
                    console.log(reason);
                    _this2.growl.error('An error occurred saving the status. Please try again.');
                });
            },
            clearFilters: function clearFilters() {
                this.setProperties({
                    searchTerm: null,
                    volunteer_id: 0
                });
            },
            deleteSubmission: function deleteSubmission(submission) {
                var _this3 = this;

                if (confirm('Are you sure you want to delete this submission? \nThis cannot be undone.')) {
                    submission.deleteRecord().then(function () {
                        _this3.growl.success('Submission deleted');
                    })['catch'](function (json) {
                        console.log(json);
                        _this3.growl.error('Could not delete this submissions at this time. Please try again.');
                    });
                }
            },
            exportResults: function exportResults(donorSubmissions) {
                var self = this;

                var selectOpp = '';
                if (self.get('volunteer_id')) {
                    selectOpp = 'Generic Opportunity';
                    this.get('model.volunteer').forEach(function (opp) {
                        if (opp.id === self.get('volunteer_id')) {
                            selectOpp = opp.title;
                        }
                    });
                } else {
                    selectOpp = 'All';
                }

                var dataToExport = [['First Name', 'Last Name', 'Email', 'Phone', 'Opportunity', 'Other Interests', 'Notes', 'Business', 'Submitted']];
                donorSubmissions.forEach(function (donor) {
                    console.log(donor);

                    var oppIdTitle = '';
                    if (donor.volunteer_id) {
                        oppIdTitle = 'Generic Opportunity';
                        self.get('model.volunteer').forEach(function (opp) {
                            if (opp.id === donor.volunteer_id) {
                                oppIdTitle = opp.title;
                            }
                        });
                    }

                    var epochTime = new Date(donor.submitted * 1000);

                    var formattedDate = self.formatDate(epochTime);

                    var donorArray = [donor.first_name, donor.last_name, donor.email, donor.phone, oppIdTitle, donor.other_interests, donor.notes, donor.business_name, formattedDate];

                    dataToExport.push(donorArray);
                });

                this.exportArrayToFile(dataToExport, selectOpp);
            }
        },

        exportArrayToFile: function exportArrayToFile(data, org_opp) {

            /*
                    console.log(array);
                    let csvContent = "data:text/csv;charset=utf-8,"  + array.map(e => e.join(",")).join("\n");
                    console.log(csvContent);
                    let encodedUri = encodeURI(csvContent);
                    window.open(encodedUri);
                }
            */
            var csvContent = '';
            data.forEach(function (infoArray, index) {
                console.log(infoArray);
                var dataString = '"' + infoArray.join('","') + '"';
                csvContent += index < data.length ? dataString + '\n' : dataString;
            });

            // The download function takes a CSV string, the filename and mimeType as parameters
            // Scroll/look down at the bottom of this snippet to see how download is called
            var download = function download(content, fileName, mimeType) {
                var a = document.createElement('a');
                mimeType = mimeType || 'application/octet-stream';

                if (navigator.msSaveBlob) {
                    // IE10
                    navigator.msSaveBlob(new Blob([content], {
                        type: mimeType
                    }), fileName);
                } else if (URL && 'download' in a) {
                    //html5 A[download]
                    a.href = URL.createObjectURL(new Blob([content], {
                        type: mimeType
                    }));
                    a.setAttribute('download', fileName);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
                }
            };
            var currentTime = new Date();

            var timestamp = currentTime.getMonth() + 1 + '-' + currentTime.getDate() + '-' + currentTime.getFullYear();
            download(csvContent, org_opp + ' Volunteer Applicants ' + timestamp + '.csv', 'text/csv;encoding:utf-8');
        },

        formatDate: function formatDate(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
        }
    });
});