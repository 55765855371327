define('impact-admin/mixins/sort-by-moment-date', ['exports', 'moment'], function (exports, _moment) {

    var SortByMomentDate = function SortByMomentDate(x, y) {

        x = x ? (0, _moment['default'])(x).unix() : -Infinity;
        y = y ? (0, _moment['default'])(y).unix() : -Infinity;

        if (x === y) {
            return 0;
        }
        return x < y ? -1 : 1;
    };

    exports['default'] = SortByMomentDate;
});