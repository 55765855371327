define('impact-admin/routes/frame/reports/online-prepaid-codes', ['exports', 'ember', 'impact-admin/models/prepaid-code-groups'], function (exports, _ember, _impactAdminModelsPrepaidCodeGroups) {

    var AdminReportsOnlinePrepaidCodesRoute = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            return _impactAdminModelsPrepaidCodeGroups['default'].find({
                online_code: true
            });
        }
    });

    exports['default'] = AdminReportsOnlinePrepaidCodesRoute;
});