define('impact-admin/routes/logout', ['exports', 'ember'], function (exports, _ember) {

    var LogoutRoute = _ember['default'].Route.extend({
        redirect: function redirect() {
            this.controllerFor('application').reset();
            this.transitionTo('home');
        }
    });

    exports['default'] = LogoutRoute;
});