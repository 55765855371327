define('impact-admin/controllers/apply', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        alerts: _ember['default'].A(),
        alert_type: '',
        application: _ember['default'].inject.controller(),
        manageGhd: _ember['default'].inject.service('manage-ghd'),
        currentGHDYear: (function () {
            return this.get('manageGhd.getCurrentGHDYear');
        }).property('application'),
        application_open: true,
        charitySaving: false,
        charityCreated: false,
        actions: {
            register: function register() {

                var context = this;

                if (this.validate()) {
                    context.set('charitySaving', true);

                    _impactAdminUtilsApi['default'].post('/admin/register', {
                        organization_name: this.get('organization_name'),
                        email: this.get('email'),
                        first_name: this.get('first_name'),
                        last_name: this.get('last_name')
                    }, function (json) {
                        console.log('Register Error', json);
                        context.set('charitySaving', false);
                        if (json.error === undefined) {
                            context.set('charityCreated', true);
                        } else {

                            context.get('alerts').pushObject(json.error);
                        }
                    });
                }
                return false;
            }
        },

        validate: function validate() {
            this.set('alerts', _ember['default'].A());
            var err = false;
            if (this.get('email') === undefined || this.get('email') === null || this.get('email') === "" || !new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/).test(this.get('email'))) {
                this.get('alerts').pushObject('Please enter a valid email address.');
                err = true;
            }
            if (this.get('organization_name') === undefined || this.get('organization_name') === null || this.get('organization_name') === "") {
                this.get('alerts').pushObject('Please enter organization name.');
                err = true;
            }
            return !err;
            //return this.get('alerts').length != 0;
        },

        reset: function reset() {
            this.set('email', null);
            this.set('organization_name', null);
            this.set('first_name', null);
            this.set('last_name', null);
        }
    });
});