define('impact-admin/routes/frame', ['exports', 'ember', 'impact-admin/utils/token', 'impact-admin/models/organizations', 'impact-admin/models/form-submissions', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsToken, _impactAdminModelsOrganizations, _impactAdminModelsFormSubmissions, _impactAdminUtilsApi) {
    var Route = _ember['default'].Route;
    var service = _ember['default'].inject.service;
    var get = _ember['default'].get;

    var FrameRoute = Route.extend({
        auth: service(),
        beforeModel: function beforeModel() {
            var self = this;
            if (!_impactAdminUtilsToken['default'].get() && !get(this, 'auth.isAuthenticated')) {
                this.transitionTo('home');
            }
            //Here we make sure that Ember.SocketIO is initialized BEFORE we enter any other routes.
            return new _ember['default'].RSVP.Promise(function (resolve) {
                //This starts the Pusher Connection process.
                //debugger;
                if (self.controllerFor('application').get('isImpact')) {
                    _ember['default'].SocketIO.join('impact_giveback');
                    _ember['default'].SocketIO.join('global');
                    _ember['default'].SocketIO.join('impact_admin');

                    //This is a recursive function that calls itself every 500 ms until Ember.SocketIO.io is initialized.
                    var subscribe = function subscribe() {
                        if (!_ember['default'].SocketIO.io) {
                            return _ember['default'].run.later(function () {
                                subscribe();
                            }, 500);
                        }
                        resolve(true);
                    };
                    //Start the waiting...
                    subscribe();
                } else {
                    resolve(true);
                }
            });
        },

        model: function model() {
            if (this.controllerFor('application').get('isImpact')) {
                return 'impact';
            } else {
                console.log(this.controllerFor('application').get('user.organization_id'));
                var user_org_id = this.controllerFor('application').get('user.organization_id');

                var organization = _impactAdminModelsOrganizations['default'].find(user_org_id);

                var volunteer_submission_count = _impactAdminUtilsApi['default'].get('/volunteer_submissions/' + this.controllerFor('application').get('user.organization_id') + '/total_submission_count', {}, function () {}, true);

                var form_submissions = _impactAdminModelsFormSubmissions['default'].find({
                    organization_id: user_org_id,
                    status: ['assigned', 'incomplete']
                });

                return _ember['default'].RSVP.hash({
                    organization: organization,
                    volunteer_submission_count: volunteer_submission_count,
                    form_submissions: form_submissions
                });
            }
        },

        setupController: function setupController(controller, model) {
            if (!this.controllerFor('application').get('isImpact')) {
                controller.set('volunteer_submission_count', model.volunteer_submission_count.result.total);
            }
            controller.set('assigned_forms', model.form_submissions);
            controller.set('model', model.organization);
        }

    });

    exports['default'] = FrameRoute;
});