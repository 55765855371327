define('impact-admin/routes/frame/card-reader', ['exports', 'ember', '@stripe/terminal-js', 'impact-admin/models/prepaid-code-groups', 'impact-admin/models/code-groups', 'impact-admin/models/products', 'impact-admin/utils/api'], function (exports, _ember, _stripeTerminalJs, _impactAdminModelsPrepaidCodeGroups, _impactAdminModelsCodeGroups, _impactAdminModelsProducts, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {

            var getStripeTerminalClientSecret = (0, _stripeTerminalJs.loadStripeTerminal)().then(function (value) {
                var t = value.create({
                    onFetchConnectionToken: function onFetchConnectionToken() {
                        return _impactAdminUtilsApi['default'].post('/terminal/connection_token', {}, function (json) {
                            if (json.error === undefined) {
                                return json;
                            } else {
                                console.log(json.error);
                            }
                        }).then(function (token) {
                            console.log(token);
                            return token.secret;
                        });
                    },
                    onUnexpectedReaderDisconnect: function onUnexpectedReaderDisconnect() {
                        console.log('Help, I am no longer here');
                    }
                });
                return t;
            }, function (reason) {
                console.log(reason);
            });

            return _ember['default'].RSVP.hash({
                terminal: getStripeTerminalClientSecret,
                matchCodeGroups: _impactAdminModelsCodeGroups['default'].find({
                    payment_received: false
                }),
                prepaidCodeGroups: _impactAdminModelsPrepaidCodeGroups['default'].find({
                    payment_received: false
                }),
                products: _impactAdminModelsProducts['default'].find()
            });
        },

        setupController: function setupController(controller, model) {

            controller.set('terminal', model.terminal);
            controller.set('matchCodeGroups', model.matchCodeGroups);
            controller.set('prepaidCodeGroups', model.prepaidCodeGroups);
            controller.set('products', model.products);
        }

    });
});