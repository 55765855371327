define('impact-admin/models/good-charity-submission', ['exports', 'ember', 'impact-admin/models/good'], function (exports, _ember, _impactAdminModelsGood) {

    var GoodsSubmission = _ember['default'].Model.extend({
        good_opportunity: _ember['default'].computed('good_id', function () {
            return _impactAdminModelsGood['default'].find(this.get('good_id'));
        })
    });

    GoodsSubmission.table = 'good_submissions';

    exports['default'] = GoodsSubmission;
});