define('impact-admin/models/volunteer-submissions', ['exports', 'ember', 'impact-admin/models/volunteer'], function (exports, _ember, _impactAdminModelsVolunteer) {

    var Submission = _ember['default'].Model.extend({
        volunteer_opportunity: _ember['default'].computed('volunteer_id', function () {
            if (this.get('volunteer_id')) {
                return _impactAdminModelsVolunteer['default'].find(this.get('volunteer_id'));
            }

            return "Generic";
        })
    });

    Submission.table = 'volunteer_submissions';

    exports['default'] = Submission;
});