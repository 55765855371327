define('impact-admin/components/delivery-method-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',

    emailDelivery: (function () {
      return this.get('card.delivery_method') === 'email';
    }).property('card'),

    mailDelivery: (function () {
      return this.get('card.delivery_method') === 'mail';
    }).property('card'),

    pickupDelivery: (function () {
      return this.get('card.delivery_method') === 'pickup';
    }).property('card')

  });
});