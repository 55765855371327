define('impact-admin/components/profile-strength', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        progressPercentage: _ember['default'].computed('org.have_registered_for_stripe', 'org.membership_active', 'org.profile_complete', 'org.match_is_complete', function () {
            var percentGoal = 0;

            var org = this.get('org');

            if (org.profile_complete) {
                percentGoal += 50;
            }

            if (org.video) {
                percentGoal += 10;
            }
            if (org.keywords.length > 0) {
                percentGoal += 10;
            }
            if (org.show_goods) {
                percentGoal += 10;
            }
            if (org.volunteer_total) {
                percentGoal += 10;
            }

            if (org.image || org.featured_image_1 || org.featured_image_2 || org.featured_image_3 || org.featured_image_4) {
                percentGoal += 10;
            }

            return percentGoal;
        }),

        hasImage: _ember['default'].computed('org', function () {

            var org = this.get('org');
            return org.image || org.featured_image_1 || org.featured_image_2 || org.featured_image_3 || org.featured_image_4;
        }),

        strengthStepCount: _ember['default'].computed('org.have_registered_for_stripe', 'org.membership_active', 'org.profile_complete', 'org.match_is_complete', function () {
            var percentGoal = 0;

            var org = this.get('org');

            if (org.profile_complete) {
                percentGoal += 1;
            }

            if (org.video) {
                percentGoal += 1;
            }
            if (org.keywords.length > 0) {
                percentGoal += 1;
            }
            if (org.show_goods) {
                percentGoal += 1;
            }
            if (org.volunteer_total) {
                percentGoal += 1;
            }

            if (org.image || org.featured_image_1 || org.featured_image_2 || org.featured_image_3 || org.featured_image_4) {
                percentGoal += 1;
            }

            return percentGoal;
        })

    });
});