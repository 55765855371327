define('impact-admin/helpers/convert-to-mt', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

    //Converts from cents to dollars.
    var helper = _ember['default'].Helper.helper(function (params) {
        var time = params[0];
        // Parse the time using moment.js
        var parsedTime = (0, _moment['default'])(time, 'HH:mm:ss');

        // Subtract one hour
        var adjustedTime = parsedTime.subtract(1, 'hours');
        console.log(adjustedTime);
        // Format the adjusted time
        return adjustedTime.format('hh:mm A');
    });

    exports['default'] = helper;
});