define('impact-admin/components/charity-status-changer', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({
        statuses: [{
            label: 'Pending',
            value: 'pending'
        }, {
            label: 'Active',
            value: 'active'
        }, {
            label: 'Archived',
            value: 'deactivated'
        }],

        ghd_profile_statuses: [{
            label: 'Approved',
            value: true
        }, {
            label: 'Pending',
            value: false
        }],

        general_profile_statuses: [{
            label: 'Approved',
            value: true
        }, {
            label: 'Pending',
            value: false
        }],

        actions: {
            saveStatus: function saveStatus() {
                var _this = this;

                var model = this.get('charity');
                if (model.isReallyDirty()) {
                    this.set('savingStatus', true);
                    model.save().then(function () {
                        _this.growl.success('Status saved');
                        _this.set('savingStatus', false);
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this.growl.error('An error occurred saving the status. Please try again.');
                        _this.set('savingStatus', false);
                    });
                }
            },
            saveGivingHeartsDayProfileStatus: function saveGivingHeartsDayProfileStatus() {
                var _this2 = this;

                var profile_statuses = this.get('profile_statuses');
                if (profile_statuses.isReallyDirty()) {
                    this.set('savingStatus', true);
                    profile_statuses.save().then(function () {
                        _this2.growl.success('Status saved');
                        _this2.set('savingStatus', false);
                    })['catch'](function (reason) {
                        _this2.set('savingStatus', false);
                        _this2.growl.error('An error occurred saving the status. Please try again.');
                        console.log(reason);
                    });
                }
            },
            setPermission: function setPermission(value, component) {
                var _this3 = this;

                this.set('isSavingPerms', true);
                var permissionType = component.get('permissionType');
                var permissions = this.get('permissions');
                var statuses = this.get('statuses');

                var prms = null;
                if (permissionType === 'ghd_access' && !value && statuses.get('ghd_profile_approved')) {
                    // save the profile status too
                    statuses.set('ghd_profile_approved', false);
                    prms = statuses.save();
                }

                permissions.set(permissionType, value);
                _ember['default'].RSVP.Promise.all([permissions.save(), prms]).then(function () {
                    _this3.set('isSavingPerms', false);
                    _this3.growl.success('Permission saved');
                    _impactAdminUtilsApi['default'].get('/account/refresh-cache/', {}, function (json) {
                        if (json.error) {
                            //self.growl.error(message);
                        } else {
                                //self.growl.success(json.result);
                            }
                    }, true);
                })['catch'](function (error) {
                    console.log(error);
                    _this3.set('isSavingPerms', false);
                    _this3.growl.error('An error occurred trying to set this permission. Please try again.');
                });
            },
            saveAdminAccess: function saveAdminAccess() {
                var _this4 = this;

                var model = this.get('charity');
                if (model.isReallyDirty()) {
                    this.set('isSavingAdminAccess', true);
                    model.save().then(function () {
                        _this4.growl.success('Admin Access saved');
                        _this4.set('isSavingAdminAccess', false);
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this4.growl.error('An error occurred saving the admin access. Please try again.');
                        _this4.set('isSavingAdminAccess', false);
                    });
                }
            },
            saveGeneralProfileStatus: function saveGeneralProfileStatus() {
                var _this5 = this;

                var profile_statuses = this.get('profile_statuses');
                if (profile_statuses.isReallyDirty()) {
                    this.set('savingStatus', true);
                    profile_statuses.save().then(function () {
                        _this5.growl.success('Status saved');
                        _this5.set('savingStatus', false);
                    })['catch'](function (reason) {
                        _this5.set('savingStatus', false);
                        _this5.growl.error('An error occurred saving the status. Please try again.');
                        console.log(reason);
                    });
                }
            }
        }
    });
});