define('impact-admin/components/charity-profile-info', ['exports', 'ember', 'impact-admin/models/organization-keywords', 'impact-admin/models/organization-locations', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsOrganizationKeywords, _impactAdminModelsOrganizationLocations, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({
        us_states: ['North Dakota'],
        serve_areas: ['State', 'County', 'City'],
        canAddMoreKeywords: _ember['default'].computed('charity.keywords_objects.[]', function () {
            var length = this.get('charity.keywords_objects.length');

            return length < 5;
        }),

        canAddLocations: _ember['default'].computed('locations.[]', function () {
            var length = this.get('locations.length');

            return length < 10;
        }),
        charityNamePlaceholder: _ember['default'].computed(function () {
            return this.get('LABELS.CHARITY') + ' Name';
        }),

        banner_image: _ember['default'].computed('charity.banner', function () {
            if (this.get('charity.banner') === 'red') {
                return 'images/GHD-Charity-Banner-Red.jpg';
            } else if (this.get('charity.banner') === 'blue') {
                return 'images/GHD-Charity-Banner-Blue.jpg';
            } else if (this.get('charity.banner')) {
                return this.get('charity.banner');
            }

            return 'images/GHD-Charity-Banner-Red.jpg';
        }),
        isValidVideoUrl: (function () {
            var url = this.get('charity.video');
            var r = /youtube|vimeo|youtu.be/;
            if (url !== null && url !== "") {
                return url.match(r) != null && url.match(r).length > 0;
            }
            return true;
        }).property('charity.video'),

        actions: {

            scrollToGeneral: function scrollToGeneral() {
                var elmnt = document.getElementById("general");
                elmnt.scrollIntoView();
            },
            scrollToMissionInfo: function scrollToMissionInfo() {
                var elmnt = document.getElementById("mission-info");
                elmnt.scrollIntoView();
            },
            scrollToMedia: function scrollToMedia() {
                var elmnt = document.getElementById("media");
                elmnt.scrollIntoView();
            },

            addBoardMember: function addBoardMember() {
                var name = this.get('boardMemberName');
                var title = this.get('boardMemberTitle');
                var charity = this.get('charity');

                if (!name || !title) {
                    return;
                }
                charity.addBoardMember(name, title);

                this.set('boardMemberName', '');
                this.set('boardMemberTitle', '');
            },
            setEditingBoardMember: function setEditingBoardMember(member, idx) {
                this.set('editingBoardMemberIdx', idx);
                if (idx === -1) {
                    this.set('editName', '');
                    this.set('editTitle', '');
                    return;
                }
                this.set('editName', member.name);
                this.set('editTitle', member.title);
            },
            moveMemberUp: function moveMemberUp(idx) {
                var charity = this.get('charity');
                charity.moveBoardMemberUp(idx);
            },
            moveMemberDown: function moveMemberDown(idx) {
                var charity = this.get('charity');
                charity.moveBoardMemberDown(idx);
            },
            editBoardMember: function editBoardMember(idx) {
                var name = this.get('editName');
                var title = this.get('editTitle');
                var charity = this.get('charity');

                charity.editBoardMember(name, title, idx);
                this.set('editName', '');
                this.set('editTitle', '');
                this.set('editingBoardMemberIdx', -1);
            },
            delBoardMember: function delBoardMember(idx) {
                var charity = this.get('charity');
                charity.delBoardMember(idx);
            },
            setBanner: function setBanner(color) {
                this.set('charity.banner', color);
            },
            closeIfFull: function closeIfFull(select) {
                if (this.get('selectedTypes.length') >= 3) {
                    select.actions.close();
                }
            },
            closeIfFullGoods: function closeIfFullGoods(select) {
                if (this.get('selectedGoods.length') >= 3) {
                    select.actions.close();
                }
            },
            removeImage: function removeImage(image) {
                if (window.confirm("Do you want to remove this image from your profile?\n\nNote: You still need to save your profile to confirm these changes.")) {
                    this.set(image, "");
                }
            },
            updateSelectedTypes: function updateSelectedTypes(newTypes) {
                var isImpact = this.get('isImpact');
                var newTypeIDs = newTypes.map(function (type) {
                    return type.id;
                });
                newTypeIDs.forEach(function (type) {
                    if (!isImpact && type === 10) {
                        alert('DMF Funds not allowed. A DMF fund is directly managed by Dakota Medical Foundation. ' + 'If you believe this is a mistake please contact a system administrator');
                        var index = newTypeIDs.indexOf(10);
                        newTypes.splice(index, 1);
                    }
                    if (!isImpact && type === 11) {
                        alert('Impact Funds not allowed. An Impact fund is directly managed by the Impact Foundation. ' + 'If you believe this is a mistake please contact a system administrator');
                        var index = newTypeIDs.indexOf(11);
                        newTypes.splice(index, 1);
                    }
                });

                if (newTypes.length <= 3) {
                    this.set('selectedTypes', newTypes);
                } else {
                    alert('Only 3 types allowed. Please remove one before adding another');
                }
            },
            updateSelectedGoods: function updateSelectedGoods(newGoods) {
                if (newGoods.length <= 3) {
                    this.set('selectedGoods', newGoods);
                } else {
                    alert('Only 3 types allowed. Please remove one before adding another');
                }
            },
            addKeyword: function addKeyword() {
                var _this = this;

                var keyword = this.get('newKeyword');
                this.set('loadingKeyword', true);

                if (keyword) {
                    if (this.validateKeyword()) {
                        keyword = keyword.trim();
                        var keywordRecord = _impactAdminModelsOrganizationKeywords['default'].createRecord({
                            organization_id: this.get('charity.id'),
                            keyword: keyword
                        });
                        keywordRecord.save().then(function (json) {
                            if (json.response.error) {
                                //This means the keyword already exists
                                if (json.response.error.err_message === 'error: duplicate key value violates unique constraint "organization_keywords_index_keyword_organization_id"') {
                                    _this.growl.error('That keyword already exist. Please enter a different one.');
                                } else {
                                    _this.growl.error('An error occurred adding this keyword. Please try again.');
                                    console.log(json);
                                }
                            } else {
                                _this.set('newKeyword', null);
                            }
                            _this.set('loadingKeyword', false);
                        })['catch'](function (json) {
                            _this.growl.error('An error occurred adding this keyword. Please try again.');
                            console.log(json);
                            _this.set('loadingKeyword', false);
                        });
                    } else {
                        this.growl.error(this.get('keywordError'));
                        this.set('loadingKeyword', false);
                    }
                } else {
                    this.growl.error('Please enter a keyword');
                    this.set('loadingKeyword', false);
                }
            },
            removeKeyword: function removeKeyword(keywordItem) {
                var _this2 = this;

                keywordItem.deleteRecord().then(function (json) {
                    if (json.response.error) {
                        _this2.growl.error('An error occurred deleting this keyword. Please try again');
                        console.log(json);
                    } else {
                        _this2.growl.success('Keyword removed');
                    }
                })['catch'](function (json) {
                    _this2.growl.error('An error occurred deleting this keyword. Please try again');
                    console.log(json);
                });
            },
            resetState: function resetState() {
                this.set('selectedState', null);
            },
            stateSelected: function stateSelected(us_state) {
                var self = this;

                if (this.get('serveArea') === 'County') {
                    this.set('loadingCounties', true);

                    _impactAdminUtilsApi['default'].get('/location-search/counties-by-state', {
                        state: us_state
                    }, function (county_names) {
                        var counties = [];

                        var id = 1;
                        county_names.forEach(function (county_name) {
                            var county = {
                                id: id,
                                text: county_name
                            };
                            id++;
                            counties.pushObject(county);
                        });

                        self.set('counties', counties);
                        self.set('loadingCounties', false);
                    });
                } else if (this.get('serveArea') === 'City') {
                    this.set('loadingCities', true);
                    _impactAdminUtilsApi['default'].get('/location-search/cities-by-state', {
                        state: us_state
                    }, function (city_names) {
                        var cities = [];

                        var id = 1;
                        city_names.forEach(function (city_name) {
                            var city = {
                                id: id,
                                text: city_name
                            };
                            id++;
                            cities.pushObject(city);
                        });

                        self.set('cities', cities);
                        self.set('loadingCities', false);
                    });
                }
            },
            addLocation: function addLocation(type) {
                var _this3 = this;

                var properties = {
                    organization_id: this.get('charity.id')
                };
                if (type === 'city') {
                    properties.city = this.get('selectedCity.text');
                    properties.state = this.get('selectedState');
                } else if (type === 'county') {
                    properties.county = this.get('selectedCounty.text');
                    properties.state = this.get('selectedState');
                } else if (type === 'state') {
                    properties.state = this.get('selectedState');
                }

                var newLocation = _impactAdminModelsOrganizationLocations['default'].createRecord(properties);

                newLocation.save().then(function () {
                    _this3.growl.success('Location saved');
                    _this3.setProperties({
                        selectedState: null,
                        selectedCounty: null,
                        selectedCity: null,
                        serveArea: null,
                        counties: [],
                        cities: []
                    });
                })['catch'](function (reason) {
                    console.log(reason);
                });
            },
            removeLocation: function removeLocation(location) {
                var _this4 = this;

                if (confirm('Are you sure you would like to delete this location?')) {
                    location.deleteRecord().then(function () {
                        _this4.growl.success('Location was deleted.');
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this4.growl.error('There was an error when trying to delete this location. Please try again.');
                    });
                }
            }
        },

        validateKeyword: function validateKeyword() {
            var keyword = this.get('newKeyword');
            var valid = true;

            if (keyword.length < 3) {
                this.set('keywordError', 'Please enter 3 or more characters.');
                valid = false;
                return valid;
            }

            this.get('charity.keywords_objects').forEach(function (keywordItem) {
                if (keywordItem.get('keyword').toLowerCase() === keyword.toLowerCase()) {
                    valid = false;
                    this.set('keywordError', 'That keyword already exist. Please enter a different one.');
                }
            });

            return valid;
        }

    });
});