define('impact-admin/routes/frame/dashboard', ['exports', 'ember', 'impact-admin/models/donations', 'impact-admin/models/organizations', 'impact-admin/models/good', 'impact-admin/models/good-charity-submission', 'impact-admin/models/volunteer', 'impact-admin/models/volunteer-submissions', 'impact-admin/mixins/table', 'impact-admin/models/links', 'impact-admin/utils/api', 'impact-admin/models/form-submissions'], function (exports, _ember, _impactAdminModelsDonations, _impactAdminModelsOrganizations, _impactAdminModelsGood, _impactAdminModelsGoodCharitySubmission, _impactAdminModelsVolunteer, _impactAdminModelsVolunteerSubmissions, _impactAdminMixinsTable, _impactAdminModelsLinks, _impactAdminUtilsApi, _impactAdminModelsFormSubmissions) {

    var DashboardRoute = _ember['default'].Route.extend({

        beforeModel: function beforeModel() {
            this._super();

            //Here we make sure that Ember.SocketIO is initialized BEFORE we enter any other routes.
            return new _ember['default'].RSVP.Promise(function (resolve) {

                //This starts the Pusher Connection process.
                _ember['default'].SocketIO.join('impact_giveback');

                //This is a recursive function that calls itself every 500 ms until Ember.SocketIO.io is initialized.
                var subscribe = function subscribe() {
                    if (!_ember['default'].SocketIO.io) {
                        return _ember['default'].run.later(function () {
                            subscribe();
                        }, 500);
                    }
                    //debugger;
                    resolve(true);
                };
                //Start the waiting...
                subscribe();

                //reject(error);
            });
        },

        model: function model() {
            if (this.modelFor('frame') === 'impact') {
                var organizations = _impactAdminModelsOrganizations['default'].find({
                    ghd_access: true
                }, {

                    sort: {
                        id: 'DESC'
                        //total_with_match: 'DESC'
                    },
                    limit: 10
                });

                var allOrganizations = _impactAdminModelsOrganizations['default'].find({
                    ghd_access: true
                }, {
                    sort: {
                        id: 'DESC'
                    }
                });

                return _ember['default'].RSVP.hash({
                    all_organizations: allOrganizations,
                    organizations: organizations
                });
            } else {
                //can't reach into the common config... Will this do?
                var ghd_year = new Date().getFullYear();
                var organizationId = this.controllerFor('application').get('user.organization_id');
                var donations = _impactAdminModelsDonations['default'].find({
                    organization_id: organizationId,
                    ghd_year: ghd_year
                }, {
                    limit: 10,
                    sort: {
                        id: 'desc'
                    }
                });
                var organization = _impactAdminModelsOrganizations['default'].find(organizationId);

                var calendarEvents = _impactAdminUtilsApi['default'].get('/calendar_events/upcoming', {}, function () {}, true);

                var volunteer = _impactAdminModelsVolunteer['default'].find({
                    organization_id: organizationId,
                    removed: false
                });
                var volunteerSubmissions = _impactAdminModelsVolunteerSubmissions['default'].find({
                    organization_id: organizationId
                });

                var goods = _impactAdminModelsGood['default'].find({
                    organization_id: organizationId,
                    removed: false
                });
                var goodsPledges = _impactAdminModelsGoodCharitySubmission['default'].find({
                    organization_id: organizationId
                });

                var form_submissions = _impactAdminModelsFormSubmissions['default'].find({
                    organization_id: organizationId
                });

                var links = _impactAdminModelsLinks['default'].find({
                    removed: false
                });

                return _ember['default'].RSVP.hash({
                    donations: donations,
                    organization: organization,
                    calendar_events: calendarEvents,
                    volunteer: volunteer,
                    volunteer_submissions: volunteerSubmissions,
                    goods: goods,
                    goods_pledges: goodsPledges,
                    links: links,
                    form_submissions: form_submissions
                });
            }
        },

        setupController: function setupController(controller, model) {
            _impactAdminMixinsTable['default'].apply(controller);
            if (controller.get('isImpact')) {
                controller.set('organizations', model.organizations);
                controller.set('allOrganizations', model.all_organizations);
            } else {
                if (!model.organization.full_admin_access) {
                    console.log('Only the form');
                }

                controller.set('donations', model.donations);
                controller.set('organization', model.organization);
                controller.set('calendar_events', model.calendar_events.result.upcoming_events);

                controller.set('volunteer', model.volunteer);
                controller.set('volunteer_submissions', model.volunteer_submissions);
                controller.set('goods', model.goods);
                controller.set('goods_pledges', model.goods_pledges);
                controller.set('links', model.links);
                controller.set('assigned_forms', model.form_submissions);
            }
        }
    });

    exports['default'] = DashboardRoute;
});