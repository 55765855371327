define('impact-admin/routes/frame/reports', ['exports', 'ember'], function (exports, _ember) {

    var AdminReportsRoute = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        }
    });

    exports['default'] = AdminReportsRoute;
});