define('impact-admin/components/ghd-admin-dashboard', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Component.extend({

        dedicationModal: false,

        sortProps: ['name'],
        currentSort: 'name',

        chargeAllExport: [],
        stripeFilter: false,
        chargingOrgs: false,

        membershipConfigVisible: false,

        sortedList: _ember['default'].computed.sort('list', 'sortProps'),

        filteredPeople: _ember['default'].computed.filter('sortedList', function (charity) {
            var searchFilter = this.get('nameSearch');
            var regex = new RegExp(searchFilter, 'i');
            return charity.name.match(regex);
        }).property('sortedList', 'nameSearch'),

        searchFilterCount: _ember['default'].computed('filteredPeople', function () {
            return this.get('filteredPeople').length;
        }),

        totalStripe: _ember['default'].computed('sortedList', function () {
            var count = 0;
            this.get('list').forEach(function (org) {
                if (org.have_registered_for_stripe) {
                    count += 1;
                }
            });
            return count;
        }),

        totalMembership: _ember['default'].computed('sortedList', function () {
            var count = 0;
            this.get('list').forEach(function (org) {
                if (org.membership_active) {
                    count += 1;
                }
            });
            return count;
        }),
        profilesComplete: _ember['default'].computed('sortedList', function () {
            var count = 0;
            this.get('list').forEach(function (org) {
                if (org.profile_complete) {
                    count += 1;
                }
            });
            return count;
        }),

        matchComplete: _ember['default'].computed('sortedList', function () {
            var count = 0;
            this.get('list').forEach(function (org) {
                if (org.match_is_complete) {
                    count += 1;
                }
            });
            return count;
        }),

        totalGHDCharityMatch: _ember['default'].computed('sortedList', function () {
            var count = 0;
            this.get('list').forEach(function (org) {
                if (org.raised_match) {
                    count += org.raised_match;
                }
            });
            return count;
        }),

        totalOrgs: _ember['default'].computed('sortedList', function () {
            return this.get('sortedList').length;
        }),

        stripeLeft: _ember['default'].computed('totalOrgs', 'totalStripe', function () {
            return this.get('totalOrgs') - this.get('totalStripe');
        }),

        membershipsLeft: _ember['default'].computed('totalOrgs', 'totalMembership', function () {
            return this.get('totalOrgs') - this.get('totalMembership');
        }),

        profilesLeft: _ember['default'].computed('totalOrgs', 'profileComplete', function () {
            return this.get('totalOrgs') - this.get('profileComplete');
        }),

        filterWatch: (function () {
            this.get('filteredPeople');
        }).observes('nameSearch'),

        pushWatch: (function () {
            this.set('currentSort', this.get('sortProps')[0]);
        }).observes('sortProps'),

        sortWatch: (function () {
            console.log(this.get('currentSort'));
        }).observes('currentSort'),

        stripeOrder: _ember['default'].computed('sortProps', function () {
            if (this.get('sortProps')[0] === 'have_registered_for_stripe' + ':desc') {
                return '↑';
            } else if (this.get('sortProps')[0] === 'have_registered_for_stripe') {
                return '↓';
            }
            return '↕';
        }),

        membershipOrder: _ember['default'].computed('sortProps', function () {
            if (this.get('sortProps')[0] === 'membership_active' + ':desc') {
                return '↑';
            } else if (this.get('sortProps')[0] === 'membership_active') {
                return '↓';
            }
            return '↕';
        }),

        profileOrder: _ember['default'].computed('sortProps', function () {
            if (this.get('sortProps')[0] === 'profile_percentage_complete' + ':desc') {
                return '↑';
            } else if (this.get('sortProps')[0] === 'profile_percentage_complete') {
                return '↓';
            }
            return '↕';
        }),

        matchOrder: _ember['default'].computed('sortProps', function () {
            if (this.get('sortProps')[0] === 'match_is_complete' + ':desc') {
                return '↑';
            } else if (this.get('sortProps')[0] === 'match_is_complete') {
                return '↓';
            }
            return '↕';
        }),

        alphaOrder: _ember['default'].computed('sortProps', function () {
            if (this.get('sortProps')[0] === 'name' + ':desc') {
                return '↑';
            } else if (this.get('sortProps')[0] === 'name') {
                return '↓';
            }
            return '↕';
        }),

        volunteerOrder: _ember['default'].computed('sortProps', function () {
            if (this.get('sortProps')[0] === 'volunteer_total' + ':desc') {
                return '↑';
            } else if (this.get('sortProps')[0] === 'volunteer_total') {
                return '↓';
            }
            return '↕';
        }),

        goodsOrder: _ember['default'].computed('sortProps', function () {
            if (this.get('sortProps')[0] === 'goods_total' + ':desc') {
                return '↑';
            } else if (this.get('sortProps')[0] === 'goods_total') {
                return '↓';
            }
            return '↕';
        }),

        organzation_full_report_url: (function () {
            return '/account/payall';
        }).property('model'),

        actions: {

            openNotificationModal: function openNotificationModal(charity) {
                this.set('selectedCharity', charity);
                this.set('dedicationModal', true);
            },
            alertCharity: function alertCharity(email_type, org) {
                var self = this;
                var data = {
                    email_type: email_type,
                    id: org.id
                };

                if (confirm('Are you sure you want email ' + org.name + '?')) {
                    _impactAdminUtilsApi['default'].post('/account/send-unpaid-alert/', data, function (json) {

                        self.exportArrayToFile(json.orgs);
                        return json;
                    }, true);
                }
            },
            sendEmailAll: function sendEmailAll(email_type) {
                var self = this;
                var filteredList = [];

                var alertMessage = '';

                if (email_type === 'org_missing_stripe_account') {
                    alertMessage = "Alert all charities that are missing a stripe connection.";
                    self.get('list').forEach(function (org) {

                        if (!org.have_registered_for_stripe) {
                            filteredList.push(org);
                        }
                    });
                } else if (email_type === 'org_unpaid_membership') {
                    alertMessage = "Email all charities that have not paid their membership.<br>COunt:";
                    self.get('list').forEach(function (org) {

                        if (!org.membership_active) {
                            filteredList.push(org);
                        }
                    });
                } else if (email_type === 'org_profile_incomplete') {
                    alertMessage = "Email all charities that have not finished their profile.";
                    self.get('list').forEach(function (org) {

                        if (org.profile_percentage !== 100) {
                            filteredList.push(org);
                        }
                    });
                } else if (email_type === 'org_missing_ghd_match') {
                    alertMessage = "Email all charities that have not added their match or goal.";
                    self.get('list').forEach(function (org) {

                        if (org.profile_percentage !== 100) {
                            filteredList.push(org);
                        }
                    });
                }
                if (confirm(alertMessage)) {
                    self.emailAll(email_type, filteredList);
                }
            },

            setTableSort: function setTableSort(value) {
                if (this.get('sortProps')[0] === value + ':desc') {
                    this.set('sortProps', ['name']);
                } else if (this.get('sortProps')[0] === value) {
                    this.set('sortProps', [value + ':desc', 'name']);
                } else {
                    this.set('sortProps', [value, 'name']);
                }

                this.get('sortedList');
            },

            selectCharityAlert: function selectCharityAlert(selection) {
                if (selection === 'ongoing') {
                    this.set('isOngoing', true);
                } else {
                    this.set('isOngoing', false);
                }
            },
            exportResults: function exportResults(orgs) {

                var orgToExport = [['ID', 'Name', 'Stripe Connected', 'Membership Paid', 'Profile Completed', 'Match', 'DMF', 'Goal', 'Volunteer', 'Good']];
                orgs.forEach(function (org) {
                    var orgArray = [org.id, org.name, org.have_registered_for_stripe, org.membership_active ? 'PAID' : 'UNPAID', org.profile_percentage_complete * 100 + '%', org.raised_match ? org.raised_match / 100 : 0, org.dmf_match ? org.dmf_match / 100 : 0, org.ghd_goal ? org.ghd_goal / 100 : 0, org.volunteer_total ? org.volunteer_total : 0, org.goods_total ? org.goods_total : 0];

                    orgToExport.push(orgArray);
                });
                this.exportArrayToFile(orgToExport);
            }
        },

        emailAll: function emailAll(email_type, filtered_orgs) {
            filtered_orgs.forEach(function (org) {
                var data = {
                    email_type: email_type,
                    id: org.id
                };
                _impactAdminUtilsApi['default'].post('/account/send-unpaid-alert/', data, function (json) {
                    return json;
                }, true);
            });
        },

        exportArrayToFile: function exportArrayToFile(data) {

            var csvContent = '';
            data.forEach(function (infoArray, index) {
                var dataString = '"' + infoArray.join('","') + '"';
                csvContent += index < data.length ? dataString + '\n' : dataString;
            });

            // The download function takes a CSV string, the filename and mimeType as parameters
            // Scroll/look down at the bottom of this snippet to see how download is called
            var download = function download(content, fileName, mimeType) {
                var a = document.createElement('a');
                mimeType = mimeType || 'application/octet-stream';

                if (navigator.msSaveBlob) {
                    // IE10
                    navigator.msSaveBlob(new Blob([content], {
                        type: mimeType
                    }), fileName);
                } else if (URL && 'download' in a) {
                    //html5 A[download]
                    a.href = URL.createObjectURL(new Blob([content], {
                        type: mimeType
                    }));
                    a.setAttribute('download', fileName);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                } else {
                    location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
                }
            };

            download(csvContent, 'charged-charity-report.csv', 'text/csv;encoding:utf-8');
        }

    });
});