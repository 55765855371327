define('impact-admin/components/ghd-config-card', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        editing: false,

        isInputDisabled: _ember['default'].computed('editing', function () {
            return !this.get('editing');
        }),

        actions: {
            toggle: function toggle() {
                this.sendAction('toggleActive', this.get('config'));
            },

            'delete': function _delete() {
                this.sendAction('deleteConfig', this.get('config'));
            },

            startEdit: function startEdit() {
                this.set('editing', true);
            },

            update: function update() {
                this.sendAction('updateConfig', this.get('config'));
            },

            cancelEdit: function cancelEdit() {
                this.get('config').revertRecord();
                this.set('editing', false);
            }
        }
    });
});