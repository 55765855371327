define('impact-admin/routes/frame/settings', ['exports', 'ember', 'impact-admin/models/admin-users', 'impact-admin/models/admin-users-notifications', 'impact-admin/models/email-notifications', 'impact-admin/models/organizations'], function (exports, _ember, _impactAdminModelsAdminUsers, _impactAdminModelsAdminUsersNotifications, _impactAdminModelsEmailNotifications, _impactAdminModelsOrganizations) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return _ember['default'].RSVP.hash({
                user: this.controllerFor('application').get('user'),
                admin_users: _impactAdminModelsAdminUsers['default'].find({
                    type: 'impact'
                }),
                notifications: _impactAdminModelsAdminUsersNotifications['default'].findFirst({
                    admin_user_id: this.controllerFor('application').get('user.id')
                }),
                email_notifications: _impactAdminModelsEmailNotifications['default'].find(),
                organizations: _impactAdminModelsOrganizations['default'].find()
            });
        }
    });
});