define('impact-admin/routes/frame/match-history', ['exports', 'ember', 'impact-admin/models/organizations', 'impact-admin/models/organization-match'], function (exports, _ember, _impactAdminModelsOrganizations, _impactAdminModelsOrganizationMatch) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel(transition) {
            //Check to see if this charity is requesting only its own things
            if (!this.controllerFor('application').get('isImpact')) {
                var charityIdDefined = transition.params['frame.match-history'].charity_id;
                var charityId = this.controllerFor('application').get('user.organization_id');

                if (charityIdDefined.toString() !== charityId.toString()) {
                    transition.abort();
                }
            }
        },

        model: function model(params) {
            var organization = _impactAdminModelsOrganizations['default'].find(params.charity_id);
            var organization_match = _impactAdminModelsOrganizationMatch['default'].find({
                organization_id: params.charity_id
            });

            return _ember['default'].RSVP.hash({
                organization: organization,
                organizationMatch: organization_match
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('match', model.organizationMatch.content);

            var yearsParticipated = new Set();
            model.organizationMatch.content.slice().reverse().forEach(function (om) {
                yearsParticipated.add(om.ghd_year);
            });
            controller.set('yearsParticipated', Array.from(yearsParticipated));

            var matches = [];
            model.organizationMatch.content.forEach(function (om) {
                matches.push({
                    'date': om.date_created,
                    'ghd_year': om.ghd_year,
                    'pledge_name': om.pledge_name,
                    'amount': om.amount
                });
            });

            controller.set('matches', matches);
            controller.set('model', model.organization);
        }
    });
});