define('impact-admin/controllers/frame/settings', ['exports', 'ember', 'impact-admin/models/admin-users', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsAdminUsers, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        sortEmailNotifications: ['title'],
        sortedEmailNotifications: _ember['default'].computed.sort('model.email_notifications', 'sortEmailNotifications'),
        notificationTypes: _ember['default'].computed('model.notifications.new_charity', 'model.notifications.charity_updated_profile', 'model.notifications.charity_payment_failed', 'model.notifications.charity_membership_upgraded', 'model.notifications.charity_membership_auto_renewed', function () {
            var userNotifications = this.get('model.notifications');
            var notificationArray = [{
                label: 'New Charity',
                value: 'new_charity',
                selected: userNotifications.get('new_charity')
            }, {
                label: 'Charity Updated Profile',
                value: 'charity_updated_profile',
                selected: userNotifications.get('charity_updated_profile')
            }, {
                label: 'Charity Payment Failed',
                value: 'charity_payment_failed',
                selected: userNotifications.get('charity_payment_failed')
            }, {
                label: 'Charity Membership Upgraded',
                value: 'charity_membership_upgraded',
                selected: userNotifications.get('charity_membership_upgraded')
            }, {
                label: 'Charity Membership Renewed',
                value: 'charity_membership_auto_renewed',
                selected: userNotifications.get('charity_membership_auto_renewed')
            }];
            console.log('Notifications Changed');

            return notificationArray;
        }),

        actions: {
            removeUser: function removeUser(user) {
                var _this = this;

                var length = this.get('model.admin_users.length');
                if (length > 1) {
                    if (confirm('Are you sure you want to delete this user?')) {
                        user.deleteRecord().then(function () {
                            _this.growl.success('User deleted');
                        })['catch'](function (reason) {
                            console.log(reason);
                            _this.growl.error('An error occurred deleting this user. Please try again.');
                        });
                    }
                } else {
                    this.growl.error('You cannot delete the last user for this charity.');
                }
            },
            addUser: function addUser() {
                this.set('addingUser', true);
            },
            resendReceipts: function resendReceipts(email, adminEmail, specificTransaction) {
                console.log(email);
                if (email || specificTransaction) {
                    _impactAdminUtilsApi['default'].post('/giving/adminresendreceipts', {
                        email: email,
                        admin_email: adminEmail ? adminEmail : '',
                        specific_transaction_id: specificTransaction ? specificTransaction : ''
                    }, function (json) {
                        if (json.error) {
                            alert(json.error);
                            console.log(json.error);
                        } else {
                            alert("All of your donations receipts have been re-emailed to you!");
                        }
                    });
                }
                this.set('email', null);
            },
            clearAllGHDGoals: function clearAllGHDGoals() {
                var _this2 = this;

                var resp = confirm("Clear All GHD Goals?");
                if (!resp) {
                    return;
                }
                var savedOrgs = [];
                this.get('model.organizations').forEach(function (org) {
                    if (org.ghd_goal) {
                        org.set('ghd_goal', 0);
                        savedOrgs.push(org.save());
                    }
                });
                _ember['default'].RSVP.Promise.all(savedOrgs).then(function () {
                    _this2.growl.success(savedOrgs.length + ' DMF Goals Cleared');
                });
            },
            clearAllGHDMatch: function clearAllGHDMatch() {
                var _this3 = this;

                var resp = confirm("Clear All GHD Match?");
                if (!resp) {
                    return;
                }
                var savedOrgs = [];
                this.get('model.organizations').forEach(function (org) {
                    if (org.dmf_match) {
                        org.set('dmf_match', 0);
                        savedOrgs.push(org.save());
                    }
                });
                _ember['default'].RSVP.Promise.all(savedOrgs).then(function () {
                    _this3.growl.success(savedOrgs.length + ' DMF Matches Cleared');
                });
            },
            clearAllRaisedMatch: function clearAllRaisedMatch() {
                var _this4 = this;

                var resp = confirm("Clear All Raised Match?");
                if (!resp) {
                    return;
                }
                var savedOrgs = [];
                this.get('model.organizations').forEach(function (org) {
                    if (org.raised_match) {
                        org.set('raised_match', 0);
                        savedOrgs.push(org.save());
                    }
                });
                _ember['default'].RSVP.Promise.all(savedOrgs).then(function () {
                    _this4.growl.success(savedOrgs.length + ' Raised Matches Cleared');
                });
            },
            cancelAddUser: function cancelAddUser() {
                this.setProperties({
                    userEmail: '',
                    userName: '',
                    userLastName: '',
                    addingUser: false
                });
            },
            saveUser: function saveUser() {
                var _this5 = this;

                var _getProperties = this.getProperties('userEmail', 'userName', 'userLastName');

                var userEmail = _getProperties.userEmail;
                var userName = _getProperties.userName;
                var userLastName = _getProperties.userLastName;

                var newUser = _impactAdminModelsAdminUsers['default'].createRecord({
                    email: userEmail,
                    organization_id: null,
                    username: userEmail,
                    type: 'impact',
                    first_name: userName,
                    last_name: userLastName
                });
                newUser.save().then(function () {
                    _this5.growl.success('New user has been added');
                    _this5.setProperties({
                        userEmail: '',
                        userName: '',
                        userLastName: '',
                        addingUser: false
                    });
                })['catch'](function (error) {
                    _this5.growl.error(error);
                    console.log(error);
                });
            },
            setNotification: function setNotification(notification) {
                var _this6 = this;

                var notificationType = notification.value;
                var newValue = !notification.selected;
                this.set('model.notifications.' + notificationType, newValue);
                this.get('model.notifications').save().then(function () {
                    _this6.growl.success('Preference saved');
                })['catch'](function (reason) {
                    console.log(reason);
                    _this6.growl.error('Could not save your preferences. Please try again.');
                });
            },
            saveEmailText: function saveEmailText(email_text, column) {
                var _this7 = this;

                var email_notification = this.get('model.email_notifications').findBy('type', column);
                email_notification.set('email_text', email_text);
                if (email_notification.isReallyDirty()) {
                    email_notification.save().then(function () {
                        _this7.growl.success('Email text saved.');
                    })['catch'](function (json) {
                        _this7.growl.error('An error occurred trying to save the email text. Please try again.');
                        console.log(json);
                    });
                } else {
                    this.growl.success('Email text saved.');
                }
            },
            toggleViewReceipt: function toggleViewReceipt() {
                console.log('here');
                this.set('viewingReceipt', !this.get('viewingReceipt'));
            }
        }
    });
});