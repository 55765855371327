define('impact-admin/services/labels', ['exports', 'ember', 'impact-admin/config/environment'], function (exports, _ember, _impactAdminConfigEnvironment) {

    var dict = {};

    // Keeping this simple for now until the need arises for more externalized strings

    if (_impactAdminConfigEnvironment['default'].isImpactApp) {
        dict = {
            ADMIN_NAME: 'Giving Hearts Day Moderator',
            CHARITY: 'Charity',
            CHARITIES: 'Charities',
            TLA: 'GHD',
            FUNDRAISER_NAME: 'Giving Hearts Day',
            NONPROFITS: 'Non-Profits'
        };
    }

    exports['default'] = _ember['default'].Service.extend(dict);
});