define('impact-admin/routes/frame/calendar-events', ['exports', 'ember', 'impact-admin/models/calendar-events', 'impact-admin/mixins/table', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminModelsCalendarEvents, _impactAdminMixinsTable, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            if (this.modelFor('frame') === 'impact') {
                //can't reach into the common config... Will this do?

                var calendarEvents = _impactAdminModelsCalendarEvents['default'].find({
                    removed: false
                });

                var upcomingEvents = _impactAdminUtilsApi['default'].get('/calendar_events/upcoming', {}, function () {}, true);

                return _ember['default'].RSVP.hash({
                    calendar_events: calendarEvents,
                    upcoming_events: upcomingEvents
                });
            }
        },

        setupController: function setupController(controller, model) {
            _impactAdminMixinsTable['default'].apply(controller);
            controller.set('calendar_events', model.calendar_events.content);
            controller.set('upcoming_events', model.upcoming_events.result.upcoming_events);
        }
    });
});