define('impact-admin/services/auth', ['exports', 'ember', 'auth0-js', 'impact-admin/utils/token', 'impact-admin/config/environment', 'crypto-js'], function (exports, _ember, _auth0Js, _impactAdminUtilsToken, _impactAdminConfigEnvironment, _cryptoJs) {

    /**
     * Most of this code is straight from https://auth0.com/docs/quickstart/spa/ember/01-login#add-a-callback-component
     */

    var computed = _ember['default'].computed;
    var Service = _ember['default'].Service;
    var get = _ember['default'].get;
    var RSVP = _ember['default'].RSVP;
    var isPresent = _ember['default'].isPresent;
    exports['default'] = Service.extend({

        auth0: computed(function () {
            return new _auth0Js['default'].WebAuth({
                domain: _impactAdminConfigEnvironment['default'].auth0_domain,
                redirectUri: _impactAdminConfigEnvironment['default'].adminAppURL,
                clientID: _impactAdminConfigEnvironment['default'].auth0_clientID,
                audience: _impactAdminConfigEnvironment['default'].auth0_api,
                responseType: 'token id_token',
                scope: 'openid',
                nonce: _cryptoJs['default'].lib.WordArray.random(128 / 8).toString()
            });
        }),

        login: function login() {
            get(this, 'auth0').authorize();
        },

        handleAuthentication: function handleAuthentication() {
            var _this = this;

            return new RSVP.Promise(function (resolve, reject) {
                get(_this, 'auth0').parseHash(function (err, authResult) {
                    if (authResult && authResult.accessToken && authResult.idToken) {
                        console.log(authResult);
                        _this.setSession(authResult);
                    } else if (err) {
                        return reject(err);
                    }

                    return resolve();
                });
            });
        },

        isAuthenticated: computed(function () {
            return isPresent(this.getSession().access_token) && !this.isExpired();
        }).volatile(),

        /**
         * Return the cached auth0_tokens, or rehydrate from local storage
         */
        getSession: function getSession() {
            return _impactAdminUtilsToken['default'].getAuth0Tokens();
        },

        setSession: function setSession(_ref) {
            var accessToken = _ref.accessToken;
            var idToken = _ref.idToken;
            var expiresIn = _ref.expiresIn;

            if (accessToken && idToken) {
                // Set the time that the Access Token will expire at
                var expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());

                var auth0_tokens = {
                    access_token: accessToken,
                    id_token: idToken,
                    expires_at: expiresAt
                };
                _impactAdminUtilsToken['default'].setAuth0Tokens(_.assign(auth0_tokens));
            }
        },

        logout: function logout() {
            // Clear Access Token and ID Token from local storage
            get(this, 'auth0').logout({
                returnTo: _impactAdminConfigEnvironment['default'].adminAppURL + '/#/logout'
            });
            _impactAdminUtilsToken['default'].setAuth0Tokens({});
        },

        isExpired: function isExpired() {
            // Check whether the current time is past the
            // Access Token's expiry time
            var expiresAt = this.getSession().expires_at;
            return new Date().getTime() >= expiresAt;
        }
    });
});
/* globals _ */