define('impact-admin/components/prepaid-codes-list-filter', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports['default'] = _ember['default'].Component.extend({
        /**
         * Tracking array for dirty filtering object properties
         */
        dirty: _ember['default'].A(),

        /**
         * Default filtering object
         */
        defaults: {
            endDate: "2025",
            fulfilled: "all",
            purchaseType: "all"
        },

        yearList: _ember['default'].computed(function () {
            var yearRange = _.range((0, _moment['default'])().year() + 1, 2016, -1).map(function (x) {
                return Object.assign({ value: x, label: x });
            });

            return [{ value: 'all', label: 'All' }].concat(_toConsumableArray(yearRange));
        }),

        /**
         * Sets up instance of component
         */
        didInsertElement: function didInsertElement() {
            this.reset();
        },

        /**
         * Resets the current status of the component to it's post-init state
         */
        reset: function reset() {
            this.set('dirty', _ember['default'].A());
            this.setProperties(this.defaults);
            this.sendAction('updateFilters', { endDate: "2025" });
        },

        /**
         * Overridden set, passes parameters of set() to this.updateDirty()
         * to make sure the array of dirty properties is consistently up to date.
         *
         * @param keyName
         * @param value
         *
         * @return void
         */
        set: function set(keyName, value) {
            this.updateDirty(keyName, value);
            this._super.apply(this, arguments);
        },

        /**
         * Updates the dirty array if keyName matches a property of this.default. This
         * will only happen if the value is different than the properties current value
         * and different than the default value provided in this.default
         *
         * @param keyName
         * @param value
         *
         * @return void
         */
        updateDirty: function updateDirty(keyName, value) {
            var index = this.get('dirty').indexOf(value);

            if (_.has(this.get('defaults'), keyName)) {
                if (value !== this.get(keyName)) {
                    if (index === -1) {
                        this.get('dirty').pushObject(keyName);
                    }
                } else {
                    this.get('dirty').splice(index, 1);
                }
            }
        },

        filter: function filter(key, value) {
            this.set(key, value);
            this.sendFilter();
        },

        sendFilter: _.debounce(function () {
            this.sendAction('updateFilters', this.compile());
        }, 200),

        /**
         * Creates a new object based on the keys available in defaults and whether
         * the value of those properties are different than those set in default.
         */
        compile: function compile() {

            return _.pick(this.getProperties(_.allKeys(this.defaults)), function () {
                return true;
            }) || {};
        },

        /**
         * Computed property testing if the filter object is dirty
         *
         * @return boolean
         */
        isDirty: _ember['default'].computed('dirty.[]', function () {
            return this.get('dirty').length > 0;
        }),

        actions: {
            filterEndDate: function filterEndDate(value) {
                this.sendAction('filterEndDate', { endDate: value });
                this.filter('endDate', value);
            },

            filterFulfillment: function filterFulfillment(value) {
                this.filter('fulfilled', value);
            },

            filterPurchaseType: function filterPurchaseType(value) {
                this.filter('purchaseType', value);
            },

            /**
             * Proxy action passes to this.reset()
             *
             * @return void
             */
            clearFilters: function clearFilters() {
                this.reset();
            }
        }
    });
});
/* global _ */