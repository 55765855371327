define('impact-admin/helpers/cat', ['exports', 'ember'], function (exports, _ember) {
    exports.cat = cat;

    function cat(params /*, hash*/) {
        var result = '';
        if (params) {
            params.forEach(function (param) {
                result = result + param;
            });
        }
        return result;
    }

    exports['default'] = _ember['default'].Helper.helper(cat);
});