define('impact-admin/helpers/break-lines', ['exports', 'ember'], function (exports, _ember) {
    exports.breakLines = breakLines;

    function breakLines(params /*, hash*/) {
        var text = params[0];
        text = _ember['default'].Handlebars.Utils.escapeExpression(text);
        text = text.toString();
        text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
        return text.htmlSafe();
    }

    exports['default'] = _ember['default'].Helper.helper(breakLines);
});