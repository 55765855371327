define('impact-admin/controllers/frame/payments', ['exports', 'ember', 'impact-admin/utils/api', 'moment', 'impact-admin/utils/ghd-helper'], function (exports, _ember, _impactAdminUtilsApi, _moment, _impactAdminUtilsGhdHelper) {
    exports['default'] = _ember['default'].Controller.extend({

        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        today: (0, _moment['default'])().add(1, 'days'),
        stripe: _ember['default'].inject.service(),
        hasUpgraded: false,
        nextGHDYear: _impactAdminUtilsGhdHelper['default'].calculateNextGivingHeartsYear((0, _moment['default'])()),
        noCardOnFile: false,

        //less than october and greater than febuary, remember the index is starts at 0
        paymentOnTime: new Date().getMonth() < 10 && new Date().getMonth() > 1,

        showLateFeeOverride: !(new Date().getMonth() < 10 && new Date().getMonth() > 1),

        invoice_url: _ember['default'].computed('model.invoice', 'organization_id', function () {
            return _impactAdminUtilsApi['default'].url + '/membership/invoice/' + this.get('org_id');
        }),

        selectedSection: 'subscription',

        isSubscription: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'subscription';
        }),

        isStripe: _ember['default'].computed('selectedSection', function () {
            return this.get('selectedSection') === 'stripe';
        }),

        isForeverSponsored: _ember['default'].computed('model.organization.membership_length', function () {
            return this.get('model.organization.membership_length') === 'forever';
        }),

        isFundAdmin: _ember['default'].computed('isImpact', 'model.organization.isDmfOrImpact', function () {
            return this.get('model.organization.isDmfOrImpact') && !this.get('isImpact');
        }),

        actions: {

            selectSection: function selectSection(section) {
                this.set('selectedSection', section);
            },

            disconnectStripe: function disconnectStripe() {
                var _this2 = this;

                var res = confirm('Are you sure you want to disconnect their Stripe account?');
                if (res) {
                    (function () {
                        var self = _this2;
                        _this2.set('loading', true);
                        //Stripe Customer ID:  cus_9JFqXDFdC9gUH9
                        //Stripe Subscription ID:  sub_9JFqu2d8MpCPs3
                        _impactAdminUtilsApi['default'].post('/membership/cancel', {
                            charity_id: _this2.get('model.organization.id'),
                            membership_id: _this2.get('model.organization.membership_id')
                        }, function (json) {
                            if (json.error) {
                                console.log(json);
                                self.growl.error('There was an error while processing your request. Please try again.');
                            } else {
                                self.growl.success('Membership has been successfully canceled.');
                                _impactAdminUtilsApi['default'].post('/account/disconnect-stripe', {
                                    id: self.get('model.organization.id')
                                }, function (json) {
                                    if (json.error) {
                                        console.log(json);
                                        self.growl.error('There was an error while processing your request. Please try again.');
                                    } else {
                                        self.growl.success('Stripe was disconnected.');
                                    }
                                    self.set('loading', false);
                                }, true);
                            }
                        }, true);
                    })();
                }
            },
            setPermission: function setPermission(value, component) {
                var _this3 = this;

                this.set('isSavingPerms', true);
                var permissionType = component.get('permissionType');
                var permissions = this.get('permissions');
                var statuses = this.get('statuses');

                var prms = null;
                if (permissionType === 'ghd_access' && !value && statuses.get('ghd_profile_approved')) {
                    // save the profile status too
                    statuses.set('ghd_profile_approved', false);
                    prms = statuses.save();
                }

                permissions.set(permissionType, value);
                _ember['default'].RSVP.Promise.all([permissions.save(), prms]).then(function () {
                    _this3.set('isSavingPerms', false);
                    _this3.growl.success('Permission saved');
                })['catch'](function (error) {
                    console.log(error);
                    _this3.set('isSavingPerms', false);
                    _this3.growl.error('An error occurred trying to set this permission. Please try again.');
                });
            },

            saveEmailText: function saveEmailText(value, column) {
                var _this4 = this;

                var charity = this.get('organization');
                charity.set(column, value);
                if (charity.isReallyDirty()) {
                    charity.save().then(function () {
                        _this4.growl.success('Saved');
                    })['catch'](function (error) {
                        console.log(error);
                        _this4.growl.error('Could not save the email text right now. Please try again.');
                    });
                } else {
                    this.growl.success('Saved');
                }
            },
            changeCharityStatus: function changeCharityStatus(newStatus) {
                var _this5 = this;

                var organization = this.get('organization');
                if (confirm('Are you sure you want to change the ' + this.LABELS.CHARITY.toLowerCase() + ' status?') && organization.get('status') !== newStatus) {
                    organization.set('status', newStatus);
                    organization.save().then(function () {
                        _this5.growl.success('This ' + _this5.LABELS.CHARITY.toLowerCase() + ' has been set to ' + newStatus + '.');
                        if (newStatus === "deleted") {
                            _this5.get('users').forEach(function (user) {
                                user.deleteRecord().then(function () {
                                    _this5.growl.success('User deleted');
                                })['catch'](function (reason) {
                                    console.log(reason);
                                    _this5.growl.error('An error occurred deleting this user.');
                                });
                            });
                        }
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this5.growl.error('There was an error updating this ' + _this5.LABELS.CHARITY.toLowerCase() + '. Please try again.');
                    });
                }
            },

            refund_membership: function refund_membership() {
                var self = this;
                this.set('sending_refund', true);

                var organization = this.get('model.organization');
                console.log("Start refund");
                _impactAdminUtilsApi['default'].get('/organization_memberships/' + organization.get('membership_id') + '/refund/', {}, function (json) {
                    if (json.error) {
                        var message = typeof json.error === 'string' ? json.error : 'Refund failed';
                        self.growl.error(message);
                        self.set('refund_failed', true);
                    } else {
                        self.growl.success("Reverted membership to unpaid.");
                        self.set('sending_refund', false);
                        self.set('organization.membership_payment_type', null);
                    }
                }, true);
            },
            cancelUpgrade: function cancelUpgrade() {
                this.set('membership_type', this.get('model.membership.type'));
                this.set('paymentType', null);
                this.set('premium_membership_type', null);
                this.toggleProperty('payWithNewCard');
            },
            cancelUpdate: function cancelUpdate() {
                this.set('membership_type', this.get('model.membership.type'));
                this.set('paymentType', null);
                this.set('premium_membership_type', null);
                this.toggleProperty('updateCard');
            },
            cancelMembership: function cancelMembership() {
                var _this6 = this;

                if (confirm('Are you sure you would like to cancel your paid subscription?')) {
                    (function () {
                        _this6.set('loading', true);
                        var self = _this6;
                        var data = {
                            charity_id: _this6.get('model.organization.id'),
                            membership_id: _this6.get('model.organization.membership_id')
                        };
                        _impactAdminUtilsApi['default'].post('/membership/cancel', data, function (json) {
                            if (json.error) {
                                console.log(json);
                                self.growl.error('There was an error while processing your request. Please try again.');
                            } else {
                                self.growl.success('Your paid subscription has been successfully canceled.');
                                var emberMembership = _ember['default'].Object.create(json);
                                self.setupPaymentOptions(emberMembership);
                            }
                            self.set('loading', false);
                        }, true);
                    })();
                }
            },
            toggleUpdateCard: function toggleUpdateCard() {
                this.toggleProperty('updateCard');
            },

            toggleNewCard: function toggleNewCard() {
                this.toggleProperty('payWithNewCard');
            },
            updateCard: function updateCard(card) {
                var _this7 = this;

                //Update the card on this customer
                var top = this;
                if (typeof Stripe === 'undefined') {
                    console.log('Stripe service is not available');
                    this.growl.error('Payment service is not available. Please refresh your browser and try again');
                } else {
                    this.set('loading', true);
                    var stripe = this.get('stripe');
                    stripe.card.createToken(card).then(function (response) {
                        var token = response.id;
                        var self = _this7;
                        _impactAdminUtilsApi['default'].post('/membership/add-card', {
                            stripe_token: token,
                            charity_id: _this7.get('model.organization.id')
                        }, function (json) {
                            if (json.error) {
                                console.log(json);
                                self.growl.error('There was an error while processing your request. Please try again.');
                            } else {
                                self.growl.success('Your payment information was updated.');
                                top.setupPaymentOptions(top.get('model.organization'));
                            }
                            self.set('updateCard', false);
                            self.set('loading', false);
                        }, true);
                    })['catch'](function (reason) {
                        console.log(reason);
                        _this7.growl.error('There was a problem adding your card. ' + reason.error.message, { closeIn: 10000 });
                        _this7.set('loading', false);
                    });
                }
            },
            removeCard: function removeCard() {
                var self = this;

                //Wow this is complicated to check if the user has a current 1 year subscription
                if (self.get('hasPaidSubscription') && self.get('model.membership.stripe_id') !== 'premium_membership_two_years' && !self.get('model.membership.cancel_at_period_end')) {
                    self.growl.error('Please cancel the Paid Subscription by updating the subscription to Unpaid before removing the card');
                    return;
                }

                _impactAdminUtilsApi['default'].post('/membership/remove-card', {
                    charity_id: this.get('model.organization.id')
                }, function (json) {
                    if (json.error) {
                        console.log(json);
                        self.growl.error('There was an error while processing your request. Please try again.');
                    } else {
                        self.growl.success('Your card was deleted.');
                    }
                    self.setupPaymentOptions(self.get('model.organization'));
                    self.set('updateCard', false);
                    self.set('loading', false);
                }, true);
            },
            paySubscription: function paySubscription(paymentType, card) {
                var _this8 = this;

                this.set('loading', true);

                var data = {
                    type: this.get('membership_type'),
                    organization_id: this.get('model.organization.id'),
                    organization_name: this.get('model.organization.name'),
                    payment_type: paymentType,
                    customer_id: this.get('model.organization.stripe_customer_id'),
                    stripe_token: null
                };

                if (paymentType === 'card') {
                    if (typeof Stripe === 'undefined') {
                        console.log('Stripe service is not available');
                        this.growl.error('Payment service is not available. Please refresh your browser and try again');
                    } else {
                        var stripe = this.get('stripe');
                        stripe.card.createToken(card).then(function (response) {
                            var token = response.id;
                            data.stripe_token = token;
                            _this8.chargeCharitySubscription(data);
                        })['catch'](function (reason) {
                            console.log(reason);
                            _this8.growl.error('There was a problem processing your payment. ' + reason.error.message, { closeIn: 10000 });
                            _this8.set('loading', false);
                        });
                    }
                } else if (paymentType === 'check') {
                    if (!this.get('checkNumber') || !this.get('checkAmount')) {
                        this.growl.error('Please enter a check number and a check amount');
                        this.set('loading', false);
                        return;
                    } else {
                        data.check_number = this.get('checkNumber');
                        data.check_amount = this.get('checkAmount');
                        this.chargeCharitySubscription(data);
                    }
                } else if (paymentType === 'sponsor_one_year') {
                    //Will reset every year like check and card payment types
                    data.sponsor = true;
                    data.sponsor_type = this.get('nextGHDYear');
                    this.chargeCharitySubscription(data);
                } else if (paymentType === 'sponsor_impact') {
                    data.sponsor = true;
                    data.sponsor_type = 'forever';
                    this.chargeCharitySubscription(data);
                } else if (paymentType === 'sponsor_dmf') {
                    data.sponsor = true;
                    data.sponsor_type = 'forever';
                    this.chargeCharitySubscription(data);
                } else if (paymentType === 'trial_date') {
                    var trial_end = this.get('trial_end_date').endOf('day').unix();
                    data.trial_end = trial_end;
                    this.chargeCharitySubscription(data);
                } else {
                    return;
                }
            },
            addCharityCard: function addCharityCard(paymentType, card) {
                var _this9 = this;

                this.set('loading', true);
                var orgUser = this.get('model.users.content')[0];
                var data = {
                    type: this.get('membership_type'),
                    organization_id: this.get('model.organization.id'),
                    organization_name: this.get('model.organization.name'),
                    payment_type: paymentType,
                    customer_id: this.get('model.organization.stripe_customer_id'),
                    stripe_token: null,
                    user_first_name: orgUser.first_name,
                    user_last_name: orgUser.last_name,
                    user_email: orgUser.email
                };

                if (paymentType === 'card') {
                    if (typeof Stripe === 'undefined') {
                        console.log('Stripe service is not available');
                        this.growl.error('Payment service is not available. Please refresh your browser and try again');
                    } else {
                        var stripe = this.get('stripe');
                        stripe.card.createToken(card).then(function (response) {
                            var token = response.id;
                            data.stripe_token = token;
                            _this9.addCard(data);
                        })['catch'](function (reason) {
                            console.log(reason);
                            _this9.growl.error('There was a problem processing your payment. ' + reason.error.message, { closeIn: 10000 });
                            _this9.set('loading', false);
                        });
                    }
                } else {
                    return;
                }
            },
            payWithCardOnFile: function payWithCardOnFile() {
                var data = {
                    type: this.get('membership_type'),
                    organization_id: this.get('model.organization.id'),
                    organization_name: this.get('model.organization.name'),
                    payment_type: 'card',
                    customer_id: this.get('model.organization.stripe_customer_id'),
                    stripe_token: null
                };
                this.chargeCharitySubscription(data);
            },

            downloadFile: _impactAdminUtilsApi['default'].downloadPdf
        },

        chargeCharitySubscription: function chargeCharitySubscription(data) {
            var self = this;
            data.payment_on_time = this.get('paymentOnTime');
            self.set('loading', true);
            _impactAdminUtilsApi['default'].post('/account/payment', data, function (json) {
                if (json.errors) {
                    console.log(json);
                    self.growl.error('There was an error while processing your request. Please try again.');
                } else {
                    console.log(json);
                    var newest_membership = json.membership;

                    self.set('model.organization.membership_id', newest_membership.id);
                    self.set('model.organization.membership_payment_type', newest_membership.payment_type);
                    self.set('model.organization.membership_charge_id', newest_membership.stripe_charge_id);
                    self.set('model.organization.membership_length', newest_membership.ghd_year);

                    self.set('model.organization.membership_card_last_four', newest_membership.stripe_card_last_4);
                    self.set('model.organization.membership_check_number', newest_membership.check_number);
                    self.set('model.organization.membership_check_amount', newest_membership.check_amount);
                    self.set('model.organization.membership_customer_id', newest_membership.stripe_customer_id);
                    self.set('model.organization.membership_active', newest_membership.active);

                    if (!data.check_number) {
                        self.set('cardBrand', json.currentCard.brand);
                        self.set('cardLast4', json.currentCard.last4);
                        self.set('cardExpMonth', json.currentCard.exp_month);
                        self.set('cardExpYear', json.currentCard.exp_year);
                        self.set('cardInfoAvailable', true);
                    }

                    self.growl.success('Your membership has been successfully updated.');
                    //let emberMembership = Ember.Object.create(json);
                    //self.setupPaymentOptions(emberMembership);
                    //self.set('hasUpgraded', true);
                }

                //bandaid because, print invoice won't show unless the quantity is updated,
                //and it appears that ts-data just gives us the cached membership back...
                //self.set('model.membership.quantity', json.quantity);
                self.set('loading', false);
            }, true);
        },

        addCard: function addCard(data) {
            var self = this;

            self.set('loading', true);
            _impactAdminUtilsApi['default'].post('/account/add-card', data, function (json) {
                if (json.errors) {
                    console.log(json);
                    self.growl.error('There was an error while processing your request. Please try again.');
                } else {
                    self.growl.success('Your card has been successfully updated.');
                    //let emberMembership = Ember.Object.create(json);
                    //self.setupPaymentOptions(emberMembership);
                    //self.set('hasUpgraded', true);
                }

                //bandaid because, print invoice won't show unless the quantity is updated,
                //and it appears that ts-data just gives us the cached membership back...
                //self.set('model.membership.quantity', json.quantity);
                self.set('cardBrand', json.currentCard.brand);
                self.set('cardLast4', json.currentCard.last4);
                self.set('cardExpMonth', json.currentCard.exp_month);
                self.set('cardExpYear', json.currentCard.exp_year);

                self.set('cardInfoAvailable', true);
                self.set('loading', false);
            }, true);
        },

        setupPaymentOptions: function setupPaymentOptions(org) {

            this.set('currentPayment', 'card');
            this.set('loadingCard', true);
            var _this = this;
            _impactAdminUtilsApi['default'].get('/organizations/get-customer-info', {
                customer_id: org.get('stripe_customer_id')
            }, function (json) {
                console.log(json);
                if (json.error) {

                    _this.set('cardInfoAvailable', false);
                    _this.set('noCardOnFile', true);
                } else {

                    _this.set('cardInfoAvailable', true);
                    _this.set('cardBrand', json.result.brand);
                    _this.set('cardLast4', json.result.last4);
                    _this.set('cardExpMonth', json.result.exp_month);
                    _this.set('cardExpYear', json.result.exp_year);
                    _this.set('cardZip', json.result.address_zip);
                }
                _this.set('loadingCard', false);
            }, true);
        },

        reset: function reset() {
            this.setProperties({
                loading: false,
                hasUpgraded: false,
                paymentType: null,
                checkNumber: null,
                checkAmount: null,
                couponType: null,
                trial_end_date: null,
                premium_membership_type: null
            });
            this.get('membership_type');
        }
    });
});