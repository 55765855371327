define('impact-admin/controllers/frame/donations-by-charity', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Controller.extend({
        name_filter: '',
        operatingBudgets: [{
            label: "Under five-hundred thousand",
            value: "under_five_hundred_thousand"
        }, {
            label: "Over five-hundred thousand",
            value: "over_five_hundred_thousand"
        }],

        ghdYears: [{
            label: "2023",
            value: "2023"
        }, {
            label: "2022",
            value: "2022"
        }, {
            label: "2021",
            value: "2021"
        }, {
            label: "2020",
            value: "2020"
        }, {
            label: "2019",
            value: "2019"
        }, {
            label: "2018",
            value: "2018"
        }, {
            label: "2017",
            value: "2017"
        }, {
            label: "2016",
            value: "2016"
        }, {
            label: "2015",
            value: "2015"
        }],

        // ghdSums: [],

        init: function init() {
            this.set('ghd_year_filter', this.get('ghdYears')[0].value);

            // TODO: this isn't used, but i don't know why, so i'm leaving the comment
            // api.get('/donations_by_ghd_year', {}, (json) => {
            //     this.set('ghdSums', json.result);
            // });
        },

        actions: {
            downloadFile: _impactAdminUtilsApi['default'].downloadFile,
            clearFilters: function clearFilters() {
                this.set('ghd_year_filter', this.get('ghdYears')[0].value);
                this.set('filter_types', []);
                this.set('state_filter', '');
                this.set('city_filter', '');
                this.set('name_filter', '');
                this.set('operating_budget_filter', null);
            },
            transitionToOrg: function transitionToOrg(id) {
                this.transitionToRoute('frame.donations', id);
            }
        },

        filteredOrgs: (function () {
            var _this = this;

            var content = this.get('model');

            if (content) {
                var _ret = (function () {
                    if (_this.get('city_filter')) {
                        (function () {
                            var city_filter = _this.get('city_filter');
                            content = content.filter(function (org) {
                                if (org.street_city) {
                                    return org.street_city.toUpperCase().indexOf(city_filter.toUpperCase()) > -1;
                                }
                                return false;
                            });
                        })();
                    }

                    if (_this.get('operating_budget_filter')) {
                        (function () {
                            var budget_filter = _this.get('operating_budget_filter');
                            content = content.filter(function (org) {
                                return org.operating_budget === budget_filter;
                            });
                        })();
                    }

                    if (_this.get('state_filter')) {
                        content = content.filterBy('street_state', _this.get('state_filter'));
                    }

                    if (_this.get('filter_types.length') > 0) {
                        content = _this.filter_charities_by_type(content);
                    }

                    var name_filter = _this.get('name_filter');

                    if (name_filter) {
                        content = content.filter(function (org) {
                            return org.name.toUpperCase().indexOf(name_filter.toUpperCase()) > -1;
                        });
                    }

                    _this.set('filteredCharityIds', content.getEach('id'));
                    _ember['default'].run.debounce(_this, 'getCharitiesTotals', {
                        ids: _this.get('filteredCharityIds'),
                        modelLength: _this.get('model.length')
                    }, 1000);

                    return {
                        v: content
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            }
        }).property('name_filter', 'filter_types', 'model', 'state_filter', 'city_filter', 'operating_budget_filter', 'ghd_year_filter'),

        getCharitiesTotals: function getCharitiesTotals(args) {
            var data = {};

            if (args.ids.length !== args.modelLength) {
                data.ids = args.ids;
            }

            data.ghd_year = this.get('ghd_year_filter');

            var self = this;
            _impactAdminUtilsApi['default'].post('/donation/totals', data, function (json) {
                if (json.result) {
                    self.setProperties({
                        total_with_match: json.result.total_with_match,
                        total_without_match: json.result.total_donations,
                        total_fees_paid: json.result.total_fees_paid
                    });
                } else if (json.error) {
                    self.setProperties({
                        total_with_match: 0,
                        total_without_match: 0,
                        total_fees_paid: 0
                    });
                    self.growl.error('Could not get donation totals at this time.');
                }
            }, false);
        },

        filteredSums: (function () {
            var total = 0;
            this.get('filteredOrgs').forEach(function (content) {
                if (!isNaN(content.total)) {
                    total += content.total;
                }
            });
            return total;
        }).property('filteredOrgs'),

        filteredSumsWithMatch: (function () {
            var total = 0;
            this.get('filteredOrgs').forEach(function (content) {
                var withMatch = content.get('donations_total_with_match');
                if (!isNaN(withMatch)) {
                    total += withMatch;
                }
            });
            return total;
        }).property('filteredOrgs'),

        feesPaid: (function () {
            var total = 0;
            this.get('filteredOrgs').forEach(function (content) {
                var withMatch = content.get('total_charges_paid');
                if (!isNaN(withMatch)) {
                    total += withMatch;
                }
            });
            return total;
        }).property('filteredOrgs'),

        organzation_full_report_url: (function () {
            return '/donations_by_orgs/csv?year=' + this.get('ghd_year_filter');
        }).property('model', 'ghd_year_filter'),

        filter_charities_by_type: function filter_charities_by_type(content) {
            var filter = this.get('filter_types').map(function (type) {
                return type.id;
            });

            if (content) {
                content = content.filter(function (item) {
                    if (item.get('types').length > 0) {
                        var filtArr = _.intersection(item.get('types').getEach('id'), filter);
                        if (filtArr.length > 0) {
                            return filtArr.length === filter.length;
                        }
                        return false;
                    }
                    return false;
                });
            }
            return content;
        }
    });
});
/* global _ */