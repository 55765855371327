define('impact-admin/components/form-row', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        change: function change() {
            var updatedForm = this.get('form');
            if (updatedForm.isReallyDirty()) {
                updatedForm.save();
            }
        },

        actions: {
            duplicateForm: function duplicateForm(form) {
                this.sendAction('duplicate', form);
            },

            deleteForm: function deleteForm(form) {
                this.sendAction('delete', form);
            },

            editElement: function editElement(form) {
                this.sendAction('edit', form);
            }
        }

    });
});