define('impact-admin/models/events', ['exports', 'ember'], function (exports, _ember) {

  /*
   *Donations Model
   */
  var Events = _ember['default'].Model.extend({});

  Events.table = 'events';

  exports['default'] = Events;
});