define('impact-admin/controllers/frame/donation-receipts', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        viewingReceipt: false,

        actions: {
            saveEmailText: function saveEmailText(value, column) {
                var _this = this;

                var charity = this.get('organization');
                charity.set(column, value);
                if (charity.isReallyDirty()) {
                    charity.save().then(function () {
                        _this.growl.success('Saved');
                    })['catch'](function (error) {
                        console.log(error);
                        _this.growl.error('Could not save the email text right now. Please try again.');
                    });
                } else {
                    this.growl.success('Saved');
                }
            },
            toggleViewReceipt: function toggleViewReceipt() {
                this.set('viewingReceipt', !this.get('viewingReceipt'));
            }
        }
    });
});