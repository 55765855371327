define('impact-admin/routes/frame/prepaid-codes/prepaid-code', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            var prepaidCodeId = params.prepaid_code_id;

            var prepaidCode = new Promise(function (resolve) {
                _impactAdminUtilsApi['default'].get('/prepaid_codes/' + prepaidCodeId, {}, function (json) {
                    if (json.error) {
                        console.error('Failed to retrieve prepaid code. (' + json.error + ')');
                        return resolve({});
                    }

                    resolve(json.data);
                }, true);
            });

            return _ember['default'].RSVP.hash({
                prepaidCode: prepaidCode
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model.prepaidCode);
        }
    });
});