define('impact-admin/controllers/frame/reports/scheduled-donations', ['exports', 'ember', 'impact-admin/models/scheduled-donations', 'impact-admin/models/organizations', 'moment'], function (exports, _ember, _impactAdminModelsScheduledDonations, _impactAdminModelsOrganizations, _moment) {
    exports['default'] = _ember['default'].Controller.extend({

        selected_year: '2021',

        noActiveTransactions: _ember['default'].computed('transactions', function () {
            return this.get('transactions.content').length === 0;
        }),

        activeDonations: _ember['default'].computed('transactions', 'donations', function () {
            var activeTransactions = this.get('transactions').map(function (t) {
                return t.id;
            });
            return this.get('donations').filter(function (d) {
                return activeTransactions.indexOf(d.get('scheduled_transactions_id')) > -1;
            });
        }),

        filteredDonations: _ember['default'].computed('activeDonations', 'selected_year', function () {
            var _this = this;

            return this.get('activeDonations').filter(function (d) {
                return (0, _moment['default'])(d.date_created, 'YYYY-MM-DD HH:mm:ss').isSame((0, _moment['default'])(_this.get('selected_year'), 'YYYY'), 'year') || null;
            });
        }),

        actions: {
            clearFilters: function clearFilters() {
                this.set('charity_name_filter', '');
                this.set('first_name_filter', '');
                this.set('last_name_filter', '');
            },
            searchDonations: function searchDonations() {
                var _this2 = this;

                this.set('searching', true);
                var charity_name = this.get('charity_name_filter');
                var first_name = this.get('first_name_filter');
                var last_name = this.get('last_name_filter');
                var orgids = [];

                var DonationsFind = function DonationsFind(donationsFilter) {
                    _impactAdminModelsScheduledDonations['default'].find(donationsFilter).then(function (json) {
                        if (json.content.length > 0) {
                            // don't know why, but I can't query on organization_id, so I have to do it manually when the result comes back...
                            if (charity_name) {
                                json = json.filter(function (donation) {
                                    return orgids.indexOf(donation.organization_id) !== -1;
                                });
                                if (json.length === 0) {
                                    _this2.set('nothingFound', true);
                                }
                            } else {
                                _this2.set('nothingFound', false);
                            }

                            _this2.set('model', json);
                        } else {
                            _this2.set('model', null);
                            _this2.set('nothingFound', true);
                        }
                        _this2.set('searching', false);
                    });
                };

                var donationsFilter = {};

                if (first_name) {
                    donationsFilter.first_name = {
                        ilike: first_name + '%'
                    };
                }

                if (last_name) {
                    donationsFilter.last_name = {
                        ilike: last_name + '%'
                    };
                }

                if (charity_name) {
                    var organizationFilter = {};
                    organizationFilter.name = {
                        ilike: charity_name + '%'
                    };

                    _impactAdminModelsOrganizations['default'].find(organizationFilter).then(function (json) {
                        for (var i = 0; i < json.content.length; i++) {
                            var id = parseInt(json.content[i].id);
                            if (orgids.indexOf(id) === -1) {
                                orgids.push(id);
                            }
                        }

                        new DonationsFind(donationsFilter);
                    });
                } else {
                    new DonationsFind(donationsFilter);
                }
            }
        }
    });
});