define('impact-admin/controllers/frame/donors/index', ['exports', 'ember', 'impact-admin/models/users', 'impact-admin/models/transactions'], function (exports, _ember, _impactAdminModelsUsers, _impactAdminModelsTransactions) {
    exports['default'] = _ember['default'].Controller.extend({
        hasSearched: false,

        actions: {
            searchDonors: function searchDonors() {
                var _this = this;

                this.set('searching', true);
                var first_name = this.get('first_name_filter');
                var last_name = this.get('last_name_filter');
                var email = this.get('email_filter');
                var lastfour = this.get('lastfour_filter');
                var business = this.get('business_filter');

                var DonorsFind = function DonorsFind(donorsFilter) {
                    _impactAdminModelsUsers['default'].find(donorsFilter).then(function (json) {
                        if (json.content.length > 0) {
                            _this.set('model', json);
                            _this.set('nothingFound', false);
                        } else {
                            _this.set('model', null);
                            _this.set('nothingFound', true);
                        }
                        _this.set('searching', false);
                    });
                };

                var donorsFilter = {};

                if (first_name) {
                    donorsFilter.first_name = {
                        ilike: first_name + '%'
                    };
                }

                if (last_name) {
                    donorsFilter.last_name = {
                        ilike: last_name + '%'
                    };
                }

                if (email) {
                    donorsFilter.email = {
                        ilike: email + '%'
                    };
                }

                if (business) {
                    donorsFilter.business = {
                        ilike: business + '%'
                    };
                }

                // if we are doing a last four search, we have to join the transactions db and the users
                if (lastfour) {
                    var transactionFilter = {};
                    transactionFilter.stripe_card_last_4 = {
                        '=': lastfour
                    };

                    _impactAdminModelsTransactions['default'].find(transactionFilter).then(function (json) {
                        // for these last four results, get the unique user_ids
                        var userids = [];
                        for (var i = 0; i < json.content.length; i++) {
                            if (userids.indexOf(json.content[i].user_id) === -1) {
                                userids.push(json.content[i].user_id);
                            }
                        }
                        //add those user_ids to the search as an AND
                        donorsFilter.id = {
                            '=': userids
                        };
                        new DonorsFind(donorsFilter);
                    });
                } else {
                    // Not doing a last four search? okay, go for we, since we don't depend on any other table then.
                    new DonorsFind(donorsFilter);
                }
            },

            goToDonor: function goToDonor(id) {
                this.transitionToRoute('frame.donors.donor', id);
            }
        }
    });
});