define('impact-admin/controllers/frame/reports/org-info', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        nameQuery: null,
        filteredOrgs: (function () {
            var selectedTypes = this.get('selectedTypes');
            var nameQuery = this.get('nameQuery');
            var selectedOrgs = this.get('model.organizations');
            if (selectedOrgs.get('length') > 0 && !_ember['default'].isEmpty(nameQuery)) {
                selectedOrgs = selectedOrgs.filter(function (org) {
                    return org.name.toLowerCase().indexOf(nameQuery.toLowerCase()) >= 0;
                });
            }
            if (selectedOrgs.get('length') > 0 && selectedTypes.length > 0) {
                selectedOrgs = selectedOrgs.filter(function (org) {
                    var found = false;
                    selectedTypes.forEach(function (type) {
                        if (!found && org.get('types').isAny('id', type.id)) {
                            found = true;
                        }
                    });
                    return found;
                });
            }
            return selectedOrgs;
        }).property('selectedTypes', 'nameQuery'),

        actions: {
            clear: function clear() {
                this.set('nameQuery', null);
                this.set('selectedTypes', _ember['default'].A([]));
            }
        }
    });
});