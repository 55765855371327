define('impact-admin/routes/frame/matching-codes/edit', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/models/code-groups', 'impact-admin/models/organizations', 'impact-admin/config/environment', 'impact-admin/mixins/table', 'impact-admin/models/types', 'moment', 'impact-admin/models/code-matching-rules-organizations', 'impact-admin/models/code-matching-rules-types'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminModelsCodeGroups, _impactAdminModelsOrganizations, _impactAdminConfigEnvironment, _impactAdminMixinsTable, _impactAdminModelsTypes, _moment, _impactAdminModelsCodeMatchingRulesOrganizations, _impactAdminModelsCodeMatchingRulesTypes) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            var code_group_id = params.matching_code_group_id;
            return _ember['default'].RSVP.hash({
                organizations: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/organizations/' + code_group_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve organizations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                donations: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/donations/' + code_group_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve donations. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                codes: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/codes/' + code_group_id, {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve codes. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                code_group: _impactAdminModelsCodeGroups['default'].find(code_group_id),
                balance: new _ember['default'].RSVP.Promise(function (resolve) {
                    _impactAdminUtilsApi['default'].get('/match/' + code_group_id + '/balance', {}, function (json) {
                        if (json.error) {
                            console.error('Failed to retrieve codes. (' + json.error + ')');
                            resolve([]);
                        } else {
                            resolve(json.data);
                        }
                    }, true);
                }),
                charities: _impactAdminModelsOrganizations['default'].find({ status: 'active' }),
                allCharities: _impactAdminModelsOrganizations['default'].find(),

                validOrganizations: _impactAdminModelsCodeMatchingRulesOrganizations['default'].find({ code_group_id: code_group_id }),
                validTypes: _impactAdminModelsCodeMatchingRulesTypes['default'].find({ code_group_id: code_group_id }),

                charity_types: _impactAdminModelsTypes['default'].find()
            });
        },
        setupController: function setupController(controller, model) {

            controller.set('model', model);

            controller.set('start_date', (0, _moment['default'])(model.code_group.start_date, 'YYYY-MM-DD HH:mm:ss'));
            controller.set('end_date', (0, _moment['default'])(model.code_group.end_date, 'YYYY-MM-DD HH:mm:ss'));
            var match_types = [{ value: 'none', name: 'None' }, { value: 'dollarForDollar', name: 'Dollar for dollar' }];
            if (model.code_group.match_type === 'dollarForDollar') {
                controller.set('match_type', match_types[1]);
            } else {
                controller.set('match_type', match_types[0]);
            }

            var validOrgs = model.validOrganizations;

            var charities = model.allCharities;

            var filteredCharities = [];
            validOrgs.forEach(function (org) {
                filteredCharities.push(charities.find(function (it) {
                    return it.id === org.organization_id;
                }));
            });

            controller.set('oldOrgs', filteredCharities);

            controller.set('model.oldOrgs', filteredCharities);

            var types = model.charity_types;
            var validTypes = model.validTypes;
            var filteredTypes = [];
            validTypes.forEach(function (type) {
                filteredTypes.push(types.find(function (category) {
                    return category.id === type.type_id;
                }));
            });

            controller.set('oldTypes', filteredTypes);
            controller.set('model.oldTypes', filteredTypes);

            controller.set('publicURL', _impactAdminConfigEnvironment['default'].publicAppURL);
            controller.set('donations_link', _impactAdminConfigEnvironment['default'].adminAppURL + '/#/export/' + model.code_group.export_id);

            console.log(model);
            _impactAdminMixinsTable['default'].apply(controller);
        }
    });
});