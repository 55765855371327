define('impact-admin/controllers/frame/campaigns/new', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        'frame.campaigns': _ember['default'].inject.controller(),

        actions: {
            saveCampaign: function saveCampaign() {
                var _this = this;

                var campaign = this.get('model');

                if (!campaign.get('start_date') || !campaign.get('end_date')) {
                    this.growl.error('Please enter a start and end date.');
                } else {
                    if (campaign.get('start_date')._isAMomentObject) {
                        campaign.set('start_date', campaign.get('start_date').format('YYYY-MM-DD'));
                    }

                    if (campaign.get('end_date')._isAMomentObject) {
                        campaign.set('end_date', campaign.get('end_date').format('YYYY-MM-DD'));
                    }

                    campaign.setProperties({
                        start_date_epoch: (0, _moment['default'])(campaign.get('start_date'), 'YYYY-MM-DD').unix(),
                        end_date_epoch: (0, _moment['default'])(campaign.get('end_date'), 'YYYY-MM-DD').unix()
                    });

                    if (this.get('charity_id')) {
                        campaign.set('organization_id', this.get('charity_id'));

                        campaign.save().then(function (json) {
                            _this.growl.success('Campaign was saved.');
                            _this.transitionToRoute('frame.campaigns.campaign', json.record.get('id'));
                        })['catch'](function (json) {
                            _this.growl.error('Campaign could not be saved at this time. Please try again.');
                            console.log(json);
                        });
                    } else {
                        this.growl.error('Could not create a campaign at this time. Please refresh your browser and try again.');
                    }
                }
            }
        }
    });
});