define("impact-admin/helpers/timestamp-to-date", ["exports", "ember"], function (exports, _ember) {

    //Converts from timestamp to date.
    var helper = _ember["default"].Helper.helper(function (params) {
        var originalDate = new Date(params[0]);
        var year = originalDate.getFullYear();
        var month = String(originalDate.getMonth() + 1).padStart(2, "0");
        var day = String(originalDate.getDate()).padStart(2, "0");
        var formattedDate = year + "-" + month + "-" + day;
        return formattedDate.htmlSafe();
    });

    exports["default"] = helper;
});