define('impact-admin/controllers/frame/donors/donor', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        sortProperties: ['date'],
        sortAscending: false,
        alerts: [],
        edit: false,
        actions: {
            edit: function edit() {
                this.set('edit', true);
            },
            cancel: function cancel() {
                this.get('model').revertRecord();
                this.set('edit', false);
            },
            updateAccount: function updateAccount() {
                var self = this;
                this.set('loading', true);
                this.set('alerts', []);
                if (!this.get('model.first_name')) {
                    this.get('alerts').pushObject('First name is required.');
                }
                if (!this.get('model.last_name')) {
                    this.get('alerts').pushObject('Last name is required.');
                }
                if (!this.get('model.email')) {
                    this.get('alerts').pushObject('Email is required.');
                }

                if (this.get('alerts').length === 0) {
                    var userRecord = this.get('model');
                    if (userRecord.isReallyDirty()) {
                        userRecord.save().then(function (result) {
                            if (!result.response.error) {
                                self.set('user', result.record);
                                self.growl.success('Account Updated');
                            } else {
                                self.get('alerts').pushObject('There was a problem updating your account information. Please try again.');
                            }
                            self.set('loading', false);
                            self.set('edit', false);
                        });
                    } else {
                        this.growl.success('Account Updated');
                        this.set('edit', false);
                        this.set('loading', false);
                    }
                } else {
                    this.set('loading', false);
                }
            }
        },

        isFacebookUser: _ember['default'].computed('model.facebook_id', function () {
            return this.get('model.facebook_id');
        }),

        isGoogleUser: _ember['default'].computed('model.google_id', function () {
            return this.get('model.google_id');
        }),

        reset: function reset() {
            this.set('edit', false);
            this.set('viewingReceipt', false);
            this.set('receipt', null);
            this.set('alerts', []);
        }
    });
});