define('impact-admin/routes/frame/account/index', ['exports', 'ember', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('org_id', model.organization.id);
            controller.set('organization', model.organization);
            controller.set('users', model.users);
            controller.set('permissions', model.permissions);
            controller.set('membership', model.membership);
            controller.set('statuses', model.statuses);

            if (model.admin_user_notifications) {
                controller.set('admin_user_notifications', model.admin_user_notifications);
            }

            if (!controller.get('application.isImpact')) {
                controller.set('user', model.users.findBy('id', controller.get('application.user.id')));
            }

            if (controller.get('organization.have_registered_for_stripe')) {
                var dmfOrImpact = _.some(model.organization.get('types'), function (type) {
                    return type.name === 'DMF Funds' || type.name === 'Impact Funds';
                });

                if (!dmfOrImpact) {
                    controller.set('loading_stripe', true);
                    _impactAdminUtilsApi['default'].get('/organizations/' + controller.get('org_id') + '/stripe_info', {
                        id: controller.get('org_id')
                    }, function (json) {
                        controller.set('can_see_stripe', true);
                        controller.set('stripe_balance', json.result.balance);
                        controller.set('stripe_account', json.result.account);
                        controller.set('loading_stripe', false);
                    }, true);
                }
            }
        }
    });
});
/* globals _ */