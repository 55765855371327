define('impact-admin/routes/frame/scheduled-admin', ['exports', 'ember', 'impact-admin/mixins/table', 'impact-admin/models/organizations', 'impact-admin/models/organization-campaigns', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminMixinsTable, _impactAdminModelsOrganizations, _impactAdminModelsOrganizationCampaigns, _impactAdminUtilsApi) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {

            var charity = _impactAdminModelsOrganizations['default'].find();

            var campaigns = _impactAdminModelsOrganizationCampaigns['default'].find();

            var scheduled_donations = _impactAdminUtilsApi['default'].get('/scheduled_donations/search', {
                ghd_year: '2023',
                status: 'scheduled',
                limit: 50,
                offset: 0
            }, function () {}, true);

            return _ember['default'].RSVP.hash({
                charity: charity,
                campaigns: campaigns,
                scheduled_donations: scheduled_donations
            });
        },

        setupController: function setupController(controller, model) {

            var maxPageSize = 50;
            controller.set('limit', maxPageSize);

            if (model.scheduled_donations.result && model.scheduled_donations.result.length > 0) {
                var num_donations = model.scheduled_donations.result[0].full_count;
                controller.set('donations_count', num_donations);
            } else {
                controller.set('donations_count', 0);
            }

            controller.set('lowerLimit', 0);
            if (model.scheduled_donations.result && model.scheduled_donations.result.length < maxPageSize) {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', model.scheduled_donations.result.length);
            } else {
                controller.set('lowerLimit', 1);
                controller.set('upperLimit', maxPageSize);
            }

            console.log('Hehe');
            console.log(model.scheduled_donations);
            controller.set('charity', model.charity);
            controller.set('campaigns', model.campaigns);
            controller.set('scheduled_donations', model.scheduled_donations.result);
            _impactAdminMixinsTable['default'].apply(controller);
        }
    });
});