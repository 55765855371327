define("impact-admin/controllers/frame/processed-donations", ["exports", "ember", "impact-admin/models/donations", "moment", "impact-admin/utils/api"], function (exports, _ember, _impactAdminModelsDonations, _moment, _impactAdminUtilsApi) {
    var observer = _ember["default"].observer;
    exports["default"] = _ember["default"].Controller.extend({
        application: _ember["default"].inject.controller(),
        isImpact: _ember["default"].computed.alias('application.isImpact'),
        manageGhd: _ember["default"].inject.service('manage-ghd'),
        isGHD: (function () {
            return this.get('manageGhd.isGHD');
        }).property('application'),
        showGHDDonations: (function () {
            return this.get('manageGhd.showGHDDonations');
        }).property('application'),
        currentGHDYear: (function () {
            return this.get('manageGhd.getCurrentGHDYear');
        }).property('application'),

        searchTimeout: null,
        stripe_syncing: false,
        donationsList: null,
        lowerLimit: 1,
        upperLimit: 0,

        //search field properties:
        stripe_trans: null,
        first_name: null,
        last_name: null,
        email_address: null,
        city: null,
        state: null,
        last_four: null,
        selectedState: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        ghd_year: '2025',
        campaign_id: null,
        phone: null,
        exportUlr: null,
        filter: null,

        urlAndFilterObserver: observer('phone', 'campaign_id', 'selectedState', 'ghd_year', 'email_address', 'end_date', 'start_date', 'city', 'last_name', 'first_name', 'stripe_card_last_4', 'stripe_trans', 'charity.id', function () {
            this.setFilter();
            this.setExportUrl();
        }),

        setExportUrl: function setExportUrl() {
            var url = '/donations/csv/'; //?org_id=' + this.get('charity.id');
            var filter = this.get('filter');
            url += '?filter=' + encodeURI(JSON.stringify(filter));
            this.set('exportUrl', url);
        },

        actions: {
            stripe_sync: function stripe_sync(organization) {
                this.set('stripe_syncing', true);

                _impactAdminUtilsApi["default"].get('/organizations/' + organization.get('id') + '/stripe_sync', {}, function () {}, true);

                setTimeout(function () {
                    document.location.reload(true);
                }, 120000);
            },

            clearNameFilter: function clearNameFilter() {
                this.set('name_filter', '');
            },

            clearFilters: function clearFilters() {
                this.setProperties({
                    stripe_trans: null,
                    first_name: null,
                    last_name: null,
                    email_address: null,
                    city: null,
                    state: null,
                    last_four: null,
                    phone_number: null,
                    selectedState: null,
                    start_date: null,
                    end_date: null,
                    start_time: null,
                    end_time: null,
                    campaign_id: null,
                    stripe_card_last_4: null
                });
                this.send('search');
            },

            year_change: function year_change(year) {
                this.set('ghd_year', year.target.value);
            },

            search: function search() {
                if (this.get('filter')['campaign_id'] < 0) {
                    this.get('filter')['campaign_id'] = null;
                }
                var self = this;
                this.set('lowerLimit', 1);

                //This native function should replace this JSON workaround. structuredClone(this.get('filter')
                var filterDeepCopy = JSON.parse(JSON.stringify(this.get('filter')));

                try {
                    _impactAdminModelsDonations["default"].find(filterDeepCopy, {
                        limit: 50
                    }).then(function (results) {
                        self.set('upperLimit', results.get('content.length') || 0);
                        console.log(results.get('content'));
                        self.set('model', results.get('content'));
                        self.set('donations_count', results.get('content').get('firstObject.full_count') || 0);
                    });
                } catch (ex) {
                    console.log('Filter Issue');
                    console.log(ex);
                }
            },

            downloadFile: _impactAdminUtilsApi["default"].downloadFile
        },

        setFilter: function setFilter() {
            var charity_id = this.get('charity.id');

            var filter_obj = {};

            if (charity_id) {
                filter_obj['organization_id'] = charity_id;
            }

            if (this.get('stripe_trans')) {
                filter_obj['stripe_charge_id'] = this.get('stripe_trans');
            }

            if (this.get('stripe_card_last_4')) {
                filter_obj['stripe_card_last_4'] = this.get('stripe_card_last_4');
            }

            if (this.get('first_name')) {
                filter_obj['first_name'] = {
                    "ilike": this.get('first_name') + '%'
                };
                filter_obj['anonymous'] = false;
            }

            if (this.get('last_name')) {
                filter_obj['last_name'] = {
                    'ilike': this.get('last_name') + '%'
                };
                filter_obj['anonymous'] = false;
            }

            if (this.get('city')) {
                filter_obj['billing_city'] = {
                    'ilike': this.get('city') + '%',
                    '!=': null
                };
            }

            if (this.get('start_date')) {
                filter_obj['start_date'] = {
                    '>=': this.get('start_date').startOf('day').unix()
                };
            }

            if (this.get('end_date')) {
                filter_obj['end_date'] = {
                    '<=': this.get('end_date').endOf('day').unix()
                };
            }

            if (this.get('email_address')) {
                filter_obj['email'] = {
                    'ilike': this.get('email_address') + '%'
                };
            }

            if (this.get('ghd_year')) {
                filter_obj['ghd_year'] = this.get('ghd_year');
            }

            if (this.get('selectedState')) {
                filter_obj['billing_state'] = this.get('selectedState');
            }

            if (this.get('phone')) {
                filter_obj['phone'] = this.get('phone');
            }

            if (this.get('campaign_id')) {
                filter_obj['campaign_id'] = this.get('campaign_id');
            }

            this.set('filter', filter_obj);
        },

        filter_years: _ember["default"].computed('', function () {
            var m = (0, _moment["default"])();
            var years = [];
            for (var n = m.year(); n >= 2015; n--) {
                years.push(n);
            }
            return years;
        })

    });
});