define('impact-admin/models/ghd-configs', ['exports', 'ember'], function (exports, _ember) {

  /*
   *Donations Model
   */
  var GhdConfigs = _ember['default'].Model.extend({});

  GhdConfigs.table = 'ghd_configs';

  exports['default'] = GhdConfigs;
});