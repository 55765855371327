define('impact-admin/initializers/growl', ['exports', 'impact-admin/services/growl-notifications'], function (exports, _impactAdminServicesGrowlNotifications) {
    exports['default'] = {
        name: 'growl',
        initialize: function initialize(app) {
            app.register('growl:main', _impactAdminServicesGrowlNotifications['default']);
            app.inject('route', 'growl', 'growl:main');
            app.inject('controller', 'growl', 'growl:main');
            app.inject('component', 'growl', 'growl:main');
        }
    };
});