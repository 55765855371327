define('impact-admin/routes/frame/donors/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            return;
        }
    });
});