define('impact-admin/routes/frame/matching-codes/index', ['exports', 'ember', 'impact-admin/models/code-groups'], function (exports, _ember, _impactAdminModelsCodeGroups) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },
        model: function model() {
            return _ember['default'].RSVP.hash({
                codeGroups: _impactAdminModelsCodeGroups['default'].find()
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set('codeGroups', model.codeGroups);
        }
    });
});