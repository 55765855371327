define('impact-admin/controllers/frame/profile/edit', ['exports', 'ember', 'impact-admin/utils/multi-select-updater', 'impact-admin/models/organizations-to-types', 'impact-admin/models/organizations-to-locations', 'impact-admin/models/good-charity-categories', 'numeral'], function (exports, _ember, _impactAdminUtilsMultiSelectUpdater, _impactAdminModelsOrganizationsToTypes, _impactAdminModelsOrganizationsToLocations, _impactAdminModelsGoodCharityCategories, _numeral) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),

        has_giving_hearts_day_access: _ember['default'].computed.alias('model.permissions.ghd_access'),
        pdfDocuments: _ember['default'].computed.alias('model.pdfDocuments'),
        operatingBudgets: [{
            label: "Under five-hundred thousand",
            value: "under_five_hundred_thousand"
        }, {
            label: "Over five-hundred thousand",
            value: "over_five_hundred_thousand"
        }],

        checkedLocations: _ember['default'].computed.filterBy('locations', 'isSelected'),

        videoUrl: (function () {
            var video = this.get('model.video');
            var prefix = "//www.youtube.com/embed/";
            if (video) {
                var youtubeid = undefined;
                if (video.match(/youtube/) && !video.match(/^embed/)) {
                    if (video.indexOf("?v=") > 0) {
                        if (video.indexOf("&", video.indexOf("?v=") + 3) > 0) {
                            youtubeid = video.substring(video.indexOf("?v=") + 3, video.indexOf("&", video.indexOf("?v=") + 3));
                        } else {
                            youtubeid = video.substring(video.indexOf("?v=") + 3);
                        }
                        return prefix + youtubeid;
                    }
                } else if (video.match(/youtu.be/)) {
                    youtubeid = video.substring(video.indexOf(".be") + 4);
                    return prefix + youtubeid;
                } else if (video.match(/vimeo/)) {
                    prefix = "//player.vimeo.com/video/";
                    var vimeoid = video.substring(video.indexOf(".com/") + 5);
                    return prefix + vimeoid;
                }
                return video;
            }
            return "";
        }).property('model.video'),

        actions: {

            saveProfile: function saveProfile() {
                var _this = this;

                if (this.validate()) {
                    (function () {
                        var self = _this;
                        var org = _this.get('model');

                        var new_types = _this.get('selectedTypes').mapBy('id');
                        var old_types = _.pluck(_this.get('model._clean.types'), 'id'); //_.clone(this.get('orgTypes.content'));

                        var new_goods = _this.get('selectedGoods').mapBy('id');
                        var old_goods = _.pluck(_this.get('model._clean.good_charity_categories'), 'id');

                        // []; //

                        var new_locations = _.pluck(_this.get('checkedLocations'), 'id');
                        var old_locations = _.pluck(_this.get('model._clean.locations'), 'id'); //_.clone(this.get('orgTypes.content'));

                        org.set('goal_amount', Math.round((0, _numeral['default'])().unformat(org.get('goal_amount_dollars')) * 100));
                        org.set('ghd_goal', Math.round((0, _numeral['default'])().unformat(org.get('ghd_goal_dollars')) * 100));
                        org.set('raised_match', Math.round((0, _numeral['default'])().unformat(org.get('raised_match_dollars')) * 100));
                        org.set('dmf_match', Math.round((0, _numeral['default'])().unformat(org.get('dmf_match_dollars')) * 100));
                        org.set('board_members', org.get('board_members') === null ? JSON.stringify([]) : Array.isArray(org.get('board_members')) ? JSON.stringify(org.get('board_members')) : org.get('board_members'));

                        if (org.isReallyDirty()) {
                            var newRecord = org.save(); //this._super(org);

                            newRecord.then(function (json) {
                                if (json.response.error) {
                                    _this.growl.error('Your profile changes were not saved. Please try again.');
                                    console.log(json.response.error);
                                } else {
                                    _this.growl.success('Changes saved.');

                                    (0, _impactAdminUtilsMultiSelectUpdater['default'])(json.record, new_types, old_types, _impactAdminModelsOrganizationsToTypes['default'], 'type_id', 'organization_id', function () {
                                        org._clean.types = self.get('model.typeObjects.content');
                                    });

                                    (0, _impactAdminUtilsMultiSelectUpdater['default'])(json.record, new_goods, old_goods, _impactAdminModelsGoodCharityCategories['default'], 'good_category_id', 'organization_id', function () {
                                        org._clean.goodTypes = self.get('model.goodObjects.content');
                                    });

                                    (0, _impactAdminUtilsMultiSelectUpdater['default'])(json.record, new_locations, old_locations, _impactAdminModelsOrganizationsToLocations['default'], 'location_id', 'organization_id');
                                    _this.transitionToRoute('frame.profile', org.get('id'));
                                }
                            });
                        } else {
                            if (org.id) {
                                (0, _impactAdminUtilsMultiSelectUpdater['default'])(org, new_types, old_types, _impactAdminModelsOrganizationsToTypes['default'], 'type_id', 'organization_id', function () {
                                    org._clean.types = self.get('model.typeObjects.content');
                                });

                                (0, _impactAdminUtilsMultiSelectUpdater['default'])(org, new_goods, old_goods, _impactAdminModelsGoodCharityCategories['default'], 'good_category_id', 'organization_id', function () {
                                    org._clean.goodTypes = self.get('model.goodObjects.content');
                                });

                                (0, _impactAdminUtilsMultiSelectUpdater['default'])(org, new_locations, old_locations, _impactAdminModelsOrganizationsToLocations['default'], 'location_id', 'organization_id', function () {
                                    org._clean.locations = self.get('model.locationObjects.content');
                                });
                            }
                        }

                        //Save donation suggestions
                        var donation_suggestions = _this.get('model.donation_suggestions');

                        donation_suggestions.forEach(function (suggestion) {
                            var _this2 = this;

                            if (suggestion.isReallyDirty()) {
                                suggestion.save().then(function (json) {
                                    if (json.response.error) {
                                        _this2.growl.error('An error occurred saving changes to a donation suggestion. Please try again');
                                    }
                                });
                            }
                        });
                    })();
                }
                return false;
            },
            cancel: function cancel() {
                var model = this.get('model');
                model.revertRecord();

                var donation_suggestions = this.get('model.donation_suggestions');
                donation_suggestions.forEach(function (suggestion) {
                    suggestion.revertRecord();
                });

                this.transitionToRoute('frame.profile');
            }
        },

        validate: function validate() {
            return true;
        }
    });
});
/* global _ */