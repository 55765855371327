define('impact-admin/routes/frame/prepaid-codes/prepaid-code-group', ['exports', 'ember', 'impact-admin/utils/api', 'impact-admin/models/organizations', 'impact-admin/models/transactions', 'impact-admin/mixins/table', 'moment'], function (exports, _ember, _impactAdminUtilsApi, _impactAdminModelsOrganizations, _impactAdminModelsTransactions, _impactAdminMixinsTable, _moment) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            var prepaidCodeGroupId = params.prepaid_code_group_id;

            var prepaidCodeGroup = new Promise(function (resolve) {
                _impactAdminUtilsApi['default'].get('/prepaid_code_groups/' + prepaidCodeGroupId + '/balance', {}, function (json) {
                    if (json.error) {
                        console.error('Failed to retrieve prepaid group. (' + json.error + ')');
                        return resolve({});
                    }

                    if (json.data.transaction_id) {
                        _impactAdminModelsTransactions['default'].find(json.data.transaction_id).then(function (result) {
                            resolve(Object.assign({}, json.data, { transaction: result }));
                        });
                    } else {
                        resolve(Object.assign({}, json.data, { transaction: null }));
                    }
                }, true);
            });

            var prepaidSumOrganizationDonations = new Promise(function (resolve) {
                _impactAdminUtilsApi['default'].get('/prepaid_code_groups/' + prepaidCodeGroupId + '/org_donations', {}, function (json) {
                    if (json.error) {
                        console.error('Failed to retrieve prepaid group. (' + json.error + ')');
                        return resolve({});
                    }

                    resolve(json.data);
                }, true);
            });

            var prepaidCodeDonations = new Promise(function (resolve) {
                _impactAdminUtilsApi['default'].get('/prepaid_code_groups/' + prepaidCodeGroupId + '/donations', {}, function (json) {
                    if (json.error) {
                        console.error('Failed to retrieve prepaid group. (' + json.error + ')');
                        return resolve([]);
                    }

                    resolve(json.data);
                }, true);
            });

            var prepaidCodeBalances = new Promise(function (resolve) {
                _impactAdminUtilsApi['default'].get('/prepaid_code_groups/' + prepaidCodeGroupId + '/card_balances', {}, function (json) {
                    if (json.error) {
                        console.error('Failed to retrieve prepaid group. (' + json.error + ')');
                        return resolve({});
                    }

                    resolve(json.data.sort(function (a, b) {
                        return a.id - b.id;
                    }));
                }, true);
            });

            var organizations = _impactAdminModelsOrganizations['default'].find({
                status: 'active'
            });

            return _ember['default'].RSVP.hash({
                prepaidCodeGroup: prepaidCodeGroup,
                prepaidSumOrganizationDonations: prepaidSumOrganizationDonations,
                prepaidCodeDonations: prepaidCodeDonations,
                prepaidCodeBalances: prepaidCodeBalances,
                organizations: organizations
            });
        },
        setupController: function setupController(controller, model) {
            _impactAdminMixinsTable['default'].apply(controller);
            controller.set('model', model);
            controller.set('fulfilled_by', model.prepaidCodeGroup.fulfilled_by);
            controller.set('verified_by', model.prepaidCodeGroup.verified_by);
            controller.set('payment_received', model.prepaidCodeGroup.payment_received);
            controller.set('date_fulfilled', model.prepaidCodeGroup.date_fulfilled ? (0, _moment['default'])(model.prepaidCodeGroup.date_fulfilled) : null);

            var prepaidGroup = model.prepaidCodeGroup;
            if (prepaidGroup.overage_org_id) {
                var organizations = model.organizations;
                var org = organizations.find(function (org) {
                    return org.id === prepaidGroup.overage_org_id;
                });
                controller.set('selectedOrg', org || {});
            } else {
                controller.set('selectedOrg', {});
            }
        }
    });
});