define('impact-admin/utils/multi-select-updater', ['exports', 'ember'], function (exports, _ember) {

    /*
     * This is an attempt to generalize the problem of saving many_to_many relationships to the api. e.g.: Multi-select Boxes, or Checkbox Groups.
     * In order to explain how this works, I'll set up a scenario.  Imagine a table of "Organizations" and a table of "Locations". An Organization may serve multiple Locations
     * so we have a linking table called "OrganizationsToLocation" that contains the columns 'organization_id' and 'location_id'.
     * On the client side we have three models Organizations, Locations, and OrganizationsToLocations.
     *
     * PARAMERTERS:
     *   base_object: This is the object from which the links will originate. (e.g.: The model for a particular organization whose locations will be determined. )
     *   new_things: Array of objects the base_object wants to link to: (e.g.: A new array of Location models.)
     *   old_things: Array of the object base_object used to link to: (e.g. The list of Location models that base_model linked to before this save was attempted.)
     *   link_table: The Model of the linking table that connects the two object is the relatoinship. (e.g.: OrganizationsToLocations)
     *   link_id_name_variable: The name of the column in which the id of the object being linked to is stored in link_table (e.g.: 'location_id')
     *   link_id_name_base: The name of the column in which the base_object's id is stored in link_table: (e.g.: 'organization_id')
     *   callback: a callback function.
     *
     */

    var multiSelectUpdater = function multiSelectUpdater(base_object, new_things, old_things, link_table, link_id_name_variable, link_id_name_base, callback) {

        //var newTagIDs = _.pluck(new_things, 'id');
        //var oldTagIDs = _.pluck(old_things, 'id');

        var addedTags = _.difference(new_things, old_things);
        var removedTags = _.difference(old_things, new_things);

        var base_object_id = base_object.get('id');

        //Create and add the new tag links.
        if (addedTags.length > 0) {
            _.each(addedTags, function (variable_id) {
                var linkRecord = link_table.createRecord();
                linkRecord.set(link_id_name_variable, variable_id);
                linkRecord.set(link_id_name_base, base_object_id);

                linkRecord.save();
            });
        }

        //Find and remove the deleted tag links.
        if (removedTags.length > 0) {
            var findParams = {};
            findParams[link_id_name_variable] = removedTags;
            findParams[link_id_name_base] = base_object_id;
            var remTags = link_table.find(findParams);

            remTags.then(function (results, err) {
                if (!err) {
                    _.each(results.get('content'), function (link) {
                        _ember['default'].run.next(function () {
                            link.deleteRecord();
                        });
                    });
                }
            });
        }

        if (callback && typeof callback === 'function') {
            callback();
        }
        // if (organization._clean === undefined) {
        //     organization._clean = {};
        // }
        // record._clean.typeObjects = array2strings(newTagIDs);
        //organization._clean.types = new_things;
    };

    exports['default'] = multiSelectUpdater;
});
/* globals _ */