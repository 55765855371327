define('impact-admin/models/organization-locations', ['exports', 'ember'], function (exports, _ember) {

    var OrganizationLocations = _ember['default'].Model.extend({
        display: _ember['default'].computed('city', 'county', 'state', function () {
            if (this.get('city')) {
                return this.get('city') + ', ' + this.get('state');
            }
            if (this.get('county')) {
                return this.get('county') + ' County, ' + this.get('state');
            }

            return this.get('state');
        })
    });

    OrganizationLocations.table = 'organization_locations';

    exports['default'] = OrganizationLocations;
});