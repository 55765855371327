define('impact-admin/controllers/frame/campaigns/campaign', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
    exports['default'] = _ember['default'].Controller.extend({
        editMode: false,
        queryParams: ['editMode'],
        'frame.campaigns': _ember['default'].inject.controller(),

        actions: {
            transitionToEdit: function transitionToEdit() {
                this.set('editMode', true);
            },

            saveCampaign: function saveCampaign() {
                var _this = this;

                var campaign = this.get('model');

                if (!campaign.get('start_date') || !campaign.get('end_date')) {
                    this.growl.error('Please enter a start and end date.');
                } else {
                    if (campaign.start_date._isAMomentObject) {
                        campaign.set('start_date', campaign.start_date.format('YYYY-MM-DD'));
                    }

                    if (campaign.end_date._isAMomentObject) {
                        campaign.set('end_date', campaign.end_date.format('YYYY-MM-DD'));
                    }

                    campaign.setProperties({
                        start_date_epoch: (0, _moment['default'])(campaign.get('start_date'), 'YYYY-MM-DD').unix(),
                        end_date_epoch: (0, _moment['default'])(campaign.get('end_date'), 'YYYY-MM-DD').unix()
                    });

                    if (campaign.isReallyDirty()) {
                        campaign.save().then(function () {
                            _this.growl.success('Campaign was saved.');
                            _this.set('editMode', false);
                        })['catch'](function (json) {
                            _this.growl.error('Campaign could not be saved at this time. Please try again.');
                            console.log(json);
                        });
                    } else {
                        this.set('editMode', false);
                    }
                }
            },

            cancelEdit: function cancelEdit() {
                this.get('model').revertRecord();
                this.set('editMode', false);
            }
        }
    });
});