define('impact-admin/controllers/frame/goods', ['exports', 'ember', 'impact-admin/models/good', 'impact-admin/utils/api', 'impact-admin/utils/multi-select-updater', 'impact-admin/models/good-charity-categories'], function (exports, _ember, _impactAdminModelsGood, _impactAdminUtilsApi, _impactAdminUtilsMultiSelectUpdater, _impactAdminModelsGoodCharityCategories) {
    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        editing: false,
        localFullfill: null,
        localLinks: null,
        localSelected: null,

        filteredModel: _ember['default'].computed('model.volunteer', 'searchTerm', function () {
            var _this = this;

            var searchTerm = this.get('searchTerm');
            if (searchTerm) {
                var _ret = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    _this.get('model.volunteer').forEach(function (volunteer_opportunity) {
                        if (volunteer_opportunity.get('title').toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(volunteer_opportunity);
                        }
                    }, _this);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret === 'object') return _ret.v;
            } else {
                return this.get('model.volunteer');
            }
        }),

        filteredGoods: _ember['default'].computed('model.goods', 'searchTerm', function () {
            var _this2 = this;

            var searchTerm = this.get('searchTerm');
            if (searchTerm) {
                var _ret2 = (function () {
                    var matches = [];
                    searchTerm = searchTerm.toUpperCase();
                    _this2.get('model.goods').forEach(function (good) {
                        if (good.get('title').toUpperCase().indexOf(searchTerm) > -1) {
                            matches.push(good);
                        }
                    }, _this2);

                    return {
                        v: matches
                    };
                })();

                if (typeof _ret2 === 'object') return _ret2.v;
            } else {
                return this.get('model.goods');
            }
        }),

        canAddGoods: _ember['default'].computed('model.goods.[]', function () {
            var length = this.get('model.goods.length');
            return length;
        }),

        countFullfillText: _ember['default'].computed('model.charity.goods_fullfillment_hours', function () {
            var length = this.get('model.charity.goods_fullfillment_hours.length');
            return length;
        }),

        canAddMoreGoods: _ember['default'].computed('model.goods.[]', function () {
            var length = this.get('model.goods.length');
            return length < 10;
        }),

        actions: {
            toggleEditing: function toggleEditing() {
                this.toggleProperty('editing');
            },

            editGoodsProfile: function editGoodsProfile() {
                this.set('localFullfill', this.get('model.charity.goods_fullfillment_hours'));
                this.set('localLinks', this.get('model.charity.good_wishlist_url'));
                this.set('localSelected', this.get('selectedGoods'));
                this.toggleProperty('editing');
            },

            cancelGoods: function cancelGoods() {
                this.set('model.charity.goods_fullfillment_hours', this.get('localFullfill'));
                this.set('model.charity.good_wishlist_url', this.get('localLinks'));
                this.set('selectedGoods', this.get('localSelected'));
                this.toggleProperty('editing');
            },

            saveProfile: function saveProfile() {
                var _this3 = this;

                if (this.get('model.charity')) {
                    var _ret3 = (function () {
                        var self = _this3;
                        var org = _this3.get('model.charity');

                        var new_goods = _this3.get('selectedGoods');
                        var new_goods_ids = _this3.get('selectedGoods').mapBy('id');
                        var old_goods_ids = _this3.get('oldGoods').mapBy('id');
                        org.set('good_wishlist_url', org.get('good_wishlist_url') === null ? JSON.stringify([]) : Array.isArray(org.get('good_wishlist_url')) ? JSON.stringify(org.get('good_wishlist_url')) : org.get('good_wishlist_url'));

                        var goods = _this3.get('filteredGoods');
                        if (goods.content.length > 0 && new_goods_ids.length === 0) {
                            _this3.growl.error('You must add Goods Category Tags because you have Goods listed.');
                            return {
                                v: undefined
                            };
                        }
                        if (org.isReallyDirty()) {
                            var newRecord = org.save();
                            newRecord.then(function (json) {
                                if (json.response.error) {
                                    _this3.growl.error('Your good changes were not saved. Please try again.');
                                    console.log(json.response.error);
                                } else {
                                    _this3.growl.success('Changes saved.');

                                    (0, _impactAdminUtilsMultiSelectUpdater['default'])(org, new_goods_ids, old_goods_ids, _impactAdminModelsGoodCharityCategories['default'], 'good_category_id', 'organization_id', function () {

                                        self.set('model.charity.goodObjects', new_goods);
                                        self.set('model.selectedGoods', new_goods);
                                    });

                                    _this3.toggleProperty('editing');
                                }
                            });
                        } else {
                            if (org.id) {
                                (0, _impactAdminUtilsMultiSelectUpdater['default'])(org, new_goods_ids, old_goods_ids, _impactAdminModelsGoodCharityCategories['default'], 'good_category_id', 'organization_id', function () {

                                    _impactAdminUtilsApi['default'].get('/good_charity_categories/org/' + org.id, {}, function (json) {
                                        if (json.error) {
                                            self.growl.error(json.error);
                                        } else {
                                            self.set('model.selectedGoods', json.result);
                                        }
                                    }, true);
                                });
                                var updatedGoods = JSON.parse(JSON.stringify(_this3.get('selectedGoods')));
                                _this3.set('oldGoods', updatedGoods);
                                self.growl.success('Changes saved.');
                                _this3.toggleProperty('editing');
                            }
                        }
                    })();

                    if (typeof _ret3 === 'object') return _ret3.v;
                }
                return false;
            },

            closeIfFullGoods: function closeIfFullGoods(select) {
                if (this.get('selectedGoods.length') >= 5) {
                    select.actions.close();
                }
            },
            updateSelectedGoods: function updateSelectedGoods(newGoods) {
                var selectedGoods = this.get('selectedGoods');
                if (newGoods.length <= selectedGoods.length || newGoods.length <= 5) {
                    this.set('selectedGoods', newGoods);
                } else {
                    alert('Only 5 types allowed. Please remove one before adding another');
                }
            },

            addWishlist: function addWishlist() {
                var name = this.get('wishlistName');
                var link = this.get('wishlistLink');
                var charity = this.get('model.charity');

                if (!name || !link) {
                    return;
                }
                charity.addWishlist(name, link);

                this.set('wishlistName', '');
                this.set('wishlistLink', '');
            },
            delWishlist: function delWishlist(idx) {
                var charity = this.get('model.charity');
                charity.delWishlist(idx);
            },

            duplicateOpportunity: function duplicateOpportunity(opportunity) {
                this.set('newOpportunity', _impactAdminModelsGood['default'].createRecord({
                    organization_id: opportunity.organization_id,
                    title: opportunity.title,
                    short_description: opportunity.short_description,
                    description: opportunity.description,
                    notes: opportunity.notes
                }));

                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    _ember['default'].$('html, body').animate({
                        scrollTop: $('#new-opportunity').offset().top
                    }, 800);
                });
            },
            saveOpportunity: function saveOpportunity(opportunity, newSkills, oldSkills, isNew) {
                var _this4 = this;

                opportunity.set('organization_id', this.get('model.charity_id'));
                if (isNew) {
                    opportunity.save().then(function (json) {
                        if (json.response.error) {
                            _this4.growl.error('There was an error saving your changes. ' + json.response.error);
                        } else {
                            _this4.set('newOpportunity', null);
                            _this4.growl.success('Changes Saved');
                        }
                    });
                } else {
                    opportunity.save().then(function (json) {
                        if (json.response.error) {
                            _this4.growl.error('There was an error saving your changes. ' + json.response.error);
                        } else {
                            if (isNew) {
                                _this4.set('newOpportunity', null);
                            }
                            _this4.growl.success('Changes Saved');
                        }
                    });
                }
                this.set('isDuplicate', false);
            },
            addNew: function addNew() {
                var selectedGoods = this.get('oldGoods');
                if (selectedGoods.length === 0) {
                    this.growl.error('You must add Goods Category Tags before adding Goods.');
                    return;
                }
                if (this.get('canAddGoods') >= 10) {
                    alert('Reached 10 limit capacity, please remove an item to continue.');
                } else {
                    this.set('newOpportunity', _impactAdminModelsGood['default'].createRecord());
                }
                //Ember.run.scheduleOnce('afterRender', this, function () {
                //Ember.$('html, body').animate({
                //scrollTop: $('#new-opportunity').offset().top
                //}, 300);
                //});
            },
            cancelAddNew: function cancelAddNew() {
                this.set('newOpportunity', null);
            },
            remove: function remove(opportunity) {
                var _this5 = this;

                if (confirm('Are you sure you want to remove this good item?')) {
                    opportunity.set('organization_id', this.get('model.charity_id'));
                    opportunity.set('removed', true);
                    opportunity.save().then(function () {
                        var submissionsHide = _this5.get('model.goods').filterBy('good_id', opportunity.get('id'));
                        _this5.get('model.goods').removeObjects(submissionsHide);
                    });
                }
            },
            hideAllGoods: function hideAllGoods(org) {
                var organization = org;
                organization.set('show_goods', !organization.get('show_goods'));
                organization.save().then(function () {
                    //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                    //this.get('model.submissions').removeObjects(submissionsHide);
                });
            },
            showAllGoods: function showAllGoods(org) {
                var organization = org;
                if (confirm('Are you sure you want to enable NEW & USED GOODS?')) {
                    organization.set('show_goods', true);
                    organization.save().then(function () {
                        //let submissionsHide = this.get('model.submissions').filterBy('volunteer_id', opportunity.get('id'));
                        //this.get('model.submissions').removeObjects(submissionsHide);
                    });
                }
            }
        }
    });
});
/* globals $ */