define('impact-admin/initializers/tm-data', ['exports', 'ember', 'impact-admin/utils/token', 'impact-admin/config/environment', 'impact-admin/utils/api'], function (exports, _ember, _impactAdminUtilsToken, _impactAdminConfigEnvironment, _impactAdminUtilsApi) {

    var alreadyRun = false;

    /* global Pusher */
    /* jshint eqeqeq:false */

    exports['default'] = {
        name: 'tm-data-client',
        initialize: function initialize() {
            if (alreadyRun) {
                return;
            } else {
                alreadyRun = true;
            }

            _ember['default'].Filter = {
                compare: function compare(value1, value2, operator) {
                    var self = this;

                    // If the comparison value is an array, recursive check if any are true
                    if (_.isArray(value2)) {
                        return _.some(value2, function (valuex) {
                            // Recursive Call
                            return self.compare(value1, valuex, operator);
                        });
                    } else if (_.isArray(value1)) {
                        return _.some(value1, function (valuex) {
                            // Recursive Call
                            return self.compare(valuex, value2, operator);
                        });
                    } else {
                        switch (operator) {
                            case "=":
                                if (_.isNumber(value1)) {
                                    value1 = parseFloat(value1);
                                }
                                if (_.isNumber(value2)) {
                                    value2 = parseFloat(value2);
                                }
                                return value1 == value2;
                            case "!=":
                                if (_.isNumber(value1)) {
                                    value1 = parseFloat(value1);
                                }
                                if (_.isNumber(value2)) {
                                    value2 = parseFloat(value2);
                                }
                                return value1 != value2;
                            case ">":
                                return parseFloat(value1) > parseFloat(value2);
                            case "<":
                                return parseFloat(value1) < parseFloat(value2);
                            case ">=":
                                return parseFloat(value1) >= parseFloat(value2);
                            case "<=":
                                return parseFloat(value1) <= parseFloat(value2);
                            case "like":
                            case "LIKE":
                                value2 = '^' + value2.replace(/\%/g, '.*') + '$';
                                return value1 !== null && value1.search(new RegExp(value2)) !== -1;
                            case "ilike":
                            case "ILIKE":
                                value2 = '^' + value2.replace(/\%/g, '.*') + '$';
                                return value1 !== null && value1.search(new RegExp(value2, "i")) !== -1;
                            case "not like":
                            case "NOT LIKE":
                                value2 = '^' + value2.replace(/\%/g, '.*') + '$';
                                return value1 !== null && value1.search(new RegExp(value2)) === -1;
                            case "not ilike":
                            case "NOT ILIKE":
                                value2 = '^' + value2.replace(/\%/g, '.*') + '$';
                                return value1 !== null && value1.search(new RegExp(value2, "i")) === -1;
                            default:
                                console.log('Unknown Operator');
                                return false;
                        }
                    }
                },

                expandFilter: function expandFilter(filter) {
                    // Loop each field key
                    _.each(filter, function (value, key) {
                        if (_.isString(value)) {
                            filter[key] = {
                                "=": value
                            };
                        } else if (_.isArray(value)) {
                            filter[key] = {
                                "=": value
                            };
                        } else if (_.isNumber(value)) {
                            filter[key] = {
                                "=": value
                            };
                        } else if (_.isBoolean(value)) {
                            filter[key] = {
                                "=": value
                            };
                        }
                    });

                    return filter;
                },

                matches: function matches(object, filter) {
                    var self = this;

                    return _.every(filter, function (filter_value, filter_key) {
                        //console.log("filter_value, filter_key", filter_value, filter_key);

                        return _.every(filter_value, function (compare_value, operator) {
                            //console.log("compare_value, operator", compare_value, operator);

                            // Find value to test
                            var object_value = object.get(filter_key);
                            if (object_value !== undefined) {
                                // Check for match
                                var compare = self.compare(object_value, compare_value, operator);
                                //console.log("compare", compare);
                                return compare;
                            } else {
                                //console.log('Value Not Found', object);
                                return false;
                            }
                        });
                    });
                }
            };

            //This is an almost-exact copy of the deprecated Ember.DeferredMixin.
            //If there's a better way to do this, I guess we shoudl
            _ember['default'].RSVPMixin = _ember['default'].Mixin.create({
                /**
                 Add handlers to be called when the Deferred object is resolved or rejected.
                  @method then
                 @param {Function} resolve a callback function to be called when done
                 @param {Function} reject  a callback function to be called when failed
                 */
                then: function then(resolve, reject, label) {
                    var deferred, promise, entity;

                    entity = this;
                    deferred = _ember['default'].get(this, '_deferred');
                    promise = deferred.promise;

                    function fulfillmentHandler(fulfillment) {
                        if (fulfillment === promise) {
                            return resolve(entity);
                        } else {
                            return resolve(fulfillment);
                        }
                    }
                    return promise.then(resolve && fulfillmentHandler, reject, label);
                },

                /**
                 Resolve a Deferred object and call any `doneCallbacks` with the given args.
                  @method resolve
                 */
                resolve: function resolve(value) {
                    var deferred, promise;

                    deferred = _ember['default'].get(this, '_deferred');
                    promise = deferred.promise;

                    if (value === this) {
                        deferred.resolve(promise);
                    } else {
                        deferred.resolve(value);
                    }
                },

                /**
                 Reject a Deferred object and call any `failCallbacks` with the given args.
                 @method reject
                 */
                reject: function reject(value) {
                    _ember['default'].get(this, '_deferred').reject(value);
                },

                _deferred: _ember['default'].computed(function () {
                    //Ember.deprecate('Usage of Ember.DeferredMixin or Ember.Deferred is deprecated.', this._suppressDeferredDeprecation);
                    //debugger;
                    return _ember['default'].RSVP.defer('Ember: RSVPMixin - ' + this);
                })

            });
            /*
             * TM Data Socket IO Class
             */
            _ember['default'].SocketIO = {
                debug: false,
                loading: false,
                loaded: false,
                connecting: false,
                io: false,
                pusher_api: null,
                pusher_encrypted: true,
                pusher_auth_endpoint: null,
                pusher_auth_transport: 'jsonp',
                table_sync_callbacks: {},

                /*
                 * Load the pusher js file
                 */
                load: function load() {
                    if (!this.loading) {
                        var self = this;
                        self.loading = true;
                        _ember['default'].$.getScript("https://d3dy5gmtp8yhk7.cloudfront.net/2.2/pusher.min.js", function () {
                            //this function returns (data, textStatus, jqxhr)
                            self.loaded = true;
                        });
                    }
                },

                /*
                 * Connect to pusher using api key
                 */
                connect: function connect() {
                    var self = this;
                    if (!self.connecting) {
                        self.connecting = true;

                        // Connect to pusher
                        self.io = new Pusher(self.pusher_api, {
                            encrypted: self.pusher_encrypted,
                            authEndpoint: self.pusher_auth_endpoint,
                            authTransport: self.pusher_auth_transport
                        });

                        // Pusher debugging
                        if (self.debug) {
                            Pusher.log = function (message) {
                                if (window.console && window.console.log) {
                                    window.console.log(message);
                                }
                            };
                        }

                        //NOTE: disabling as it causes a ton of lookups on prod GHD
                        // Bind to default tm data sync events
                        // self.listen("tm-data-sync", function(data) {
                        //     _.each(data, function(actions, table) {
                        //         // Check if table callback exists
                        //         if (self.table_sync_callbacks[table] !== undefined) {
                        //             self.table_sync_callbacks[table](actions);
                        //         }
                        //     });
                        // });
                    }
                },

                /*
                 * Listen on pusher for an event on all connected channels
                 */
                listen: function listen(ev, fn) {
                    var self = this;

                    // Defer until pusher script is loaded
                    if (!this.loaded) {
                        this.load();
                        return setTimeout(function () {
                            self.listen(ev, fn);
                        }, 1000);
                    }

                    // Connect to pusher
                    if (!this.io) {
                        this.connect();
                    }

                    // Bind to a event accross all channels
                    this.io.bind(ev, function (data) {
                        fn(data);
                    });
                },

                listen_to_table: function listen_to_table(table_name, fn) {
                    this.table_sync_callbacks[table_name] = fn;
                },

                /*
                 * Join a pusher room
                 */
                join: function join(room_id) {
                    var self = this;

                    // Defer until pusher script is loaded
                    if (!this.loaded) {
                        this.load();
                        return setTimeout(function () {
                            self.join(room_id);
                        }, 1000);
                    }

                    // Connect to pusher
                    if (!this.io) {
                        this.connect();
                    }

                    // Joining channel
                    return this.io.subscribe(String(room_id));
                }
            };

            function mustImplement(message) {
                var fn = function fn() {
                    var className = this.constructor.toString();

                    throw new Error(message.replace('{{className}}', className));
                };
                fn.isUnimplemented = true;
                return fn;
            }

            _ember['default'].Adapter = _ember['default'].Object.extend({
                find: mustImplement('{{className}} must implement find'),
                findQuery: mustImplement('{{className}} must implement findQuery'),
                findAll: mustImplement('{{className}} must implement findAll'),
                findAllById: mustImplement('{{className}} must implement findAllById'),
                createRecord: mustImplement('{{className}} must implement createRecord'),
                save: mustImplement('{{className}} must implement save'),
                updateRecord: mustImplement('{{className}} must implement updateRecord'),
                deleteRecord: mustImplement('{{className}} must implement deleteRecord')
            });

            _ember['default'].RestAdapter = _ember['default'].Adapter.extend({
                api_endpoint: '',
                auth: function auth(params) {
                    params._token = _impactAdminUtilsToken['default'].get();
                    return params;
                },
                //Get called when a query fails.
                //returns json
                queryFailed: function queryFailed() {
                    //For Example:
                    // if (json.error === "The API key they sent doesn't match any of ours.") {
                    //     App.get('Router.router').transitionTo('frame.logout');
                    // }
                },

                ajax: function ajax(url, params, callback) {
                    // return Ember.RestAdapterBatch.addToBatch(url, params, callback);
                    var self = this;
                    var headers = {
                        Authorization: ' Bearer ' + _impactAdminUtilsToken['default'].getAuth0Tokens().access_token
                    };
                    var query = _ember['default'].$.param(params);
                    return _ember['default'].$.ajax({
                        type: 'GET',
                        url: this.get('api_endpoint') + url + (query ? '?' + query : ''),
                        headers: headers,
                        success: function success(json) {
                            // console.log("json", json);
                            if (json.error && json.code === 500) {
                                console.log('Problem reading query from server: ' + json.error);
                                self.queryFailed(json);
                            } else {
                                callback(json);
                            }
                        },
                        dataType: 'json'
                    });
                },

                find: function find(record, id) {
                    var self = this;

                    // Url
                    var url = '/' + _ember['default'].get(record.constructor, 'table');
                    url += '/' + id;

                    // IE Fix
                    //url += '?callback=?';

                    // Params
                    var params = {};

                    this.ajax(url, params, function (json) {
                        if (!json) {
                            console.error('Problem reading query from server. No error recieved.');
                            self.queryFailed(json);
                            return;
                        }

                        if (json.result !== null && json.result !== undefined && json.result[0] !== undefined) {
                            record.load(json.result[0]);
                        } else {
                            console.log('Problem reading record from server: ' + json.error);
                            self.queryFailed(json);
                        }
                    });
                },
                findAll: function findAll(klass, records) {
                    var self = this;

                    // Url
                    var url = '/' + _ember['default'].get(klass, 'table');

                    // IE Fix
                    //url += '?callback=?';

                    // Params
                    var params = {};

                    this.ajax(url, params, function (json) {
                        if (!json) {
                            console.error('Problem reading query from server. No error recieved.');
                            self.queryFailed(json);
                            return;
                        }

                        if (json.result !== null && json.result !== undefined) {
                            klass.load(json.result, records);
                        } else {
                            console.log('Problem reading all from server: ' + json.error);
                            self.queryFailed(json);
                        }
                    });
                },
                findAllById: function findAllById(klass, records) {
                    var self = this;

                    // Construct URL
                    var url = '/' + _ember['default'].get(klass, 'table');

                    // Generate a unique cache-busting parameter
                    var cacheBuster = 'cb=' + Date.now();

                    // Prepare query parameters
                    var ids = _.keys(records);
                    var params = { filter: JSON.stringify({ id: ids }) };

                    // Build query string with cache-busting parameter
                    var query = _ember['default'].$.param(params);
                    url = url + '?' + query + '&' + cacheBuster;

                    // Perform AJAX request
                    this.ajax(url, {}, function (json) {
                        if (!json) {
                            console.error('Failed to fetch data: No response received.');
                            self.queryFailed(json);
                            return;
                        }

                        if (json.result && Array.isArray(json.result)) {
                            // Clear cached records before reloading
                            _.forEach(records, function (record) {
                                if (record) {
                                    record.set('_clean', {}); // Ensure reload on next fetch
                                }
                            });

                            // Load new records from the server
                            json.result.forEach(function (record) {
                                var existingRecord = records[record.id];
                                if (existingRecord) {
                                    existingRecord.load(record);
                                } else {
                                    console.warn('Record with ID ' + record.id + ' not found in cache.');
                                }
                            });
                        } else {
                            console.log('Server returned an issue:', json.error);
                            self.queryFailed(json);
                        }
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                        console.error('AJAX Error: ' + textStatus + ' - ' + errorThrown);
                    });
                },
                findQuery: function findQuery(klass, records, filter, options) {
                    var self = this;
                    // Url
                    var url = '/' + _ember['default'].get(klass, 'table');

                    // IE Fix
                    //url += '?callback=?';

                    // Params
                    var params = {};

                    // Filtering
                    if (filter) {
                        params.filter = JSON.stringify(filter);
                    }

                    // Options - Sort
                    if (options && options.sort) {
                        params.sort = JSON.stringify(options.sort);
                    }

                    // Options - Limit
                    if (options && options.limit) {
                        params.limit = options.limit;
                    }

                    // Options - Offset
                    if (options && options.offset) {
                        params.offset = options.offset;
                    }

                    this.ajax(url, params, function (json) {
                        if (!json) {
                            console.error('Problem reading query from server. No error recieved.');
                            self.queryFailed(json);
                            return;
                        }

                        if (json.result !== null && json.result !== undefined) {
                            klass.load(json.result, records);
                        } else {
                            console.log('Problem reading query from server: ' + JSON.stringify(json.error));
                            self.queryFailed(json);
                        }
                    });
                },
                createRecord: function createRecord(record) {
                    //console.log("adapter createRecord", record);

                    // Url
                    var url = this.get('api_endpoint');
                    url += '/' + _ember['default'].get(record.constructor, 'table');

                    // Params
                    var data = record.getDirty();

                    // Auth
                    data = this.auth(data);

                    var headers = {
                        Authorization: ' Bearer ' + _impactAdminUtilsToken['default'].getAuth0Tokens().access_token
                    };
                    return _ember['default'].$.ajax({
                        type: 'POST',
                        url: url,
                        data: data,
                        headers: headers,
                        success: function success(json) {
                            if (json.result !== undefined && json.result !== null && json.result[0] !== undefined) {
                                record.load(json.result[0]);
                            } else {
                                console.log('There was a problem saving to the server.');
                                console.log(json.result);
                            }
                        }
                    });
                },
                updateRecord: function updateRecord(id, record) {
                    //console.log("adapter updateRecord", record);

                    // Url
                    var url = '/' + _ember['default'].get(record.constructor, 'table');
                    url += '/' + id;

                    // IE Fix
                    url += '?callback=?';

                    // Params
                    var data = record.getDirty();

                    // Method Override
                    data._method = 'PUT';

                    // Auth
                    data = this.auth(data);

                    return _impactAdminUtilsApi['default'].put(url, data, function (json) {
                        if (json.result !== undefined && json.result !== null && json.result[0] !== undefined) {
                            record.load(json.result[0]);
                        } else {
                            console.log('There was a problem updating to the server.');
                        }
                    });
                },
                deleteRecord: function deleteRecord(id, record) {
                    //console.log("adapter deleteRecord", id, record);

                    // Url
                    var url = this.get('api_endpoint');
                    url += '/' + _ember['default'].get(record.constructor, 'table');
                    url += '/' + id;

                    // IE Fix
                    url += '?callback=?';

                    // Params
                    var data = {};

                    //TODO: Why do DELETE over a GET???
                    // Method Override
                    data._method = 'DELETE';

                    // Auths
                    data = this.auth(data);

                    var headers = {
                        Authorization: ' Bearer ' + _impactAdminUtilsToken['default'].getAuth0Tokens().access_token
                    };
                    return _ember['default'].$.ajax({
                        type: 'GET',
                        url: url,
                        data: data,
                        headers: headers,
                        success: function success(json) {
                            console.log(json);
                            if (json.result !== undefined && json.result !== null && json.result[0] !== undefined) {
                                // Nothing
                            } else {
                                    console.log('There was a problem deleting from the server.');
                                }
                        }
                    });
                }
            });

            /*
             * Base class for objects
             */
            _ember['default'].Model = _ember['default'].Object.extend({});

            _ember['default'].Model.reopen(_ember['default'].RSVPMixin, {
                // Saved to server
                _persisted: false,
                // Copy of object on server
                _clean: null,
                isLoaded: false,
                isSaving: false,
                isLoading: _ember['default'].computed.not('isLoaded'),
                isPersisted: _ember['default'].computed.bool('_persisted'),
                // Initialize
                init: function init() {
                    this._super();
                    //When updating records, we will run init again to trigger some models' computed properties.
                    //We don't want to wipe out _clean in those cases.
                    if (!this.get('isLoaded')) {
                        this.set('_clean', {});
                    }
                },
                observeLoaded: _ember['default'].observer('isLoaded', function () {
                    // Once loaded, resolve promise
                    if (this.get('isLoaded')) {
                        this.resolve(this);
                    }
                }),
                // Return object in plain json object
                getJson: function getJson() {
                    var v,
                        ret = [];
                    for (var key in this) {
                        if (this.hasOwnProperty(key)) {
                            v = this[key];
                            // Ignore private parameters
                            if (key.charAt(0) === '_') {
                                continue;
                            }
                            // Ignore toString function
                            if (v === 'toString') {
                                continue;
                            }
                            // Ignore Functions
                            if (_ember['default'].typeOf(v) === 'function') {
                                continue;
                            }
                            // Ignore Undefined - Computed Properties
                            if (_ember['default'].typeOf(v) === 'undefined') {
                                continue;
                            }
                            // Ignore Instances - Other Classes
                            if (_ember['default'].typeOf(v) === 'instance') {
                                continue;
                            }
                            // Ignore ComputedProperties
                            if (v instanceof _ember['default'].ComputedProperty) {
                                continue;
                            }

                            ret.push(key);
                        }
                    }

                    return this.getProperties(ret);
                },
                // Returns an object with the changed fields.
                getDirty: function getDirty() {
                    var data = this.getJson();
                    var clean = this.get('_clean');

                    // Find changed data
                    for (var key in data) {
                        // Check if exists and if they are equal
                        if (clean !== null && clean[key] !== undefined && data[key] === clean[key]) {
                            delete data[key];
                        }
                    }

                    return data;
                },
                // Returns true/false depending on if there are unsave changes
                isClean: function isClean() {
                    var dirty = this.getDirty();
                    return _.isEmpty(dirty);
                },
                //Returns whenther or not the values that are stored in the database have actually changed.
                isReallyDirty: function isReallyDirty() {
                    var self = this;
                    var isReallyDirty = false;

                    //If there is no _clean object, this is a newly create record that hasn't been saved yet.
                    //We should consider it dirty.
                    if (_.isEmpty(this.get('_clean'))) {
                        return true;
                    }

                    _.each(this.get('_clean'), function (value, key) {

                        if (_ember['default'].compare(value, self.get(key)) !== 0) {
                            isReallyDirty = true;
                        }
                    });
                    return isReallyDirty;
                },
                load: function load(json) {
                    // Update
                    //var self = this;
                    this.set('_clean', json);
                    this.setProperties(json);
                    //this.beginPropertyChanges();
                    // _.each(json, function(value, key) {
                    //     self.set(key, value);
                    // });
                    // this.endPropertyChanges();
                    this.set('_persisted', true);
                    this.set('isLoaded', true);

                    // Trigger update
                    this.constructor.trigger('didUpdateRecord', this, this.get('id'));
                },
                revertRecord: function revertRecord() {
                    if (this.get('_persisted') === false) {
                        // Do nothing. Object is not in store.
                    } else {
                            this.setProperties(this.get('_clean'));
                        }
                },
                deleteRecord: function deleteRecord() {
                    var dfr = _ember['default'].RSVP.defer();
                    var record = this;
                    var id = this.get('id');
                    var adapter = this.constructor.get_adapter();

                    // Remove from hash
                    this.constructor._findAndRemoveFromCacheById(id);

                    // Remove from remote
                    adapter.deleteRecord(id, this).then(function (response) {
                        if (!response.error) {
                            dfr.resolve({
                                record: record,
                                response: response
                            });
                        } else {
                            dfr.reject(response.error);
                        }
                    });

                    return dfr.promise;
                },
                save: function save() {
                    var dfr = _ember['default'].RSVP.defer();
                    var record = this;
                    var id = record.get('id');
                    var adapter = record.constructor.get_adapter();

                    record.set('isSaving', true);

                    if (id) {
                        // Update
                        adapter.updateRecord(id, record).then(function (response) {
                            record.set('isSaving', false);
                            if (!response.error) {
                                dfr.resolve({
                                    record: record,
                                    response: response
                                });
                            } else {
                                dfr.reject(response.error);
                            }
                        });
                    } else {
                        // Create
                        adapter.createRecord(record).then(function (response) {
                            record.set('isSaving', false);
                            if (!response.error) {
                                dfr.resolve({
                                    record: record,
                                    response: response
                                });
                            } else {
                                dfr.reject(response.error);
                            }
                        });
                    }

                    return dfr.promise;
                }
            });

            /*
             * Proxys to a real model until it's saved
             */

            _ember['default'].ModelProxy = _ember['default'].ObjectProxy.extend({
                // If a value is set, then this has been inserted in the store.
                _uuid: false,
                // Saved to server
                _persisted: _ember['default'].computed.alias('content._persisted'),
                _clean: _ember['default'].computed.alias('content._clean'),
                isLoaded: _ember['default'].computed.alias('content.isLoaded'),
                isSaving: _ember['default'].computed.alias('content.isSaving'),
                isLoading: _ember['default'].computed.alias('content.isLoading'),
                isPersisted: _ember['default'].computed.alias('content.isPersisted'),
                // Initialize
                init: function init() {
                    this._super();
                    this.constructor = this.get('content').constructor;
                },
                getJson: function getJson() {
                    return this.get('content').getJson();
                },
                getDirty: function getDirty() {
                    return this.get('content').getDirty();
                },
                isClean: function isClean() {
                    return this.get('content').isClean();
                },
                isReallyDirty: function isReallyDirty() {
                    return this.get('content').isReallyDirty();
                },
                load: function load(json) {
                    var proxy_record = this.get('content');

                    // Remove proxy record from store
                    var uuid = this.get("_uuid");
                    if (uuid) {
                        proxy_record.constructor._findAndRemoveFromCacheById(uuid);
                    }

                    // Get new record
                    var record = proxy_record.constructor._findOrCreateInCacheById(json.id, json);

                    // Override proxy
                    this.set('content', record);
                    this.set('uuid', false);
                },
                revertRecord: function revertRecord() {
                    var record = this.get('content');

                    if (record.get('_persisted')) {
                        return record.revertRecord();
                    } else if (this.get('_uuid')) {
                        var uuid = this.get("_uuid");
                        record.constructor._findAndRemoveFromCacheById(uuid);
                    }
                },
                deleteRecord: function deleteRecord() {
                    var record = this.get('content');

                    if (record.get('_persisted')) {
                        // Already saved
                        return record.deleteRecord();
                    } else if (this.get('_uuid')) {
                        var uuid = this.get("_uuid");
                        record.constructor._findAndRemoveFromCacheById(uuid);
                    }
                },
                save: function save() {
                    var self = this;
                    var record = this.get('content');
                    if (self.get('_persisted')) {
                        // Already Saved
                        return record.save();
                    } else {
                        var dfr = _ember['default'].RSVP.defer();
                        var adapter = self.constructor.get_adapter();

                        adapter.createRecord(self).then(function (response) {
                            if (!response.error) {
                                dfr.resolve({
                                    record: self,
                                    response: response
                                });
                            } else {
                                dfr.reject(response.error);
                            }
                        });

                        return dfr.promise;
                    }
                }
            });

            _ember['default'].Model.reopenClass(_ember['default'].Evented, {
                hash: false,
                adapter: false,
                table: false,
                enable_sockets: true,
                find_by_id_queue: null,
                get_hash: function get_hash() {
                    if (!this.hash) {
                        this.hash = _ember['default'].Map.create();
                    }
                    return this.hash;
                },
                get_records: function get_records() {
                    if (!this.records) {
                        this.records = _ember['default'].Map.create();
                    }
                    return this.records;
                },
                push_records: function push_records(records, recordId) {
                    if (recordId) {
                        var oldrecords = this.get_records();
                        var old = oldrecords.get(recordId);
                        if (old) {
                            old.destroy();
                            console.log("Destroyed THE OLD RECORD: " + records.klass.toString());
                        }
                        oldrecords.set(recordId, records);
                    }
                },
                // Reset the contents of the hash
                reset: function reset() {
                    this.hash = _ember['default'].Map.create();
                },
                get_adapter: function get_adapter() {
                    if (!this.adapter) {
                        this.adapter = _ember['default'].RestAdapter.create();

                        // Setup sockets
                        if (this.enable_sockets) {
                            this.setup_sockets();
                        }
                    }
                    return this.adapter;
                },
                setup_sockets: function setup_sockets() {
                    var self = this;
                    var table = this.table;
                    _ember['default'].SocketIO.listen_to_table(table, function (data) {
                        //console.log(table, data);

                        // Delete action
                        if (data['delete'] !== undefined) {
                            _.each(data['delete'], function (id) {
                                //console.log("delete " + table + ': ' + id);
                                self._findAndRemoveFromCacheById(id);
                            });
                        }
                        // Create
                        var ids = [];

                        if (data.create !== undefined) {
                            ids = ids.concat(data.create);
                        }

                        if (data.update !== undefined) {
                            ids = ids.concat(data.update);
                        }
                        //console.log("ids", ids);

                        if (ids.length > 0) {
                            // Unique array of ids
                            ids = _.uniq(ids);
                            _.each(ids, function (id) {
                                //console.log("load " + table + ': ' + id);
                                self._findOrCreateInCacheById(id, false, true);
                            });
                        }
                    });
                },
                // Universal find method
                find: function find(parameter, options, recordId) {
                    var type = _ember['default'].typeOf(parameter);
                    if (type === "string" || type === "number") {
                        return this._findOrCreateInCacheById(parameter);
                    } else if (type === "object") {
                        return this._findQueryAndReloadAll(parameter, options, recordId);
                    } else {
                        return this._findAndReloadAll(recordId);
                    }
                },
                // Finds a query in the local cache
                findInCache: function findInCache(parameter, options, recordId) {
                    var type = _ember['default'].typeOf(parameter);
                    if (type === "string" || type === "number") {
                        return this._findOrCreateInCacheById(parameter, false, false);
                    } else if (type === "object") {
                        return this._findQueryFromCache(parameter, options, recordId);
                    } else {
                        return this._findFromCacheAll(recordId);
                    }
                },
                // Finds the first item that matches the query in cache and will remote load if not found
                findFirst: function findFirst(parameter, options, recordId) {
                    var cache = this.findFirstInCache(_ember['default'].$.extend(true, {}, parameter), options, recordId);
                    if (cache) {
                        //console.log('Found in cache');
                        return cache;
                    } else {
                        //console.log("Not in cache", parameter);
                        var record = this.createRecord(parameter, true);

                        // Load remote
                        this.find(parameter, options, recordId).then(function (records) {
                            var first_remote_record = records.get('firstObject');

                            if (first_remote_record) {
                                //console.log('Loaded first from api');
                                record.load(first_remote_record.getJson());
                            } else {
                                //console.log('Unable to find first on api');
                            }
                        });

                        return record;
                    }
                },
                // Finds the first item in the cache that matches the query
                findFirstInCache: function findFirstInCache(parameter, options, recordId) {
                    return this._findQueryFromCache(parameter, options, recordId).flush().get('firstObject');
                },
                // Load json records into hash
                load: function load(json, records) {
                    var self = this;
                    _.each(json, function (item) {
                        if (item.id !== undefined) {
                            // Save in save
                            self._findOrCreateInCacheById(item.id, item);
                        } else {
                            console.log('Unknown json item', item);
                        }
                    });

                    if (json.length === 0) {
                        //Before this line was added,
                        //'isLoaded' was not being set if there were zero records returned on a query.
                        records.set('isLoaded', true);
                    }

                    // Update record array
                    if (records) {
                        //records.set('isLoaded', true);
                    }
                },
                createRecord: function createRecord(json, insert) {
                    var record;

                    // Load data
                    if (json) {
                        record = this.create(json);
                    } else {
                        record = this.create();
                    }

                    // Create proxy to record
                    var proxy = _ember['default'].ModelProxy.create({
                        content: record
                    });

                    // Insert proxy object in hash
                    if (insert) {
                        var uuid = this._uuid();
                        proxy.set('_uuid', uuid);

                        this._addObjectToCacheById(uuid, proxy);
                    }

                    return proxy;
                },
                _uuid: function _uuid() {
                    // Source: https://gist.github.com/jed/982883
                    function b(a) {
                        return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
                    }
                    return b();
                },
                _isNumeric: function _isNumeric(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                },
                _getIdsQueue: function _getIdsQueue() {
                    if (!this.find_by_id_queue) {
                        this.find_by_id_queue = {};
                    }

                    return this.find_by_id_queue;
                },
                _resetIdsQueue: function _resetIdsQueue() {
                    //console.log('_resetIdsQueue');

                    // Reset queue
                    this.find_by_id_queue = {};
                },
                _processIdsQueue: function _processIdsQueue() {
                    //console.log('_processIdsQueue');
                    var queue = this._getIdsQueue();
                    this._resetIdsQueue();

                    // Load adapter and issue call
                    var adapter = this.get_adapter();
                    adapter.findAllById(this, queue);

                    //console.log("queue", _.keys(queue));
                },
                _addIdToQueue: function _addIdToQueue(id, record) {
                    // console.log('_addIdToQueue', id);
                    var queue = this._getIdsQueue();
                    queue[id] = record;
                    _ember['default'].run.debounce(this, '_processIdsQueue', 10);
                },
                _findOrCreateInCacheById: function _findOrCreateInCacheById(id, json, reload) {
                    var record;
                    var hash = this.get_hash();

                    // Cast string ids to int
                    if (typeof id === "string" && this._isNumeric(id)) {
                        id = parseFloat(id);
                    }

                    // Setup reload
                    if (reload === undefined) {
                        reload = true;
                    }

                    if (hash.has(id)) {
                        //console.log('Returning from cache', id);

                        // Find existing record
                        record = hash.get(id);
                    } else {
                        //console.log('Not in cache', id);

                        // Create new record
                        record = this.create({
                            id: id
                        });

                        // Hash
                        hash.set(id, record);
                        this.trigger('didCreateRecord', record, id);
                    }

                    // Load json locally
                    if (json) {
                        record.load(json);
                    }

                    // Load json remotely
                    if (!json && reload) {
                        this._addIdToQueue(id, record);
                    }

                    return record;
                },
                _addObjectToCacheById: function _addObjectToCacheById(id, record) {
                    var hash = this.get_hash();

                    // Cast string ids to int
                    if (typeof id === "string" && this._isNumeric(id)) {
                        id = parseFloat(id);
                    }

                    if (!hash.has(id)) {
                        // Hash
                        hash.set(id, record);
                        this.trigger('didCreateRecord', record, id);
                    } else {
                        console.log('Error adding new record to cache, it has already been added.');
                    }
                },
                _findAndRemoveFromCacheById: function _findAndRemoveFromCacheById(id) {
                    var record;
                    var hash = this.get_hash();

                    if (hash.has(id)) {
                        // Find existing record
                        record = hash.get(id);
                        hash['delete'](id);
                        this.trigger('didDeleteRecord', record, id);
                    }
                },
                // Find and reload all
                _findAndReloadAll: function _findAndReloadAll(recordId) {
                    // Return empty RecordArray
                    var records = _ember['default'].RecordArray.create({
                        klass: this,
                        content: _ember['default'].A([]),
                        hash: this.get_hash()
                    });
                    this.push_records(records, recordId);

                    // Preload existing hash records
                    //records.preload_hash();

                    var adapter = this.get_adapter();

                    // Load Records
                    adapter.findAll(this, records);
                    return records;
                },
                // Find query and reload all
                _findQueryAndReloadAll: function _findQueryAndReloadAll(filter_json, options, recordId) {

                    // Return empty RecordArray
                    var records = _ember['default'].RecordArray.create({
                        klass: this,
                        content: _ember['default'].A([]),
                        filter_json: filter_json,
                        options: options,
                        hash: this.get_hash()
                    });

                    this.push_records(records, recordId);

                    // Preload existing hash records
                    //records.preload_hash();

                    var adapter = this.get_adapter();

                    // Load Records
                    adapter.findQuery(this, records, filter_json, options);

                    return records;
                },
                // Find all in cache
                _findFromCacheAll: function _findFromCacheAll(recordId) {
                    // Return empty RecordArray
                    var records = _ember['default'].RecordArray.create({
                        klass: this,
                        content: _ember['default'].A([]),
                        hash: this.get_hash()
                    });

                    this.push_records(records, recordId);

                    // Preload existing hash records
                    records.preload_hash();

                    //We're calling debounceUpdates once here to make sure that 'isLoaded' will get set to true in the event
                    //that the query doesn't return any records.  Otherwise, it never get's set to true and the promises never resolves.
                    records.debounceUpdate();

                    // Loaded
                    //records.set('isLoaded', true);

                    return records;
                },
                // Find query in cache
                _findQueryFromCache: function _findQueryFromCache(filter_json, options, recordId) {
                    var self = this;

                    // Return empty RecordArray
                    var records = _ember['default'].RecordArray.create({
                        klass: self,
                        content: _ember['default'].A([]),
                        filter_json: filter_json,
                        options: options,
                        hash: this.get_hash()
                    });

                    this.push_records(records, recordId);

                    // Preload existing hash records
                    records.preload_hash();

                    //We're calling debounceUpdates once here to make sure that 'isLoaded' will get set to true in the event
                    //that the query doesn't return any records.  Otherwise, it never get's set to true and the promises never resolves.
                    records.debounceUpdate();

                    // Loaded
                    //records.set('isLoaded', true);

                    return records;
                },

                _createNewRecordArray: function _createNewRecordArray(options) {
                    var defaultOptions = {
                        klass: this,
                        content: _ember['default'].A([]),
                        hash: this.get_hash()
                    };

                    var finalOptions = _.extend(defaultOptions, options);

                    var recordArray = _ember['default'].RecordArray.create(finalOptions);

                    return recordArray;
                }
            });

            /*
             * Store filtered / un - filtered result sets from the hash
             */
            _ember['default'].RecordArray = _ember['default'].ArrayProxy.extend(_ember['default'].RSVPMixin, _ember['default'].Evented, {
                klass: null,
                hash: null,
                debouncer: null,
                filter_json: false,
                options: false,
                isLoaded: false,
                isLoading: _ember['default'].computed.not('isLoaded'),
                ids: _ember['default'].A('content', 'id'),
                _uuids: _ember['default'].A('content', '_uuid'),
                init: function init() {
                    this._super();

                    this.set('recordInsertQueue', {});
                    this.set('recordUpdateQueue', {});

                    this.set('debounceInsert', _.debounce(this.pushRecords, 100));
                    this.set('debounceUpdate', _.debounce(this.updateRecords, 100));

                    //Setup up listeners
                    this.klass.on('didCreateRecord', this, this.insertCheck);
                    this.klass.on('didUpdateRecord', this, this.insertCheck);
                    this.klass.on('didDeleteRecord', this, this.deleteCheck);
                },
                willDestroy: function willDestroy() {
                    this._super();
                    this.klass.off('didCreateRecord', this, this.insertCheck);
                    this.klass.off('didUpdateRecord', this, this.insertCheck);
                    this.klass.off('didDeleteRecord', this, this.deleteCheck);
                    // console.log("DESTROYING " + this.klass.toString());
                },
                preload_hash: function preload_hash() {
                    // Insert current records
                    this.hash.forEach(this.insertCheck, this);
                },
                observeLoaded: _ember['default'].observer('isLoaded', function () {
                    // Once loaded, resolve promise
                    if (this.get('isLoaded')) {
                        this.resolve(this);
                    }
                }),
                hasRecords: _ember['default'].computed(function () {
                    if (this.get('content.length') > 0) {
                        return true;
                    } else {
                        return false;
                    }
                }).property('content.[]', 'isLoaded'),
                filterObject: _ember['default'].computed(function () {
                    // Caches the expanded filter object
                    var filter_json = this.get('filter_json');
                    if (filter_json) {
                        return _ember['default'].Filter.expandFilter(filter_json);
                    } else {
                        return false;
                    }
                }).property('filter'),
                // Flushes any delayed inserts
                flush: function flush() {
                    _ember['default'].run.cancel(this.debouncer);
                    this.pushRecords();
                    return this;
                },
                insertCheck: function insertCheck(record, id) {
                    // Check Filters
                    var filter = this.get('filterObject');
                    if (filter) {
                        var passes = _ember['default'].Filter.matches(record, filter);

                        if (passes) {
                            // Insert matching record
                            this.insertRecord(record, id);
                        } else {
                            // Remove non-matching object
                            if (this.includes(record)) {
                                this.removeObject(record);
                            }
                        }
                    } else {
                        // Insert all records
                        this.insertRecord(record, id);
                    }
                },
                insertRecord: function insertRecord(record, id) {
                    var queue;
                    var record_id = id;
                    // Insert new record
                    if (!this.includes(record) && (_ember['default'].$.inArray(record.get('id'), this.get('ids')) === -1 || record.get('_uuid'))) {
                        queue = this.get('recordInsertQueue');
                        queue[record_id] = record;
                        //this.debouncer = Ember.run.debounce(this, 'pushRecords', 100);
                        this.debounceInsert();

                        //console.log('queueing insert ' + this.klass.toString());
                    } else {
                            // Updated
                            queue = this.get('recordUpdateQueue');
                            queue[record_id] = record;
                            //Ember.run.debounce(this, 'updateRecords', 100);
                            this.debounceUpdate();
                        }
                },

                pushRecords: function pushRecords() {
                    if (!(this.isDestroyed || this.isDestroying)) {
                        //var self = this;
                        var queue = _.toArray(this.get('recordInsertQueue'));
                        var left = queue.length; //How many object we should push given the limit set, and howmany already exist.

                        //console.log('inserting ' + this.klass.toString() + " " + queue.length);

                        // Check limit
                        var limit = this.get('options.limit');
                        if (limit) {
                            var length = this.get('length');
                            if (length >= limit) {
                                // Array is full
                                return false;
                            }
                            left = limit - length;
                        }

                        this.pushObjects(queue.slice(0, left));

                        this.set('isLoaded', true);

                        //empty the queue
                        this.set('recordInsertQueue', {});
                    }
                },
                updateRecords: function updateRecords() {
                    // this.set('updateCount', 0);
                    if (!(this.isDestroyed || this.isDestroying)) {

                        var queue = _.flatten(_.toArray(this.get('recordUpdateQueue')));
                        this.set('recordUpdateQueue', {});

                        //console.log('updating ' + this.klass.toString() + " " + queue.length);

                        _.each(queue, function (record) {
                            //WHY ARE WE DOING THIS HERE????
                            //self.trigger('didUpdateRecord', record.get('id'), record);

                            record.set('isLoaded', true);
                            //computed properties that rely on database calls will need init() to be called in order to properly update.
                            record.init();
                        });

                        this.set('isLoaded', true);
                    }
                },
                deleteCheck: function deleteCheck(record) {
                    //pass id if needed
                    // Remove deleted object
                    if (this.includes(record)) {
                        this.removeObject(record);
                        //this.trigger('didDeleteRecord', id, record);
                    }
                }
            });

            _ember['default'].RestAdapter.reopen({
                api_endpoint: _impactAdminConfigEnvironment['default'].api.url
            });

            // Ember.RestAdapterBatch.set('api_endpoint', config.api.url);

            _ember['default'].SocketIO.pusher_api = _impactAdminConfigEnvironment['default'].pusherKey;
            _ember['default'].SocketIO.pusher_auth_endpoint = _impactAdminConfigEnvironment['default'].api.url + '/pusher/auth';
        }
    };
});
/* global _ */