define('impact-admin/models/volunteer-opportunity-skills', ['exports', 'ember', 'impact-admin/models/volunteer-skills'], function (exports, _ember, _impactAdminModelsVolunteerSkills) {

    var VolunteerOpportunitySkills = _ember['default'].Model.extend({
        skill: _ember['default'].computed('skill_id', function () {
            return _impactAdminModelsVolunteerSkills['default'].find(this.get('skill_id'));
        })
    });

    VolunteerOpportunitySkills.table = 'volunteer_opportunity_skills';

    exports['default'] = VolunteerOpportunitySkills;
});