define('impact-admin/controllers/frame/manage', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        /**
         * Current filter object
         */
        filters: {},

        /**
         * Filters the list of organizations based on an object of filter
         * properties and settings.
         */

        archived: _ember['default'].computed('filters', function () {
            var filters = this.get('filters');
            return filters.charityStatus === 'deactivated';
        }),

        archived_orgs: (function () {
            var _this = this;

            var orgs = this.get('archived_organizations'),
                filters = this.get('filters'),
                result = orgs;

            _.each(filters, function (v, k) {
                var resolver = 'match' + k.capitalize();

                if (_.isFunction(_this[resolver])) {
                    result = result.filter(_this[resolver], {
                        filter: v
                    });
                }
            });

            return result;
        }).property('filters', 'organizations'),

        orgs: (function () {
            var _this2 = this;

            var orgs = this.get('organizations'),
                filters = this.get('filters'),
                result = orgs;

            _.each(filters, function (v, k) {
                var resolver = 'match' + k.capitalize();

                if (_.isFunction(_this2[resolver])) {
                    result = result.filter(_this2[resolver], {
                        filter: v
                    });
                }
            });

            return result;
        }).property('filters', 'organizations'),

        /**
         * Compares query string to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchQuery: function matchQuery(item) {
            // Filter the name just on alphanumeric and spaces (so a search for "Childrens" would result in matches for "Childrens" and "Children's")
            var sanitizedNameFilter = this.filter.trim().replace(/[^\w\s]/gi, '').toUpperCase();
            var sanitizedOrgName = item.name.replace(/[^\w\s]/gi, '').toUpperCase();
            return sanitizedOrgName.indexOf(sanitizedNameFilter) > -1;
        },

        /**
         * Compares membership id to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchMembership: function matchMembership(item) {
            var s = item.get('membership.plan_name');
            return !_ember['default'].isEmpty(s) && s === (this.filter === 'basic' ? 'Basic Membership' : 'Premium Membership');
        },

        /**
         * Compares profile status to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchGeneralProfileApproved: function matchGeneralProfileApproved(item) {
            var s = item.get('profile_statuses.general_profile_approved');
            return !_ember['default'].isEmpty(s) && s === (this.filter === 'approved');
        },

        /**
         * Compares ghd profile status to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchGhdProfileApproved: function matchGhdProfileApproved(item) {
            var s = item.get('profile_statuses.ghd_profile_approved');
            return !_ember['default'].isEmpty(s) && s === (this.filter === 'approved');
        },

        /**
         * Compares GivingHeartsDay status to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchGhdAccess: function matchGhdAccess(item) {
            var s = item.get('ghd_access');
            return !_ember['default'].isEmpty(s) && s === (this.filter === 'yes');
        },

        /**
         * Compares type id to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchType: function matchType(item) {
            var types = item.get('types');
            return types.findBy('id', +this.filter) !== undefined;
        },

        /**
         * Compares budget string to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchBudget: function matchBudget(item) {
            return item.get('operating_budget') === (this.filter === 'over' ? 'under_five_hundred_thousand' : 'over_five_hundred_thousand');
        },

        /**
         * Compares zipcode to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchZipcode: function matchZipcode(item) {
            var z = "" + item.get('street_postal_code');
            return z.indexOf("" + this.filter) > -1;
        },

        /**
         * Compares state id to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchStateName: function matchStateName(item) {
            return ("" + item.get('street_state')).toLowerCase() === ("" + this.filter).toLowerCase();
        },

        /**
         * Compares charity status to object property
         *
         * @param item
         *
         * @returns {boolean}
         */
        matchCharityStatus: function matchCharityStatus(item) {
            return ("" + item.get('status')).toLowerCase() === ("" + this.filter).toLowerCase();
        },

        actions: {
            /**
             * Updates the filter object with a passed object
             *
             * @param filters {*}
             *
             * @return void
             */
            updateFilters: function updateFilters(filters) {
                //let current_filters = this.get('filters');
                //if (_.isEqual(current_filters, filters)) {
                //    // do nothing
                //} else {
                this.set('filters', filters || {});
                //}
            }
        }
    });
});
/* global _ */