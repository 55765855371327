define('impact-admin/controllers/frame/forms/index', ['exports', 'ember', 'impact-admin/models/forms', 'impact-admin/models/form-elements'], function (exports, _ember, _impactAdminModelsForms, _impactAdminModelsFormElements) {
    //import moment from 'moment';

    exports['default'] = _ember['default'].Controller.extend({
        application: _ember['default'].inject.controller(),
        isImpact: _ember['default'].computed.alias('application.isImpact'),
        onlyShowActive: true,

        actions: {

            duplicateForm: function duplicateForm(originalForm) {
                var _this = this;

                var formCopy = _impactAdminModelsForms['default'].createRecord(JSON.parse(JSON.stringify(originalForm))).content;

                delete formCopy.id;

                formCopy.set('title', formCopy.get('title') + " (Copy)");
                formCopy.set('total_submissions', 0);

                formCopy.save().then(function (json) {
                    var newForm = json.response.result[0];

                    _impactAdminModelsFormElements['default'].find({
                        form_id: originalForm.id
                    }).then(function (json) {

                        var formElementsToCopy = json.content;
                        var formElementCopyPromises = formElementsToCopy.map(function (form_element) {

                            var newFormElement = _impactAdminModelsFormElements['default'].createRecord(JSON.parse(JSON.stringify(form_element))).content;
                            delete newFormElement.id;
                            newFormElement.set('form_id', newForm.id);

                            return newFormElement.save();
                        });

                        _ember['default'].RSVP.Promise.all(formElementCopyPromises).then(function (result) {
                            var success = result;
                            console.log(success);
                            _this.growl.success('Saved');
                        });

                        _this.growl.success('Form was saved.');
                    });
                })['catch'](function (json) {
                    _this.growl.error('Form could not be duplicated at this time. Please try again.');
                    console.log(json);
                });
            },

            addNew: function addNew() {
                this.set('newForm', _impactAdminModelsForms['default'].createRecord().content);
            },
            saveNewForm: function saveNewForm(form) {
                var _this2 = this;

                if (form.isReallyDirty()) {

                    form.save().then(function () {
                        _this2.growl.success('Form was saved.');
                        _this2.set('newForm', null);
                    })['catch'](function (json) {
                        _this2.growl.error('Form could not be create at this time. Please try again.');
                        console.log(json);
                    });
                } else {
                    this.set('editMode', false);
                }
            },
            cancelAddNew: function cancelAddNew() {
                this.set('newForm', null);
            },
            updateForm: function updateForm(form) {
                var _this3 = this;

                console.log('Update');
                console.log(form);

                if (form.isReallyDirty()) {
                    form.save().then(function () {
                        _this3.growl.success('Form was saved.');
                    })['catch'](function (json) {
                        _this3.growl.error('Oh no. Something went wrong. Please try again.');
                        console.log(json);
                    });
                } else {
                    this.growl.warning('Nothing to change');
                }
            },
            toggleOnlyShowActive: function toggleOnlyShowActive() {
                this.set('onlyShowActive', !this.onlyShowActive);
                var allFormsCopy = JSON.parse(JSON.stringify(this.allForms));
                if (this.onlyShowActive) {
                    var activeForms = allFormsCopy.filter(function (form) {
                        return form.is_active;
                    });
                    this.set('forms', activeForms);
                } else {
                    this.set('forms', allFormsCopy);
                }
            },
            removeElement: function removeElement(selectedForm) {
                var _this4 = this;

                console.log('Removing');

                if (confirm('Are you sure you want to delete this form?')) {
                    selectedForm.deleteRecord().then(function (json) {
                        if (json.response.error) {
                            _this4.growl.error('Error!');
                            console.log(json);
                        }
                    })['catch'](function (json) {
                        _this4.growl.error('Error Apparent!');
                        console.log(json);
                    });
                }
            }

        }
    });
});