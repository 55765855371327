define('impact-admin/components/ghd-resource-group', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    categoryResources: _ember['default'].computed('', function () {
      var _this = this;

      return this.get('resources').filter(function (resource) {
        return resource.category === _this.get('category');
      });
    })
  });
});