define('impact-admin/components/transloadit-form', ['exports', 'ember'], function (exports, _ember) {

    /*
     * Transloadit Form Component
     *
     * Embed a form with Transloadit upload processing included.
     *
     * @param {Object} blog: the blog uploading the file
     * @param {String} action: the action to execute after processing is complete
     */
    exports['default'] = _ember['default'].Component.extend({
        loadTransloadit: (function () {
            var self = this;
            var form = this.$().find('form');
            var template_id = this.get('template_id');

            form.transloadit({
                wait: true,
                autoSubmit: false,
                triggerUploadOnFileSelection: false,
                processZeroFiles: false,
                params: {
                    "auth": {
                        "key": "2fbd6575d1c844d2b664c17126d34e70"
                    },
                    "template_id": template_id
                },
                onFileSelect: function onFileSelect(filename) {
                    // Remove weird filepath that Transloadit adds
                    filename = filename.replace("C:\\fakepath\\", '');

                    // Check file type
                    if (!self.isImage(filename)) {
                        _ember['default'].run.debounce(this, function () {
                            self.growl.error('File is not a valid image type');
                        }, 1000);
                    } else {
                        form.submit();
                    }
                },
                onStart: function onStart() {
                    //console.log(assembly);
                },
                onCancel: function onCancel() {
                    self.growl.warning('File upload cancelled');
                },
                //onSuccess() returns the results from transload-it
                onSuccess: function onSuccess(assembly) {
                    if (assembly && assembly.results && assembly.results['public']) {
                        var result = assembly.results['public'][0];
                        self.set('value', result.ssl_url);
                        var action = self.get('my_action');
                        if (action) {
                            self.sendAction('my_action', result);
                        }
                    } else {
                        self.growl.warning('Invalid file type. File was not saved.');
                    }
                },
                onError: function onError() {
                    //assembly can be accessed here. It is passed to this function
                    self.growl.warning('There was an error while uploading this file. Make sure that it is a proper image type.');
                }
            });
        }).on('didInsertElement'),

        /*
         * Determines if a filename is that of an image
         *
         * @param {String} file: a filename
         * @return {Boolean}
         */
        isImage: function isImage(file) {
            var ext = file.split('.').pop().toLowerCase();
            var type = '';
            var extOptions = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];

            for (var i = 0; i < extOptions.length; i++) {
                if (extOptions[i] === ext) {
                    type = 'image';
                    break;
                }
            }

            return type === 'image';
        }
    });
});