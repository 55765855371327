define('impact-admin/helpers/format-dollars', ['exports', 'ember', 'numeral'], function (exports, _ember, _numeral) {
  exports.formatDollars = formatDollars;

  function formatDollars(params) {
    var dollars = params[0];
    if (!dollars) {
      return "0.00";
    }
    dollars = (0, _numeral['default'])(dollars.toFixed()).format('0,0.00');
    return dollars.htmlSafe();
  }

  exports['default'] = _ember['default'].Helper.helper(formatDollars);
});