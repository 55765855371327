define('impact-admin/routes/frame/prepaid-codes/new', ['exports', 'ember', 'impact-admin/models/prepaid-code-groups', 'impact-admin/models/organizations', 'moment'], function (exports, _ember, _impactAdminModelsPrepaidCodeGroups, _impactAdminModelsOrganizations, _moment) {
    exports['default'] = _ember['default'].Route.extend({
        beforeModel: function beforeModel() {
            //Only impact is allowed in here
            if (!this.controllerFor('application').get('isImpact')) {
                this.transitionTo('frame.dashboard');
            }
        },

        model: function model() {
            return _ember['default'].RSVP.hash({
                physical_card_groups: _impactAdminModelsPrepaidCodeGroups['default'].find({
                    physical_cards: true,
                    end_date: {
                        '>=': (0, _moment['default'])().local().format()
                    }
                }),
                dmf_physical_card_groups: _impactAdminModelsPrepaidCodeGroups['default'].find({
                    dmf_physical_cards: true,
                    end_date: {
                        '>=': (0, _moment['default'])().local().format()
                    }
                }),
                organizations: _impactAdminModelsOrganizations['default'].find({
                    status: 'active'
                })
            });
        }

    });
});
// setupController: function(controller) {
//     controller.reset();
// }